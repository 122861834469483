import React, { Component } from 'react'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
export const a2pay = require('./a2pay.png');
import {
  getStorageData,
} from "../../framework/src/Utilities";
// import { baseURL } from "../../blocks/bulkuploading/src/BulkUploadingController";
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { styled } from '@material-ui/core';
export const DashboardDeActive = require('./home.png')
export const DashboardActive = require('./home_active.svg')
export const ProfileActive = require('./profile_active.svg')
export const NotificationActive = require('./active_noti.svg')
export const SearchActive = require('./Searchactive.png')


const Customdiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  alignItems: 'center',
  width: '150px',
  // height: '96vh',
  padding: '20px 0px',
  '@media(max-width: 992px)': {
    display: 'none'
  }
})

export default class SideNavbar extends Component {
  profileId = "";
  state = {
    additionalPrice: "",
    isMobileView: false,
    currentPath: window.location.pathname,
    activeSection: this.getActiveSectionFromPath(window.location.pathname),
  };

  async componentDidMount() {
    try {
      const profileImageOfBo = await getStorageData("profileImageOfBo");
      const additionalPrice = JSON.parse(profileImageOfBo);
      this.setState({ additionalPrice });
    } catch (error) {
      console.error("Error fetching profile image:", error);
    }

    // Set up event listener for window resizing
    window.addEventListener("resize", this.handleResize);

    // Check if it's mobile view on initial load
    this.handleResize();

    // Add event listener for path changes
    window.addEventListener("popstate", this.handlePathChange);
  }

  componentDidUpdate() {
    if (window.location.href.includes("BownerDetaild")) {
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("popstate", this.handlePathChange);
  }

  // Original handleResize method
  handleResize = () => {
    // Check if window width is less than 600px
    const isMobileView = window.innerWidth < 600;
    this.setState({ isMobileView });
  };

  handlePathChange = () => {
    const newPath = window.location.pathname;
    if (newPath !== this.state.currentPath) {
      this.setState({
        currentPath: newPath,
        activeSection: this.getActiveSectionFromPath(newPath),
      });
    }
  };

  getActiveSectionFromPath(path: string): string {
    if (path === '/Dashboard') return 'dashboard';
    if (path === '/Notifications') return 'notifications';
    if (['/AddMyStores', '/AddOtherStore', '/StoreSettings', '/UserProfile', '/BillingSettings', '/OperatorSettings', '/RegisterNewOperator'].some(p => path === p || path.includes(p))) {
      return 'profile';
    }
    return '';
  }

  handleNavClick = (section: string) => {
    this.setState({ activeSection: section });
  };

  renderNavItem(to: string, activeIcon: string, inactiveIcon: React.ReactNode, label: string, section: string) {
    const isActive = this.state.activeSection === section;
    return (
      <Link
        to={to}
        style={{
        all:"unset",
        cursor:"pointer"
        }}
        onClick={() => this.handleNavClick(section)}
      >
        <div style={isActive ? webStyle.activeButton : webStyle.deActiveButton}>
          {isActive ? (
            <img alt={label} src={activeIcon} />
          ) : (
            inactiveIcon
          )}
          <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins" }}>{label}</span>
        </div>
      </Link>
    );
  }

  render() {
    const { currentPath } = this.state;

    return (
      <>
      <Customdiv style={{ position: "fixed", top: 0, background: "white", minHeight: "100vh" }}>
        <div style={{ cursor: "pointer" }}>
          <Link to="/Dashboard">
            <img alt="logo" src={a2pay} style={{ width: "84px", height: "41px", marginTop: 20 }} />
          </Link>
        </div>
        <div style={{ marginTop: "150px" }}>
          <div style={{ marginBottom: "40px" }}>
            {this.renderNavItem(
              "/Dashboard",
              DashboardActive,
              <img alt="Dashboard" src={DashboardDeActive} />,
              "Dashboard",
              "dashboard"
            )}
          </div>
          <div style={{ marginBottom: "40px" }}>
            {this.renderNavItem(
              "/Notifications",
              NotificationActive,
              <NotificationsNoneIcon style={{ width: "24px", height: "24px" }} />,
              "Notification",
              "notifications"
            )}
          </div>
          <div>
            {this.renderNavItem(
              "/UserProfile",
              ProfileActive,
              <PersonOutlineIcon style={{ width: "24px", height: "24px" }} />,
              "Profile",
              "profile"
            )}
          </div>
        </div>
      </Customdiv>
      </>
    );
  }
}




const webStyle = {
  activeButton:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    color:'#c12030'
  } as React.CSSProperties,
  deActiveButton:{  
    alignItems:'center',
    display:'flex',
    flexDirection:'column',
    color:'#848FAC',
    justifyContent:'center',
  } as React.CSSProperties,
} 