import React from "react";

import {
    Box,
    Typography,
    styled,
    Button
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { Toaster } from "react-hot-toast"
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import {  leftArrow } from "./assets"

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

export default class AddOperatorSettingsOp extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

render() {
    const renderInputFieldOper = (name:any, label:any) => (
        <Box style={webStyleOper.operatorfieldsMainOper as React.CSSProperties}>
            <label style={webStyleOper.operatorlabelStyleOper as React.CSSProperties}>{label}</label>
            <input
               type="text"
                name={name}
                style={{
                    MozAppearance:"textfield",
                    ...webStyleOper.operatorinputStyleAddOper,
                    borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                }}
                onChange={this.handleInputChanges2}
            />
            {this.state.requiredInput.includes(name) && <div style={{ color: 'red', marginTop: '5px' }}>{`${label} is required`}</div>}
        </Box>
    );
    const renderPhoneFieldOper = (name:any, label:any) => (
        <Box style={webStyleOper.operatorfieldsMainOper as React.CSSProperties}>
            <label style={webStyleOper.operatorlabelStyleOper as React.CSSProperties}>{label}</label>
            <input
               type="text"
                name={name}
                style={{
                    MozAppearance:"textfield",
                    ...webStyleOper.operatorinputStyleAddOper,
                    borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                }}
                onChange={this.handlePhoneNumberOpChange}
                value={this.state.phoneNumberOp}
            />
            {!this.state.isPhoneNumberValid && <div style={{ color: 'red', marginTop: '5px',fontFamily:"poppins",fontSize:"12px",fontWeight:400   }}>Invalid Phone Number</div>}
        </Box>
    );
    const renderEmailFieldOper = (name:any, label:any) => (
        <Box style={webStyleOper.operatorfieldsMainOper as React.CSSProperties}>
            <label style={webStyleOper.operatorlabelStyleOper as React.CSSProperties}>{label}</label>
            <input
               type="text"
                name={name}
                style={{
                    MozAppearance:"textfield",
                    ...webStyleOper.operatorinputStyleAddOper,
                    borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                }}
                onChange={this.handleEmailOpChange}
                value={this.state.emailAddressOp}
            />
            {this.state.errorMessage && <div style={{ color: 'red', marginTop: '5px',fontFamily:"poppins",fontSize:"12px",fontWeight:400   }}>{this.state.errorMessage}</div>}
        </Box>
    );
    return (
        // Customizable Area Start
        <Box style={webStyleOper.operatorSettingContainerOper }>
        <OperatorSideBar />
        <RightBoxLanding style={webStyleOper.OperatorMainOper as React.CSSProperties}>
            <Box style={webStyleOper.operatordrawerBoxOper}>
                <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
            </Box>
            <OperatorHeader />
            <Box style={{ padding: "10px 0 0 40px", display: 'flex', cursor:"pointer" }} onClick={this.navigateToAddOperatorOp}>
                <img src={leftArrow} style={{ width: '24px', height: '24px' }} />
                <Typography style={{ fontFamily: 'Poppins',fontWeight: 500, color: '#000', fontSize: '16px',paddingLeft: 8 }}>Back</Typography>
            </Box>
            <Box style={webStyleOper.operatorSettingFeildsMianOper}>
                <Box style={webStyleOper.operatorSettingTextMainOper}>
                    <Typography style={webStyleOper.operatorSettingTextOper}>
                    Operator Settings
                    </Typography>
                </Box>

                {renderInputFieldOper("nameOp", "Name & Surname",)}
                {renderInputFieldOper("passportId", "ID/Passport", )}
                {renderInputFieldOper("citizenStatus", "Citizen Status", )}
                {renderInputFieldOper("storeName", "Store Name", )}
                {renderInputFieldOper("address", "Address", )}
                {renderInputFieldOper("coordinates", "Coordinates",)}
                {renderPhoneFieldOper("phoneNumberOp", "Phone Number", )}
                {renderInputFieldOper("nextOfKinOp", "Next of Kin", )}
                {renderEmailFieldOper("emailAddressOp", "Email Address", )}
                {renderInputFieldOper("ethnicity", "Ethnicity", )}
                {renderInputFieldOper("gender", "Gender",)}
                {renderInputFieldOper("positions", "Position")}

                <Button onClick={this.handleAddOperator}  style={webStyleOper.operatorSubmitOper as React.CSSProperties}>
                    Submit
                </Button>
            </Box>
            <Toaster position="top-center" />
            
        </RightBoxLanding>
    </Box>
        // Customizable Area End
    );
}
}

// Customizable Area Start
const webStyleOper = {

    operatorSettingContainerOper: {
        backgroundColor: "#E2E8F0"
    }as React.CSSProperties,

    operatorSettingFeildsMianOper: {
        maxWidth: "610px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        padding: "20px",
        margin: "40px",
    }as React.CSSProperties,

    operatorSettingTextMainOper: {
        marginBottom: "40px"
    },

    operatorSettingTextOper: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        color: '#000000',
        lineHeight: '40px',
        fontSize: '20px'
    },

    // div styling
    divOper: {
        display: 'block'
    },

    operatorfieldsMainOper: {
        display: "flex",
        maxWidth: '100%',
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },

    settingTabMainOper: {
        alignItems: "center",
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
        padding: "20px 40px",

    },

    activesettingTabOper: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },

    activesettingTab2Oper: {
        backgroundColor: "rgba(193, 32, 48, 0.03)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        textAlign: "center",
        width: "100%",
        cursor: "pointer",
        borderBottom: "2px solid #C12030"
    },

    settingTabOper: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },

    settingTab2Oper: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        borderRadius: "16px",
        padding: "15px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },


    operatordrawerBoxOper:{ width: '100%' },


    operatorSubmitOper: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        fontFamily: 'Poppins',
    },


    OperatorMainOper:
        {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } ,

    greentickModal2Oper: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    // label styling
    operatorlabelStyleOper: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },

    // input styling
    operatorinputStyleAddOper: {
        color: '#273567',
        top: '26px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        padding: "15px",
        height: '40px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },

    // coordinate-box styling
    cordinateBoxOper: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%'
    },

    captureIconOper: {
        width: 'auto',
        right: '10',
        height: '30px',
        top: '30',
        position: 'absolute',
    },

}
// Customizable Area End