import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  TableHead,
  TableRow,
  TableCell,
  RadioGroup,
  FormControlLabel,
  TableBody,
  Slider,
  ThemeProvider,
  Typography,
  Modal,
  styled,
  Paper,
  TableContainer,
  Table,
  withStyles,
  Tooltip,
  createTheme,
  TableSortLabel,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, downIcon, printIcon, check, checkBox, selectedCheckbox } from "./assets";
import StyleRadio from '../../../components/src/StyleRadio.web';
import { Pagination } from "@material-ui/lab";
import CancelIcon from '@material-ui/icons/Cancel';
import { configJSON } from "./MultipageFormsController";

const MultipageFormBoxLanding = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const MultipageBackMain = styled(Box)({
  display: 'flex',
  margin: "10px 0"
})
const SubFilterBox = styled(Box)({
  '@media(max-width: 400px)': {
    width: "200px !important"
  }
})

const FilterBox = styled(Box)({
  '@media(max-width: 375px)': {
    marginTop: '-40px'
  }
})
const SortFilterBox = styled(Box)({
  '@media(max-width: 375px)': {
    width: '280px',

  }
})
const MultipageBox = styled(Box)({
  display: 'flex',
  flexWrap: "wrap",
  gap: '8px',
  backgroundColor: "#FFFFFF",
  padding: "17px 24px 17px",
  borderRadius: "4px",
  justifyContent: 'space-between',
  margin: "20px 0",
  maxWidth: "100%",

})
const MultipageWallet = styled(Typography)({
  fontFamily: 'Poppins',
  color: '#000',
  fontWeight: 600,
  fontSize: '20px',
  alignSelf: 'center',
  // padding: '10px 16px',
  '@media(max-width: 600px)': {
    padding: '0px'
  }
})
const MultipageCustomBox = styled(Box)({
  display: 'flex',
  padding: '0 40px 20px',
  gap: 16,

  '@media(max-width: 600px)': {
    justifyContent: 'center',
    marginLeft: '0px',
  }
})
const MultipageFilterText = styled(Typography)({
  '@media(max-width: 600px)': {
    marginLeft: 'unset !important'
  }


})
const CustomWalletBoxMultipage = styled(Box)({
  position: "relative",
  '@media(max-width: 450px)': {
    flexWrap: "wrap"
  }
})
const DateAndTime = styled(Box)({

  '@media(max-width: 600px)': {
    width: '200px'
  }
})
const MainFilterText = styled(Typography)({
  '@media(max-width: 600px)': {
    fontSize: '16px'
  }
})
const MultipageCustomButton2 = styled(Button)({
  alignItems: 'center',
  border: '1px solid #C12030',
  borderRadius: '8px',
  maxWidth: '159px',
  width: '100%',
  height: '44px',
  '@media(max-width: 600px)': {
    maxWidth: '130px'
  }
})
const FileSelectMultipage = styled(FormControlLabel)({
  margin: "auto !important",
  "& .MuiFormControlLabel-root": {
    margin: "auto !important"
  }
})
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#F3D2D6',
    marginTop: "10px"
  },
})(Tooltip);

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#C12030",
      },
      rail: {
        color: 'pink'
      },
      track: {
        color: 'red'
      },

    },
  },

});

interface Data {
  dateAndTime: string;
  transactionType: string;
  transactionStatus: string;
  transactionValue: any;
  loadID: any
}

interface Column {
  id:
  | "loadID"
  | "dateAndTime"
  | "transactionType"
  | "transactionStatus"
  | "transactionValue"

  label: string;
  minWidth?: number;
  align?: "right";
  sortable: boolean;
}

const providercolumns: Column[] = [
  { id: "loadID", label: "Loan ID", sortable: false },
  { id: "dateAndTime", label: "Date", sortable: true },
  { id: "transactionType", label: "Transaction Type", sortable: false },
  { id: "transactionStatus", label: "Transaction Status", sortable: false },
  { id: "transactionValue", label: "Transaction Value", sortable: true },

];


const providerrows: Data[] = [
  {
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionStatus: "",
    transactionValue: "R500",
  },
  {
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionValue: "R500",
    transactionStatus: "",
  },
  {
    transactionType: "Transaction Type",
    transactionValue: "R500",
    transactionStatus: "",
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
  },
  {
    transactionValue: "R500",
    transactionStatus: "",
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
  },
  {
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionValue: "R500",
    transactionStatus: "",
  },
  {
    loadID: '125CCC4444',
    transactionType: "Transaction Type",
    transactionValue: "R500",
    transactionStatus: "",
    dateAndTime: "20 FEB 2024 | 10:30",
  },

  {
    transactionType: "Transaction Type",
    transactionValue: "R500",
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionStatus: "",
  },
  {

    transactionValue: "R500",
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionStatus: "",
  },
  {
    transactionType: "Transaction Type",
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionValue: "R500",
    transactionStatus: "",
  },
  {
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionValue: "R500",
    loadID: '125CCC4444',
    transactionStatus: "",
  },
  {
    loadID: '125CCC4444',
    dateAndTime: "20 FEB 2024 | 10:30",
    transactionType: "Transaction Type",
    transactionValue: "R500",
    transactionStatus: "",
  },

];

const ButtonStyle = styled(Button)({
  "@media (max-width: 450px)": {
    maxWidth: "159px !important"
  }
});

const DateBox = styled(Box)({
  "@media (max-width: 400px)": {
    marginLeft: "43px !important"
  }
});

const ValueSlider = styled(Slider)({
  "@media (max-width: 400px)": {
    marginLeft: "40px !important",
    width: "144px !important"
  }
});

const LabelDiv = styled('div')({
  "@media (max-width: 400px)": {
    marginLeft: "30px !important",
    width: "188px !important"
  }
});

const SortBox = styled(Box)({
  "@media (max-width: 468px)": {
    width: "100% !important"
  },
  "@media (max-width: 450px)": {
    top: "109 !important",
    left: "0 !important"
  }
});

const SortLabel = styled('label')({
  whiteSpace: "nowrap",
  "@media (max-width: 468px)": {
    whiteSpace: "normal"
  }
});

const CellDiv = styled('div')({
  whiteSpace: "normal",
  width: "100%",
  wordWrap: "break-word"
});

// Customizable Area End

import MultipageFormsController, {
  Props,
} from "./MultipageFormsController";

export default class MultipageForms extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  ValueLabelComponent(props: any) {
    const { children, open, value } = props;
    return (
      <CustomTooltip open={open} interactive enterTouchDelay={0} title={value} >
        {children}
      </CustomTooltip>
    );
  }
  rangeSliderFunction = () => {
    return (

      <ThemeProvider theme={muiTheme} >
        <ValueSlider
          data-test-id="valueSliderTestID"
          id='dateModal'
          className="dateModal"
          value={this.state.values}
          onChange={this.handleSliderChange}
          aria-labelledby="discrete-slider-always"
          min={0}
          max={10000}
          valueLabelDisplay='on'
          ValueLabelComponent={this.ValueLabelComponent}
          style={webStyle.sliderstyle}
          valueLabelFormat={(value) => {
            return (
              <div id='dateModal' className="dateModal" style={webStyle.valueLabelStyle} >
                {`R${value}`}
              </div>
            );
          }}
        />

        <LabelDiv style={webStyle.rangeFilterMainDiv} id='dateModal' className="dateModal">
          <Typography id='dateModal' className="dateModal" style={webStyle.filterTextStyle}>R0</Typography>
          <Typography style={webStyle.valueTextStyle} id='dateModal' className="dateModal">R10000</Typography>
        </LabelDiv>
      </ThemeProvider>

    )
  }



  handleSortFilterValue = (title: string, value: boolean, testId: string, order: string) => {
    return (
      <Box id='dateModal' className="dateModal" style={{ ...webStyle.labelBoxStyle, backgroundColor: this.state.orderBy === "transactionValue" && value ? '#CDF0EE' : 'white', cursor: "pointer" }} onClick={() => this.handleSortFilterLoanData(order)} data-test-id={testId}>
        <img src={check} style={{ ...webStyle.checkImageStyle, visibility: this.state.orderBy === "transactionValue" && value ? "visible" : "hidden" } as React.CSSProperties} id='dateModal' className="dateModal" />
        <SortLabel id='dateModal' className="dateModal" style={webStyle.labelStyle as React.CSSProperties}>{title}</SortLabel>
      </Box>
    )
  }

  sortFilter = () => {
    return (
      <SortBox style={webStyle.sortFilterBox} id='dateModal' className="dateModal">
        <SortFilterBox sx={webStyle.inputBoxMultipageStyle} id='dateModal' className="dateModal">
          {this.handleSortFilterValue('Sort by Ascending to Descending', this.state.selectAsendingS, 'handleSortFilterAcending', "desc")}
          {this.handleSortFilterValue('Sort by Descending to Ascending', !this.state.selectAsendingS, 'handleSortFilterDecending', "asc")}
        </SortFilterBox>
      </SortBox>
    )
  }

  renderRemainingCell = (columnid: string, value: string) => {
    return (
      columnid === "transactionValue" ?
        <>
          <span style={webStyle.textR}>R</span>
          <span>{value.slice(1)}</span>
        </> : this.renderStatusView(value)
    )
  };

  renderStatusView = (value: string) => {
    return (
      <>
        {value === "COMPLETED" ? (
          <Box style={webStyle.inPregressBox}>
            <span style={webStyle.inPregressText}>{value}</span>
          </Box>
        ) : (
          <>
            {value === "PENDING" ? (
              <Box style={{ ...webStyle.inPregressBox, backgroundColor: '#FEF3C7' }}>
                <span style={{ ...webStyle.inPregressText, color: "#F59E0B" }}>{value}</span>
              </Box>
            ) : (
              <Box style={{ ...webStyle.inPregressBox, backgroundColor: '#FEE2E2' }}>
                <span style={{ ...webStyle.inPregressText, color: "#DC2626" }}>{value}</span>
              </Box>
            )}
          </>
        )}
      </>
    );
  };
  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;
    return (
      // Customizable Area Start
      <Box
        style={webStyle.userSettingContainer}
        data-test-id={'handleBodyClickOnScreen'}
        onClick={(event) => this.handleMultipageFormBodyClickOnScreen(event)}
      >
        <SideNavbar />
        <MultipageFormBoxLanding style={webStyle.providerSecondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>
          <Box style={{ padding: "0px 40px 40px 40px" }}>
            <MultipageBackMain>
              <img src={leftArrow} onClick={this.handleLandingPageFirst} style={webStyle.backArrow} />
              <Typography onClick={this.handleLandingPageFirst} style={webStyle.backText}>Back</Typography>
            </MultipageBackMain>
            <MultipageBox>
              <MultipageWallet>
                Loan
              </MultipageWallet>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: "10px" }}>
                <MultipageFilterText style={webStyle.filterbyText as React.CSSProperties}>
                  Filter by:
                </MultipageFilterText>
                <CustomWalletBoxMultipage style={{ display: 'flex', gap: 16, alignSelf: 'center' }} id='dateModal' className="dateModal">
                  <ButtonStyle
                    style={webStyle.timelinebuttonStyle}
                    onClick={this.SortByCategoryModal} id='dateModal'
                    className="dateModal"
                    data-test-id={'SortByCategoryModalId'}
                  >
                    <div
                      style={
                        {
                          ...webStyle.providerCatogoryBox,
                          margin: this.state.selectedMainFilter === "Transaction" ? "0 26px 0 28px" : "0 16px 0 20px"
                        }}
                      id='dateModal'
                      className="dateModal"
                    >
                      <MainFilterText style={webStyle.timelineText} id='dateModal' className="dateModal">{this.state.selectedMainFilter}</MainFilterText>
                      <img src={downIcon} id='dateModal' className="dateModal" style={{ marginLeft: '10px' }} />
                    </div>
                  </ButtonStyle>
                  <Button style={webStyle.providerTimelinebutton2} onClick={() => this.SortByModal()} id='dateModal' className="dateModal" data-test-id={'filterSortBy'}>
                    <div style={webStyle.sortBoxStyle} id='dateModal' className="dateModal">
                      <MainFilterText style={{ ...webStyle.timelineText, whiteSpace: 'nowrap' }} id='dateModal' className="dateModal">
                        Sort <span style={{ textTransform: 'lowercase' }} id='dateModal' className="dateModal">by</span>
                      </MainFilterText>
                      <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' className="dateModal" />
                    </div>
                  </Button>
                  {this.state.isOpenDateModal && this.sortFilter()}
                  {this.state.showmainFilter &&
                    <SubFilterBox
                      data-test-id="fiterMenuTestID"
                      id='dateModal' className="dateModal"
                      style={webStyle.subFilterBoxStyle}
                    >
                      <div style={webStyle.catogoryFilterMainDiv} id='dateModal' className="dateModal">
                        {this.state.mainFilter.map((item: any, index: any) => (
                          <div key={index} id='dateModal' className="dateModal" >
                            <div data-test-id={`filterOption${index}`} onClick={() => this.handleMultipageMainFilterChange(index)} style={webStyle.filterDiv} className="dateModal" id='dateModal'>
                              <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" style={webStyle.checkImage} data-test-id={`testIdSelectCategoryFilter${index}`} />
                              <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" data-test-id={`testIdSelectCategoryFilterText${index}`}>{item.text}</Typography>
                            </div>
                            {(item.text === 'Loan ID' && item.isShow) &&

                              this.state.loanID.map((item: any, index: any) => (
                                <div data-test-id={`loanIdTestId${index}`} key={item.id} onClick={() => this.handleMultipageCheckboxChange(index)} style={webStyle.subFilterView} id='dateModal' className="dateModal">
                                  <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} data-test-id={`handleLoanFilter${index}`} style={webStyle.checkImage} />
                                  <Typography style={{ ...webStyle.filterTextStyle, whiteSpace: "nowrap" } as React.CSSProperties} data-test-id={`handleLoanFilterText${index}`} id='dateModal' className="dateModal">{item.text}</Typography>
                                </div>
                              ))}
                            {(item.text === 'Transaction' && item.isShow) &&
                              this.state.trasactionArray.map((item: any, index: any) => (
                                <div data-test-id={`transactionOptionTestId${index}`} key={item.id} style={webStyle.subFilterView} onClick={() => this.handleTransactionChange(index)} id='dateModal' className="dateModal">
                                  <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} data-test-id={`testIdFilter${index}`} style={webStyle.checkImage} />
                                  <Typography style={webStyle.filterTextStyle} data-test-id={`testIdFilterText${index}`} id='dateModal' className="dateModal">{item.text}</Typography>
                                </div>
                              ))}

                            {(item.text === 'Status' && item.isShow) &&
                              this.state.statusArray.map((item: any, index: any) => (
                                <div data-test-id={`statusOptionTestId${index}`} key={item.id} style={webStyle.subFilterView} onClick={() => this.handleStatusChange(index)} id='dateModal' className="dateModal">
                                  <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} data-test-id={`testIdFilter${index}`} style={webStyle.checkImage} />
                                  <Typography style={webStyle.filterTextStyle} data-test-id={`testIdFilterText${index}`} id='dateModal' className="dateModal">{item.text}</Typography>
                                </div>
                              ))}
                            {(item.isShow && item.text === 'Value') && this.rangeSliderFunction()}
                            {(item.isShow && item.text === 'Date') &&
                              <DateBox sx={webStyle.labelDateBox}>
                                <input data-test-id="dateInputTestID" type="date" value={this.state.date} onChange={this.onStartChange} style={{
                                  ...webStyle.inputStyle,
                                  padding: "0 8px",
                                }} id='dateModal' />
                              </DateBox>

                            }
                          </div>

                        ))}
                      </div>

                    </SubFilterBox>
                  }


                </CustomWalletBoxMultipage>
              </div>
            </MultipageBox>
            <Paper>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {providercolumns.map((column: any) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={webStyle.columnCell}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              data-test-id={`columnTestID${column.id}`}
                              active={this.state.orderBy === column.id}
                              direction={this.state.orderBy === column.id ? this.state.order : 'asc'}
                              onClick={this.handleRequestSortLoanData.bind(this, column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          ) : (
                            <span>{column.label}</span>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {this.state.loanDataSorted.length !== 0 ? this.state.loanDataShow.map((item: Data, index: number) => {
                      return (
                        <TableRow data-test-id={`tableRow${index}`} hover role="checkbox" tabIndex={-1} key={index}>
                          {providercolumns.map((column: Column) => {

                            const value = item[column.id];

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={webStyle.columnCellTwo}
                              >
                                {(column.id !== "transactionStatus" && column.id !== "transactionValue") ?
                                  <CellDiv style={{ minWidth: column.id === "loadID" ? "80px" : "135px" }}>{value}</CellDiv> : this.renderRemainingCell(column.id, value)
                                }
                              </TableCell>


                            );

                          })}
                        </TableRow>

                      );
                    }) : <TableRow>
                      <TableCell
                        colSpan={5}
                        style={webStyle.noResultFoundTextLabel}
                      >
                        {configJSON.noResultFoundText}
                      </TableCell>
                    </TableRow>}

                  </TableBody>

                </Table>

              </TableContainer>
              {this.state.loanDataSorted.length !== 0 ?
                <div style={webStyle.bottomBox}>

                  <div />

                  <Pagination
                    data-test-id="paginationTestID"
                    page={this.state.currentPage}
                    style={webStyle.pagination}
                    count={Math.ceil(this.state.loanDataSorted.length / 10)}
                    onChange={(event: React.ChangeEvent<unknown>, newPage: number) => this.handleMultipageFormPagination(event, newPage)}
                  />

                  <Typography style={webStyle.pagesText}>
                    {this.state.currentPage * 10 - 9} -{" "}
                    {(this.state.currentPage * 10 - 9) + (this.state.loanDataShow.length - 1)}&nbsp;
                    of {this.state.loanDataSorted.length} results
                  </Typography>
                </div> : null
              }
            </Paper>
          </Box>
          <Modal
            data-test-id="multipageFileTypeModalTestID"
            open={this.state.openModal}
            onClose={this.handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-Category"
            style={webStyle.modelViewStyle}
          >
            <Box style={webStyle.modelViewBoxStyle}>
              <Typography style={webStyle.modeMsgStyle}>
                Select the file type you would like to download :
              </Typography>
              <Box style={webStyle.newUserBox}>
                <Box data-test-id="pdfButtonTestID" onClick={this.handleNewUserChange} style={{ ...webStyle.pdfButton, backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white' }}>
                  <Typography style={webStyle.pdfTextStyle}>PDF</Typography>
                  <div style={webStyle.smallBoxStyle}>
                    <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                      <FileSelectMultipage value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                    </RadioGroup>
                  </div>
                </Box>
                <Box data-test-id="xlsxButtonTestID" onClick={this.handleExistingUserChange} style={{ ...webStyle.pdfButton, backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white' }}>

                  <Typography style={webStyle.xlcssTextStyle}>XLSX</Typography>

                  <div style={webStyle.smallBoxStyle}>
                    <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                      <FileSelectMultipage value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                    </RadioGroup>
                  </div>
                </Box>
              </Box>
              <Button data-test-id="confirmButtonTestID" onClick={() => this.handleLoanExportConfirm()} style={webStyle.submitButton2Style}>
                Confirm
              </Button>
            </Box>
          </Modal>
          <MultipageCustomBox>
            <MultipageCustomButton2 data-test-id="multipageExportAllButtonTestID" onClick={this.handleButtonClick}>
              <Typography style={webStyle.exportText}>Export All</Typography>
            </MultipageCustomButton2>

          </MultipageCustomBox>
        </MultipageFormBoxLanding >
        <Snackbar
          data-test-id="snackbarTestID"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={this.handleSnackbarClose}
          open={this.state.isSnackbarOpen}
          autoHideDuration={2000}
        >
          <Box style={webStyle.snackbarInnerBox}>
            <CancelIcon style={{ color: 'red' }} />
            <Typography style={{ fontFamily: "Poppins" }}>{this.state.dateError}</Typography>
          </Box>
        </Snackbar>
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  noResultFoundTextLabel: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "gray",
    padding: "80px 0"
  } as React.CSSProperties,
  snackbarInnerBox: {
    background: "white",
    display: "flex",
    borderRadius: "8px",
    gap: "16px",
    padding: "14px"
  },
  userSettingContainer: {
    backgroundColor: "#E2E8F0"
  },
  providerSecondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,
  filterNoValue: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    color: 'white'
  },
  columnCellTwo: {
    color: "#475569",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    whiteSpace: "nowrap",
    maxWidth: "130px"
  } as React.CSSProperties,
  valueLabelStyle: {
    textAlign: "center",
    color: '#C12030',
    fontWeight: 400,
    fontSize: '14px'

  } as React.CSSProperties,
  inPregressBox: {
    width: '89px',
    height: '22px',
    borderRadius: '40px',
    backgroundColor: '#D1FAE5',
    padding: '2px 8px', // Adjusted padding syntax
    display: 'flex', // Using flexbox
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  } as React.CSSProperties,
  inPregressText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    color: '#059669',
    lineHeight: '18px',
  } as React.CSSProperties,
  textR: {
    fontWeight: 700,
    fontSize: '16px'
  } as React.CSSProperties,
  backArrow: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  sliderstyle: {
    marginLeft: '50px',
    width: '180px',
    marginBottom: '10px',
  } as React.CSSProperties,
  filterText: {
    display: 'flex',
    gap: 16,
    width: "60%"
  },
  backText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: "5px"
  },
  columnCell: {
    fontSize: '14px',
    fontWeight: 700, width: '100px',
    color: '#475569',
    fontFamily: 'Poppins'
  } as React.CSSProperties,
  filterbyText: {
    fontFamily: 'Inter',
    color: '#334155',
    fontWeight: 400,
    fontSize: '14px',
    //marginTop: '13px',
    marginLeft: 'auto',
    lineHeight: '22px',
    alignSelf: 'center',

  },
  timelineText: {
    fontFamily: 'Poppins',
    fontSixe: '16px',
    fontWeigth: '400',
    color: '#334155',
    textAlign: 'right',
    textTransform: 'capitalize'
  } as React.CSSProperties,
  exportText: {
    fontFamily: 'Poopins',
    fontSixe: '16px',
    fontWeigth: '400',
    color: '#C12030',
    textAlign: 'right',
    textTransform: 'capitalize',
  } as React.CSSProperties,
  printText: {
    fontFamily: 'Poopins',
    fontSixe: '16px',
    fontWeigth: '400',
    color: '#C12030',
    textAlign: 'right',
    textTransform: 'capitalize',
    marginLeft: '10px'
  } as React.CSSProperties,
  timelinebuttonStyle: {
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    maxWidth: '180px',
    width: '100%',
    height: '44px',
    padding: '4px 0px',
    alignItems: 'center',
    minWidth: "130px"
  } as React.CSSProperties,

  bottomBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    marginTop: '20px',
  } as React.CSSProperties,
  pagination: {
    marginBottom: '20px',
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#0F172A",
    fontSize: "12px"
  } as React.CSSProperties,
  pagesText: {
    color: '#64748B',
    fontFamily: "Poppins",
    fontSize: '12px',
    marginRight: '15px',
    marginBottom: '20px'
  } as React.CSSProperties,
  providerCatogoryBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: "100%",
    margin: "0 16px 0 20px"
  },
  providerTimelinebutton2: {
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    padding: '4px 16px 4px 20px',
    alignItems: 'center'
  } as React.CSSProperties,
  modelViewStyle: {
    overflowY: 'auto',
    width: '90%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
  } as React.CSSProperties,
  modelViewBoxStyle: {
    maxWidth: '550px',
    width: '100%',
    padding: 30,
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: "8px"
  } as React.CSSProperties,
  pdfTextStyle: {
    fontSize: '20px',
    fontWeigth: '400',
    fontFamily: 'Poppins',
    color: '#0F172A',
    margin: 'auto'
  } as React.CSSProperties,
  smallBoxStyle: {
    display: 'flex',
    alignItems: 'center'
  } as React.CSSProperties,
  modeMsgStyle: {
    fontSize: '22px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    maxWidth: '373px',
    margin: 'auto',
  },
  newUserBox: {
    display: 'flex',
    gap: 26,
    marginTop: 60,
    justifyContent: 'center'
  } as React.CSSProperties,
  pdfButton: {
    maxWidth: '143px',
    height: '117px',
    width: '100%',
    padding: '20px 30px',
    borderRadius: '4px',
    border: '1px solid #E6E6E6',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties,
  sortBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: "100%"
  },
  xlcssTextStyle: {
    fontSize: '20px',
    fontWeigth: '400',
    fontFamily: 'Poppins',
    color: '#0F172A',
    margin: 'auto'
  } as React.CSSProperties,

  inputBoxMultipageStyle: {
    flexDirection: 'column',
    gap: '16px',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
    },
  } as React.CSSProperties,
  sortFilterBox: {
    position: 'absolute',
    background: 'white',
    right: 0,
    top: 49,
    zIndex: 999999,
    width: "310px",
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: "8px",
    overflow: "hidden"
  } as React.CSSProperties,
  submitButton2Style: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    color: '#fff',
    height: '50px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    maxWidth: '550px',
    width: "100%",
    alignSelf: 'center',
    fontFamily: 'Poppins',
    marginTop: '53px',
    fontWeight: 400
  } as React.CSSProperties,

  labelBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5px',
    width: "100%",
    height: '48px',
    alignItems: 'center',
  } as React.CSSProperties,
  labelDateBox: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    marginLeft: '50px',
    marginBottom: '20px'
  },
  labelDateStyle: {
    fontFamily: 'Poppins',
    color: "0F172A",
    fontSize: "12px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  labelStyle: {
    fontFamily: 'Lato',
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: '5px',
    marginRight: "10px",
    cursor: "pointer"
  },
  checkImageStyle: {
    marginLeft: '5px',
    height: '24px',
    width: '24px'
  } as React.CSSProperties,
  inputStyle: {
    color: '#64748B',
    width: '160px',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  catogoryFilterBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '60px'
  },
  catogoryFilterMainDiv: {
    width: '90%',
    backgroundColor: 'white',
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",

  } as React.CSSProperties,
  subFilterBoxStyle: {
    position: 'absolute',
    background: 'white',
    zIndex: 999999,
    padding: '20px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: "250px",
    width: '100%',
    borderRadius: "8px",
    left: 0,
    top: 49
  } as React.CSSProperties,
  subFilterBox: {
    position: 'absolute',
    background: 'white',
    zIndex: 999999,
    padding: '20px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    width: "294px",
    borderRadius: "8px",
    right: "calc(100% - 95%)"
  } as React.CSSProperties,
  filterDiv: {
    cursor: 'pointer',
    display: 'flex',
    margin: '10px',
    gap: '10px',
  },
  checkImage: {
    width: '24px',
    height: '24px',
  },
  filterTextStyle: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    color: '#0F172A',

  },
  valueTextStyle: {
    fontFamily: 'Lato',
    color: '#0F172A',
    fontSize: "16px"
  },
  subFilterView: {
    cursor: 'pointer',
    display: 'flex',
    margin: '20px',
    gap: '10px',
    marginLeft: '50px'
  },

  rangeFilterMainDiv: {
    width: '220px',
    marginLeft: '43px',
    justifyContent: 'space-between',
    display: 'flex',
  }
}
// Customizable Area End