import React from "react";

import {
    Box,
    Card,
    CardContent,
    Typography,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web"
import { storeicon } from './assets';
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';

const RightBoxLandingOperator = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

export default class OperatorSettingsOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        let data4 = [
            {
                StoreTItleOpera: "Khandal saree store, 403",
                RefrenceOpera: "Reference Number",
                RefValueOpera: "23DSD3",
                CommissionsOpera: "Over all Commissions",
                CommiValueOpera: "3456",
                StatusOpera: "Loan status",
                StatusNameOpera: "Approve",
                ImageOpera: storeicon
            },
            {
                StoreTItleOpera: "Khandal saree store, 403 ", RefrenceOpera: "Reference Number",
                RefValueOpera: "23DSD3", CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456",
                StatusOpera: "Loan status", StatusNameOpera: "Approve", ImageOpera: storeicon
            }, {
                StoreTItleOpera: "Khandal saree store, 403 ", RefrenceOpera: "Reference Number", RefValueOpera: "23DSD3", CommissionsOpera: "Over all Commissions",
                CommiValueOpera: "3456", StatusOpera: "Loan status", StatusNameOpera: "Approve", ImageOpera: storeicon
            }, {
                StoreTItleOpera: "Khandal saree store, 403 ",
                RefrenceOpera: "Reference Number", RefValueOpera: "23DSD3", CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456",
                StatusOpera: "Loan status",
                StatusNameOpera: "Approve", ImageOpera: storeicon
            }, {
                StoreTItleOpera: "Khandal saree store, 403 ",
                RefrenceOpera: "Reference Number",
                RefValueOpera: "23DSD3", CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456", StatusOpera: "Loan status",
                StatusNameOpera: "Approve", ImageOpera: storeicon
            }, {
                StoreTItleOpera: "Khandal saree store, 403 ",
                RefrenceOpera: "Reference Number", RefValueOpera: "23DSD3",
                CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456",
                StatusOpera: "Loan status", StatusNameOpera: "Approve",
                ImageOpera: storeicon
            },
            {
                StoreTItleOpera: "Khandal saree store, 403 ", RefrenceOpera: "Reference Number",
                RefValueOpera: "23DSD3",
                CommissionsOpera: "Over all Commissions",
                CommiValueOpera: "3456", StatusOpera: "Loan status",
                StatusNameOpera: "Approve", ImageOpera: storeicon
            }, {
                StoreTItleOpera: "Khandal saree store, 403 ",
                RefrenceOpera: "Reference Number", RefValueOpera: "23DSD3",
                CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456",
                StatusOpera: "Loan status", StatusNameOpera: "Approve",
                ImageOpera: storeicon
            },
            {
                StoreTItleOpera: "Khandal saree store, 403 ", RefrenceOpera: "Reference Number",
                RefValueOpera: "23DSD3", CommissionsOpera: "Over all Commissions", CommiValueOpera: "3456",
                StatusOpera: "Loan status", StatusNameOpera: "Approve", ImageOpera: storeicon
            },
        ]
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <OperatorSideBar />
                <RightBoxLandingOperator style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                    <Box style={webStyleOperator.drawerBoxOperator}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <OperatorHeader />
                    <Box style={{ padding: "40px", display: "flex", gap: "30px", flexWrap: "wrap", maxWidth: "100%", backgroundColor: "rgb(226, 232, 240)" }}>
                        {data4.map((item, index) => (
                            <Card data-test-id={`operator-${index}`} key={index} style={{ maxWidth: "321px", cursor: "pointer" } as React.CSSProperties} onClick={() => this.navigateToAddOperOperator()}>

                                <CardContent>
                                    <Box sx={{ marginBottom: 2 }}>
                                        <img src={item.ImageOpera} alt="Store" style={{ width: '100%', height: '228px' }} />
                                    </Box>
                                    <Typography style={webStyleOperator.productTitleOperator}>{item.StoreTItleOpera}</Typography>
                                    <Box style={webStyleOperator.cardTexts2Operator}>
                                        <Typography style={webStyleOperator.productDescriptionOperator}>{item.RefrenceOpera}</Typography>
                                        <Typography style={webStyleOperator.referencenoOperator}>{item.RefValueOpera}</Typography>
                                    </Box>
                                    <Box style={webStyleOperator.cardTexts2Operator}>
                                        <Typography style={webStyleOperator.productDescriptionOperator}>{item.CommissionsOpera}</Typography>
                                        <Typography style={webStyleOperator.productDescriptionOperator}>{item.CommiValueOpera}</Typography>
                                    </Box>
                                    <Box style={webStyleOperator.cardTexts2Operator}>
                                        <Typography style={webStyleOperator.productDescriptionOperator}>{item.StatusOpera}</Typography>
                                        <Typography style={webStyleOperator.approveTextOperator}>{item.StatusNameOpera}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}

                    </Box>
                </RightBoxLandingOperator>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyleOperator = {
    productTitleOperator: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    cardTexts2Operator: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    } as React.CSSProperties,
    productDescriptionOperator: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    } as React.CSSProperties,
    approveTextOperator: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2Operator: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referencenoOperator: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    } as React.CSSProperties,

    drawerBoxOperator: { width: '100%', backgroundColor: "rgb(226, 232, 240)" } as React.CSSProperties,

}
// Customizable Area End