import React from "react";

import {
    Box, Button, Card, CardContent, Fade, FormControl, MenuItem, Modal, Select, Typography, styled,
} from "@material-ui/core";
import Cfapplyforvirtualassistance2Controller, {
    Props
} from "./Cfapplyforvirtualassistance2Controller";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { BackIcn, RightIcn } from './assets';
import { Toaster } from "react-hot-toast"



const LayoutMainOprat = styled(Box)({
    height: "100%",
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const TitleTextOprat = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#334155',
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: '20px',
    alignSelf: 'center',
    padding: "20px 45px",
})

const ModalBoxForgotOprat = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})
const CustomBoxContainer = styled(Box)({ 
    padding: "0 40px", 
    backgroundColor: "rgb(226, 232, 240)",
    "@media(max-width:992px)":{
        marginTop:'20px'
    } 
})

const maxLength = 1000;

export default class VirtualAssistanceOperator extends Cfapplyforvirtualassistance2Controller {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <OperatorSideBar />
                <LayoutMainOprat style={{ width: "100%"}}>
                    <Box style={webStyle.LoanHeaderOprat}>

                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />

                    </Box>
                    <CustomBoxContainer >
                        <Box style={{ cursor: "pointer", marginBottom: "30px", maxWidth: "80px" }} onClick={this.handleBackAssitOperator}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500, }}>
                                <img src={BackIcn} alt="back-icn" style={{ marginRight: "10px" }} />
                                Back
                            </Typography>
                        </Box>
                        <TitleTextOprat>
                            Virtual Assistance
                        </TitleTextOprat>
                        <Card style={{ padding: "40px 25px", margin: "30px 0", maxWidth: "600px" }}>
                            <CardContent style={{ maxWidth: "100%", display: "flex", gap: 35, flexDirection: "column", alignItems: "center", padding: "0" }}>
                                <FormControl variant="outlined" required style={{
                                    border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px", outline: "none",
                                }}>
                                    <div style={{ display: this.state.setHeading ? 'none' : 'block', position: "absolute", top: 17, left: 15, fontFamily: "Poppins", fontSize: "14px", color: "#273567", fontWeight: 400 }}>
                                        Heading
                                    </div>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue=""
                                        value={this.state.setHeading}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%', height: '55px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                            fontWeight: 400, borderRadius: '8px',
                                        }}
                                        onChange={this.handleSelectHeading}
                                    >
                                         {Object.keys(this.options).map((key) => (
                                         <MenuItem key={key} value={key} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>
                                            {key}
                                            </MenuItem>
                                            ))}
                                    </Select>

                                </FormControl>
                                <FormControl variant="outlined" required style={{
                                    border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px"
                                }}>
                                    <div style={{ display: this.state.setSubHeading ? 'none' : 'block', position: "absolute", top: 17, left: 15, fontFamily: "Poppins", fontSize: "14px", color: "#273567", fontWeight: 400 }}>
                                        Sub-Heading
                                    </div>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue=""
                                        value={this.state.setSubHeading}
                                        disabled={!this.state.setHeading}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%', height: '55px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                            fontWeight: 400, borderRadius: '8px',
                                        }}
                                        onChange={this.handleSelectSubHeading}
                                    >
                                        {this.state.setHeading && this.options[this.state.setHeading]?.map((subheading) => (
                                             <MenuItem key={subheading} value={subheading} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>
                                                {subheading}
                                                </MenuItem>
                                                 ))}
                                    </Select>
                                </FormControl>
                                <Box style={webStyle.fieldsMainOPrat}>
                                    <label style={webStyle.labelStyleOPrat as React.CSSProperties}>Message</label>
                                    <textarea
                                        placeholder="Add your message here"
                                        value={this.state.setMessage}
                                        style={{ ...webStyle.inputStyleOprate, resize:"none",scrollbarWidth:"none",border: this.state.setMessage?.length >= maxLength ? '1px solid #F87171' : webStyle.inputStyleOprate.border, outline: "none" }} onChange={this.handleSetMessage} maxLength={1000} />
                                    {this.state.setMessage?.length >= maxLength ? <Typography style={{ paddingTop: "10px", color: "#FF0404", fontFamily: "Poppins", fontWeight: 400, fontSize: "12px" }}>*Word limit exceeded in input. Please shorten your text.</Typography> : ""}
                                </Box>
                                <Button style={webStyle.submitLoanOPerator} onClick={this.virtualAssistance}>
                                    <Typography style={webStyle.submitTitleOperat as React.CSSProperties}>Submit</Typography>
                                </Button>
                            </CardContent>
                        </Card>
                    </CustomBoxContainer>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        closeAfterTransition
                        open={this.state.successModal}
                        onClose={this.handleCloseSuccessModal}
                        aria-describedby="transition-modal-description"
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.successModal}>
                            <ModalBoxForgotOprat sx={webStyle.greentickModalOperat}>
                                <img src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '119px', width: '100%', height: '119px' }} />
                                <Typography variant="h5" style={webStyle.userVerifiedOperat as React.CSSProperties}>Your request has been submitted successfully.</Typography>
                            </ModalBoxForgotOprat>
                        </Fade>
                    </Modal>
                    <Toaster position="top-center" />
                </LayoutMainOprat>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    LoanHeaderOprat: { width: '100%', backgroundColor: "rgb(226, 232, 240)", } as React.CSSProperties,
    submitLoanOPerator: {
        backgroundColor: 'C12030',
        width: '100%',
        maxWidth: '550px',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
    },
    submitTitleOperat: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
        color: '#fff',
        textTransform: 'capitalize',
    },
    greentickModalOperat: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: 'auto',
        marginTop: '100px',
        position: 'relative',
        borderRadius: '8px',
        flexShrink: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedOperat: {
        color: '#030F2D',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: "center",
        display: 'flex',
        fontStyle: "normal"
    },
    fieldsMainOPrat: {
        display:"flex",
        flexDirection:"column",
        width: "100%",
        maxWidth: "550px",
    } as React.CSSProperties,
    labelStyleOPrat: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
    },
    inputStyleOprate: {
        color:"#273567",
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        width: '100%',
        maxWidth:"550px",
        height: '200px',
        padding: "15px",
        borderRadius: '8px',
        border: "1px solid #273567",
        fontStyle: 'normal',
    },
}
// Customizable Area End