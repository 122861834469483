import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";





const CommonSearchBar = () => {
    return (
        <div style={webStyle.search}>
            <div style={webStyle.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search"
                style={webStyle.inputInput}
                inputProps={{ "aria-label": "search" }}
            />
        </div>
    );
};

export default CommonSearchBar;


const webStyle = {
    search: {
        position: "relative",
        borderRadius: "18px",
        background: "var(--0, #FFF)", // Add background color
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)", // Add box shadow
        maxWidth: "530px",
        marginTop:"5%",
        marginLeft:"3%",
        // marginLeft:window.matchMedia("(max-width: 600px)").matches ? '1%' : '5%',
        width: '100%'
    } as React.CSSProperties,
    searchIcon: {
        marginTop: "1px",
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#CFADEA",
        marginLeft: "10px",
    } as React.CSSProperties,
    inputInput: {
        padding: "12px",
        paddingLeft: "40px", // Adjust left padding to account for the search icon
        width: "100%",
        color: "#CFADEA",
        fontFamily: "HK Grotesk",
        fontWeight: 400,
        fontSize: "16px",
    } as React.CSSProperties,
}