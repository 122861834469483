import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Modal,
  Fade,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { background_image, cross, greentick, a2payImage } from './assets';
const BackimgOperator = styled('img')({
  '@media (max-width: 600px)': {
    height: '400px !important',
    objectPosition: 'bottom',
    objectFit: 'cover',
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    height: '500px !important',
    objectPosition: 'bottom',
    objectFit: 'cover',
    width: '100%'
  },
})
const LogoimgOperat = styled('img')({

  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
})

const CustomGridOperator = styled(Grid)({
  padding: '0px 80px',
  '@media (max-width: 600px)': {
    padding: '0px 20px'
  }
})
const MobTypographyOPerator = styled(Typography)({
  fontSize: 28,
  '@media (max-width: 600px)': {
    fontSize: 20
  }
})
const MobSubTypographyOperator = styled(Typography)({
  fontFamily:"Poppins",
  fontSize: 18,
  '@media (max-width: 600px)': {
    fontSize: 16
  }
})
const CustomModalOperator = styled(Modal)({
  overflow: 'scroll',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  "@media (max-width: 600px)": {
    '& .MuiPaper-root': {
      width: '90%',
    },
  },
  "@media (min-width: 601px) and (max-width: 1024px)": {
    '& .MuiPaper-root': {
      width: '70%',
    },
  },
});
const VarTypographyOperator = styled(Typography)({
  marginTop: 0,
  fontSize: 40,
  '@media (max-width: 600px)': {
    marginTop: 10,
    fontSize: 28,
  }
});
const SubTypographyOper = styled(Typography)({
  marginTop: 0,
  whiteSpace: 'nowrap',
  '@media (max-width: 600px)': {
    marginTop: 10,
    fontSize: '12px',
    whiteSpace: 'normal'
  }
})
const TimeTypographyOperator = styled(Typography)({
  whiteSpace: 'normal',
  marginTop: 0,
  fontSize: '14px',
  '@media (max-width: 600px)': {
    marginTop: 10,
    fontSize: '12px',
    whiteSpace: 'nowrap'
  }
})
const ModalBoxOperator = styled(Box)({
  width: '100%',
  maxWidth: '400px',
  height: '60%',
  '@media (max-width: 600px)': {
    width: '70%',
    maxWidth: '400px',
    height: 'auto',
  }
})
// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

export default class OperatorForgotPass extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    const { seconds, timeout } = this.state;
    const displayTime = seconds >= 10 ? seconds : `0${seconds}`;
    return (
      // Customizable Area Start
      <Grid container style={{ minHeight: '80vh' }}>
        <Grid item xs={12}
           sm={12} lg={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <LogoimgOperat src={a2payImage} style={{ position: 'absolute', top: 50, left: 242, width: '203px', height: '99px'  }} />
          <BackimgOperator src={background_image} style={{ height: '1024px', width: '100%' }} />
        </Grid>

        <CustomGridOperator item xs={12}  sm={12} lg={6} >

          <Box height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" >

            <Box style={{ width: "100%" }}>
              <MobTypographyOPerator style={webStyle.welcomeTextOperator as React.CSSProperties}>Forgot Password</MobTypographyOPerator>
              <MobSubTypographyOperator variant="body1" style={webStyle.merchantTextOPerator as React.CSSProperties}>
                No Worries, We'll send an recovery OTP to your Registered Phone Number
              </MobSubTypographyOperator>
              <label style={webStyle.labelStyleOPerator as React.CSSProperties}>Phone Number</label>
              <input
                type="text"
                name="mobileNumber"
                id="phoneNumber"
                value={this.state.phoneNumber}
                data-test-id='phone-number-input'
                onChange={this.handlePhoneNumbervalidate}
                style={{
                  ...webStyle.inputStyleOPerator,
                  borderColor: this.state.invalidInputs.includes('mobileNumber') ? 'red' : '#273567',
                }}
              />
              {this.state.errorPhoneMessage && (
                                    <span style={{ color: 'red', fontSize: '16px', marginTop: 16, fontFamily: 'Poppins' }}>
                                     {this.state.errorPhoneMessage}
                                    </span>
                                )}


              <Button style={webStyle.nextButtonoperator as React.CSSProperties} onClick={this.handleOpenModalOtp}>
                Send a recovery link
              </Button>
            </Box>
          </Box>

          <CustomModalOperator
            aria-labelledby="transition-modal-title"
            onClose={this.handleCloseModal}
            aria-describedby="transition-modal-description"
            open={this.state.isModalOpen}
            closeAfterTransition
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.isModalOpen}>
              <div
                style={webStyle.Modal2OPerator as React.CSSProperties}
              >
                <img
                  style={webStyle.crossImageOperator as React.CSSProperties}
                  src={cross}
                  onClick={this.handleCloseModal}
                />
                <VarTypographyOperator variant="h5" style={webStyle.verificationTextoperator as React.CSSProperties}>Verification</VarTypographyOperator>

                <Box style={{
                  justifyContent: 'center',
                  display: 'flex'
                }}>
                                   {this.state.otp.map((digit, index) => (
  <input
    key={index}
    ref={el => this.inputRefs[index] = el}
    type="text"
    inputMode="numeric"
    pattern="\d*"
    maxLength={1}
    value={digit}
    style={webStyle.otpInputoperator as React.CSSProperties}
    data-test-id={`txtInputotp${index}`}
    data-testId='otp_test'
    onChange={(e) => this.handleInputChangeForgot2(index, e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        this.handleInputChangeForgot2(index, '', true);
      }
    }}
  />
))}
                </Box>
                <SubTypographyOper style={webStyle.cardOtp2 as React.CSSProperties
                }>Enter the OTP code contained in the Email we sent you</SubTypographyOper>
                <TimeTypographyOperator
                  style={
                    timeout
                      ? { ...webStyle.otpverificationTextoperator2, color: 'red' }
                      : webStyle.otpverificationTextoperator2
                  }
                >
                  {timeout ? 'OTP verification timed out' : `OTP verification code expires in: ${displayTime}s`}
                </TimeTypographyOperator>
                <Typography style={webStyle.resendOtpText2 as React.CSSProperties}>Resend OTP</Typography>
                <Button style={{...webStyle.submitButton2,width: '100%',opacity: this.state.otpError ? 0.6 : ""} as React.CSSProperties}  disabled={this.state.otpError} onClick={this.handleOpenModalNewUser2}>Submit</Button>
                
              </div>
            </Fade>
          </CustomModalOperator>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            open={this.state.tickModel}
            onClose={this.handleCloseModal2}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >

            <Fade in={this.state.tickModel}>
              <ModalBoxOperator
                sx={webStyle.greentickModal2OPerator}
              >
                <img
                  src={greentick}
                  style={{
                    height: '119px',
                    marginBottom: '20px',
                    width:'100%',
                    maxWidth: '119px',
                  }}
                />
                <Typography variant="h5" style={webStyle.userVerifiedText2 as React.CSSProperties}>User Verified</Typography>
              </ModalBoxOperator>
            </Fade>

          </Modal>
        </CustomGridOperator>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  inputStyleOPerator: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500,
    padding: 15,
    color: '#273567',
    width: '100%',
    top: '26px',
    border: '1px solid',
    borderRadius: '8px',
    height: '50px',
  },
  welcomeTextOperator: {
    color: '#000',
    fontFamily: 'Poppins',
    fontWeight: '600',
    // fontSize: '28px',
    lineHeight: '42px'
  },
  merchantTextOPerator: {
    color: '#848FAC',
    fontStyle: 'normal',
    fontWeight: 400,
    maxWidth: '491px',
    // fontSize: '18px',
    lineHeight: '24px',
    fontFamily:"Poppins",
    marginBottom: 30,

  },
  nextButtonoperator: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    fontWeight: '400',
    color: '#fff',
    marginTop: '42px',
    width: '100%',
    height: '50px',
    textTransform: 'none', // Set to 'none' to prevent automatic capitalization
  },

  labelStyleOPerator: {
    fontSize: "16px",
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontStyle: 'normal',
    color: "#273567",
    letterSpacing: "0em",
    lineHeight: 'normal',
  },
  Modal2OPerator: {
    position: 'relative',
    backgroundColor: '#fff',
    margin: 'auto',
    padding: '20px',
    flexShrink: '0',
    borderRadius: '8px',
    marginTop: '100px',
    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    '@media (max-width: 600px)': {
      width: '90%', // Adjust width for smaller screens
    },
    '@media (min-width: 601px) and (max-width: 1024px)': {
      width: '70%', // Adjust width for tablets
    },
  },
  crossImageOperator: {
    position: 'absolute',
    cursor: 'pointer',
    right: '5px',
    marginRight: 10,
    marginTop: -10
  },
  verificationTextoperator: {
    display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
    fontFamily: " Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "140%"
  },
  otpInputoperator: {
    textAlign: "center",
    fontSize: "18px",
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#273567',
    borderBottom: "1px solid #273567",
    margin: "10px",
    width: "2em",
    letterSpacing: "10px",
    border: "none",
    outline: "none",
  },
  cardOtp2: {
    color: '#848FAC',
    textAlign: "center",
    fontSize: "19px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "140%",
    fontWeight: 400
  },
  otpverificationTextoperator2: {
    display: 'flex',
    fontFamily: " Poppins",
    justifyContent: 'center',
    color: '#848FAC',
    alignContent: 'ceneter',
    textAlign: "center",
    fontWeight: 400,
    lineHeight: "140%",
    marginTop: '10',
    fontSize: "19px",
    fontStyle: "normal",
  } as React.CSSProperties,
  resendOtpText2: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#273567',
    marginTop: '10',
    textAlign: "center",
    fontFamily: " Poppins",
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%"
  },
  submitButton2: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    borderRadius: '8px',
    marginTop: '16px',
    color: '#fff',
    width: '100%',
    textTransform: 'capitalize',
    height: '50px',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  greentickModal2OPerator: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    margin: 'auto',
    marginTop: '100px',
    borderRadius: '8px',
    flexShrink: '0',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
  },
  userVerifiedText2: {
    fontSize: "33px",
    justifyContent: 'center',
    lineHeight: "140%",
    fontWeight: 400,
    alignContent: 'center',
    textAlign: "center",
    color: '#030F2D',
    display: 'flex',
    fontFamily: " Poppins",
    fontStyle: "normal"
  },
};
// Customizable Area End