import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';


const ScrollToTop = ({ history }:any) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);  // Scroll to the top of the page on route change
    });
    return () => {
      unlisten();  // Cleanup the listener when the component is unmounted
    };
  }, [history]);

  return null;  // This component does not render anything
};

export default withRouter(ScrollToTop);
