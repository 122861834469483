import React from "react";

import {
    Box,
    Button,
    Typography,
    IconButton,
    // Customizable Area Start
    Grid,
    Modal,
    Fade,
    styled,
    TextField,
    Checkbox
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Toaster } from "react-hot-toast"
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { background_image, cross, greentick, a2payImage } from './assets';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
const GridImageContainer = styled(Grid)({
    display: "flex",
    position:'fixed',
    height: '100%',
    justifyContent: "center",
    width:'50%',
    left:0,
    '@media (min-width: 600px) and (max-width: 960px)': {
        height: '500px ',
        width: '100%',
        position:'relative',
        marginBottom:'20px',
    },
  
    '@media (max-width: 600px)': {
        height: '400px ',
        marginBottom:'20px',
        width:'100%',
        position:'relative',
    },
  })
  
const BackimgRegister = styled('img')({
    height:"100%",
    '@media (max-width: 600px)': {
        width:'100%',
        height: '400px ',
        objectFit: 'fill',
    },
    '@media (min-width: 600px) and (max-width: 960px)': {
        objectFit: 'fill',
        height: '500px ',
        width: '100%',
      },
})
const LogoimgRegister = styled('img')({
    '@media (max-width: 600px)': {
        left: 'unset !important',
        top: '250px !important'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        left: 'unset !important',
        top: '250px !important'
    },
})
const CustomModalRegister = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    "@media (max-width: 600px)": {
        '& .MuiPaper-root': {
            width: '90%',
        },
    },
    "@media (min-width: 601px) and (max-width: 1024px)": {
        '& .MuiPaper-root': {
            width: '70%',
        },
    },
});
const VarTypographyRegister = styled(Typography)({
    fontSize: 40,
    marginTop: 0,
    '@media (max-width: 600px)': {
        fontSize: 28,
        marginTop: 10
    }
});
const SubTypographyRegister = styled(Typography)({
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginTop: 0,
    '@media (max-width: 600px)': {
        fontSize: '12px',
        marginTop: 10,
        whiteSpace: 'normal'
    }
})
const TimeTypographyRegister = styled(Typography)({
    fontSize: '14px',
    whiteSpace: 'normal',
    marginTop: 0,
    '@media (max-width: 600px)': {
        fontSize: '12px',
        marginTop: 10,
        whiteSpace: 'nowrap'
    }
})
const ModalBox = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

// Customizable Area End

import SignuploginController, {
    Props
} from "./SignuploginController";

export default class RegisterNewUser extends SignuploginController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    RenderPassword = () => {
        const passwordValidationPo = this.validatePasswordPo();
        return (
            <>
                <Grid>
                    <label
                        style={webStyle.labelStyle as React.CSSProperties}
                    >
                        User Name(ID/Passport Number)
                    </label>
                    <br />
                    <input
                        type="text"
                        name="passportIdNumberId"
                        value={this.state.passportIdNumberId}
                        onChange={this.handlePassportValidation}
                        onInput={this.handleWhatsappNumberNumber}
                        title="Please enter a valid passport ID"
                        required
                        style={{
                            ...webStyle.inputStyle,
                            borderColor: this.state.conpassvalid.includes('passportIdNumberId') ? 'red' : '#273567'
                        } as React.CSSProperties}
                    />
                    {this.state.userNameError && <p style={{ color: 'red', marginTop: -10, marginBottom: -15,fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>Please enter valid username</p>}
                    <br />
                    <label
                        style={webStyle.labelStyle as React.CSSProperties}
                    >
                        Password
                    </label>
                    <div style={{ position: 'relative' }}>
                        <input
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            style={{
                                ...webStyle.inputStyle,
                                paddingRight: '40px',
                                borderColor: this.state.conpassvalid.includes('password') ? 'red' : '#273567'
                            } as React.CSSProperties}
                            value={this.state.password}
                            onChange={this.handleInputChangesTwo}
                        />


                        <IconButton
                            onClick={this.handleTogglePassword}
                            edge="end"
                            style={{ position: 'absolute', right: '10px', top: '43%', transform: 'translateY(-50%)' }}
                        >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>


                    <label
                        style={webStyle.labelStyle as React.CSSProperties}
                    >
                        Confirm Password
                    </label>
                    <div style={{ position: 'relative' }}>
                        <input
                            name="confirmPasswords"
                            type={this.state.confirmPassword ? 'text' : 'password'}
                            style={{
                                ...webStyle.inputStyle,
                                paddingRight: '40px',
                                borderColor: this.state.conpassvalid.includes('confirmPasswords') ? 'red' : '#273567'
                            } as React.CSSProperties}

                            value={this.state.confirmPasswords}
                            onChange={this.handleInputChangesTwo}
                        />

                        {/* Eye icon at the end */}
                        <IconButton
                            onClick={this.handleClickConfirmPassword}
                            edge="end"
                            style={{ position: 'absolute', right: '10px', top: '43%', transform: 'translateY(-50%)' }}
                        >
                            {this.state.confirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>


                    {this.state.samepass ? null : <Typography style={{ color: "#FF0404", fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins', marginTop: -10, marginLeft: 5 }}>Password do not match</Typography>}
                    <div style={{ color: '#848FAC', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 400 }}>
                        {[
                            {
                                isValid: passwordValidationPo.isCapitalLetter,
                                message: "At least one capital letter",
                            },
                            {
                                isValid: passwordValidationPo.isLowerCaseLetter,
                                message: "At least one lowercase letter",
                            },
                            {
                                isValid: passwordValidationPo.isNumber,
                                message: "At least one number",
                            },
                            {
                                isValid: passwordValidationPo.isMinLength,
                                message: "Minimum character length is 8",
                            },
                        ].map((validItem, idx) => (
                            <Grid
                                key={idx}
                                style={{
                                    marginTop: "7px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {validItem.isValid ? (
                                    <CheckCircleIcon
                                        style={{
                                            color: "#34D399",
                                            marginRight: "5px",
                                        }}
                                    />
                                ) : (
                                    <RemoveCircleIcon
                                        style={{
                                            marginRight: "5px",
                                            color: "#DC2626",
                                        }}
                                    />
                                )}
                                <span>{validItem.message}</span>
                            </Grid>
                        ))}
                    </div>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center',marginLeft:'-9px' }}>
                            <Checkbox
                                style={{ color: '#273567' }}
                                inputProps={{ "aria-label": "checkbox with custom color" }}
                            />
                            <label style={{
                                fontSize: "14px", color: "#273567", fontFamily: "Poppins",
                                fontWeight: 400, marginLeft: '4px'
                            }}>
                                Remember Me
                            </label>
                        </div>
                       
                    </Box>

                </Grid>


            </>
        )
    }

    // Customizable Area End

    render() {
        const { seconds, timeout } = this.state;
        const displayTime = seconds >= 10 ? seconds : `0${seconds}`;
        return (
            // Customizable Area Start
            <Grid container justifyContent="flex-end" alignItems="center" style={{ minHeight: '100vh' }}>
                <GridImageContainer item
            xs={12}
            sm={12} lg={6}
            md={6}>
                    <LogoimgRegister src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top: 50, left: 242 }} />
                    <BackimgRegister src={background_image} style={{ width: '100%'}} />
                </GridImageContainer>
                <Grid item xs={12}
                sm={12} lg={6}
                md={6}>
                    <Box style={{ margin: '5%', padding: '2%' }}>

                        <Box style={{ margin: "5%" }}>
                            <Typography style={webStyle.welcomeText as React.CSSProperties}>Welcome</Typography>
                            <Typography variant="body1" style={webStyle.idnumberText as React.CSSProperties}>
                                Please set up your ID Number
                            </Typography>
                            {this.RenderPassword()}
                            <Button style={webStyle.registerButton as React.CSSProperties} onClick={this.handleSubmitTwo}>
                                Register
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <CustomModalRegister
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isModalOpen}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropProps={{
                        style: {
                            backdropFilter: "blur(4px)",
                        },
                    }}
                >
                    <Fade in={this.state.isModalOpen}>
                        <div
                            style={webStyle.Modal1 as React.CSSProperties}
                        >
                            <img
                                src={cross}
                                style={webStyle.crossImage as React.CSSProperties}
                                onClick={this.handleCloseModal}
                            />
                            <VarTypographyRegister style={webStyle.verificationText as React.CSSProperties}>Verification</VarTypographyRegister>

                            <Box style={{
                                display: 'flex'
                                , justifyContent: 'center', alignContent: 'center'
                            }}>
                                {this.state.otp.map((digit: any, index: any) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`}
                                        maxLength={1}
                                        style={webStyle.otpInput as React.CSSProperties}
                                        type="text"
                                        value={digit}
                                        onChange={(e) => this.handleInputChange(index, e.target.value)}
                                    />
                                ))}
                            </Box>

                            <SubTypographyRegister style={webStyle.cardOtp as React.CSSProperties
                            }>Enter the OTP code contained in the Email we sent you</SubTypographyRegister>
                            <TimeTypographyRegister
                                style={
                                    timeout
                                        ? { ...webStyle.otpVerificationText2, color: 'red' }
                                        : webStyle.otpVerificationText2
                                }
                            >
                                {timeout ? 'OTP verification timed out' : `OTP verification code expires in: ${displayTime}s`}
                            </TimeTypographyRegister>
                            <Typography style={webStyle.resendOtpText as React.CSSProperties}>Resend OTP</Typography>
                            <Button style={webStyle.submitButton as React.CSSProperties} onClick={this.handleOpenModal2}>Submit</Button>
                        </div>
                    </Fade>
                </CustomModalRegister>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.tickModel}
                    onClose={this.handleCloseModal2}
                    closeAfterTransition
                    BackdropProps={{
                        style: {
                            backdropFilter: "blur(4px)",
                        },
                    }}
                >
                    <Fade in={this.state.tickModel}>
                        <ModalBox
                            sx={webStyle.greentickModal}
                        >
                            <img
                                src={greentick}
                                style={{
                                    height: '119px',
                                    marginBottom: '20px',
                                    width: '100%',
                                    maxWidth: '119px',
                                }}

                            />
                            <Typography variant="h5" style={webStyle.userVerifiedText as React.CSSProperties}>User Verified</Typography>
                        </ModalBox>
                    </Fade>
                </Modal>
                <Toaster position="top-center" />
            </Grid>

            // Customizable Area End
        );
    }
}


// Customizable Area Start
const Custompassinput: any = styled(TextField)({

    '& [class^="MuiInputBase-root-"]>input': {
        padding: "14.5px 14px !important",
    },

    '& [class^="PrivateNotchedOutline-root-"]': {
        // borderStyle: "none !important",
    },

    '& .MuiOutlinedInput-input': {
        // color:"#192252 !important",
        // height:'13px'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&:hover fieldset': {
            borderColor: 'grey !important',
        },
        '&.Mui-focused fieldset': {
            //   borderColor: '#273567',
        }
    }

})
const webStyle = {
    inputStyle: {
        width: "100%", // Full width on all devices
        border: "1px solid #273567",
        borderRadius: "8px",
        color: "#273567",
        height: "50px",
        boxSizing: "border-box",
        padding: "4%", // Adjust padding as needed
        marginBottom: "10px", // Adjust margin as needed
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 500
    },

    inputStyleUserName: {
        width: "100%", // Full width on all devices
        border: "1px solid #273567",
        borderRadius: "8px",
        color: "#273567",
        height: "50px",
        boxSizing: "border-box",
        padding: "4%", // Adjust padding as needed
        marginBottom: "10px", // Adjust margin as needed
    },
    inputStyleTwo: {
        color: '#273567',
        height: '30px',
        top: '26px',
        width: '412px',
        borderRadius: '8px',
        border: '1px solid #273567',

    },
    buttonStyle: {
        width: "100%",

        marginTop: "40px",
        height: "45px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    labelStyle: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        letterSpacing: "0em",
        fontWeight: "400",
        color: "#273567",
        lineHeight: "24px",
        // textAlign: "left",
    },
    labelStyleTwo: {
        lineHeight: " 24px",
        letterSpacing: "0em",
        color: "#273567",
        fontFamily: 'Poppins',
        marginTop: "16px",
        fontSize: "16px",
        fontWeight: "400",


        // textAlign: "left",
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    labelBoxTwo: {
        display: 'flex',
        gap: 50,
        flexDirection: 'row',
    },
    inputBox: {
        display: 'flex',
        marginTop: 16,
        flexDirection: 'row',
        gap: 10,
    },
    greentickModal: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        // height: '60%',
        // width: '542px',
        borderRadius: '8px',
        // maxWidth: '400px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText: {
        fontSize: "33px",
        lineHeight: "140%",
        fontWeight: 400,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        color: '#030F2D',
        textAlign: "center",
        fontFamily: " Poppins",
        fontStyle: "normal"
    },
    resendOtpText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#273567',
        marginTop: '10',
        textAlign: "center",
        fontFamily: " Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%"
    },
    otpVerificationText2: {
        display: 'flex',
        justifyContent: 'center',
        color: '#848FAC',
        alignContent: 'ceneter',
        fontWeight: 400,
        textAlign: "center",
        fontFamily: " Poppins",
        lineHeight: "140%",
        fontSize: "19px",
        marginTop: '10',
        fontStyle: "normal",
    } as React.CSSProperties,
    otpInput: {
        textAlign: "center",
        fontSize: "18px",
        letterSpacing: "10px",
        width: "2em",
        margin: "0.5em",
        border: "none",
        borderBottom: "1px solid #273567",
        outline: "none"
    },
    verificationText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
        fontFamily: " Poppins",
        fontWeight: 500,
        // fontSize: "40px",
        textAlign: "center",
        fontStyle: "normal",
        lineHeight: "140%"
    },
    crossImage: {
        width: '5%',
        objectFit: 'cover',
        position: 'absolute',
        top: '10px',
        height: '5%',
        right: '10px',
        cursor: 'pointer',
    },
    Modal1: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        // maxWidth: '400px',
        // height: 'auto',
        margin: 'auto',
        marginTop: '100px',
        // width: '50%',
        flexShrink: '0',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
        '@media (max-width: 600px)': {
            width: '90%', // Adjust width for smaller screens
        },
        '@media (min-width: 601px) and (max-width: 1024px)': {
            width: '70%', // Adjust width for tablets
        },
    },
    welcomeText: {
        fontSize: '28px',
        color: '#000',
        fontFamily: 'Poppins',
        lineHeight: '42px',
        fontWeight: '600',
    },
    idnumberText: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        marginBottom: 30,
        color: '#848FAC',
        fontWeight: '400',
        lineHeight: '21px',
        whiteSpace: 'nowrap',

    },
    fourText: {
        fontSize: '14px',
        color: '#848FAC',
        fontWeight: '400',
        fontFamily: 'Poppins',
        lineHeight: '21px',
        whiteSpace: 'nowrap',
        marginLeft: '5'
    },
    checkBoxStyle: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 400,
        letterSpacing: "0em",
        textAlign: "left",
        color: "#273567",
        marginLeft: '-8%',
    },
    registerButton: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        fontFamily: "Poppins",
        marginTop: "26px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    submitButton: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '16px',
        color: '#fff',
        width: '100%',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: 'Poppins'
    },
    cardOtp: {
        // whiteSpace: 'nowrap',
        color: '#848FAC',
        textAlign: "center",
        fontFamily: " Poppins",
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%"
    },
    letterCard: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '16px'
    },
    labelsEmailPassword: {
        color: "#334155",
        fontWeight: 700,
        font: "inter",
        textTransform: "none",
        fontFamily: "inter",
    },
    inputEmailPassword: {
        width: "100%",
        height: "50px",
        borderRadius: "8px",
        fontSize: "14px",
        marginTop: "5px",
        fontWeight: "500",
        // border: "1px solid #273567",
        paddingLeft: "10px",
        paddingRight: "10px",
        color: '#192252 !important',
        fontFamily: 'Poppins'
    },
    visiblestyle: {
        color: "#848FAC",
        cursor: "pointer"
    },

}
// Customizable Area End
