Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.loanData = [
  {
    loadID: 'AAA1112222',
    dateAndTime: "15 JAN 2024 | 09:15",
    transactionType: "Deposit",
    transactionStatus: "COMPLETED",
    transactionValue: "R1000",
  },
  {
    loadID: 'BBB3334444',
    dateAndTime: "18 JAN 2024 | 11:45",
    transactionType: "Withdrawal",
    transactionValue: "R750",
    transactionStatus: "PENDING",
  },
  {
    loadID: 'CCC5556666',
    dateAndTime: "22 JAN 2024 | 14:30",
    transactionType: "Payment",
    transactionValue: "R1200",
    transactionStatus: "FAILED",
  },
  {
    loadID: 'DDD7778888',
    dateAndTime: "25 JAN 2024 | 16:00",
    transactionType: "Transfer",
    transactionValue: "R500",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'AAA1112222',
    dateAndTime: "28 JAN 2024 | 10:20",
    transactionType: "Deposit",
    transactionValue: "R1500",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'BBB3334444',
    dateAndTime: "01 FEB 2024 | 13:45",
    transactionType: "Withdrawal",
    transactionValue: "R2000",
    transactionStatus: "PENDING",
  },
  {
    loadID: 'CCC5556666',
    dateAndTime: "03 FEB 2024 | 09:30",
    transactionType: "Payment",
    transactionValue: "R300",
    transactionStatus: "FAILED",
  },
  {
    loadID: 'DDD7778888',
    dateAndTime: "06 FEB 2024 | 12:15",
    transactionType: "Transfer",
    transactionValue: "R1000",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'AAA1112222',
    dateAndTime: "09 FEB 2024 | 14:50",
    transactionType: "Deposit",
    transactionValue: "R2500",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'BBB3334444',
    dateAndTime: "12 FEB 2024 | 08:00",
    transactionType: "Withdrawal",
    transactionValue: "R350",
    transactionStatus: "PENDING",
  },
  {
    loadID: 'CCC5556666',
    dateAndTime: "14 FEB 2024 | 10:40",
    transactionType: "Payment",
    transactionValue: "R1700",
    transactionStatus: "FAILED",
  },
  {
    loadID: 'DDD7778888',
    dateAndTime: "20 MAR 2023 | 15:30",
    transactionType: "Transfer",
    transactionValue: "R2200",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'AAA1112222',
    dateAndTime: "20 MAR 2023 | 09:00",
    transactionType: "Deposit",
    transactionValue: "R900",
    transactionStatus: "COMPLETED",
  },
  {
    loadID: 'BBB3334444',
    dateAndTime: "23 FEB 2024 | 11:25",
    transactionType: "Withdrawal",
    transactionValue: "R800",
    transactionStatus: "PENDING",
  },
  {
    loadID: 'CCC5556666',
    dateAndTime: "23 FEB 2024 | 14:10",
    transactionType: "Payment",
    transactionValue: "R1300",
    transactionStatus: "FAILED",
  }
];
exports.transactionArrayTypes = [
  { id: 1, text: 'Deposit', checked: false },
  { id: 2, text: 'Withdrawal', checked: false },
  { id: 3, text: 'Payment', checked: false },
  { id: 4, text: 'Transfer', checked: false },
];
exports.noResultFoundText = "No Result Found";
// Customizable Area End