Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.storeCardData = [
  {
    referenceNumber: "3N2A4N",
    PosStatus: "Active",
    walletBalance: 3456,
    overallCommissions: 4456,
    buxBalance: 2446,
    bonusBalance: 4633,
    numberTerminals: 2343,
    numberCardDevices: 3425,
    cardTransactionsMTD: "Transaction",
    cashDepositorMTD: "Depositor",
    loanStatus: "Good Standing",
    outStandingLoanAmount: 6473
  },
  {
    referenceNumber: "3N2B4N",
    PosStatus: "Inactive",
    walletBalance: 3456,
    overallCommissions: 4456,
    buxBalance: 2446,
    bonusBalance: 4633,
    numberTerminals: 2343,
    numberCardDevices: 3425,
    cardTransactionsMTD: "Transaction",
    cashDepositorMTD: "Depositor",
    loanStatus: "Overdue",
    outStandingLoanAmount: 6473
  },
  {
    referenceNumber: "3N2C4N",
    PosStatus: "Active",
    walletBalance: 3456,
    overallCommissions: 4456,
    buxBalance: 2446,
    bonusBalance: 4633,
    numberTerminals: 2343,
    numberCardDevices: 3425,
    cardTransactionsMTD: "Transaction",
    cashDepositorMTD: "Depositor",
    loanStatus: "Default",
    outStandingLoanAmount: 6473
  }
];
exports.seeMoreButtonText = "see more stores";
// Customizable Area End