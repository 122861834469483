import React from "react";

import {
    Box, Typography, styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import UserProfileOperator from "./UserProfileOperatorBasicBlock.web";
import UserProfileOperatorDocument from "./UserProfileOperatorDocument.web";
import CommonHeaderOperator from "../../../components/src/CommonHeaderOperator.web";

const TimeTypographyUser = styled(Typography)({
    '@media (max-width: 600px)': {
      fontSize:"16px !important"
    }
  })
  const RightBoxLandingCommon = styled(Box)({
    '@media(min-width: 992px)': {
      marginLeft: 150
    }
  })

export default class UserOperatorCommon extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

render() {
    
    return (
        // Customizable Area Start
        <Box style={{display:"flex"}}>
        <OperatorSideBar />
          <RightBoxLandingCommon style={{width:"100%",backgroundColor:"rgb(226, 232, 240)"}}>
          <Box style={{ width: '100%' }}>
                            <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                        </Box>
             <OperatorHeader />
               <Box style={webStyle.userSettingOperaContainer as React.CSSProperties}>
        <Box style={webStyle.settingTabMainOpera as React.CSSProperties}>
                   <Box
                       style={this.state.settinOperatorTab === 'UserProfileOperator' ? (webStyle.activesettingTab2Oprato as React.CSSProperties) : (webStyle.settingTab2Operat as React.CSSProperties)}
                       onClick={this.handleSettingOperatorTabs.bind(this, 'UserProfileOperator')}
                   >
                       
                           <TimeTypographyUser style={this.state.settinOperatorTab === 'UserProfileOperator' ? (webStyle.activetabTextOpera as React.CSSProperties) : (webStyle.tabTextOpera as React.CSSProperties)}>
                               User Information
                           </TimeTypographyUser>
                       
                   </Box>
                   <Box
                        className="documentTab"
                       style={this.state.settinOperatorTab === 'UserProfileOperatorDocument' ? (webStyle.activesettingTab2Oprato as React.CSSProperties) : (webStyle.settingTab2Operat as React.CSSProperties)}
                       onClick={this.handleSettingOperatorTabs.bind(this, 'UserProfileOperatorDocument')}
                   >
                       
                           <TimeTypographyUser style={this.state.settinOperatorTab === 'UserProfileOperatorDocument' ? (webStyle.activetabTextOpera as React.CSSProperties) : (webStyle.tabTextOpera as React.CSSProperties)}>
                               Document
                           </TimeTypographyUser>
                       
                   </Box>
               </Box>
               </Box>
               
               {this.state.settinOperatorTab === "UserProfileOperator" && (
                
                <UserProfileOperator navigation="" id="" />
              )}
              {this.state.settinOperatorTab === "UserProfileOperatorDocument" && <UserProfileOperatorDocument navigation="" id="" />}
               </RightBoxLandingCommon>
             
        </Box>
        // Customizable Area End
    );
}
}

// Customizable Area Start
const webStyle = {
    userSettingOperaContainer: {
        backgroundColor: "#E2E8F0",
    },

    settingTabMainOpera: {
        backgroundColor: "rgb(226, 232, 240)",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "20px 40px",
        flexWrap: "wrap",
    },

    activesettingTab2Oprato: {
        backgroundColor: 'rgba(193, 32, 48, 0.03)',
        padding: '15px',
        borderRadius: '16px',
        width: '100%',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #C12030',
    },

    tabTextOpera: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },

    activetabTextOpera: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },

    settingTab2Operat: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },

}
// Customizable Area End