export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const redwallet = require("../assets/redwallet.png");
export const whitewallet = require("../assets/whitewallet.png");
export const redcommision = require("../assets/redcommision.png");
export const whitecommision = require("../assets/whitecommision.png");
export const redpaybills = require("../assets/redpaybills.png");
export const whitepaybills = require("../assets/whitepaybills.png");
export const leftArrow = require("../assets/leftArrow.png");
export const bagIcon = require("../assets/bagIcon.png");
export const downArrow = require("../assets/downArrow.png");
export const affricanBank = require("../assets/affricanBank.png");
export const investec = require("../assets/investec.png");
export const nedbank = require("../assets/nedbank.png");
export const bidvestBank = require("../assets/bidvestBank.png");
export const arrow_back = require("../assets/arrow_back.png");
export const image_expand = require("../assets/image_expand.png");
export const BackIcn = require("../assets/BackIcn.png");
export const DropDown = require("../assets/DropDown.png");
export const ABSABank = require("../assets/Bank1.png");
export const FNBBank = require("../assets/bank2.png");