import React from "react";

import {
    Box,
    Card,
    CardContent,
    Typography,
    styled,
    Button

} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { storeicon } from './assets';

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomBox = styled(Box)({
    paddingRight: '98px',
    paddingTop:"25px",
    '@media(max-width: 600px)': {
        padding: '10px'
    }
})

const CardBox = styled(Box)({
    padding: '32px 23px',
    '@media(max-width: 600px)':{
        padding: '32px 40px 40px 40px'
    },
    '@media(max-width: 992px)': {
        padding: '32px 40px 60px 38px'
    }
  
})


export default class StoreSettingsOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        let data8 = [
            {
                StoreTItleOper: "Khandal saree store, 403",
                RefrenceOper: "Reference Number",
                RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",
                CommiValueOper: "3456",
                StatusOper: "Loan status",
                ImageOper: storeicon,
                StatusNameOper: "Approve"
            },
            {
                StoreTItleOper: "Khandal saree store, 403 ",
                RefrenceOper: "Reference Number",
                RefValueOper: "A4D0V2",CommissionsOper: "Over all Commissions",CommiValueOper: "3456",StatusOper: "Loan status",ImageOper: storeicon,StatusNameOper: "Approve"
            }, {
                StoreTItleOper: "Khandal saree store, 403 ",RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",CommiValueOper: "3456",
                StatusOper: "Loan status",ImageOper: storeicon,StatusNameOper: "Approve"
            }, {
                StoreTItleOper: "Khandal saree store, 403 ",
                RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",CommissionsOper: "Over all Commissions",CommiValueOper: "3456",StatusOper: "Loan status",ImageOper: storeicon,StatusNameOper: "Approve"
            }, {
                StoreTItleOper: "Khandal saree store, 403 ",RefrenceOper: "Reference Number",
                RefValueOper: "A4D0V2",CommissionsOper: "Over all Commissions",
                CommiValueOper: "3456",StatusOper: "Loan status",ImageOper: storeicon,
                StatusNameOper: "Approve"
            }, {
                StoreTItleOper: "Khandal saree store, 403 ",
                RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",CommiValueOper: "3456",
                StatusOper: "Loan status",ImageOper: storeicon,
                StatusNameOper: "Approve"
            },
            {
                StoreTItleOper: "Khandal saree store, 403 ",
                RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",CommiValueOper: "3456",
                StatusOper: "Loan status",ImageOper: storeicon,
                StatusNameOper: "Approve"
            }, {
                StoreTItleOper: "Khandal saree store, 403 ",
                RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",CommiValueOper: "3456",
                StatusOper: "Loan status",ImageOper: storeicon,
                StatusNameOper: "Approve"
            },
            {
                StoreTItleOper: "Khandal saree store, 403 ",RefrenceOper: "Reference Number",RefValueOper: "A4D0V2",
                CommissionsOper: "Over all Commissions",CommiValueOper: "3456",
                StatusOper: "Loan status",ImageOper: storeicon,
                StatusNameOper: "Approve"
            },
        ]
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <OperatorSideBar />
                <RightBoxLanding style={webStyleopperr.secondContaineropperr}>
                    <Box style={{ width: '100%' }}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>

                    <Box style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                        <OperatorHeader  />
                        
                        <CustomBox style={{  justifyContent: "end",backgroundColor:'#E2E8F0',display: 'flex' }}>
                            <Button data-test-id="addtootherstore" style={{ padding: '16px ',marginBottom:'20px', border: '2px solid #C12030', height: '50px', maxWidth: '270px', width: '100%' }}  onClick={this.navigateToAddOtherStoresOperator}>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, color: '#C12030', flexWrap: 'nowrap',textTransform:'capitalize' }} >Add Other Store</Typography>
                            </Button>
                            <Button data-test-id="addtomystore" style={{ marginLeft:'20px',marginBottom:'20px', padding: '16px ', backgroundColor:'#C12030', height: '50px', maxWidth: '270px', width: '100%' }} onClick={this.navigateToAddMyStoresOperator}>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, color: '#fff', flexWrap: 'nowrap',textTransform:'capitalize' }}>Add My Store</Typography>
                            </Button>
                        </CustomBox>
                        <CardBox style={{ flexWrap: "wrap",display: "flex", gap: "30px", background: "rgb(226, 232, 240)",cursor:'pointer' }}>
                            {data8.map((item, index) => (
                                <Card data-test-id={`store-${index}`} key={index} style={{ maxWidth: "351px" } as React.CSSProperties} onClick={this.navigateToSettingsStoreDetailsOperator}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 2 }}>
                                            <img src={item.ImageOper} alt="Store" style={{ width: '100%', height: '228px' }} />
                                        </Box>
                                        <Typography style={webStyleopperr.productTitle2opperr}>{item.StoreTItleOper}</Typography>
                                        <Box style={webStyleopperr.cardTexts2opperr}>
                                            <Typography style={webStyleopperr.productDescription2opperr}>{item.RefrenceOper}</Typography>
                                            <Typography style={webStyleopperr.referencenoopperr}>{item.RefValueOper}</Typography>
                                        </Box>
                                        <Box style={webStyleopperr.cardTexts2opperr}>
                                            <Typography style={webStyleopperr.productDescription2opperr}>{item.CommissionsOper}</Typography>
                                            <Typography style={webStyleopperr.productDescription2opperr}>{item.CommiValueOper}</Typography>
                                        </Box>
                                        <Box style={webStyleopperr.cardTexts2opperr}>
                                            <Typography style={webStyleopperr.productDescription2opperr}>{item.StatusOper}</Typography>
                                            <Typography style={webStyleopperr.approveTextopperr}>{item.StatusNameOper}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}

                        </CardBox>
                    </Box>
                </RightBoxLanding>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyleopperr = {
    productTitle2opperr: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    cardTexts2opperr: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    } as React.CSSProperties,
    productDescription2opperr: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    } as React.CSSProperties,
    approveTextopperr: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2opperr: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referencenoopperr: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    } as React.CSSProperties,
    secondContaineropperr:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: "#E2E8F0",
            height: "1100px"
        } as React.CSSProperties,
}
// Customizable Area End