import React from "react";

import {
  Box,
  Typography,
  styled,
  Button,
  Fade,
  Modal,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Select,
  MenuItem,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { leftArrow, ABSABank, FNBBank } from './assets'
import CustomStyledRadio from "../../../components/src/CustomStyleRadio.web";
// Customizable Area Start
import Cfwallet53Controller, {
  Props,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"

const PaymentBoxLanding = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

const CustomBox = styled(Box)({
  paddingTop: "30px",
  borderRadius: '12px',
  marginTop: '25px',
  marginLeft: '54px',
  backgroundColor: '#ffffff',
  maxWidth: "665px",
  padding: "30px",
  '@media(max-width: 600px)': {
    margin: '10px',
  }
})
const CustomImageBox = styled(Box)({
  display: 'flex',
  gap: '24px',
  '@media(max-width: 600px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})

const CardMobileBox = styled(Box)({
  maxWidth: "630px",
  borderRadius: '12px',
  padding: '20px',
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.4), 1px 2px 2px 0 rgba(0, 0, 0, 0.1)',
})

const BillsText = styled(Box)({
  '@media(max-width: 990px)': {
    marginTop: "0 !important",
    display:"none"
  }
})

const PayText = styled(Typography)({
  '@media(max-width: 900px)': {
    fontSize: "20px !important"
  }
})

const ModeText = styled(Typography)({
  '@media(max-width: 450px)': {
    fontSize: "14px !important"
  }
})
const ModeSubText = styled(Typography)({
  '@media(max-width: 450px)': {
    fontSize: "8px !important"
  }
})

const ModalBox = styled(Box)({
  boxSizing:"border-box",
  backgroundColor: "white",
  padding: "20px",
  fontSize: "24px",
  fontFamily: "Poppins",
  borderRadius: "16px",
  outline: "none",
  maxWidth: "600px",
  width: "100%",
  fontWeight: 500,
  border: '2px solid white',
  flexDirection: 'column',
  display: 'flex',
  boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
  justifyContent: 'safe center',
  overflow:'scroll',
  '@media(max-width: 600px)': {
    width: '80%',
   height:'80%'

  }
})

const CustomBorderBox = styled(Box)({
  width:'100%',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 8px',
  marginTop: '10px',
  border: '1px solid rgba(39, 53, 103, 0.50)',
  boxSizing:'border-box',
  '@media(max-width: 600px)': {
    margin: '20px auto'
  }
})

const InputForBank = styled(Typography)({
  fontSize: '16px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  color: '#64748B !important',
  '@media(max-width: 600px)': {
    fontSize:'14px'
  },
  flex:1,
  width: "200px"
})
const MainBox = styled(Box)({
  '@media(max-width: 990px)': {
    display:"unset !important"
  }
})
const WalletTopForMobile = styled(Box)({
  display:"none",
  '@media(max-width: 990px)': {
    display:"block"
  }
})
// Customizable Area End


export default class PaymentSecondPage extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {


    return (
      // Customizable Area Start

      <Box>
        <SideNavbar />
        <PaymentBoxLanding style={webStyle.PaymentContainer}>
          <MainBox style={webStyle.HeadersMain}>
            <BillsText style={{paddingLeft: "40px" }}>
              <PayText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</PayText>
            </BillsText>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </MainBox>
          <WalletTopForMobile style={{ paddingLeft: "40px" }}>
              <PayText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</PayText>
            </WalletTopForMobile>
            <CustomBox>
              <Box style={{ display: 'flex', marginBottom: "30px", cursor: 'pointer' }} >
                <img src={leftArrow} style={{ width: '24px', height: '24px', }} onClick={() => this.handleTransferToOtherWalletThree()} data-test-id={'backImageId'} />
                <Typography style={webStyle.backIcon} onClick={() => this.handleTransferToOtherWalletThree()} data-test-id={'backTextId'}>Back</Typography>
              </Box>

              <CardMobileBox>
                <Typography style={webStyle.payText as React.CSSProperties}>Pay Using</Typography>
                <Box style={{ marginTop: 20 }}>
                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <RadioGroup value={this.state.selectedPaymentMethod}
                      onChange={this.handleSelectPaymentMethod}
                      aria-label="payment-method"
                      data-test-id={`handleSelectPaymentMethod`}
                      name="payment-method">
                      <FormControlLabel style={{ padding: "20px" }} value="creditCard" data-test-id={"buttonCrediCard"} control={<CustomStyledRadio />}
                        label={
                          <ModeText
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              fontFamily: 'Poppins',
                              color: 'black',

                            }}
                          >
                            Credit Card
                          </ModeText>
                        } />
                      <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                      <FormControlLabel style={{ padding: "20px" }} value="debitCard" data-test-id={"buttonDebitCard"} control={<CustomStyledRadio />} label={
                        <ModeText
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            fontFamily: 'Poppins',
                            color: 'black'
                          }}>
                          Debit Card
                        </ModeText>
                      } />
                      <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                      <FormControlLabel style={{ padding: "20px" }} value="commissionAccount" data-test-id={"buttonCommissionAccount"} control={<CustomStyledRadio />} label={
                        <ModeText
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            fontFamily: 'Poppins',
                            color: 'black'
                          }}
                        >
                          Commission Account
                          <Typography style={webStyle.subText}>Available Balance R2000</Typography>
                        </ModeText>
                      } />
                      <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                      {this.state.showWallet &&
                        <>
                          <FormControlLabel style={{ padding: "20px" }} value="khandala" control={<CustomStyledRadio />} label={
                            <>
                              <ModeText
                                style={{
                                  fontWeight: 400,
                                  fontSize: 16,
                                  fontFamily: 'Poppins',
                                  color: 'black'
                                }}
                              >
                                Khandal store
                              </ModeText>
                              <ModeSubText style={{
                                fontWeight: 400,
                                fontSize: "10px",
                                fontFamily: 'Poppins',
                                color: 'black'
                              }}>Available Balance R000</ModeSubText>
                            </>
                          } />
                          <div style={{ borderBottom: '1px solid #E2E8F0' }} />{
                            this.state.selectedPaymentMethod == "khandala" &&
                            this.handleWalletToast()
                          }
                          <FormControlLabel style={{ padding: "20px" }} value="shyamstore" control={<CustomStyledRadio />} label={
                            <>
                              <ModeText
                                style={{
                                  fontWeight: 400,
                                  fontSize: 16,
                                  fontFamily: 'Poppins',
                                  color: 'black'
                                }}
                              >
                                Shree shyam store
                              </ModeText>
                              <ModeSubText style={{
                                fontWeight: 400,
                                fontSize: "10px",
                                fontFamily: 'Poppins',
                                color: 'black'
                              }}>Available Balance R1000</ModeSubText>
                            </>
                          } />
                          <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                          <FormControlLabel style={{ padding: "20px" }} value="shubham" control={<CustomStyledRadio />} label={
                            <>
                              <ModeText
                                style={{
                                  fontWeight: 400,
                                  fontSize: 16,
                                  fontFamily: 'Poppins',
                                  color: 'black'
                                }}
                              >
                                Shubham store
                              </ModeText>

                              <ModeSubText style={{
                                fontWeight: 400,
                                fontSize: "10px",
                                fontFamily: 'Poppins',
                                color: 'black'
                              }}>Available Balance R5000</ModeSubText>
                            </>
                          } />
                          <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                        </>
                      }
                      <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                      <FormControlLabel style={{ padding: "20px" }} value="eft" data-test-id={"buttonEVF"} control={<CustomStyledRadio />} label={
                        <ModeText
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            fontFamily: 'Poppins',
                            color: 'black'
                          }}
                        >
                          EFT
                        </ModeText>
                      } />
                      {this.state.selectedPaymentMethod == "eft" &&
                        <CustomImageBox>
                          <Box
                            className="divutedttt"
                            style={{
                              ...webStyle.whitebackgroundBox,
                              border: this.state.bankSelect == 1 && this.state.bankModal === true ? "1px solid rgba(193, 32, 48, 1)" : "none"
                            }}
                            data-test-id="test" 
                            onClick={() => {
                              this.handleBankOpenModal(),
                                this.handlerbank(1)
                            }}>
                            <img src={ABSABank} style={{ width: '85px', height: '60px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                          </Box>
                          <Box style={{
                            ...webStyle.whitebackgroundBox,
                            border: this.state.bankSelect == 2 && this.state.bankModal === true ? "1px solid rgba(193, 32, 48, 1)" : "none"
                          }} onClick={() => {
                            this.handleBankOpenModal(),
                              this.handlerbank(2)
                          }}>
                            <img src={FNBBank} style={{ width: '85px', height: '60px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                          </Box>

                        </CustomImageBox>
                      }
                    </RadioGroup>
                  </FormControl>

                  <Box style={{ display: 'flex', marginTop: 32, gap: '8px', justifyContent: 'space-between' }}>
                    <Button style={webStyle.cancelButton} data-test-id="handlecancel" onClick={() => this.handleGoBack()}>
                      <Typography style={webStyle.cancelText as React.CSSProperties}>Cancel</Typography>
                    </Button>
                    <Button style={webStyle.submitButton} onClick={this.handlePaymentDone} data-test-id={'submitID'}>
                      <Typography style={webStyle.submitText as React.CSSProperties}>Submit</Typography>
                    </Button>
                  </Box>
                </Box>
              </CardMobileBox>
            </CustomBox>
          
          <Toaster position="top-center" />
          <Modal
            aria-labelledby="transition-modal-title"
            closeAfterTransition
            onClose={this.handleBankCloseModal}
            open={this.state.bankModal}
            style={{display:'flex',justifyContent:'center',alignItems:'center', overflow:'scroll'}}
            aria-describedby="transition-modal-description"
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }} >
            <Fade in={this.state.bankModal}>
              <ModalBox sx={webStyle.ModalStyle}>
                <Typography style={webStyle.bankDetails}>
                  Bank Details
                </Typography>
                <Typography style={webStyle.subBankDetails}>Bank Details</Typography>
                <CustomBorderBox>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Bank Name</InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "FIRST NATIONAL BANK" : "absa"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Account Number</InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "62675862976" : "4070708686"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >{this.state.bankSelect == 1 ? "Account Status" : "Account Name"}</InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "Active Account" : "T/A A2 PAY"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Branch Name</InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "BENMORE GARDENS 925" : "FOURWAYS"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Branch Code
                    </InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "251255" : "632905"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Swift Code
                    </InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "FIRNZAJJ" : "ABSAZAJJ"}</Typography>
                  </Typography>
                  <Typography style={webStyle.subContainerText}>
                    <InputForBank
                    >Date Opened
                    </InputForBank> 
                    <Typography style={webStyle.bankDetailsText}>{this.state.bankSelect == 1 ? "2017-02-09" : "20080207"}</Typography>
                  </Typography>
                </CustomBorderBox>
                <Box>
                  <Typography style={webStyle.detailText}>
                    Please complete the EFT to the bank account selected and mention store reference Number.
                  </Typography>
                  <Typography style={webStyle.detailTextTwo}>Once you are done, wait for sometime we will process and add the money to your wallet</Typography>
                </Box>
                <Box style={{ display: 'flex', marginTop: 32, gap: '8px', justifyContent: 'space-between' }}>
                  <Button style={webStyle.cancelButton} data-test-id="cancelID" onClick={() => this.handleBankCloseModal()} >
                    <Typography style={webStyle.cancelText as React.CSSProperties}>Cancel</Typography>
                  </Button>
                  <Button style={webStyle.submitButton} data-test-id="handleBankCloseModal11"  onClick={() => this.handleBankDetailsModel()} >
                    <Typography style={webStyle.submitText as React.CSSProperties}>Submit</Typography>
                  </Button>
                </Box>
              </ModalBox>
            </Fade>
          </Modal>
        </PaymentBoxLanding>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  PaymentContainer:
    {
      backgroundColor: "#E2E8F0",
      marginBottom: "30px"
    } as React.CSSProperties,

    bankDetailsText:{
      flex:1,
      textAlign:'left',
      marginLeft: "5px", 
      fontFamily: "Poppins", 
      fontSize: "14px", 
    fontWeight: 500
    } as React.CSSProperties,
  BoxContainer: {
    borderRadius: '12px',
    border: '1px solid #848FAC',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    maxWidth: '283px',
    padding: '16px',
    width: '100%',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  } as React.CSSProperties,
  subText: {
    fontWeight: 400,
    fontSize: '10px',
    fontFamily: 'Poppins',
    color: 'black'
  },
  backIcon: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    paddingLeft: 8,
    cursor: 'pointer'
  },

  payText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '20px',
    marginTop: 15
  },
  radioMain: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50,
    margin: "10px 0",
    fontFamily: 'Poppins',
  },
  cancelButton: {
    maxWidth: '285px',
    width: '100%',
    height: '56px',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #C12030',
    backgroundColor: '#fff'
  },
  submitButton: {
    maxWidth: '285px',
    width: '100%',
    height: '56px',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: 'C12030'
  },
  cancelText: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#C12030'
  },
  submitText: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#fff'
  },
  whitebackgroundBox: {
    maxWidth: '132px',
    width: '100%',
    height: '70px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.1)',
    cursor: "pointer"
  },
  ModalStyle: {
    padding: '15px'
  },
  bankDetails: {
    color: '#000',
    fontFamily: 'Poppins',
    fontWeigth: 500,
    fontSize: '20px'
  },
  subBankDetails: {
    color: '#273567',
    fontFamily: 'Poppins',
    fontWeigth: 400,
    fontSize: '16px',
    marginTop: '22px'
  },

  subContainerText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#64748B',
    marginTop: '10px',
    display: "flex",
    alignItems: "center"
  },
  detailText: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#000',
    marginTop: '20px'
  },
  detailTextTwo: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#000'
  },
  HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,

  choiceList: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    width: '100%'
  },
  inputStyle: {
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#64748B',
    width: '100%',
  },

};
// Customizable Area End
