import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData,removeStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast"
const successNotify = () => toast.success('Submitted Successful',{
  style: {
    maxWidth: 550,
    height:'12px',fontSize:'16px',
    fontWeight:400,
    fontFamily: "Poppins",
  },
 
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectStore:any;
  terminal:any;
  amount:any;
  remainingAmount:any;
  endAmount:any;
  terminalError:boolean;
  endAmountError:boolean;
  storeError:boolean;
  cashAmountError:boolean;
  cashFlotDetails:boolean;
  selectedTerminal:any
  selectedStore:any
  startAmount:any;
  borderAmount:boolean;
  flag:boolean;
  count:any;
  startCashFloatProcess:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfcashfloat2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectStore:1,
      terminal:1,
      amount:"",
      remainingAmount:'',
      endAmount:'',
      endAmountError:false,
      terminalError:false,
      storeError:false,
      cashFlotDetails:false,
      cashAmountError:false,
      selectedStore:'',
      selectedTerminal:'',
      startAmount:'',
      borderAmount:false,
      flag:false,
      count:0,
      startCashFloatProcess:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }
  componentDidMount = async () => {
   

    // setTimeout(() => {
      removeStorageData('selectStore')
      removeStorageData('terminal')
      removeStorageData('amount')
      removeStorageData('startCashFloatProcess')
      removeStorageData('todaysDate')
    // }, 1000)
}
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
   handleChange = (event:any) => {
   this.setState({selectStore:event.target.value,storeError:false})
  };
  handleSetAmount = (event:any) => {
    const inputAlternateNumber = event.target.value.replace(/[^0-9]/g, '');;
    // Check if the input is a valid 10-digit number
    let formattedNumber = '';
    if (inputAlternateNumber) {
      formattedNumber = Number(inputAlternateNumber).toLocaleString('en-IN');
    }
    
      this.setState({
        amount: formattedNumber,
        cashAmountError:false,
        borderAmount:false
        //isValidAlternateNumber: inputAlternateNumber.length === 10,
      });
  }
  handleSetEndAmount = (event:any) => {
    const inputAlternateNumber = event.target.value.replace(/[^0-9]/g, '');;
    // Check if the input is a valid 10-digit number
    let formattedNumber = '';
    if (inputAlternateNumber) {
      formattedNumber = Number(inputAlternateNumber).toLocaleString('en-IN');
    }
   
      this.setState({
        endAmount: formattedNumber,
        endAmountError:false
        //isValidAlternateNumber: inputAlternateNumber.length === 10,
      });
    
  }
  handleTerminalChange = (event:any) => {
    this.setState({terminal:event.target.value, terminalError:false})
  }
  
  handleOperatorSubmit = () => {
    const {terminal,amount} = this.state
    if (terminal == 1){
      this.setState({terminalError:true})
    }
    else if(amount == null){
      this.setState({cashAmountError:true,borderAmount:true,terminalError:false,storeError:false})
    }
    else if (terminal != '' && amount != null){
      this.setState({cashFlotDetails:true,borderAmount:false})
      setStorageData("selectStore", "Azad Store");
      setStorageData("terminal", this.state.terminal);
      setStorageData("amount", this.state.amount);
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CashFlotDetailsOperator');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }
  handleSubmit = () => {
    const {terminal,selectStore,amount} = this.state
    if(selectStore == 1){
      this.setState({storeError:true})
    }
     else if (terminal == 1){
      this.setState({terminalError:true,storeError:false})
    }
    else if(amount == ''){
      this.setState({cashAmountError:true,borderAmount:true,terminalError:false,storeError:false})
    }
    else if (selectStore != '' && terminal != '' && amount != ''){
      this.setState({cashFlotDetails:true,borderAmount:false,startCashFloatProcess:true})
      setStorageData("selectStore", this.state.selectStore);
      setStorageData("terminal", this.state.terminal);
      setStorageData("amount", this.state.amount);
      setStorageData("startCashFloatProcess",true)
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'StartDayAmount');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }
   goToNext = () =>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'StartDayAmount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
   }
  handleEndAmountSubmit  = () =>{
    const {endAmount} = this.state
    if(endAmount == ''){
      this.setState({endAmountError:true})
    }
    else{
    successNotify()
    this.setState({endAmountError:false,flag:true})
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-IN', options);
    setTimeout(()=>{
      this.setState({cashFlotDetails:true,borderAmount:false})
      setStorageData("todaysDate", formattedDate);
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'MerchantCashFloat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    },1000)
   
    }
  }
  handleEndAmountSubmitOperator  = () =>{
    const {endAmount} = this.state
    if(endAmount == ''){
      this.setState({endAmountError:true})
    }
    else{
    successNotify()
    this.setState({endAmountError:false,flag:true})
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-IN', options);
    setTimeout(()=>{
      this.setState({cashFlotDetails:true,borderAmount:false})
      setStorageData("flag", true);
      setStorageData("todaysDate", formattedDate);
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'OperatorCashFloat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    },1000)
   
    }
  }
  handleCashFloatDetails = () => {
    const {amount} = this.state

    if(amount == ''){
      this.setState({cashAmountError:true})
    }
  }
  goToDetails = () =>{
    let item = {store:this.state.selectStore,"terminal":this.state.terminal,"amount":this.state.amount}
    // setStorageData("selectStore", this.state.selectStore);
    // setStorageData("terminal", this.state.terminal);
    // setStorageData("amount", this.state.amount);
    this.setState({cashFlotDetails:true,borderAmount:false,flag:true})
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EndDayAmount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  goToDetailsOperator = () =>{
    this.setState({cashFlotDetails:true,borderAmount:false,flag:true})
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CashFlotDetailsOperator');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
   //api for sending request
   sendApiRequest = (endpoint:string, data:any,METHOD:string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      METHOD
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  
  // add money to wallet API call
  StartDay = () => {
    this.handleSubmit();
    const data = {
      Store: this.state.selectStore,
      POS: this.state.terminal,
      StartAmount: this.state.amount
    };
    return this.sendApiRequest("StartDay", data,"POST");
  }
  //end cashfloat
  EndDay = () => {
    this.handleEndAmountSubmit();
    const data = {
      Store: this.state.selectedStore,
      POS: this.state.selectedTerminal,
      StartAmount: this.state.startAmount,
      EndAmount:this.state.endAmount
    };
    return this.sendApiRequest("EndDay", data,"POST");
  }
  StartDayOperator = () => {
    this.handleOperatorSubmit();
    const data = {
      Store: this.state.selectStore,
      POS: this.state.terminal,
      StartAmount: this.state.amount
    };
    return this.sendApiRequest("StartDayOperator", data,"POST");
  }
  EndDayOperator = () => {
    this.handleEndAmountSubmitOperator();
    const data = {
      Store: this.state.selectedStore,
      POS: this.state.selectedTerminal,
      StartAmount: this.state.startAmount,
      EndAmount:this.state.endAmount
    };
    return this.sendApiRequest("EndDayOpeartor", data,"POST");
  }
  // Customizable Area End
}
