import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    Modal,
    Fade,
    styled
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
const BackimgOperator = styled('img')({
    '@media (max-width: 600px)': {
        height: '400px !important',
        objectFit: 'cover',
        objectPosition: 'bottom'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        height: '500px !important',
        objectFit: 'cover',
        objectPosition: 'bottom',
        width: '100%'
    },
})
const LogoimgOperator = styled('img')({
    '@media (max-width: 600px)': {
        left: 'unset !important',
        top: '250px !important'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        left: 'unset !important',
        top: '250px !important'
    },
})
const SubTypograpgyOperator = styled(Typography)({
    fontSize: '28px',
    ineHeight: '42px',
    marginTop: 0,
    flexWrap: "wrap",
    '@media (max-width: 600px)': {
        fontSize: '18px',
        lineHeight: '24px',
        flexWrap: "nowrap",
        marginTop: 30,
        padding:5
    },

})
const SubInTypograpgyOperator = styled(Typography)({
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    flexWrap: "wrap",
    '@media (max-width: 600px)': {
        fontSize: '12px',
        flexWrap: "wrap",
        padding:5
    },

})
const VarTypographyOperator = styled(Typography)({
    fontSize: 40,
    marginTop: 0,
    '@media (max-width: 600px)': {
      fontSize: 28,
      marginTop: 10
    }
  });
const CustomModalOperator = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    "@media (max-width: 600px)": {
      '& .MuiPaper-root': {
        width: '90%',
      },
    },
    "@media (min-width: 601px) and (max-width: 1024px)": {
      '& .MuiPaper-root': {
        width: '70%',
      },
    },
  });
  const SubTypographyOperator = styled(Typography)({
    whiteSpace: 'nowrap',
    marginTop: 0,
    '@media (max-width: 600px)': {
      fontSize: '12px',
      marginTop: 10,
      whiteSpace: 'normal'
    }
  })
  const TimeTypographyOperator = styled(Typography)({
    fontSize: '14px',
    whiteSpace: 'normal',
    marginTop: 0,
    '@media (max-width: 600px)': {
      fontSize: '12px',
      marginTop: 10,
      whiteSpace: 'nowrap'
    }
  })
  const ModalBoxOperator = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
      height: 'auto',
      width: '70%',
      maxWidth: '400px',
    }
  })
// Customizable Area Start
import { background_image, cross, greentick, a2payImage } from './assets';

// Customizable Area End

import SignuploginController, {
    Props
} from "./SignuploginController";

export default class ExistingUserOperator extends SignuploginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        const { seconds, timeout } = this.state;
        const displayTime = seconds >= 10 ? seconds : `0${seconds}`;
        return (
            // Customizable Area Start
            <Grid container>
                <Grid item xs={12}
                    sm={12} lg={6}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <LogoimgOperator src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top: 50, left: 242 }} />
                    <BackimgOperator src={background_image} style={{ width: '100%', height: '1024px' }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box style={webStyle.firstBox}>
                        <Box style={{ width: '100%' }}>
                            <SubTypograpgyOperator style={webStyle.welcomeText as React.CSSProperties}>Welcome back to A2pay</SubTypograpgyOperator>
                            <SubInTypograpgyOperator variant="body1" style={webStyle.merchantText as React.CSSProperties}>
                                if you are existing user enter your phone number
                            </SubInTypograpgyOperator>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelStyleTwo as React.CSSProperties}>Phone Number</label>
                                <input
                                    id="phoneNumber"
                                    type="text"
                                    name="mobileNumber"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                    style={{
                                        ...webStyle.inputStyle,
                                        borderColor: this.state.invalidInputs.includes('mobileNumber') ? 'red' : '#273567',
                                    }}
                                />
                                {this.state.invalidInputs.includes('mobileNumber') && (
                                    <span style={{ color: 'red', fontSize: '16px', marginTop: 16, fontFamily: 'Poppins' }}>
                                        Please enter a valid 10-digit number.
                                    </span>
                                )}
                            </Box>
                            <Button data-test-id="registor" style={webStyle.nextButton as React.CSSProperties} onClick={this.handleOpenModalOtp}>Next</Button>
                        </Box>
                    </Box>
                    <CustomModalOperator
            aria-labelledby="transition-modal-title"
            onClose={this.handleCloseModal}
            aria-describedby="transition-modal-description"
            open={this.state.isModalOpen}
            closeAfterTransition
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.isModalOpen}>
              <div
                style={webStyle.Modal1 as React.CSSProperties}
              >
                <img
                  style={webStyle.crossImage as React.CSSProperties}
                  src={cross}
                  onClick={this.handleCloseModal}
                />
                <VarTypographyOperator variant="h5" style={webStyle.verificationText as React.CSSProperties}>Verification</VarTypographyOperator>

                <Box style={{
                  justifyContent: 'center',
                  display: 'flex'
                }}>
                  {this.state.otp.map((digit: any, index: any) => (
                    <input
                      key={index}
                      data-testid="otp_test"
                      id={`otp-input-${index}`}
                      maxLength={1}
                      type="text"
                      style={webStyle.otpInput as React.CSSProperties}
                      value={digit}
                      data-test-id={`checkotpop${index}`}
                      onChange={(e) => this.handleInputChange(index, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          e.preventDefault();
                          this.handleInputChange(index, '', true);
                        }
                      }}
                    />
                  ))}
                </Box>
                <SubTypographyOperator style={webStyle.cardOtp as React.CSSProperties
                }>Enter the OTP code contained in the Email we sent you</SubTypographyOperator>
                <TimeTypographyOperator
                  style={
                    timeout
                      ? { ...webStyle.otpVerificationText2, color: 'red' }
                      : webStyle.otpVerificationText2
                  }
                >
                  {timeout ? 'OTP verification timed out' : `OTP verification code expires in: ${displayTime}s`}
                </TimeTypographyOperator>
                <Typography style={webStyle.resendOtpText as React.CSSProperties}>Resend OTP</Typography>
                <Button style={{
                                        ...webStyle.submitButton,
                                        width: '100%',
                                        opacity: this.state.otpError ? 0.6 : ""
                                    } as React.CSSProperties} 
                                    
                                    disabled={this.state.otpError} onClick={this.handleOpenModalNewUser}>Submit</Button>
              </div>
            </Fade>
          </CustomModalOperator>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            open={this.state.tickModel}
            onClose={this.handleCloseModal2}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.tickModel}>
              <ModalBoxOperator
                sx={webStyle.greentickModal}
              >
                <img
                  src={greentick}
                  style={{
                    height: '119px',
                    marginBottom: '20px',
                    width:'100%',
                    maxWidth: '119px',
                  }}
                />
                <Typography variant="h5" style={webStyle.userVerifiedTextTwo as React.CSSProperties}>User Verified</Typography>
              </ModalBoxOperator>
            </Fade>
          </Modal>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    nextButton: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '42px',
        color: '#fff',
        width: '100%',
        height: '50px',
        borderRadius: '8px',
        justifyContent: 'center',
        alightItems: 'center',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: 'Poppins'
    },
    labelStyleTwo: {
        color: "#273567",
        fontFamily: 'Poppins',
        lineHeight: " 24px",
        letterSpacing: "0em",
        marginTop: "16px",
        fontSize: "16px",
        fontWeight: "400"
    },
    welcomeText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize: '28px',
        // lineHeight: '42px'
    },

    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '14px'
    },
    merchantText: {
        // fontSize: '14px',
        color: '#848FAC',
        fontWeight: '400',
        fontFamily: 'Poppins',
        // lineHeight: '21px',
        // whiteSpace: 'nowrap',
    },

    inputStyleTwo: {
        width: '100%',
        color: '#273567',
        borderRadius: '8px',
        border: '1px solid #273567',
        height: '50px',
        fonstSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        flexShrink: 0,
        paddingLeft: 15,
        boxShadow: '0px 0px 10px 0px rgba(0, 151, 203, 0.20)'
    },
    Modal1: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '400px',
        height: 'auto',
        margin: 'auto',
        marginTop: '100px',
        width: '50%',
        flexShrink: '0',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)'
    },
    crossImage: {
        position: 'absolute',
        right: '5px',
        cursor: 'pointer',
        marginTop: -10,
        marginRight: 10
    },
    verificationText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
        fontFamily: " Poppins",
        fontWeight: 500,
        // fontSize: "40px",
        textAlign: "center",
        fontStyle: "normal",
        lineHeight: "140%"
    },
    otpInput: {
        textAlign: "center",
        fontSize: "18px",
        letterSpacing: "10px",
        width: "2em",
        margin: "10px",
        border: "none",
        borderBottom: "1px solid #273567",
        outline: "none",
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#273567',
    },
    cardOtp: {
    color: '#848FAC',
    textAlign: "center",
    fontSize: "19px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    lineHeight: "140%",
    fontWeight: 400

    },
    userVerifiedTextTwo: {
        fontSize: "33px",
        lineHeight: "140%",
        fontWeight: 400,
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: "center",
        color: '#030F2D',
        fontFamily: " Poppins",
        fontStyle: "normal"
    },
    otpVerificationText2: {
        display: 'flex',
    justifyContent: 'center',
    fontFamily: " Poppins",
    color: '#848FAC',
    alignContent: 'ceneter',
    fontWeight: 400,
    textAlign: "center",
    lineHeight: "140%",
    marginTop: '10',
    fontSize: "19px",
    fontStyle: "normal",
    } as React.CSSProperties,

    resendOtpText: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        color: '#273567',
        marginTop: '10',
        textAlign: "center",
        fontFamily: " Poppins",
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%"
    },

    greentickModal: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        // height: '60%',
        // width: '542px',
        borderRadius: '8px',
        // maxWidth: '400px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    firstBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        padding: '0px 30px'
    },
    submitButton: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        borderRadius: '8px',
        marginTop: '16px',
        color: '#fff',
        width: '100%',
        textTransform: 'capitalize',
        height: '50px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    },
    inputStyle: {
        width: '100%',
        color: '#273567',
        top: '26px',
        border: '1px solid',
        borderRadius: '8px',
        padding: 15,
        height: '50px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500
    },
};
// Customizable Area End
