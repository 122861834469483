import React, { Component } from "react";

import {
    Box,
    Typography,
    styled,
    Modal
} from "@material-ui/core";
export const CloseIcn = require('./CloseIcn.png');
import { Link } from "react-router-dom";
import SimpleListMenu from "./SimpleListMenu.web";

interface HeaderProps { }

interface HeaderState {
    logoutModalOperator:boolean,
    windowWidth: number;
    activeHeaderLink: string;
}



const TimeTypographyHeader = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})
const CustomBox = styled(Typography)({
    '@media (max-width: 600px)': {
        justifyContent: 'center'
    }
})
export default class OperatorHeader extends Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        logoutModalOperator:false,
        windowWidth: window.innerWidth,
        activeHeaderLink: ""
    };

    confirmLogout = () => {
        this.setState({ logoutModalOperator: true });
    }
    handeCloseLogoutOperator=()=>{
        this.setState({ logoutModalOperator: false });
    }
    handleRemoveRole = () => {
        localStorage.removeItem("Role")
        localStorage.removeItem("token")
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    render() {
        const { windowWidth, activeHeaderLink } = this.state;
        let path = window.location.pathname;
        return (
            // Customizable Area Start
            <>
                {windowWidth < 600 ? (
                    <SimpleListMenu />
                ) : (
                    <CustomBox style={webStyle.settingTabMainDoc as React.CSSProperties}>

                        <Link to="/OperatorUserProfile" style={webStyle.activesettingTabDoc as React.CSSProperties}>
                            <Box >
                                <TimeTypographyHeader style={path === "/OperatorUserProfile" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>User Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/OperatorSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties} >
                            <Box>
                                <TimeTypographyHeader style={path === "/OperatorSettingsOperator" || path === "/RegisterNewOperatorOp" || path === "/AddOperatorSettings" || path === "/AddOperatorsOp" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Operator Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/BillingSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties} >
                            <Box>
                                <TimeTypographyHeader style={path === "/BillingSettingsOperator" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Billing Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/StoreSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties}>
                            <Box>
                                <Typography style={path === "/AddMyStoresOperators" || path === "/AddOtherStoreOperator" || path == "/StoreSettingDetailsOperator" || path === "/StoreSettingsOperator" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Store Settings</Typography>
                            </Box>
                        </Link>
                        <Box 
                        onClick={this.confirmLogout}  
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                        } as React.CSSProperties}
                    >
                        <Box>
                            <TimeTypographyHeader style={activeHeaderLink === "/Login" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                Log Out
                            </TimeTypographyHeader>
                        </Box>
                    </Box>

                    <Modal
                        onClose={this.handeCloseLogoutOperator}
                        open={this.state.logoutModalOperator}
                        aria-describedby="modal-modal-description"
                        aria-labelledby="modal-modal-title"
                    >
                        <Box style={{
                            backgroundColor: "white",
                            position: 'absolute',
                            top: '50%',
                            border: '2px solid white',
                            left: '50%',
                            padding: "10px",
                            fontWeight: 500,
                            borderRadius: "8px 8px 32px 8px",
                            fontFamily: "Poppins",
                            transform: 'translate(-50%, -50%)',
                            outline: "none",
                            maxWidth:"600px",
                            width:"100%",
                            fontSize: "24px",
                        }}>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <Box style={webStyle.modalTextOpperOperator as React.CSSProperties}>
                                    Are you sure you want logout?
                                </Box>
                                <Box style={{cursor:"pointer",marginTop:"10px",marginRight:"10px"}} onClick={this.handeCloseLogoutOperator}>
                                    <img src={CloseIcn} alt="close-icn"/>
                                </Box>
                            </Box>
                            <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
                            <Box style={webStyle.buttonContainerOpperOperator as React.CSSProperties}>
                                <Box onClick={this.handeCloseLogoutOperator} style={webStyle.noButtonOpperOperator as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Cancel</Typography>
                                </Box>
                                <Link to="/Login" onClick={this.handleRemoveRole} style={webStyle.yesButtonOpperOperator as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Logout</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Modal>
                    </CustomBox>
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    settingTabMainDoc: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "10px 40px",
        flexWrap: "wrap",
        backgroundColor: "#E2E8F0",
    },
    activesettingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",

    },
    activetabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    settingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "186px",
        textAlign: "center",
        cursor: "pointer"
    },
    settingTabDocDisabled: {
        backgroundColor: 'rgba(132, 143, 172, 0.5)',
        color: '#6c757d',
        cursor: 'not-allowed',
        opacity: 0.65,  pointerEvents: 'none'

    },
    modalTextOpperOperator:{
        fontSize:"16px",
        fontFamily:"Poppins",
        fontWeight:"600",
        lineHeight:"24px",
        marginTop:"10px"
    },
    buttonContainerOpperOperator: {
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-end",
        margin:"15px"
    },
    noButtonOpperOperator: {
        height: "40px",
        borderRadius: "8px",
        border:"1px solid #848FAC",
        width: "100%",
        maxWidth: "105px",
        textAlign: "center",
        alignItems: "center",
        fontFamily: 'Poppins',
        display: "flex",
        cursor: "pointer",
        fontWeight: 700,
        justifyContent: 'center',
        lineHeight: '24px',
        color: "#848FAC",
        fontSize: '16px',
        marginRight:"10px"
    },
    yesButtonOpperOperator: {
        all:"unset",
        borderRadius: "8px",
        background: "#C12030",
        maxWidth: "105px",
        alignItems: "center",
        height: "40px",
        width: "100%",
        textAlign: "center",
        justifyContent: 'center',
        fontFamily: 'Poppins',
        lineHeight: '24px',
        cursor: "pointer",
        fontWeight: 700,
        display: "flex",
        fontSize: '16px',
        color: "#ffffff",
    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
}
// Customizable Area End
