import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Select,
    styled,
    MenuItem
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const StyledSelect = styled(Select)({
    '&>.MuiSelect-select:focus':{
    backgroundColor:'transparent',
    },
    '&>#simple-select-outline':{
      display:'flex',
      paddingLeft:'15px',
      justifyContent:'center',
      height:"100%",
      color:'#273567',
      flexDirection:'column',
      fontSize:'14px',
      fontWeight:500,
    }
  })
// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { Toaster } from "react-hot-toast";





export default class UserProfileOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    componentDidMount = async () => {
        this.handleGetUserOperatorInformation()
       }
    render() {
        return (
            // Customizable Area Start
            <Box >
              <Box style={{width:"100%",backgroundColor:"#F6F7FA"}}>
            <Box style={webStyle.userSettingContainer2 as React.CSSProperties}>
 
                <Box style={webStyle.userSettingFeildsMian as React.CSSProperties}>
                    <Box style={webStyle.userSettingTextMain as React.CSSProperties}>
                        <Typography style={webStyle.userSettingText as React.CSSProperties}>User Settings</Typography>
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Name & Surname</label>
                        <input
                            data-test-id="firstName"
                            name="firstName"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.firstName}
                            onChange={this.handleInputChanges}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>ID/Passport</label>
                        <input
                            data-test-id='passport'
                            name="passportIdNumberId"
                            maxLength={13}
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor: '#273567',
                              }}
                            value={this.state.passportIdNumberId}
                            onChange={this.handleInputChanges}
                        />
                   {this.state.passportIdNumberIdError && <div className="errorMsg-email" style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.passportIdNumberIdError}</div>}

                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Citizen Status</label>
                        <input
                            data-test-id="citizenStatus"
                            name="citizenStatus"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.citizenStatus}
                            onChange={this.handleInputChanges}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={{...webStyle.labelStyle as React.CSSProperties,color:"#27356780"}}>Store Name(Non Editable)</label>
                        <input
                            name="storeName"
                            type="text"
                            style={{...webStyle.inputStyle, borderColor:"#27356780"}}
                            value=""
                            disabled
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Home Address</label>
                        <input
                            data-test-id="homeAddress"
                            name="homeAddress"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.homeAddress}
                            onChange={this.handleInputChanges}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={{...webStyle.labelStyle as React.CSSProperties,color:"#27356780"}}>Coordinates(Non Editable)</label>
                        <input
                            name="coordinates"
                            type="text"
                            style={{...webStyle.inputStyle, borderColor:"#27356780"}}
                            value=""
                            disabled
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Phone Number</label>
                        <input
                            data-test-id='phoneNumber'
                            name="phoneNumber"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.phoneNumber}
                            onChange={this.handlePhoneNumberChangeUserProfile}
                        />
                        {this.state.errorPhoneMessage && <div style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.errorPhoneMessage}</div>}
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Next of Kin</label>
                        <input
                            data-test-id='nextOfKin'
                            name="nextOfKin"
                            type="text"
                            maxLength={50}
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.nextOfKin}
                            onChange={this.handleInputChanges}
                        />
                    {this.state.nextOfKinError && <div style={{ color: 'red', marginTop: '5px', fontSize: '12px', fontWeight: 400, fontFamily: "Poppins" }}>{this.state.nextOfKinError}</div>}
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Email</label>
                        <input
                            data-test-id='email'
                            name="email"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                              onChange={this.handleEmailChange}
                              value={this.state.email}
                        />
                      {this.state.errorMessage && <div className="errorMsg-email" style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.errorMessage}</div>}
                    </Box>

                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Ethnicity</label>
                        <input
                            data-test-id='ethnicity'
                            name="ethnicity"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                              onChange={this.handleInputChanges}
                            value={this.state.ethnicity}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Gender</label>
                        <StyledSelect
                           data-test-id='gender'
                           name="gender"
                           value={this.state.gender}
                            id="simple-select-outline"
                            onChange={this.handleInputChanges}
                            style={{
                                ...webStyle.inputStyle,
                                top: 0,
                                padding: 0,
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                transformOrigin: { vertical: "top", horizontal: "left" },
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            }}
                            disableUnderline
                        >
                            <MenuItem value={'male'} style={webStyle.menuItem}>Male</MenuItem>
                            <MenuItem value={'female'} style={webStyle.menuItem}>Female</MenuItem>
                            <MenuItem value={'other'} style={webStyle.menuItem}>Other</MenuItem>
                        </StyledSelect>
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={{...webStyle.labelStyle as React.CSSProperties,color:"#27356780"}}>Position(Non Editable)</label>
                        <input
                            name="position"
                            type="text"
                            style={{...webStyle.inputStyle, borderColor:"#27356780"}}
                            value=""
                            disabled
                        />
                    </Box>
                    <Button data-test-id='submitoperator' onClick={this.handleOperatorSubmit} style={webStyle.submitButton as React.CSSProperties}>Submit</Button>
                </Box>
            </Box>
            </Box>
            <Toaster position="top-center"/>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    userSettingContainer2: {
        backgroundColor: '#E2E8F0',
    },
    userSettingFeildsMian: {
        backgroundColor: "#FFFFFF",
        maxWidth: "610px",
        borderRadius: "4px",
        padding: "20px",
        margin: "40px"
    },
    userSettingTextMain: {
        marginBottom: "40px"
    },
    userSettingText: {
        fontFamily: 'Poppins',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '40px',
        fontSize: '20px'
    },
    fieldsMain: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    labelStyle: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    inputStyle: {
        width: '100%',
        color: '#273567',
        borderRadius: '8px',
        border: '1px solid',
        top: '26px',
        padding: "15px",
        fontFamily: 'Poppins',
        height: '40px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
    },
    cordinateBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    captureIcon: {
        height: '30px',
        width: 'auto',
        top: '30',
        right: '10',
        position: 'absolute',
    },
    settingTabMain: {
        display:"flex",
        alignItems:"center",
        flexWrap:"wrap",
        gap:"12px",
        padding:"20px 40px",
    },
    activesettingTab: {
        padding: "15px",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        borderRadius: "16px",
        width: "100%",
        textAlign: "center",
        maxWidth: "200px",
        cursor: "pointer"

    },
    activesettingTab2: {
        backgroundColor: "rgba(193, 32, 48, 0.03)",
        borderRadius: "16px",
        width: "100%",
        padding: "15px",
        textAlign: "center",
        maxWidth: "200px",
        cursor: "pointer",
        borderBottom: "2px solid #C12030"
    },
    settingTab: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        borderRadius: "16px",
        padding: "15px",
        maxWidth: "200px",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },
    settingTab2: {
        padding: "15px",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        width: "100%",
        borderRadius: "16px",
        textAlign: "center",
        cursor: "pointer",
        maxWidth: "200px",
        borderBottom: "2px solid #848FAC"
    },
    activetabText: {
        fontWeight: '500',
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: '30px',
        color: "#C12030",
    },
    tabText2: {
        fontWeight: '500',
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: '30px',
        color: "#848FAC",
    },
    checkboxText: {
        fontFamily: 'Poppins',
        lineHeight: '24px',
        fontWeight: '400',
        fontSize: '16px',
        color: '#273567',
        marginTop: 16
    },
    radioMain: {
        flexDirection: 'row',
        display: 'flex',
        gap: 50
    },
    menuItem: {
        fontSize: '14px',
        color: '#273567',
        fontFamily: "Poppins",
        fontWeight: 500,
      },
    submitButton: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        textTransform: 'capitalize',
        height: '50px ',
        borderRadius: '8px',
        width: "100%",
        fontFamily:'Poppins',
        fontWeight: 400,
    },
}
// Customizable Area End
