import React from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import Header from "../../../components/src/Header.web"
import SideNavbar from "../../../components/src/SideBar.web"
import { storeicon } from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';

const RightBoxLandingOperator = styled(Box)({
    '@media(min-width: 992px)': {
      marginLeft: 150
    }
  })

const CustomBoxContainer = styled(Box)({
    padding:"40px",
    display:"flex",
    gap:"30px",
    flexWrap:"wrap",
    maxWidth:"100%",
    "@media (max-width:600px)":{
        justifyContent:'center',
        alignItems:'center'
    }
})
export default class OperatorSettings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
     
    }
    componentDidMount = async () => {
        this.handleGetStoreSettingsData()
       }
render() {
    const { initialStores, listOfStores } = this.state;
    const allItemsShown = initialStores >= listOfStores.length;
    return (
        // Customizable Area Start
        <Box style={{display:"flex",backgroundColor:"rgb(226, 232, 240)"}}>
        <SideNavbar />
          <RightBoxLandingOperator style={{width:"100%",}}>
          <Box style={webStyle.drawerBox }>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
             <Header/>
             <Box style={{backgroundColor: "rgb(226, 232, 240)"}}>
             <CustomBoxContainer>
             {listOfStores.slice(0, initialStores).map((item, index) => (
                        <Card key={index} style={{ maxWidth: "321px",cursor:"pointer" } as React.CSSProperties} data-test-id={`backnewoprat${index}`} onClick={() => this.handleAddNewOperator(item.CommiValue)}>
                          
                            <CardContent>
                                <Box sx={{ marginBottom: 2 }}>
                                    <img src={storeicon} alt="Store" style={{ width: '100%', height: '228px' }} />
                                </Box>
                                <Typography style={webStyle.productTitle}>{item.StoreTItle}</Typography>
                                <Box style={webStyle.cardTexts2}>
                                    <Typography style={webStyle.productDescription}>{item.Refrence}</Typography>
                                    <Typography style={webStyle.referenceno}>{item.RefValue}</Typography>
                                </Box>
                                <Box style={webStyle.cardTexts2}>
                                    <Typography style={webStyle.productDescription}>{item.Commissions}</Typography>
                                    <Typography style={{...webStyle.productDescription,fontSize:'16px'}}>{item.CommiValue}</Typography>
                                </Box>
                                <Box style={webStyle.cardTexts2}>
                                    <Typography style={webStyle.productDescription}>{item.Status}</Typography>
                                    <Typography style={{...webStyle.approveText,color:item.statusColor}}>{item.StatusName}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
            </CustomBoxContainer>
                    <Box style={{display:"flex",justifyContent:"center"}}>
                     <Button
             style={allItemsShown ? {...webStyle.seeMoreButton,background:'grey',color:'white'} :webStyle.seeMoreButton}
             data-test-id="seeMoreButtonTestID"
             disabled={allItemsShown}
             onClick={this.handleSeeMore}
            >
              <span style={{fontSize:'16px'}}>See More Stores</span>
            </Button>
            </Box>
            </Box>
                           
             </RightBoxLandingOperator>
             </Box>
        // Customizable Area End
    );
}
}

// Customizable Area Start
const webStyle = {
    productTitle: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    cardTexts2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    } as React.CSSProperties,
    productDescription: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    } as React.CSSProperties,
    approveText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referenceno: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    } as React.CSSProperties,

    drawerBox:{ width: '100%',backgroundColor: "rgb(226, 232, 240)" }as React.CSSProperties,
    seeMoreButton: {
        width: "228px",
        height: "50px",
        color: "#fff",
        fonstSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        backgroundColor: "#C12030",
        margin: "32px auto 30px",
        textTransform: "capitalize",
      } as React.CSSProperties,
}
// Customizable Area End