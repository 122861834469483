import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CardContent,
  Card,
  FormControlLabel,
  RadioGroup,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import StyleRadio from '../../../components/src/StyleRadio.web';
import { background_image, a2payImage } from './assets';
// Customizable Area End

import SignuploginController, {
  Props
} from "./SignuploginController";


const TimeTypographyOprat = styled(Typography)({
  '@media (max-width: 600px)': {
    fontSize: "20px !important"
  },
  '@media (max-width: 810px)': {
    fontSize: "18px !important"
  }
})
const SubTimeTypographyOPrat = styled(Typography)({
  '@media (max-width: 600px)': {
    fontSize: "12px !important"
  },
  '@media (max-width: 810px)': {
    fontSize: "12px !important"
  }
})

const LogoImageOprat = styled('img')({

  '@media (max-width: 600px)': {

    height: '400px !important',
    objectPosition: 'bottom',
    objectFit: 'cover',


  },


  '@media (min-width: 600px) and (max-width: 992px)': {

    height: '500px !important',
    objectPosition: 'bottom',
    objectFit: 'cover',
    width: '100%'

  },

})

const MainImageOprat = styled('img')({



  '@media (max-width: 811px)': {
    left: 'calc(100% - 65%) !important',
    top: 'calc(100% - 70%) !important'
  },

  '@media (max-width: 600px)': {
    left: 'calc(100% - 65%) !important',
    top: 'calc(100% - 70%) !important'
  },


  '@media (max-width: 415px)': {
    left: 'calc(100% - 75%) !important',
    top: 'calc(100% - 70%) !important'
  },


  '@media (max-width: 380px)': {
    left: 'calc(100% - 75%) !important',
    top: 'calc(100% - 70%) !important'
  },

})

const FieldGridOprat = styled(Grid)({

  '@media (max-width: 810px)': {
    padding: "0 20% !important"
  },

})

const NewUserCardOprat = styled(Card)({

  '@media (max-width: 1024px) ': {
    width: "170px"
  },

})

const ExistingUserCardOprat = styled(Card)({

  '@media (max-width: 1024px)': {
    width: "170px"
  },

})

const MainContainerOprat = styled(Grid)({

  '@media (max-width: 960px)': {
    height: "unset"
  },

})

export default class ChooseOperator extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <MainContainerOprat container >
        <Grid item
          xs={12}
          sm={12} lg={6}
          md={6}
        >
          <MainImageOprat src={a2payImage} style={{ position: 'absolute', left: "15%", top: "15%",  }} />
          <LogoImageOprat src={background_image} style={{ height: "100%", width: '100%',  }} />

        </Grid>

        <FieldGridOprat style={{ padding: '0px 60px', width: "100%" }} item xl={6} lg={6} md={6} xs={12} sm={12}>

          <Box style={webStyle.rightMainOprat as React.CSSProperties}>

            <Box style={{ width: "100%" }}>

              <Box >
                <TimeTypographyOprat style={webStyle.welcomeTextOprat as React.CSSProperties}>Welcome</TimeTypographyOprat>

                <SubTimeTypographyOPrat variant="body1" style={webStyle.merchantTextOPrat as React.CSSProperties}>
                  Please select if you are a new user or existing user to continue
                </SubTimeTypographyOPrat>

              </Box>

              <Box style={{ display: 'flex', flexWrap: "wrap",gap: 20, width: '100%' }}>

                <Box data-test-id="newUserCheck" style={{ width: '100%', maxWidth: "220px" }} onClick={() => {
                  this.props.navigation.navigate('OperatorSignUp')
                }}>
                  <NewUserCardOprat data-test-id='checkNewUser' style={{ position: 'relative', width: '100%', height: '180px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)', border: this.state.isNewUserChecked ? '2px solid #C12030' : '', borderRadius: '8px' }}>
                    <Box style={webStyle.cardBoxOprat as React.CSSProperties}>
                      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                        <FormControlLabel value="female" control={<StyleRadio  checked={this.state.isNewUserChecked} />} label="" />
                      </RadioGroup>
                    </Box>
                    <CardContent style={webStyle.cardContentOprat as React.CSSProperties}>
                      <Typography style={{ color: this.state.isNewUserChecked ? '#C12030' : 'black', fontSize: '20px', fontWeight: 500, fontFamily: 'Poppins' } as React.CSSProperties}>New User</Typography>
                    </CardContent>
                  </NewUserCardOprat>
                </Box>

                <Box style={{ width: '100%', maxWidth: "220px" }} onClick={() => {
                  this.props.navigation.navigate('ExistingOperatorFlow')
                }}>

                  <ExistingUserCardOprat style={{ position: 'relative',boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)', width: '100%', height: '180px',  border: this.state.isExistingUserChecked ? '2px solid #C12030' : '', borderRadius: '8px' }}>
                    
                    <Box style={webStyle.cardBoxOprat as React.CSSProperties}>
                      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                        <FormControlLabel value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                      </RadioGroup>
                    </Box>

                    <CardContent style={webStyle.cardContentOprat as React.CSSProperties}>
                      <Typography style={{ color: this.state.isExistingUserChecked ? '#C12030' : 'black',fontFamily: 'Poppins', fontSize: '20px', fontWeight: 500,  } as React.CSSProperties}>Existing User</Typography>

                    </CardContent>
                  </ExistingUserCardOprat>

                </Box>
              </Box>

              <Button style={webStyle.nextButtonOPrat as React.CSSProperties} onClick={this.handleNextButtonClick} data-test-id="nextBtn">Next</Button>

            </Box>
          </Box>

        </FieldGridOprat>
      </MainContainerOprat>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  welcomeTextOprat: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '28px',
    color: '#000',
    lineHeight: '42px'
  },
  merchantTextOPrat: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#848FAC',
    marginBottom: 35
  },
  nextButtonOPrat: {
    backgroundColor: '#C12030',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    color: '#fff',
    width: '100%',
    maxWidth: "486px",
    height: '50px',
    borderRadius: '8px',
    marginTop: '16px',
    textTransform: 'capitalize',
    top: 31,
  },
  cardContentOprat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80%',
    cursor: 'pointer'
  },
  cardBoxOprat: {
    position: 'absolute',
    right: '-10px',
    top: '8px',
    justifyContent: 'center'
  },
  rightMainOprat: {
    height: "100%",
    marginLeft:"6%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },


};
// Customizable Area End