Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.labelXLSXText = "XLSX";
exports.noResultFoundText = "No Result Found";
exports.providerSKUArrayValues = [{
  id: 1,
  text: "SKU 1",
  checked: false
}, {
  id: 2,
  text: "SKU 2",
  checked: false
}, {
  id: 3,
  text: "SKU 3",
  checked: false
}, {
  id: 4,
  text: "SKU 4",
  checked: false
},
];
exports.productSalesByCateData = [
  {
    "sku": "SKU 1",
    "qty": 200,
    "costOfSales": "R220",
    "sellingValue": "R480",
    "grossProfit": "R140",
    "category": "Category1"
  },
  {
    "sku": "SKU 2",
    "qty": 600,
    "costOfSales": "R300",
    "sellingValue": "R600",
    "grossProfit": "R200",
    "category": "Category2"
  },
  {
    "sku": "SKU 3",
    "qty": 100,
    "costOfSales": "R180",
    "sellingValue": "R400",
    "grossProfit": "R220",
    "category": "Category1"
  },
  {
    "sku": "SKU 4",
    "qty": 170,
    "costOfSales": "R320",
    "sellingValue": "R700",
    "grossProfit": "R380",
    "category": "Category2"
  },
  {
    "sku": "SKU 1",
    "qty": 900,
    "costOfSales": "R270",
    "sellingValue": "R550",
    "grossProfit": "R280",
    "category": "Category3"
  },
  {
    "sku": "SKU 2",
    "qty": 200,
    "costOfSales": "R350",
    "sellingValue": "R800",
    "grossProfit": "R450",
    "category": "Heritage Handloom Suppliers of Authentic Regional Fabrics"
  },
  {
    "sku": "SKU 3",
    "qty": 500,
    "costOfSales": "R200",
    "sellingValue": "R450",
    "grossProfit": "R250",
    "category": "Majestic Silks and Cottons Importers of Quality and Authenticity"
  },
  {
    "sku": "SKU 4",
    "qty": 300,
    "costOfSales": "R400",
    "sellingValue": "R900",
    "grossProfit": "R500",
    "category": "Category2"
  },
  {
    "sku": "SKU 1",
    "qty": 970,
    "costOfSales": "R150",
    "sellingValue": "R300",
    "grossProfit": "R150",
    "category": "Category1"
  },
  {
    "sku": "SKU 2",
    "qty": 100,
    "costOfSales": "R280",
    "sellingValue": "R600",
    "grossProfit": "R320",
    "category": "Category3"
  },
  {
    "sku": "SKU 3",
    "qty": 500,
    "costOfSales": "R380",
    "sellingValue": "R750",
    "grossProfit": "R370",
    "category": "Category2"
  },
  {
    "sku": "SKU 4",
    "qty": 700,
    "costOfSales": "R240",
    "sellingValue": "R520",
    "grossProfit": "R280",
    "category": "Category1"
  },
  {
    "sku": "SKU 1",
    "qty": 180,
    "costOfSales": "R420",
    "sellingValue": "R800",
    "grossProfit": "R380",
    "category": "Category3"
  },
  {
    "sku": "SKU 2",
    "qty": 400,
    "costOfSales": "R190",
    "sellingValue": "R400",
    "grossProfit": "R210",
    "category": "Category2"
  },
  {
    "sku": "SKU 3",
    "qty": 400,
    "costOfSales": "R310",
    "sellingValue": "R650",
    "grossProfit": "R340",
    "category": "Category3"
  }
];
exports.inventoryRowData = [
  {
    category: "Category1",
    sku: "SKU 1",
    description: "Description1",
    stockQuantity: 200,
    stockValue: "R500",
    reorderPoint: 12,
    reorderQuantity: 500
  },
  {
    category: "Category2",
    sku: "SKU 2",
    description: "Description2",
    stockQuantity: 201,
    stockValue: "R500",
    reorderPoint: 13,
    reorderQuantity: 501
  }, {
    category: "Category3",
    sku: "SKU 3",
    description: "Description3",
    stockQuantity: 202,
    stockValue: "R503",
    reorderPoint: 14,
    reorderQuantity: 503
  }, {
    category: "Category4",
    sku: "SKU 4",
    description: "Description4",
    stockQuantity: 203,
    stockValue: "R503",
    reorderPoint: 15,
    reorderQuantity: 504
  }, {
    category: "Vintage Sarees with Historical Significance and Design",
    sku: "SKU 1",
    description: "Khandal saree store has been the industry's standard ever since the 1500s",
    stockQuantity: 203,
    stockValue: "R504",
    reorderPoint: 16,
    reorderQuantity: 505
  }, {
    category: "Contemporary Fusion Sarees",
    sku: "SKU 2",
    description: "Description6",
    stockQuantity: 204,
    stockValue: "R505",
    reorderPoint: 17,
    reorderQuantity: 506
  }, {
    category: "Category7",
    sku: "SKU 3",
    description: "Description7",
    stockQuantity: 205,
    stockValue: "R506",
    reorderPoint: 18,
    reorderQuantity: 507
  }, {
    category: "Category8",
    sku: "SKU 4",
    description: "Description8",
    stockQuantity: 206,
    stockValue: "R507",
    reorderPoint: 19,
    reorderQuantity: 508
  }, {
    category: "Category9",
    sku: "SKU 1",
    description: "Description9",
    stockQuantity: 207,
    stockValue: "R508",
    reorderPoint: 20,
    reorderQuantity: 509
  }, {
    category: "Category10",
    sku: "SKU 2",
    description: "Description10",
    stockQuantity: 208,
    stockValue: "R509",
    reorderPoint: 21,
    reorderQuantity: 510
  },
  {
    category: "Category11",
    sku: "SKU 3",
    description: "Description10",
    stockQuantity: 209,
    stockValue: "R510",
    reorderPoint: 22,
    reorderQuantity: 511
  },
  {
    category: "Category12",
    sku: "SKU 4",
    description: "Description12",
    stockQuantity: 210,
    stockValue: "R512",
    reorderPoint: 22,
    reorderQuantity: 512
  },
];
exports.stockLevelOverviewText = "Stock Level Overview";
exports.walletHistoryData = [
  { 
    date:"1980-12-13",
    timestamp: "12:00PM",
    id: "1",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R100",
    balance: "R5400",
    productCode: 1909922,
    productDescription: "Description",
    terminal: "General",
    user: "User 1",
    comment: "Description",
  },
  {
    date:"1985-11-15",
    timestamp: "12:01PM",
    id: "2",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R120",
    balance: "R5500",
    productCode: 1909923,
    productDescription: "Description",
    terminal: "General",
    user: "User 2",
    comment: "Comment",
  },
  {
    date:"1990-10-12",
    timestamp: "12:02PM",
    id: "3",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R140",
    balance: "R5600",
    productCode: 1909924,
    productDescription: "Description",
    terminal: "General",
    user: "User 3",
    comment: "Comment",
  },
  {
    date:"1995-09-02",
    timestamp: "12:03PM",
    id: "4",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R160",
    balance: "R5700",
    productCode: 1909925,
    productDescription: "Description",
    terminal: "General",
    user: "User 4",
    comment: "Comment",
  },
  {
    date:"2000-12-31",
    timestamp: "12:04PM",
    id: "5",
    type: "Online",
    status: "Status",
    description: "Khandal saree store has been the industry's standard ever since the 1500s, It has quality and craftsmanship",
    amount: "R180",
    balance: "R5800",
    productCode: 1909926,
    productDescription: "Khandal saree store has been the industry's standard ever since the 1500s, It has quality and craftsmanship",
    terminal: "General",
    user: "User 5",
    comment: "Absolutely love the quality and craftsmanship of the sarees from Khandal Saree Store",
  },
  {
    date:"2001-01-31",
    timestamp: "12:05PM",
    id: "6",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R200",
    balance: "R5900",
    productCode: 1909927,
    productDescription: "Description",
    terminal: "General",
    user: "User 6",
    comment: "Comment",
  },
  {
    date:"1997-07-17",
    timestamp: "12:06PM",
    id: "7",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R220",
    balance: "R6000",
    productCode: 1909928,
    productDescription: "Description",
    terminal: "General",
    user: "User 7",
    comment: "Comment",
  },
  {
    date:"2015-11-05",
    timestamp: "12:07PM",
    id: "8",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R240",
    balance: "R6100",
    productCode: 1909929,
    productDescription: "Description",
    terminal: "General",
    user: "User 8",
    comment: "Comment",
  },
  {
    date:"2024-05-26",
    timestamp: "12:08PM",
    id: "9",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R260",
    balance: "R6200",
    productCode: 1909930,
    productDescription: "Description",
    terminal: "General",
    user: "User 9",
    comment: "Comment",
  },
  {
    date:"2008-03-23",
    timestamp: "12:09PM",
    id: "10",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R280",
    balance: "R6300",
    productCode: 1909931,
    productDescription: "Description",
    terminal: "General",
    user: "User 10",
    comment: "Comment",
  },
  {
    date:"2019-04-09",
    timestamp: "12:10PM",
    id: "11",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R300",
    balance: "R6400",
    productCode: 1909932,
    productDescription: "Description",
    terminal: "General",
    user: "User 11",
    comment: "Comment",
  },
  {
    date:"2024-01-18",
    timestamp: "12:11PM",
    id: "12",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R320",
    balance: "R6500",
    productCode: 1909933,
    productDescription: "Description",
    terminal: "General",
    user: "User 12",
    comment: "Comment",
  },
  {
    date:"2016-02-28",
    timestamp: "12:12PM",
    id: "13",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R340",
    balance: "R6600",
    productCode: 1909934,
    productDescription: "Description",
    terminal: "General",
    user: "User 13",
    comment: "Comment",
  },
  {
    date:"2023-08-27",
    timestamp: "12:13PM",
    id: "14",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R360",
    balance: "R6700",
    productCode: 1909935,
    productDescription: "Description",
    terminal: "General",
    user: "User 14",
    comment: "Comment",
  },
  {
    date:"1991-05-16",
    timestamp: "12:14PM",
    id: "15",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R380",
    balance: "R6800",
    productCode: 1909936,
    productDescription: "Description",
    terminal: "General",
    user: "User 15",
    comment: "Comment",
  },
  {
    date:"1995-05-18",
    timestamp: "12:15PM",
    id: "16",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R400",
    balance: "R6900",
    productCode: 1909937,
    productDescription: "Description",
    terminal: "General",
    user: "User 16",
    comment: "Comment",
  },
  {
    date:"1994-07-15",
    timestamp: "12:16PM",
    id: "17",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R420",
    balance: "R7000",
    productCode: 1909938,
    productDescription: "Description",
    terminal: "General",
    user: "User 17",
    comment: "Comment",
  },
  {
    date:"2022-12-16",
    timestamp: "12:04PM",
    id: "18",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R440",
    balance: "R7100",
    productCode: 1909939,
    productDescription: "Description",
    terminal: "General",
    user: "User 18",
    comment: "Comment",
  },
  {
    date:"1995-11-30",
    timestamp: "12:18PM",
    id: "19",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R460",
    balance: "R7200",
    productCode: 1909940,
    productDescription: "Description",
    terminal: "General",
    user: "User 19",
    comment: "Comment",
  },
  {
    date:"2024-03-29",
    timestamp: "12:19PM",
    id: "20",
    type: "Online",
    status: "Status",
    description: "Description",
    amount: "R480",
    balance: "R7300",
    productCode: 1909941,
    productDescription: "Description",
    terminal: "General",
    user: "User 20",
    comment: "Comment",
  }
];
exports.walletHistoryHeadingText = "Wallet History";
exports.printText = "Print";
// Customizable Area End