import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast"

const notifyError = () => toast.error("Please select repayment term", {
  style: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
    maxWidth: "100%",
  },
})

const notifyError2 = () => toast.error("Please enter loan amount", {
  style: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
    maxWidth: "100%",
  },
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  pageData: number;
  rowsPerPageData: number;
  isOpenSortBySalesR: boolean;
  values: Array<number>;
  rePayTerm: any;
  handleSuccessModal: boolean;
  setStore: string;
  setName: string;
  setSurname: string;
  setPhoneNumber: string;
  errorPhoneMessage: string;
  errorMessage: string;
  setEmail: string;
  setRefNumber: string;
  setStoreAdd: string;
  setLoanAmount: any;
  setDailyRepay: number;
  inputColor: any;
  loanList:Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfloanapplication2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiGetLoanListId:string = "";
  apiGetOperatorLoanListId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      pageData: 0,
      rowsPerPageData: 10,
      isOpenSortBySalesR: false,
      values: [0, 0],
      rePayTerm: "",
      handleSuccessModal: false,
      setStore: "",
      setName: "",
      setSurname: "",
      setPhoneNumber: "+27 ",
      errorPhoneMessage: "",
      errorMessage: "",
      setEmail: "",
      setRefNumber: "",
      setStoreAdd: "",
      setLoanAmount: "",
      setDailyRepay: 0,
      inputColor: "#273567",
      loanList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      this.handleRestAPIResponseMessage(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.apiGetLoanListId:
          this.handleMessageGetLoanList(responseJson)
          break;
          case this.apiGetOperatorLoanListId:
            this.handleMessageGetOperatorLoanList(responseJson)
            break;
      }
    }
  }  
  handleMessageGetLoanList(responseJson:any){
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({ loanList: responseJson.data });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetOperatorLoanList(res:any){
    if (res != null && res.errors === undefined) {
      return
    } else {
      this.parseApiErrorResponse(res);
    }
  }
  goBackClick = () => {
    this.props.navigation.navigate('Dashboard');
  };

  loanCalculator = () => {
    this.props.navigation.navigate('LoanCalculator');
  }
  goBackLoanCalc = () => {
    this.props.navigation.navigate('ApplyForLoan');
  }
  navigateToTermsLoanApplication = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LoanApplicationForm"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToLoanApplicationOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LoanApplicationFormOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToLoanApplication = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ApplicationFormOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToApplyForLoan = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorApplyForLoan"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToLoanCalculator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LoanCalculatorOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToLandingPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Dashboard"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToOperatorLandingPage=()=>{
    this.props.navigation.navigate('OperatorDashboard')
  }


  handleAutoFill = (event: any) => {
    const value = event.target.value;
    this.setState({
      setLoanAmount: value,
      inputColor: value ? '#C12030' : '#273567' // Update color based on value
    });
  };

  handleSelectStore = (e: any) => {
    this.setState({ setStore: e?.target?.value })
  }
  handleSetName = (e: any) => {
    this.setState({ setName: e?.target?.value })
  }
  handleSurname = (e: any) => {
    this.setState({ setSurname: e?.target?.value })
  }
  handlePhonenumber = (e: any) => {
    let inputPhoneN = e.target.value;
    inputPhoneN = inputPhoneN.replace(/[^\d+]/g, '');
    const digitsOnl = inputPhoneN.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';
    if (digitsOnl.length > 2) {
      formattedNumber += ' ' + digitsOnl.slice(2, 4);
    }
    if (digitsOnl.length > 4) {
      formattedNumber += ' ' + digitsOnl.slice(4, 7);
    }
    if (digitsOnl.length > 7) {
      formattedNumber += ' ' + digitsOnl.slice(7, 11);
    }
    const isValidNu = digitsOnl.length === 11;

    this.setState({
      setPhoneNumber: formattedNumber,
      errorPhoneMessage: isValidNu ? '' : 'Invalid Phone Number',
    });
  }
  validateEmailChange = () => {
    const { setEmail } = this.state;

    if (typeof setEmail !== 'string' || !setEmail.trim()) {
      this.setState({ errorMessage: "Please enter a valid email address." });
      return;
    }

    // Define a regex pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,3}$/;

    // Check if the email matches the regex pattern
    if (!emailPattern.test(setEmail)) {
      this.setState({ errorMessage: "Email is not in the correct format." });
      return;
    }

    this.setState({ errorMessage: '' });
  };
  handleEmail = (e: any) => {
    this.setState({
      setEmail: e.target.value,
    });
    this.validateEmailChange();
  }
  storeRefNumber = (e: any) => {
    this.setState({ setRefNumber: e?.target?.value })
  }
  handleStoreAdd = (e: any) => {
    this.setState({ setStoreAdd: e?.target?.value })
  }
  calculateDailyRepay = (loanAmount: any, months: any) => {

    if (!loanAmount || !months || parseInt(loanAmount) === 0) {
      return 0;
    }

    //new calculation
    const cleanedInput = loanAmount.replace(/[^0-9.]/g, ''); //cleaned loan amount entered
    const numericCleanedInput = Number(cleanedInput); // define loan amount as numeric value
    let interest = ((numericCleanedInput * 7) / 100) + 275; //including contract fee and other charges 
    if (interest > 2600) {
      interest = 2600;
    }
    const pv = interest + numericCleanedInput; // principal amount
    const i = 35.25 / 100 / 365;  //interest PA
    const n = months * 30; // counting days
    const numerator = i * pv; //as per furmula
    const denominator = 1 - Math.pow(1 + i, -n);  //as per furmula
    const dailyPayment = numerator / denominator;  //total repayment daily

    return parseFloat(dailyPayment.toFixed(2));
  }

  handleLoanAmount = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    const { rePayTerm } = this.state;
    let formattedNumber = '';
    let dailyRepay = 0;
    let inputColor = '#273567';
    let newRePayTerm = "";

    if (value && parseInt(value) > 0) {
        formattedNumber = Number(value).toLocaleString('en-IN');
        dailyRepay = this.calculateDailyRepay(value, rePayTerm);
        inputColor = '#C12030';
        newRePayTerm = rePayTerm;
    }

    this.setState({
        setLoanAmount: formattedNumber ? `R${formattedNumber}` : '',
        inputColor: inputColor,
        setDailyRepay: dailyRepay,
        rePayTerm: newRePayTerm,
    });
}

  handleTermSelectOperator = (event: any, newValue: any) => {
    const months = newValue;
    const { setLoanAmount } = this.state;
    const dailyRepay = this.calculateDailyRepay(setLoanAmount, months);

    this.setState({
      rePayTerm: newValue,
      setDailyRepay: dailyRepay
    });
  };

  handleSubmitForm = () => {
    const { setName, setSurname, setPhoneNumber, setEmail, setStore, setRefNumber, setStoreAdd, errorPhoneMessage, errorMessage } = this.state;

    if (setName === "") {
      toast.error("Name is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setSurname === "") {
      toast.error("Surname is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setPhoneNumber === "+27 ") {
      toast.error("Phone no. is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (errorPhoneMessage != "") {
      toast.error("Please enter a valid phone number", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setEmail === "") {
      toast.error("Email is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (errorMessage != "") {
      toast.error("Please enter a valid email address", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setStore === "") {
      toast.error("Please select the store", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setRefNumber === "") {
      toast.error("Store reference number is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else if (setStoreAdd === "") {
      toast.error("Store address is required", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      });
    } else {
      this.setState({
        handleSuccessModal: true,
        setName: "",
        setSurname: "",
        setPhoneNumber: "",
        setStore: "",
        setEmail: "",
        setRefNumber: "",
        setStoreAdd: ""
      });
    }
  }

  validatePhoneNumber = (phoneNumber: any) => {
    // Regular expression for validating phone number format (10 digits)
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  }

  validateEmail = (email: any) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  handleApplicationSucc = () => {
    this.setState({ handleSuccessModal: false })
  }

  handleForm = () => {
    if (this.state.setLoanAmount == "") {
      toast.error("Please enter loan amount", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    } else if (this.state.setDailyRepay == 0) {
      toast.error("Please select repayment term", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })

    } else {
      this.props.navigation.navigate('ApplicationForm');

    }
  }
 
  handleBackCalc = () => {
    this.props.navigation.navigate('LoanCalculator');
  }

  sendApiRequest = (endpoint:string, data:any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const httpBody = {
      data: data,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  
  // Loan amount and repay term API call
  LoanAmount = () => {
    this.handleForm();
    const data = {
      LoanAmount: this.state.setLoanAmount,
      Term: this.state.rePayTerm,
      DailyRepay: this.state.setDailyRepay
    };
    return this.sendApiRequest("Loan", data);
  }
  
  // API calling for loan application form
  handleLoanApplicationForm = () => {
    this.handleSubmitForm();
    const data = {
      Name: this.state.setName,
      Surname: this.state.setSurname,
      PhoneNumber: this.state.setPhoneNumber,
      Email: this.state.setEmail,
      Store: this.state.setStore,
      RefrenceNumber: this.state.setRefNumber,
      Address: this.state.setStoreAdd
    };
    return this.sendApiRequest("LoanApplicationForm", data);
  }
  getLoanList(){
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetLoanListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetLoanListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `loanList/get`
    );
    
    requestGetLoanListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiGetLoanListId = requestGetLoanListMessage.messageId;
    requestGetLoanListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetLoanListMessage.id, requestGetLoanListMessage);
  }
  getOperatorLoanList(){
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetOperatorLoanListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetOperatorLoanListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `loanList/get`
    );
    
    requestGetOperatorLoanListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiGetOperatorLoanListId = requestGetOperatorLoanListMessage.messageId;
    requestGetOperatorLoanListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetOperatorLoanListMessage.id, requestGetOperatorLoanListMessage);
  }
  // Customizable Area End
}
