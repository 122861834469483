import React from "react";

import {
    Box,
    Typography,
    styled,
    Button,
    Fade,
    Modal,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Select,
    MenuItem,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { leftArrow, affricanBank, investec, nedbank, bidvestBank } from './assets'
import CustomStyledRadio from "../../../components/src/CustomStyleRadio.web";
// Customizable Area Start
import Cfwallet53Controller, {
    Props,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';

const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "757px",
    padding: "30px",
    borderRadius: '12px',
})
const CustomImageBox = styled(Box)({
    display: 'flex',
    gap: '24px',
    '@media(max-width: 600px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})

const CardMobileBox = styled(Box)({
    maxWidth: "630px",
    borderRadius: '12px',
    padding: '20px',
    backgroundColor: '#ffffff',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
})

const BillsText = styled(Box)({
    '@media(max-width: 992px)': {
        marginTop: "0 !important"
    }
})

const PayText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "16px !important"
    }
})

const EFTText = styled(Typography)({
    '@media(max-width: 450px)': {
        fontSize: "14px !important"
    }
})
const EFTSubText = styled(Typography)({
    '@media(max-width: 450px)': {
        fontSize: "8px !important"
    }
})
// Customizable Area End


export default class EFTPaymentMode extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {


        return (
            // Customizable Area Start

            <Box>
                <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                    <Box style={webStyle.HeadersMain}>
                        <BillsText style={{ marginTop: "20px", paddingLeft: "40px" }}>
                            <PayText style={{ fontSize: "24px", fontFamily: 'Poppins', fontWeight: 700 }}>Pay Bills</PayText>
                        </BillsText>
                        <Box>
                            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                        </Box>
                    </Box>
                    <Box style={{ padding: "40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <CustomBox style={webStyle.backContainer}>
                            <Box style={{ display: 'flex', marginBottom: "30px", cursor: 'pointer' }} onClick={this.handleEFTPaymentModeBack}>
                                <img src={leftArrow} style={{ width: '24px', height: '24px', }} />
                                <Typography style={webStyle.backIcon}>Back</Typography>
                            </Box>

                            <CardMobileBox style={webStyle.cardStyle}>
                                <Typography style={webStyle.payText as React.CSSProperties}>Pay Using</Typography>
                                <Box style={{ marginTop: 20 }}>
                                    <FormControl component="fieldset" style={{ width: "100%" }}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <FormControlLabel style={{ padding: "20px" }} value="female" control={<CustomStyledRadio />}
                                                label={
                                                    <EFTText
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            fontFamily: 'Poppins',
                                                            color: 'black',

                                                        }}
                                                    >
                                                        Credit Card
                                                    </EFTText>
                                                } />
                                            <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                                            <FormControlLabel style={{ padding: "20px" }} value="male" control={<CustomStyledRadio />} label={
                                                <EFTText
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        fontFamily: 'Poppins',
                                                        color: 'black'
                                                    }}
                                                >
                                                    Debit Card
                                                </EFTText>
                                            } />
                                            <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                                            <FormControlLabel style={{ padding: "20px" }} value="commision" control={<CustomStyledRadio />} label={
                                                <>
                                                    <EFTText
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            fontFamily: 'Poppins',
                                                            color: 'black'
                                                        }}
                                                    >
                                                        Commission Account
                                                    </EFTText>
                                                    <EFTSubText
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 10,
                                                            fontFamily: 'Poppins',
                                                            color: '#000'
                                                        }}
                                                    >
                                                        Available Balance R2000
                                                    </EFTSubText>
                                                </>
                                            } />
                                            <div style={{ borderBottom: '1px solid #E2E8F0' }} />
                                            <FormControlLabel style={{ padding: "20px" }} value="eft" control={<CustomStyledRadio />} label={
                                                <EFTText
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        fontFamily: 'Poppins',
                                                        color: 'black'
                                                    }}
                                                >
                                                    EFT
                                                </EFTText>
                                            } />


                                        </RadioGroup>
                                    </FormControl>
                                    <CustomImageBox>
                                        <Box style={webStyle.whitebackgroundBox}>
                                            <img src={affricanBank} style={{ width: '104px', height: '58px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                                        </Box>
                                        <Box style={webStyle.whitebackgroundBox}>
                                            <img src={investec} style={{ width: '101px', height: '21px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                                        </Box>
                                        <Box style={webStyle.whitebackgroundBox}>
                                            <img src={nedbank} style={{ width: '100px', height: '33px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                                        </Box>
                                        <Box style={webStyle.whitebackgroundBox}>
                                            <img src={bidvestBank} style={{ width: '101px', height: '21px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }} />
                                        </Box>
                                    </CustomImageBox>
                                    <Box style={{ display: 'flex', marginTop: 32, gap: '8px' }}>
                                        <Button style={webStyle.cancelButton}>
                                            <Typography style={webStyle.cancelText as React.CSSProperties}>Cancel</Typography>
                                        </Button>
                                        <Button style={webStyle.submitButton} onClick={this.handleBankOpenModal}>
                                            <Typography style={webStyle.submitText as React.CSSProperties}>Submit</Typography>
                                        </Button>


                                    </Box>
                                </Box>
                            </CardMobileBox>
                        </CustomBox>
                    </Box>
                </RightBoxLanding2>
               
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    secondContainer:
        {
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,

    BoxContainer: {
        borderRadius: '12px',
        border: '1px solid #848FAC',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        maxWidth: '283px',
        padding: '16px',
        width: '100%',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    } as React.CSSProperties,

    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },
    backContainer: {
        // backgroundColor: '#ffffff',
    },
    cardStyle: {
        // maxWidth: "630px",
    },
    payText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '20px',
        marginTop: 15
    },
    radioMain: {
        display: 'flex',
        flexDirection: 'row',
        gap: 50,
        margin: "10px 0",
        fontFamily: 'Poppins',
    },
    cancelButton: {
        maxWidth: '285px',
        width: '100%',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
        border: '1px solid #C12030',
        backgroundColor: '#fff'
    },
    submitButton: {
        maxWidth: '285px',
        width: '100%',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'C12030'
    },
    cancelText: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: '#C12030'
    },
    submitText: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: '#fff'
    },
    whitebackgroundBox: {
        maxWidth: '132px',
        width: '100%',
        height: '70px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px 4.579px 18.318px 0px rgba(0, 0, 0, 0.06), 0px 2.29px 4.579px 0px rgba(0, 0, 0, 0.03)'
    },
    ModalStyle: {
        // position: 'relative',
        // backgroundColor: '#fff',
    },
    bankDetails: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeigth: 500,
        fontSize: '20px'
    },
    subBankDetails: {
        color: '#273567',
        fontFamily: 'Poppins',
        fontWeigth: 400,
        fontSize: '16px',
        marginTop: '22px'
    },
    subContainer: {
        // border: '1px solid rgba(39, 53, 103, 0.50)',
        // marginTop:'10px'
    },
    subContainerText: {
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#64748B',
        marginTop: '10px'
    },
    detailText: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#000',
        marginTop: '20px'
    },
    detailTextTwo: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#000'
    },
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,

};
// Customizable Area End
