Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SalesReporting";
exports.labelBodyText = "SalesReporting Body";

exports.btnExampleTitle = "CLICK ME";

exports.overviewTitle = "Overview";
exports.salesByUnitTitle = "Sales";
exports.salesSumTitle = "Sales (Cum)";
exports.revenueBreakdownTitle = "Revenue Breakdown";
exports.comparisonTitle = "Sales Comparison"
exports.overviewUnit = "Unit";
exports.overviewRevenue = "Revenue";

exports.sampleReporting = {
  "overview": {
    "unit": 7,
    "revenue": 35073
  },
  "sales": {},
  "revenueBreakdown": {
    "Laundry": 100,
    "Dry Cleaning": 200,
    "Pressing": 300,
    "Blankets": 400
  },
  "currency": "$",
}

exports.periods = [{ pkey: 'hour', value: 'Today' }, { pkey: 'day', value: 'This Week' }, { pkey: 'week', value: 'This Month' }, { pkey: 'month', value: 'This Year' }];
exports.chartColors = ['#fff100', '#ff8c00', '#e81123', '#ec008c', '#68217a', '#00188f', '#00bcf2', '#00b294', '#009e49', '#bad80a'];
exports.chartLabels = {
  'hour': ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
  'day': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  'week': [],
  'month': ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
}

exports.webSalesChartDataLabels = ["Time", "Sales"];
exports.webRevenueBreakdownLabels = ["Key", "Value"];

exports.webSalesChartOptions = {
  curveType: "function",
  legend: { position: "bottom" },
};

exports.httpGetMethod = 'GET'
exports.getSalesReportApiContentType = 'application/json'
exports.getSalesReportApiEndPoint = 'bx_block_salesreporting/sales_report'
exports.errorTitle = 'Error'
exports.noDataText = 'No Data';
exports.salesReportRowData = [
  {
    date: "1980-08-09",
    sku: "SKU 1",
    value: "APPROVED",
    provider: "Emma Johnson",
    commission: "R340",
    qty: "111"
  },
  {
    date: "1985-08-07",
    qty: "50",
    value: "R3650",
    provider: "Olivia Davis",
    sku: "SKU 2",
    commission: "R340"
  },
  { 
    date: "1981-11-09",
    value: "R2000",
    provider: "Alice Smith",
    commission: "R150",
    sku: "SKU 3",
    qty: "50"
  },
  { 
    date: "1987-12-17",
    provider: "John Doe",
    commission: "R280",
    value: "R3500",
    qty: "75",
    sku: "SKU 4"
  },
  {
    date: "1991-10-01",
    commission: "R300",
    provider: "Sarah Williams",
    qty: "90",
    value: "R4200",
    sku: "SKU 1"
  },
  {
    date: "200-01-01",
    qty: "120",
    commission: "R400",
    provider: "Michael Brown",
    value: "R5600",
    sku: "SKU 2"
  },
  {
    date:"2001-04-08",
    provider: "Olivia Davis",
    qty: "85",
    sku: "SKU 3",
    value: "R3900",
    commission: "R320"
  },
  {
    date: "2004-02-29",
    commission: "R350",
    provider: "James Wilson",
    qty: "100",
    sku: "SKU 4",
    value: "R4500"
  },
  {
    date: "2007-10-31",
    provider: "Sophia Miller",
    value: "R3000",
    commission: "R240",
    qty: "65",
    sku: "SKU 1"
  },
  {
    date: "2021-01-30",
    commission: "R200",
    provider: "William Moore",
    qty: "55",
    value: "R2500",
    sku: "SKU 2"
  },
  {
    date: "2004-09-23",
    provider: "Ethan Taylor",
    qty: "70",
    value: "R3200",
    commission: "R260",
    sku: "SKU 3"
  },
  {
    date: "2023-08-20",
    provider: "Ava Anderson",
    value: "R3700",
    commission: "R290",
    qty: "80",
    sku: "SKU 4"
  },
  {
    date: "2016-05-23",
    qty: "95",
    commission: "R310",
    provider: "Mason Thomas",
    sku: "SKU 1",
    value: "R4300"
  },
  {
    date: "2009-02-09",
    provider: "Isabella Jackson",
    commission: "R330",
    qty: "105",
    value: "R4700",
    sku: "SKU 2"
  },
  {
    date: "1996-10-04",
    provider: "Jacob White",
    commission: "R340",
    value: "R4800",
    sku: "SKU 3",
    qty: "110"
  }
];
exports.noResultFoundText = "No Result Found";
exports.salesReportingHeading = "VAS Total Sales & Commission";
// Customizable Area End