import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import { background_image, a2payImage } from './assets';
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const Backimg = styled('img')({
  '@media (max-width: 600px)': {
    height: '400px !important',
    objectFit: 'cover',
    objectPosition: 'bottom'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    height: '500px !important',
    objectFit: 'cover',
    objectPosition: 'bottom',
    width: '100%'
  },
})
const Logoimg = styled('img')({
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },
})
const GenderDropLogin = styled(Select)({
  border: "1px solid #273567",
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  '&.Mui-focused': {
    border: "1.5px solid #273567",
    boxShadow: "0 0 0px 2px #3990e7"
  }
})


// Customizable Area End

import SignuploginController, {
  Props
} from "./SignuploginController";

export default class NewOperatorlogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  generateInputStyle(field: string) {
    const borderColor = this.state.invalidInputs.includes(field) ? 'red' : '#273567';
    return {
      ...webStyle.inputStyle,
      borderColor: borderColor,
    };
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ minHeight: '80vh' }}>
          <Grid item xs={12}
            sm={12} lg={6}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}>
            <Logoimg src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', left: 242, top: 50 }} />
            <Backimg src={background_image} style={{ width: '100%', height: '1100px' }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box style={{ margin: '10%' }}>
              <Box>
                <Typography style={webStyle.registerMerchanteText as React.CSSProperties}>Register as Operator</Typography>
                <Typography variant="body1" style={webStyle.subHeader as React.CSSProperties}>Enter the fields below to get started</Typography>
              </Box>
              <Box style={{ marginTop: 42 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      style={this.generateInputStyle('firstName')}
                      onChange={this.handleInputChanges}
                      value={this.state.firstName}
                    />
                  </Box>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Last Name</label>
                    <input
                      name="lastName"
                      type="text"
                      style={this.generateInputStyle('lastName')}
                      onChange={this.handleInputChanges}
                      value={this.state.lastName}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Citizen Status</label>
                    <input
                      type="text"
                      name="citizenStatus"
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('citizenStatus') ? 'red' : '#273567',
                      }}
                      value={this.state.citizenStatus}
                      onChange={this.handleInputChanges}
                    />
                  </Box>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Store Name</label>
                    <input
                      name="storeName"
                      type="text"
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('storeName') ? 'red' : '#273567',
                      }}
                      onChange={this.handleInputChanges}
                      value={this.state.storeName}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Position</label>
                    <input
                      type="text"
                      name="postion"
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('postion') ? 'red' : '#273567',
                      }}
                      onChange={this.handleInputChanges}
                      value={this.state.postion}
                    />
                  </Box>
                  <Box sx={webStyle.labelBox2}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>ID Number/Passport ID  </label>
                    <input
                      type="text"
                      name="passportIdNumberId"
                      value={this.state.passportIdNumberId}
                      onChange={this.handlePassportValidation}
                      onInput={this.handleWhatsappNumberNumber}
                      required
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('passportIdNumberId') ? 'red' : '#273567'
                      } as React.CSSProperties}
                      
                    />
                   
                      <Typography
                        style={{
                          color: "red",
                          fontWeight: 400,
                          fontSize: "12px",
                          fontFamily: 'Poppins',
                          marginTop: "-0px", 
                        }}
                      >
                        {this.state.errorMessage}
                      </Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Coordinates</label>
                    <input
                      type="text"
                      name="coordinates"
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('coordinates') ? 'red' : '#273567'
                      }}
                      onChange={this.handleInputChanges}
                      value={this.state.coordinates}
                    />
                  </Box>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Phone Number</label>
                    <input
                      data-test-id="phoneNumber" id="phoneNumber" name="phoneNumber" type="text" value={this.state.phoneNumber} onInput={this.handleWhatsappNumberNumber} onChange={this.handlePhoneNumberChange}
                      style={{ ...webStyle.inputStyle, borderColor: this.state.invalidInputs.includes('phoneNumber') ? 'red' : '#273567' }} />
                    {this.state.errorPhoneMessage
                      &&
                      <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>
                        {this.state.errorPhoneMessage}
                      </div>
                    }
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyleOprat as React.CSSProperties}>Ethnicity</label>
                    <input
                      type="text"
                      name="ethinicity"
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('ethinicity') ? 'red' : '#273567',
                      }}
                      value={this.state.ethinicity}
                      onChange={this.handleInputChanges}
                    />
                  </Box>
                  <Box sx={webStyle.labelBox}>
                 

                    <Box sx={webStyle.selectStoreBox} >


                  
                      <label style={webStyle.labelStyleOprat as React.CSSProperties}>
                        Gender
                      </label>
                     


                      <FormControl variant="outlined" required style={webStyle.formControlStyleOp}>


                        <GenderDropLogin
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name='sex'
                          value={this.state.sex}
                          onChange={this.handleInputChanges}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              horizontal: 'left',
                              vertical: 'top',
                            },
                            getContentAnchorEl: null,
                          }}

                          style={{
                            borderColor: this.state.invalidInputs.includes('sex') ? 'red' : '#273567',
                            ...webStyle.dropDownStyle,
                          }}
                        >

                          <MenuItem value={"Male"} style={webStyle.selectStoreStyleoprat}>
                            Male
                          </MenuItem>

                          <MenuItem value={"Female"} style={webStyle.selectStoreStyleoprat}>
                            Female
                          </MenuItem><MenuItem value={"Others"} style={webStyle.selectStoreStyleoprat}>
                            Others
                          </MenuItem>

                          
                        </GenderDropLogin>
                       



                      </FormControl>
                     

                    </Box>
                  </Box>

                </Box>
              </Box>

              <Box sx={webStyle.labelBox}>


                <label style={webStyle.labelStyleTwo3 as React.CSSProperties}>Address Line 1</label>
                <input
                  type="text"
                  name="addressline1"
                  style={{
                    ...webStyle.inputStyle,
                    borderColor: this.state.invalidInputs.includes('addressline1') ? 'red' : '#273567',
                  }}
                  value={this.state.addressline1}
                  onChange={this.handleInputChanges}
                />
              </Box>
              <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelStyleTwo3 as React.CSSProperties}>Address Line 2</label>
                <input
                  type="text"
                  name="addressline2"
                  style={{
                    ...webStyle.inputStyle,
                    borderColor: this.state.invalidInputs.includes('addressline2') ? 'red' : '#273567',
                  }}
                  value={this.state.addressline2}
                  onChange={this.handleInputChanges}
                />
              </Box>
              <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelStyleTwo3 as React.CSSProperties}>Email Address</label>
                <input
                  type="text"
                  // name="emailAddress"
                  style={{
                    ...webStyle.inputStyleTwo,
                    width: "100%",
                    borderColor: this.state.invalidInputs.includes('email') ? 'red' : '#273567',
                  }}
                  onChange={this.handleEmailChange}
                />
                {this.state.errorMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>{this.state.errorMessage}</div>}
              </Box>
              <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelStyleTwo3 as React.CSSProperties}>Alternate Phone Number/WhatsApp number</label>
                <input
                  data-test-id="alternateNumber"
                  name="alternateNumber"
                  type="text"
                  value={this.state.alternateNumber}
                  onChange={this.handleAlternateNumberChange}
                  style={{
                    ...webStyle.inputStyle,
                    borderColor: this.state.invalidInputs.includes('alternateNumber') ? 'red' : '#273567'
                  }}
                />
                {this.state.errorAlternatePhoneMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>{this.state.errorAlternatePhoneMessage}</div>}
              </Box>

              <Button data-test-id="submitOperater" style={{
                ...webStyle.registerButton,
                width: "100%"
              } as React.CSSProperties} onClick={this.handleOperatorSubmit}>Register</Button>
              <Box sx={webStyle.accountBox}>
                <Typography style={webStyle.alreadyButton as React.CSSProperties}>Already have an account?</Typography>
                <Typography style={webStyle.loginButton as React.CSSProperties} onClick={this.navigateTologin} data-test-id="loginBtn">
                  Log in
                </Typography>

              </Box>
            </Box>
          </Grid>
          <Toaster position="top-center" />
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    border: "none",
    width: "100%",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
    height: "45px"
  },
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    paddingBottom: "30px",
    alignItems: "center"
  },
  inputStyle: {
    width: '100%',
    color: '#273567',
    top: '26px',
    border: '1px solid',
    borderRadius: '8px',
    padding: 15,
    height: '40px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500
  },
  inputStyleTwo: {
    top: '26px',
    color: '#273567',
    borderRadius: '8px',
    height: '40px',
    border: '1px solid #273567',
    padding: 15,
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500
  },
  registerMerchanteText: {
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    lineHeight: '42px',
    fontSize: '28px'
  },
  labelStyleTwo3: {
    color: "#273567",
    fontFamily: 'Poppins',
    lineHeight: " 24px",
    letterSpacing: "0em",
    marginTop: "16px",
    fontSize: "16px",
    fontWeight: 400
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%"
  },
  labelBox2: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%'
  },

  subHeader: {
    fontSize: '14px',
    color: '#848FAC',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    marginBottom: 30,
    fontWeight: 400,
    fontFamily: 'Poppins',

  },
  groupIcon: {
    width: 'auto',
    height: '30px',
    right: '10',
    top: '30',
    position: 'absolute',
    // Adjust the spacing between the input and the image
  },
  loginButton: {
    color: '273567',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    marginLeft: '4',
    cursor: 'pointer'
  },
  vatText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#273567',
    marginTop: 16
  },
  alreadyButton: {
    color: '273567',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal'
  },

  registerButton: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    marginTop: '35px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#fff',
    width: '412px',
    height: '50px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    '@media (min-width: 600px)': {
      width: '2',
    },
  },
  labelBoxTwo: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50
  },
  registerdButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#273567',
    marginTop: 16
  },

  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'space-between',
    // Additional responsive adjustments
    '@media (min-width: 600px)': {
      flexDirection: 'row',
    },
  } as React.CSSProperties,
  groupIconMobile: {
    width: '60%', // Adjust the width for smaller screens
    height: '60%', // Adjust the height for smaller screens
    position: 'relative',
    right: '5px',
    bottom: '25%',
    transform: 'translateY(-55%)',
    alignSelf: 'flex-end',
    marginBottom: '-100%',
    marginRight: '-15%',
  },
  labelStyleOprat: {
    fontFamily: 'Poppins',
    color: "#273567",
    fontSize: "16px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: 400,
  },
  backgroundImage:
  {
    width: "100%",
    height: '100%'
  },
  accountBox:
  {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '16px'
  },
  BackgroundMainImage: {
    width: '100%',
    height: '1100px'
  },
  selectStoreBox: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%"
  } as React.CSSProperties,
  formControlStyleOp: {
    borderRadius: "8px",
    background: "white"
  } as React.CSSProperties,
  dropDownStyle: {
    width: '100%',
    height: '40px',
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#273567",
    fontWeight: 500,
    borderRadius: '8px',
    background: "white"
  } as React.CSSProperties,
  selectStoreStyleoprat: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,
};
// Customizable Area End
