import React from "react";
import {
  Box,
  Typography,
  Button,
  Fade,
  Modal,
  styled,
  Select,
  MenuItem,
} from "@material-ui/core";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick, upload_icn } from "./assets";
import { Toaster } from "react-hot-toast";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";
import Header from "../../../components/src/Header.web";

const RightBoxLanding3 = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});
const ModalBoxForgot3 = styled(Box)({
  height: "60%",
  width: "100%",
  maxWidth: "400px",
  "@media (max-width: 600px)": {
    height: "auto",
    width: "70%",
    maxWidth: "400px",
  },
});
const StyledSelect3 = styled(Select)({
  '&>.MuiSelect-select:focus':{
  backgroundColor:'transparent',
  },
  '&>#demo-simple-select-outlined':{
    display:'flex',
    paddingLeft:'15px',
    justifyContent:'center',
    height:"100%",
    color:'#273567',
    flexDirection:'column',
    fontSize:'14px',
    fontWeight:500,
  }
})
const StyledFileInput3 = styled("input")({
  width: "100%",
  height: "100%",
  opacity: 0,
  top: 0,
  left: 0,
  outline: "none",
  cursor: "pointer",
  border: "none",
  position: "absolute",
  "::placeholder": {
    fontSize: "14px",
    fontFamily: "Poppins",
    marginTop: "10px",
    fontWeight: 400,
  },
});

export default class AddMyStores extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  renderInputField3(name3:any, label:any) {
    const _this3 = this
    const currentValue = this.state[name3 as keyof typeof _this3.state];
    const inputStyles = {
      errorStyle: { border: "1px solid red" },
      validStyle: { border: "1px solid #273567" },
      baseStyle: { ...webStyle.inputStyle3 },
  };
  const borderValidate3 = this.state.requiredInput.includes(name3)
  ? "red"
  : "#273567";
  const checkValidBorder3 = (condition:boolean)=>{
    return condition? inputStyles.validStyle.border
    : inputStyles.errorStyle.border
  }

  const renderContactDetailField = () => (
    <>
      <input
        data-test-id="contactDetail"
        name={name3}
        value={this.state.contactDetail}
        type="text"
        onChange={this.handleContactDetailsChange}
        style={{
          ...inputStyles.baseStyle,
          border: checkValidBorder3(this.state.isPhoneNumberValid),
        }}
      />
      {!this.state.isPhoneNumberValid && (
        <div style={webStyle.validationMessage3}>Invalid Phone Number</div>
      )}
    </>
  );

  const renderEmailAddressField = () => (
    <>
      <input
        name={name3}
        type="email"
        style={{
          ...inputStyles.baseStyle,
          // borderColor:borderValidate3,
          border: checkValidBorder3(this.state.isEmailValid),
        }}
        onChange={this.handleEmailChange}
        value={this.state.email}
      />
      {this.state.errorMessage && (
        <div className="errorMessageEmail3" style={webStyle.validationMessage3}>
          {this.state.errorMessage}
        </div>
      )}
    </>
  );

  const renderSelectField = () => {
    return (
      <StyledSelect3
        name={name3}
        value={currentValue}
        id="demo-simple-select-outlined"
        onChange={this.handleInputChanges}
        style={{
          ...inputStyles.baseStyle,
          padding:0,
          top: 0,
          borderColor:borderValidate3,
        }}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
        disableUnderline
      >
        <MenuItem value={'yes'} style={webStyle.menuItem3}>Yes</MenuItem>
        <MenuItem value={'no'} style={webStyle.menuItem3}>No</MenuItem>
      </StyledSelect3>
    );
  };

  const renderUifNumberField = () => (
    <>
      <input
        data-test-id="uifNumber"
        name={name3}
        value={this.state.uifNumber}
        type="text"
        onChange={this.handleUifNumberChange}
        style={{
          ...inputStyles.baseStyle,
          border: checkValidBorder3(this.state.isUifNumberValid),
        }}
      />
      {!this.state.isUifNumberValid && (
        <div style={webStyle.validationMessage3}>
          UIF Number should be 8 digits
        </div>
      )}
    </>
  );

  const renderKycDocumentationField3 = () => (
    <div id="kycDocumentation3">
      <div style={{ cursor: "pointer", marginTop: "5px" }}>
        <label htmlFor="kycDoc3" style={{ display: "block" }}>
          <div
            style={{
              ...webStyle.inputStyleDoc3,
              borderColor:this.state.showValidationMessage && !this.state.kycDocumentation 
              ? "red"
              : "",
              position: "relative",
            }}
          >
            <div id="kycDocumentationName3" style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                  }}>
              <span style={{
                  marginLeft: '5px',
                  color:'rgb(39, 53, 103)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 500,
                }}>
                {this.state.kycDocumentation ? this.state.kycDocumentation : 'Upload Document'}
              </span>
            </div>
            <StyledFileInput3
              type="file"
              id="kycDoc3"
              data-test-id="kycDocumentation"
              name={name3}
              style={{
                width: "100%",
                top: 0,
                position: "absolute",
                height: "100%",
                cursor: "pointer",
                opacity: 0,
                left: 0,
                }}
              onChange={this.handleUploadKycDocumentation}
              onBlur={this.handleFileBlur}
            />
          </div>
        </label>
      </div>
      {this.state.showValidationMessage && !this.state.kycDocumentation && (
        <div id="errorMessageFileUpload3" style={webStyle.validationMessageDoc3}>
          Please upload in correct format (.PDF / .JPG / .PNG)
        </div>
      )}
    </div>
  );

  const renderDefaultField3 = () => (
    <input
      name={name3}
      type="text"
      maxLength={name3==='nextOfKin'?50:undefined}
      value={currentValue}
      style={{
        ...inputStyles.baseStyle,
        borderColor: borderValidate3,
      }}
      onChange={this.handleInputChanges2}
    />
  );

  const inputFieldMap: Record<string, () => JSX.Element> = {
    typeStore: renderSelectField,
    emailAddress: renderEmailAddressField,
    contactDetail: renderContactDetailField,
    vatStatus: renderSelectField,
    kycDocumentation: renderKycDocumentationField3,
    uifNumber: renderUifNumberField,
    cipro: renderSelectField,
  };

  const inputField:JSX.Element = inputFieldMap[name3]  ? inputFieldMap[name3]() : renderDefaultField3();

  return (
    <Box style={webStyle.fieldsMain3 as React.CSSProperties}>
      <label style={webStyle.labelStyle2 as React.CSSProperties}>{label}</label>
      {inputField}
    </Box>
  );
}

  render() {
    return (
      <Box style={webStyle.userSettingContainer3}>
        <SideNavbar />
        <RightBoxLanding3
          style={webStyle.secondContainer as React.CSSProperties}
        >
          <Box style={webStyle.drawerBox}>
            <CommonDrawer anchor={"right"} style={{ width: "100%" }} />
          </Box>
          <Header />
          <Box
            style={{
              paddingLeft: 40,
              display: "flex",
              cursor: "pointer",
              maxWidth: "80px",
              marginTop: "25px",
            }}
            onClick={this.goBack}
          >
            <img src={leftArrow} style={{ width: "24px", height: "24px" }} />
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                color: "#000",
                paddingLeft: 8,
                cursor: "pointer",
              }}
            >
              Back
            </Typography>
          </Box>
          <Box style={webStyle.userSettingFeildsMian}>
            <Box style={webStyle.userSettingTextMain as React.CSSProperties}>
              <Typography
                style={webStyle.userSettingText as React.CSSProperties}
              >
                Add My Store
              </Typography>
            </Box>
            {this.renderInputField3("storeName", "Store Name")}
            {this.renderInputField3("contactDetail", "Contact Details")}
            {this.renderInputField3("emailAddress", "Email Address")}
            {this.renderInputField3("nextOfKin", "Next of Kin")}
            {this.renderInputField3("storeAddress", "Store Address")}
            <Box sx={webStyle.cordinateBox}>
              <label style={webStyle.labelStyle as React.CSSProperties}>
                Store Coordinates
              </label>
              <input
                type="text"
                name="updateCoordinates"
                title={this.state.updateCoordinates}
                value={this.state.updateCoordinates}
                style={{
                  ...webStyle.inputStyleAdd,
                  paddingRight:'50px',
                  borderColor: this.state.requiredInput.includes(
                    "updateCoordinates"
                  )
                    ? "red"
                    : "#273567",
                }}
                onChange={this.handleInputChanges2}
                readOnly
              />
              <img
                style={webStyle.captureIcon3 as React.CSSProperties}
                alt="Capture Icon"
                onClick={this.handleCoordinatesClick}
                src={capture_icon}
              />
              {this.state.requiredInput.includes("updateCoordinates") && (
                <div style={{ marginTop: "5px", color: "red" }}>
                  Store Coordinates is required
                </div>
              )}
            </Box>
            {this.renderInputField3("typeStore", "Type of Store")}
            {this.renderInputField3("cipro", "CIPRO Registration Status")}
            {this.renderInputField3("vatStatus", "VAT Status")}
            {this.renderInputField3("uifNumber", "UIF Number")}
            {this.renderInputField3("kycDocumentation", "KYC Documentation")}
            <Button
            data-test-id="submitBtn"
              onClick={()=>this.handleSubmit3(true)}
              style={webStyle.submitButton2 as React.CSSProperties}
            >
              Submit
            </Button>
          </Box>
          <Toaster position="top-center" />
          <Modal
            aria-labelledby="transition-modal-title"
            closeAfterTransition
            open={this.state.tickModel}
            onClose={this.handleCloseModal2}
            aria-describedby="transition-modal-description"
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.tickModel}>
              <ModalBoxForgot3 sx={webStyle.greentickModal}>
                <img
                  src={greentick}
                  alt="Green Tick"
                  style={{
                    marginBottom: "20px",
                    maxWidth: "119px",
                    width: "100%",
                    height: "119px",
                  }}
                />
                <Typography
                  variant="h5"
                  style={webStyle.userVerifiedText2 as React.CSSProperties}
                >
                  Store has been sent to Admin for approval, Hang Tight!"
                </Typography>
              </ModalBoxForgot3>
            </Fade>
          </Modal>
          <Modal
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    open={this.state.showUploadModal}
                >
                    <Box style={{
                      position: 'absolute',
                        backgroundColor: "black",
                        padding: "10px",
                        color: "white",
                        top: '50%',
                        left: '50%',
                        width: 300,
                        borderRadius: "12px",
                        fontFamily: "Poppins",
                        textAlign: 'center',
                        border: '2px solid #000',
                        transform: 'translate(-50%, -50%)',
                        fontSize: "24px",
                        fontWeight: 500,
                    }}>
                        Uploading.....
                    </Box>
                </Modal>
        </RightBoxLanding3>
      </Box>
    );
  }
}

const webStyle = {
  userSettingContainer3: {
    backgroundColor: "#E2E8F0",
  },
  userSettingFeildsMian: {
    maxWidth: "610px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    padding: "20px",
    margin: "40px",
  },
  labelStyle2: {
    fontFamily: "Poppins",
    color: "#273567",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: " 24px",
    fontWeight: "400",
  },
  userSettingTextMain: {
    marginBottom: "40px",
  },
  userSettingText: {
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#000000",
    lineHeight: "40px",
    fontSize: "20px",
  },
  inputStyle3: {
    color: "#273567",
    width: "100%",
    top: "26px",
    borderRadius: "8px",
    border: "1px solid",
    height: "40px",
    fontFamily: "Poppins",
    padding: "15px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  errorMessage3:{
    marginTop: "5px",
    color: "red",
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "poppins",
  },
  drawerBox: { width: "100%" } as React.CSSProperties,
  fieldsMain3: {
    display: "flex",
    maxWidth: "100%",
    flexDirection: "column",
    width: "100%",
    margin: "20px 0",
  },
  inputStyleAdd: {
    color: "#273567",
    top: "26px",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid",
    padding: "15px",
    height: "40px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#273567",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  cordinateBox: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    width: "100%",
  },
  captureIcon3: {
    width: "auto",
    right: "10",
    backgroundColor:'#fff',
    zIndex:10,
    height: "30px",
    cursor:'pointer',
    top: "30",
    position: "absolute",
  },
  submitButton2: {
    backgroundColor: "#C12030",
    marginTop: "16px",
    fontSize: "16px",
    color: "#fff",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    width: "100%",
    fontFamily: "Poppins",
  },
  secondContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#E2E8F0",
  },
  greentickModal: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    margin: "auto",
    marginTop: "100px",
    flexShrink: "0",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)",
  },
  menuItem3: {
    fontSize: '14px',
    color: '#273567',
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  userVerifiedText2: {
    fontSize: "33px",
    lineHeight: "140%",
    justifyContent: "center",
    alignContent: "center",
    fontWeight: 400,
    textAlign: "center",
    color: "#030F2D",
    display: "flex",
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  validationMessage3:{
    color: "red",
    marginTop: "5px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
  },
  displayFileName3:{
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  validationMessageDoc3: {
    fontWeight: 400,
    marginTop: "5px",
    fontSize: "12px",
    color: "red",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  inputStyleDoc3: {
    height: "40px",
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "8px 40px 8px 8px", // Adjust padding for text and image
    boxSizing: "border-box",
    backgroundSize: "24px", // Adjust the size of the image
    backgroundImage: `url(${upload_icn})`, // Set the image as the background
    backgroundPosition: "right 8px center", // Adjust the position of the image
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,
};
