import React, {ChangeEvent} from "react";

import {
    Box,
    Typography,
    Button,
    Modal,
    styled,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    RadioGroup,
    FormControlLabel,
    TableBody,
    ThemeProvider,
    Slider,
    createTheme,
    withStyles,
    Tooltip,
    TableSortLabel
} from "@material-ui/core";
import VisualAnalyticsController, {
    Props, configJSON
} from "./VisualAnalyticsController";

import StyleRadio from '../../../components/src/StyleRadio.web';
import SideNavbar from "../../../components/src/SideBar.web";

import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, downIcon, printIcon, check } from "./assets";
import { Pagination } from "@material-ui/lab";
import ReactToPrint from 'react-to-print';

const InventoryBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const InventoryCustomBox = styled(Box)({
    display: 'flex',
    gap: 16,
    marginTop: '20px',
    padding: "0 40px 20px",
    '@media(max-width: 600px)': {
        marginLeft: '0px',
        justifyContent: 'center'
    }
})

const InventoryWallet = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    display:"flex",
    alignItems:"center",
    width: "30%",
    '@media(max-width: 850px)': {
        fontSize: '16px',
    },
    '@media(max-width: 710px)': {
        fontSize: '14px',
        width: "100%"
    },
    '@media(max-width: 600px)': {
        padding: '0px',
        fontSize: '14px',
        width: "100%"
    },

})

const InventoryExportContainer = styled(Box)({

    '@media(max-width: 450px)': {
        gap: "20px !important"
    }

})

const InventoryBackMain = styled(Box)({

    display: 'flex',
    margin: "10px 0"

})


const UserBox = styled(Box)({

    backgroundColor: "#FFFFFF",
    padding: "17px 24px",
    borderRadius: "4px",
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: 'space-between',
    gap: '8px',
    margin: "20px 0",
    maxWidth: "100%",

})

const InventoryCustomButton = styled(Button)({

    padding: '4px 16px 4px 20px',
    alignItems: 'center',
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',

    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})

const InventoryButtonCust = styled(Button)({

    alignItems: 'center',
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    padding: '4px 16px 4px 20px',

    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})


const InventoryCustomFormLabelRadio = styled(FormControlLabel)({

    margin: "auto !important",
    "& .MuiFormControlLabel-root": {
        margin: "auto !important"
    }

})


const InventoryExportBox = styled(Box)({

    '@media(max-width: 450px)': {
        height: "100px !important",
        marginaxWidth: "100px !important"
    }

})

const InvSubcategoryBox = styled(Typography)({
    '@media(max-width: 375px)': {
        marginLeft: '10px',
    }
})

interface Column {
    id:
    | "category"
    | "sku"
    | "description"
    | "stockQuantity"
    | "stockValue"
    | "reorderPoint"
    | "reorderQuantity"
    label: string;
    minWidth?: number;
    align?: "right";
    sortable?: boolean;
}
interface InventoryDataItems {
    category: string;
    sku: string;
    description: string;
    stockQuantity: number;
    stockValue: string;
    reorderPoint: number;
    reorderQuantity: number;
};

const columns: Column[] = [
    { id: "category", label: "Category", sortable: false },
    { id: "sku", label: "SKU", sortable: false },
    { id: "description", label: "Description", sortable: false },
    { id: "stockQuantity", label: "Stock Quantity", sortable: true },
    { id: "stockValue", label: "Stock Value", sortable: true },
    { id: "reorderPoint", label: "Reorder Point", sortable: true },
    { id: "reorderQuantity", label: "Reorder Quantity", sortable: true },
];



export const RangeBubbleContainer = styled(Slider)(({ theme }) => ({

    width: '180px !important',
    marginLeft: '50px !important',
    marginBottom: '10px !important',
    "@media (max-width:390px)":{
        width: '150px !important',
        marginLeft: '43px !important'
    },
    "& .PrivateValueLabel-offset-32": {
        marginTop: '65px !important',
        "& .PrivateValueLabel-circle-33": {
            borderRadius: '5px !important',
            transform: 'rotate(0deg) !important',
            height: 0,
            width: 0,
            padding: '12px 16px !important',
            margin: '2px !important',
            backgroundColor: '#F3D2D6 !important',

            "& .PrivateValueLabel-label-34": {
                color: '#C12030 !important',
                transform: 'rotate(0deg) !important',
            }
        }
    }
}));

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#C12030",
            },
            track: {
                color: 'red'
            },
            rail: {
                color: 'pink'
            },

        },
    }
});

const InvtCustomTooltip = withStyles(({
    tooltip: {
        marginTop:"10px",
        backgroundColor: '#F3D2D6'
    },
}))(Tooltip);

const DateModelBox = styled(Box)({
    '@media (max-width: 385px)': {
        left:"0 !important",
        top:"108px !important",
        width: "250px !important"
    },
})

const DateModelSecondBox = styled(Box)({
    '@media (max-width: 435px)': {
        width: '280px !important'
    },
})

const CategoryFilter = styled(Box)({
    left:0,
    top:"48px",
    width:"250px",
    '@media (max-width: 390px)': {
        width: '215px !important'
    },
})


const CateoryFilterText = styled(Box)({
    '@media (max-width: 390px)': {
        fontSize: "16px !important"
    },
})

const FilterName = styled(Box)({
    '@media (max-width: 390px)': {
        fontSize: "13px !important"
    },
})


const FilterMian = styled(Box)({
    alignItems:"center",
    '@media (max-width: 700px)': {
        marginLeft: ""
    },
})

const FilterByMain = styled(Box)({
    display:"flex",
    gap:"16px",
    position:"relative",
    "@media (max-width:385px)":{
    flexWrap:"wrap"
    }
})

const FilterButton = styled(Button)({
    
})

const FilterTypo = styled(Typography)({
    
})

const CategoryDiv = styled(Typography)({
   
})

const SortText = styled(Typography)({
    "@media (max-width: 385px)":{
        width:"230px"
    }
})
const RangeText = styled(Typography)({
    '@media (max-width: 415px)': {
        fontSize: "14px !important"
    },
    '@media (max-width: 390px)': {
        width: "160px !important",
        marginLeft:"43px"
    },
});

const InventPrintHeaderCell = styled(TableCell)({
    borderBottom: "none",
    color:"white !important",
    fontWeight:700,
    padding:"6px"   
});

const OddPrintStyledBodyRow = styled(TableRow)({
    fontWeight:700,
    borderBottom: "none",
    borderTop: "none",
    backgroundColor: "#f5f5f5",
    color:"white"
});

const InventPrintBodyCell = styled(TableCell)({
    color:"#626161",
    borderBottom: "none",
    padding:"6px"
});

const InventPrintHeaderRow = styled(TableRow)({
    color:"white",
    backgroundColor: "#2980ba",
    fontWeight:700
 });
export default class InventoryTrend extends VisualAnalyticsController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount(){
        this.getInventoryTrend()
    }

    ValueLabelComponent(props: any) {
        const { children, open, value } = props;
        return (
            <InvtCustomTooltip
                open={open}
                interactive
                enterTouchDelay={0}
                title={value}
                id="dateModal"
                className="dateModal"
                data-test-id="valueLabelComponent"
            >
                {children}
            </InvtCustomTooltip>
        );
    }
    formatLabel(text: any, values: any) {
        return (
            <div style={{ textAlign: "center", color: '#C12030', fontWeight: 400, fontSize: '14px' }} id='dateModal' className="dateModal">
                {text === 'Stock Value' ? `R${values}` : values}
            </div>
        )
    };
    rangeSlider = (id:number, text: string, values: number[], handleChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void, max: number) => {

        return (
            <ThemeProvider theme={muiTheme}>
                <RangeBubbleContainer
                    id='dateModal'
                    className="dateModal"
                    value={values}
                    ValueLabelComponent={this.ValueLabelComponent}
                    onChange={handleChange}
                    min={0}
                    aria-labelledby="discrete-slider-always"
                    valueLabelDisplay='on'
                    max={max}
                    valueLabelFormat={this.formatLabel.bind(this, text)}
                    data-test-id={`rangeSlider${id}`} 
                />
                <RangeText style={webStyle.rangeFilterMainDiv} id='dateModal' className="dateModal">
                    <Typography id='dateModal' className="dateModal" style={webStyle.filterTextStyle}>0</Typography>
                    <Typography style={this.state.values[1] >= 800 ? webStyle.filterNoValue : webStyle.filterTextStyle} id='dateModal' className="dateModal">
                        {this.state.values[1] >= 800 ? 0 : 1000}
                    </Typography>
                </RangeText>
            </ThemeProvider>
        )
    }


    InvthandleSortFilterValue = (value: boolean, title: string, order:string) => {
        return (

            <Box id='dateModal' className="dateModal" style={{ ...webStyle.InvtlabelBoxStyle, backgroundColor: this.state.orderBy==="stockValue"&&value ? '#CDF0EE' : 'white', }} onClick={()=>this.handleSortFilter(order)} data-test-id={'handleSortFilterAcending'}>
                {this.state.orderBy==="stockValue"&&value ? <img src={check} style={{ marginLeft: '5px', height: '24px', width: '24px' }} /> : <div style={{ marginLeft: '5px', height: '24px', width: '24px' }} />}
                <SortText id='dateModal' className="dateModal" style={webStyle.labelStyle as React.CSSProperties}>{title}</SortText>
            </Box>
        )
    }
    renderModal = () => {
        return (
            <Modal
                open={this.state.openModal}
                onClose={this.handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                data-test-id="inventoryFileTypeModalTestID"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowY: 'auto',
                    width: '90%',
                    height: '90%',
                    margin: 'auto'
                }}

            >
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: '550px',
                    width: '100%',
                    padding: 30,
                    backgroundColor: 'white',
                    textAlign: 'center',
                    borderRadius:"7px"
                }}>

                    <Typography style={{
                        fontSize: '22px',
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        maxWidth: '373px',
                        margin: 'auto',
                    }}>
                        Select the file type you would like to download :
                    </Typography>

                    <InventoryExportContainer style={{ display: 'flex', gap: 55, marginTop: 45, justifyContent: 'center' }}>

                        <InventoryExportBox data-test-id="pdfButtonTestID" onClick={this.handleNewUserChange} style={{
                            display: 'flex',
                            textAlign: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '143px',
                            height: '117px',
                            padding: '20px 30px',
                            borderRadius: '4px',
                            border: '1px solid #E6E6E6',
                            cursor: "pointer",
                            backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white',
                        }}>


                            <Typography style={{ fontSize: '20px', fontWeigth: '400', fontFamily: 'Poppins', color: '#0F172A', margin: 'auto' } as React.CSSProperties}>PDF</Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                    <InventoryCustomFormLabelRadio value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                                </RadioGroup>
                            </div>

                        </InventoryExportBox>

                        <InventoryExportBox data-test-id="xlsxButtonTestID" onClick={this.handleExistingUserChange} style={{

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '143px',
                            height: '117px',
                            padding: '20px 30px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: "pointer",
                            backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white',

                        }}>

                            <Typography style={{ color: '#0F172A', fontSize: '20px', fontWeigth: '400', fontFamily: 'Poppins', margin: 'auto' } as React.CSSProperties}>XLSX</Typography>

                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                    <InventoryCustomFormLabelRadio value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                                </RadioGroup>

                            </div>

                        </InventoryExportBox>

                    </InventoryExportContainer>
                    <Button data-test-id="confirmButtonTestID" onClick={()=>this.handleInventoryExportConfirm()} style={webStyle.submitButton2}>
                        Confirm
                    </Button>
                </Box>
            </Modal>
        )
    }
    renderInventory = () => {
        return (

            <InventoryCustomBox>

                <InventoryCustomButton data-test-id="inventoryExportAllButtonTestID" style={webStyle.exportbutton} onClick={this.handleButtonClick}>
                    <Typography style={webStyle.exportText}>Export All</Typography>
                </InventoryCustomButton>
                <style>
                    {`
                        @media print {
                            html, body {
                                height: initial !important;
                                overflow: initial !important;
                                margin: 0;
                                padding: 0; 
                            }
                            @page {
                                margin: 0.5cm; 
                            }
                        }
                    `}
                </style>
                {this.state.inventorySortedData.length!==0?
                <ReactToPrint
                    data-test-id="print"
                    trigger={() => (
                        <InventoryButtonCust data-test-id="print1" style={webStyle.exportbutton} >
                            <img src={printIcon} />
                            <Typography style={webStyle.exportText}>Print</Typography>
                        </InventoryButtonCust>
                    )}
                    content={() => this.printableDivRef.current}
                    documentTitle="stock_level_overview"
                /> : 
                <InventoryButtonCust data-test-id="print2" style={webStyle.exportbutton} >
                <img src={printIcon} />
                <Typography style={webStyle.exportText}>Print</Typography>
            </InventoryButtonCust>}
            </InventoryCustomBox>
        )
    }
    tableContaine = () => {
        return (

            <Paper>
                <TableContainer>

                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ color: '#475569', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 700, width: '100px', }}

                                    >
                                        {column.sortable ? (
                                            <TableSortLabel
                                                data-test-id={`columnOrderChange${column.id}TestID`}
                                                active={this.state.orderBy === column.id}
                                                direction={this.state.orderBy === column.id ? this.state.order : 'asc'}
                                                onClick={this.handleRequestSort.bind(this,column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        ) : (
                                            <span data-test-id={`columnOrderChange${column.id}TestID`} onClick={()=>this.handleRequestSort(column.id)}>{column.label}</span>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.inventorySortedData.length!==0?
                                this.state.inventoryShowData.map((value: InventoryDataItems, index: number) => (
                                    <TableRow data-test-id={`sortedTableRow${index}TestID`} key={index} hover>

                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}
                                                style={webStyle.tableCellStyle}
                                            >
                                                {column.id === 'stockValue' && value[column.id].includes('R') ? (
                                                    <>
                                                        <span style={{ fontWeight: 'bold' }}>R</span>
                                                        <span>{value[column.id].slice(1)}</span>
                                                    </>
                                                ) : (
                                                    value[column.id]
                                                )}
                                            </TableCell>
                                        ))}

                                    </TableRow>
                                )):<TableRow>
                                <TableCell 
                                    colSpan={7} 
                                    style={webStyle.inventoryNoResultFoundTextTableCell}
                                >
                                    {configJSON.noResultFoundText}
                                </TableCell>
                            </TableRow>}
                        </TableBody>


                    </Table>

                </TableContainer>

                {this.state.inventorySortedData.length!==0?
                    <div style={{ display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        padding: '20px 10px' }}
                    >
                    <div />
                    <Pagination 
                        data-test-id="inventoryPaginationTestID"
                        style={webStyle.paginantioinStyle} 
                        count={Math.ceil(this.state.inventorySortedData.length / 10)} 
                        page={this.state.providerSelectedPage} 
                        onChange={(event:React.ChangeEvent<unknown>, newPage: number)=>
                        this.handlePagination(event,newPage,"inventoryPagination")} 
                    />
                    <Typography style={webStyle.pageDataCount}>
                        {this.state.providerSelectedPage*10-9} -{" "}
                        {(this.state.providerSelectedPage*10-9)+(this.state.inventoryShowData.length-1)}&nbsp;
                        of {this.state.inventorySortedData.length} results
                    </Typography>
                </div>:null}
            </Paper>
        )
    }

    renderPrintableTable = () => {
        return (
          <Box style={{ display: "none"}}>
            <TableContainer ref={this.printableDivRef}  style={webStyle.inventPrintTableContainerStyle}>
                <Box style={webStyle.inventPrintTableMainHeading}>{configJSON.stockLevelOverviewText}</Box>
                <Table style={webStyle.inventPrintMainTable}>
                    <TableHead>
                    <InventPrintHeaderRow>
                        {columns.map((column) => (
                        <InventPrintHeaderCell
                            key={column.id}
                            align={column.align}
                            style={webStyle.inventPrintColumnCell}
                        >
                            <span>{column.label}</span>
                        </InventPrintHeaderCell>
                        ))}
                    </InventPrintHeaderRow>
                    </TableHead>
                    <TableBody>
                    {this.state.inventorySortedData.map((Item: InventoryDataItems, index: number) => (
                        index % 2 !== 0 ? 
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <InventPrintBodyCell
                                key={column.id}
                                align={column.align}
                                >
                                {Item[column.id]}
                                </InventPrintBodyCell>
                            ))}
                        </TableRow>:
                        <OddPrintStyledBodyRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <InventPrintBodyCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {Item[column.id]}
                                </InventPrintBodyCell>
                            ))}
                        </OddPrintStyledBodyRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </Box>
        );
      };

    render() {
        return (
            // Customizable Area Start
            <Box 
                style={webStyle.inventoryContainer} 
                data-test-id={'handleInventoryBodyClickOnScreen'}
                onClick={(event) => this.handleProviderBodyClick(event)}
            >

                <SideNavbar />
                <InventoryBoxLanding style={webStyle.subContainer}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ padding: "0 40px 20px" }}>
                        <InventoryBackMain >
                            <Box data-test-id="backBtnTestID" style={{ maxWidth: "500px", display: "flex", alignItems: "center" }} onClick={this.handleInventoryBack}>
                                <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                                <Typography style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, marginLeft: 5, cursor: 'pointer' }}>Back</Typography>
                            </Box>
                        </InventoryBackMain>
                        <UserBox style={webStyle.inventoryFeildsMian}>
                            <InventoryWallet style={webStyle.InventoryText as React.CSSProperties}>
                                Stock Level Overview
                            </InventoryWallet>
                            <FilterMian style={{ display: 'flex', gap: 10, flexWrap: "wrap"}}>


                                        <FilterTypo style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: "14px", color: "#334155" }}>
                                            Filter by:
                                        </FilterTypo>
                                    <FilterByMain>
                                        <FilterButton data-test-id="categoryMainButtonTestID" style={webStyle.timelinebutton2} onClick={this.SortByCategoryModal} className="dateModal" id='dateModal'>
                                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontFamily: 'Poppins', fontWeight: 400 }} id='dateModal'>
                                                <CategoryDiv style={{ ...webStyle.timelineText, whiteSpace: 'nowrap', fontFamily: 'Poppins', fontWeight: 400 }} id='dateModal'>
                                                    Category
                                                </CategoryDiv>
                                                <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' />
                                            </Box>
                                        </FilterButton>
                                        {this.state.showCategory === true ?
                                        <CategoryFilter data-test-id="sortingTypesButtonTestID" style={{ position: 'absolute', background: 'white', zIndex: 999999, padding: '20px', boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)', borderRadius: "8px", }} id='dateModal' className="dateModal">
                                            <div style={webStyle.catogoryFilterMainDiv} id='dateModal' className="dateModal">
                                                {this.state.InvtmainFilter.map((item: any, index: any) => (
                                                    <div key={index} id='dateModal' className="dateModal" >
                                                        <div data-test-id={`subCategoryButtons${index}TestID`} style={webStyle.filterDiv} id='dateModal' className="dateModal" onClick={()=>this.handleMainInvtFilterChange(index)} >
                                                            <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} style={webStyle.checkImage} data-test-id="testIdSelectCategoryFilter" />
                                                            <CateoryFilterText style={webStyle.filterTextStyle} id='dateModal' className="dateModal" data-test-id="testIdSelectCategoryFilterText">{item.text}</CateoryFilterText>
                                                        </div>
                                                        {(item.text === 'Category' && item.isShow) &&
                                                            this.state.categoryArray.map((item: any, index: any) => (
                                                                <InvSubcategoryBox data-test-id={`categoryTypeButton${index}TestID`} key={item.id} style={webStyle.subFilterView} id='dateModal' className="dateModal" onClick={()=>this.handleCheckboxChange(index)}>
                                                                    <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" data-test-id={`testIdFilter${index}`}style={webStyle.checkImage} />
                                                                    <FilterName id='dateModal' className="dateModal" style={webStyle.filterTextStyle} data-test-id={`testIdFilterText${index}`}>{item.text}</FilterName>
                                                                </InvSubcategoryBox>
                                                            ))}
                                                        {(item.text === 'SKU' && item.isShow) &&
                                                            this.state.skuArray.map((item: any, index: any) => (
                                                                <InvSubcategoryBox data-test-id={`skuType${index}TestID`} style={webStyle.subFilterView} key={item.id} id='dateModal' className="dateModal" onClick={()=>this.handleSKUBoxChange(index)} >
                                                                    <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" data-test-id={`skutestIdFilter${index}`} style={webStyle.checkImage} />
                                                                    <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" data-test-id={`skutestIdFilterText${index}`}>{item.text}
                                                                    </Typography>
                                                                </InvSubcategoryBox>
                                                            ))}

                                                        {item.isShow && item.text === 'Stock Quantity' &&

                                                            this.rangeSlider(item.id, item.text, this.state.stockQuantity, this.handleStockQuantitySliderChange, 1000)
                                                        }
                                                        {item.isShow && item.text === 'Reorder Quantity' &&

                                                            this.rangeSlider(item.id, item.text, this.state.Quantityvalues, this.handleQuantitySliderChange, 1000)
                                                        }

                                                        {item.isShow && item.text === 'Stock Value' &&
                                                            this.rangeSlider(item.id, item.text, this.state.Stockvalues, this.handleStockSliderChange, 1000)
                                                        }

                                                        {item.isShow && item.text === 'Reorder Point' &&
                                                            this.rangeSlider(item.id, item.text, this.state.Reordvalues, this.handleReorderSliderChange, 1000)}



                                                    </div>
                                                ))}
                                            </div>
                                        </CategoryFilter> : ""}

                                        <FilterButton style={webStyle.timelinebutton2} className="dateModal" id='dateModal' onClick={() => this.filterSortBy()} data-test-id={'filterSortBy'}>
                                            <div style={webStyle.sortBox} id='dateModal' className="dateModal">
                                                <CategoryDiv style={{ ...webStyle.timelineText, whiteSpace: 'nowrap' }} className="dateModal" id='dateModal' >
                                                    Sort <span style={{ textTransform: 'lowercase' }} id="dateModal">by</span>
                                                </CategoryDiv>
                                                <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' className="dateModal" />
                                            </div>
                                        </FilterButton>
                                        {this.state.isOpenDateModal &&
                                            <DateModelBox style={webStyle.InvtdateModelStyle} id='dateModal' className="dateModal">
                                                <DateModelSecondBox style={webStyle.inputBox} id='dateModal' className="dateModal">
                                                    {this.InvthandleSortFilterValue(this.state.selectAsending, 'Sort by Ascending to Descending', 'desc')}
                                                    {this.InvthandleSortFilterValue(!this.state.selectAsending, 'Sort by Descending to Ascending', 'asc')}
                                                </DateModelSecondBox>
                                            </DateModelBox>
                                        }
                                    </FilterByMain>
                            </FilterMian>
                        </UserBox>
                        {this.tableContaine()}

                    </Box>

                    {this.renderInventory()}

                    {this.renderModal()}


                </InventoryBoxLanding>
                {this.renderPrintableTable()}
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    tableCellStyle:{
        fontFamily: 'Poppins', 
        fontWeight: 400, 
        color: "#475569", 
        fontSize: '14px',
        minWidth:"100px"
    },
    inventPrintTableContainerStyle:{
        width: "750px",
        margin: "20px auto 0 auto",
    },
    inventPrintTableMainHeading:{
        fontFamily:"sans-serif",
        fontSize:"25px"
    },
    inventPrintMainTable:{
        marginTop: "30px"
    },
    inventPrintColumnCell: {
        color: '#475569',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 700, width: '100px' 
    } as React.CSSProperties,
    paginantioinStyle:{
        FontFamily:"InterReguular",
        fontWeight:400,
        color:"#0F172A",
        fontSize:"12px"
    },
    pageDataCount:{ 
        fontFamily:"Poppins",
        fontWeight:400,
        color:"#64748B",
        marginRight:"10px",
        fontSize:"12px"
    },
    inventoryNoResultFoundTextTableCell:{
        textAlign:"center",
        fontFamily:"Poppins",
        fontSize:"20px",
        color:"gray",
        padding:"80px 0"
    } as React.CSSProperties,
    inventoryContainer: {
        backgroundColor: "#E2E8F0"
    },
    subContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    inventoryFeildsMian: {

    },

    InventoryText: {

    },
    filterby: {
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: '400',
        fontSize: '14px',
        marginTop: '13px',
        marginLeft: 'auto',
        lineHeight: '22px'
    },
    InventorytimelineText: {
        fontFamily: 'Poopins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    exportText: {
        fontFamily: 'Poopins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textTransform: 'capitalize',
    } as React.CSSProperties,
    Inventorytimelinebutton: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',

    } as React.CSSProperties,
    exportbutton: {


    } as React.CSSProperties,
    Inventorytimelinebutton2: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center'
    } as React.CSSProperties,
    InventoryinputBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    submitButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        maxWidth: '550px',
        width: "100%",
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400
    } as React.CSSProperties,
    datemodalStyle: {

    },
    InventorylabelBox: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    labelStyle: {
        fontFamily: 'Lato',
        color: "0F172A",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    inputStyle: {
        color: '#64748B',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    catogoryFilterBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '60px',
    },
    catogoryFilterMainDiv: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    filterDiv: {
        cursor: 'pointer',
        display: 'flex',
        margin: '10px',
        gap: '10px',
        alignItems: "center"
    },
    checkImage: {
        width: '24px',
        height: '24px',
    },
    filterTextStyle: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',

    },
    subFilterView: {
        cursor: 'pointer',
        display: 'flex',
        margin: '20px',
        gap: '10px',
        marginLeft: '50px'
    },
    rangeFilterMainDiv: {
        width: '195px',
        marginLeft: '50px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    timelinebutton: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',

    } as React.CSSProperties,

    timelinebutton2: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',
    } as React.CSSProperties,
    inputBox: {
        gap: '16px',
        flexDirection: 'column',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
        width: '300px',
    } as React.CSSProperties,
    timelineText: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize',

    } as React.CSSProperties,
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    filterbyText: {
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '13px',
        marginLeft: 'auto',
        lineHeight: '22px'
    },
    InvtsliderValue: {
        color: "red"
    } as React.CSSProperties,
    InvtsliderStyle: {
        width: '195px',
        marginLeft: '50px',
        marginBottom: '10px',
    },
    InvtfilterNoValue: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white'
    },
    filterNoValue: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white'
    },
    sortBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    InvtdateModelStyle: {
        position: 'absolute',
        width:"310px",
        right:0,
        top:"49px",
        background: 'white',
        zIndex: 999999,
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        borderRadius:"8px",
        overflow:"hidden"
    } as React.CSSProperties,
    InvtlabelBoxStyle: {
        display: 'flex',
        paddingRight:"10px",
        justifyContent: 'center',
        paddingTop: '5px',
        width: "100%",
        height: '48px',
        alignItems: 'center',
        cursor:"pointer"
    } as React.CSSProperties,
    InvtbottomBoxStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: '20px',
    } as React.CSSProperties,
    InvtpaginationStyle: {
        marginBottom: '20px'
    } as React.CSSProperties,
    InvtpagesTextStyle: {
        color: '#64748B',
        fontFamily: "Poppins",
        fontSize: '12px',
        marginRight: '15px',
        marginBottom: '20px'
    } as React.CSSProperties,
    submitButtonDum: {
        textTransform: 'capitalize',
        width: "100%",
        maxWidth: '550px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400,
        backgroundColor: '#C12030',
        fontSize: '16px',
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
    } as React.CSSProperties,
}
// Customizable Area End
