import React from "react";

import {
    Box,
    Card,
    CardContent,
    Typography,
    styled,
    Button

} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import Header from "../../../components/src/Header.web"
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { storeicon } from './assets';

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomBox = styled(Box)({
    paddingRight: '195px',
    paddingTop: "25px",
    '@media(max-width: 769px)': {
    paddingRight: '165px'
    },
    '@media(max-width: 600px)': {
        padding: '10px'
    }
})

const CardBox = styled(Box)({
    padding: '32px 23px',
    '@media(max-width: 600px)': {
        padding: '32px 40px 40px 40px',
        justifyContent:'center',
        alignItems:'center',
    },
    '@media(max-width: 992px)': {
        padding: '32px 40px 60px 38px'
    }

})


export default class StoreSettings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);

    }
    componentDidMount = async () => {
       this.handleGetStoreSettingsData()
      }
    render() {
        const {  listOfStores, initialStores } = this.state;
        const allItemsShown = initialStores >= listOfStores.length;
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <RightBoxLanding style={webStyle.secondContainer}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>

                    <Box style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                        <Header />

                        <CustomBox style={{ justifyContent: "end", backgroundColor: '#E2E8F0', display: 'flex' }}>
                            <Button style={{ padding: '16px ', marginBottom: '20px', border: '2px solid #C12030', height: '50px', maxWidth: '270px', width: '100%' }} data-test-id="backstore" onClick={() => { this.handleAddotherStore() }}>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, color: '#C12030', flexWrap: 'nowrap', textTransform: 'capitalize' }} >Add Other Store</Typography>
                            </Button>
                            <Button style={{ marginLeft: '20px', marginBottom: '20px', padding: '16px ', backgroundColor: '#C12030', height: '50px', maxWidth: '270px', width: '100%' }} data-test-id="addkstore" onClick={() => { this.handleMyStore() }}>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400, color: '#fff', flexWrap: 'nowrap', textTransform: 'capitalize' }}>Add My Store</Typography>
                            </Button>
                        </CustomBox>
                        <CardBox style={{ flexWrap: "wrap", display: "flex", gap: "30px", background: "rgb(226, 232, 240)",  padding:'20px 40px'}}>
                        {listOfStores.slice(0, initialStores).map((item, index) => (
                                <Card key={index} style={{ maxWidth: "351px",cursor:"pointer" } as React.CSSProperties} data-test-id={`storedetail${index}`} onClick={() => { this.handleStoreDetail(item.CommiValue) }}>
                                    <CardContent>
                                        <Box sx={{ marginBottom: 2 }}>
                                            <img src={storeicon} alt="Store" style={{ width: '100%', height: '228px' }} />
                                        </Box>
                                        <Typography style={webStyle.productTitle2}>{item.StoreTItle}</Typography>
                                        <Box style={webStyle.cardTexts2}>
                                            <Typography style={webStyle.productDescription2}>{item.Refrence}</Typography>
                                            <Typography style={webStyle.referenceno}>{item.RefValue}</Typography>
                                        </Box>
                                        <Box style={webStyle.cardTexts2}>
                                            <Typography style={webStyle.productDescription2}>{item.Commissions}</Typography>
                                            <Typography style={{...webStyle.productDescription2,fontSize:'16px'}}>{item.CommiValue}</Typography>
                                        </Box>
                                        <Box style={webStyle.cardTexts2}>
                                            <Typography style={webStyle.productDescription2}>{item.Status}</Typography>
                                            <Typography style={{...webStyle.approveText,color:item.statusColor}}>{item.StatusName}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}

                        <Box style={{display:"flex",justifyContent:"center",width:"100%"}}>
                     <Button
             style={allItemsShown ? {...webStyle.seeMoreStore,background:'grey',color:'white'} :webStyle.seeMoreStore}
             data-test-id="seeMoreStoreTestID"
             disabled={allItemsShown}
             onClick={this.handleSeeMore}
            >
              <span style={{fontSize:'16px'}}>See More Stores</span>
            </Button>
            </Box>
                        </CardBox>
                    </Box>
                </RightBoxLanding>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    productTitle2: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    cardTexts2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    } as React.CSSProperties,
    productDescription2: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    } as React.CSSProperties,
    approveText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referenceno: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    } as React.CSSProperties,
    secondContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: "#E2E8F0",
            height: "1100px"
        } as React.CSSProperties,
        seeMoreStore: {
            width: "228px",
            height: "50px",
            color: "#fff",
            fonstSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
            backgroundColor: "#C12030",
            margin: "32px auto 30px",
            textTransform: "capitalize",
          } as React.CSSProperties,
}
// Customizable Area End