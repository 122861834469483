import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"
import { getStorageData } from "../../../framework/src/Utilities";
const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "690px",
    width: '100%',
   // height: "676px",
   // paddingTop: "30px",
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto'
    }
})
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { arrow_back, } from "./assets";
const CustomTypography = styled(Typography)({
    paddingLeft: '35px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})

const WalletTop = styled(Box)({
    '@media(max-width: 990px)': {
        marginTop: "0 !important",
        display:"none"
      }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "20px !important"
      }
  })
  const HeaderBox = styled(Box)({
    '@media(max-width: 990px)': {
      display:"unset !important"
    }
  })
  const WalletTopForMobile = styled(Box)({
    display:"none",
    '@media(max-width: 990px)': {
      display:"block"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";

export default class TransferToOtherWallet extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        let showSuccess = await getStorageData('showTransferToOtherWalletSuccess')
        if (showSuccess != null) {
            this.handleSuccess()
        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={webStyle.mainView}>
                    <SideNavbar />
                    <RightBoxLanding2 style={webStyle.second_container}>
                    <HeaderBox style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </HeaderBox>
          <WalletTopForMobile style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</WalletText>
            </WalletTopForMobile>
                        <CustomBox>
                            <Box style={webStyle.secondMainContainer}>
                                <Box style={webStyle.backIcon}
                                    data-test-id={"back-arrow"} >
                                    <img src={arrow_back}
                                        style={webStyle.arrowBack} onClick={() => this.handleWalletChooseBank()} data-test-id={'backImageId'}/>
                                    <Typography style={webStyle.backText} onClick={() => this.handleWalletChooseBank()} data-test-id={'backTextId'}>
                                        Back
                                    </Typography>
                                </Box>
                                <Box style={webStyle.storeBox}>
                                    <Typography style={webStyle.walletText}>
                                        Add your Wallet ID
                                    </Typography>
                                    <input
  className="no-outline"
  type="text"
  name="username"
  placeholder="Enter Wallet ID"
  value={this.state.walletId}
  onChange={this.handleSetWalletId}
  style={{
    ...webStyle.inputStyle,
    border: `1px solid ${this.state.walletIdError ? 'red' : '#273567'}`,
  }}
/>

{this.state.walletIdError && (
  <div style={{ color: 'red', fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>
    Enter your correct ID number
  </div>
)}

                                    <Button style={webStyle.firstButton}
                                        data-test-id={'buttonPress'}
                                        onClick={this.sendWalletID}>
                                       Next
                                    </Button>
                                </Box>

                            </Box>
                            </CustomBox>
                    </RightBoxLanding2>
                    <Toaster position="top-center" toastOptions={{
                        success: {
                            style: {
                                padding: '16px',
                            },
                        },
                    }} />
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainView: {
        display: "flex",
        backgroundColor: "#F6F7FA",
    },
    container: {
        margin: '60px 0px',
    },
    walletText: {
        color: "#273567",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        marginLeft: "8px",
    },
    firstButton: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        marginTop: "20px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
        fontWeight: 400
    } as React.CSSProperties,
    inputStyle: {
        color: "#000000",
        fontFamily: 'Poppins',
        borderColor: 'white',
        fontWeight: 500,
        fontSize: '14px',
        alignSelf: 'center',
        borderRadius: '12px',
        justifyContent: 'space-between',
        border: `1px solid #273567`,
        alignItems: 'center',
        width: '100%',
        padding: '0px 10px 0px',
        height: '50px',
        marginTop: '10px',
        boxSizing: 'border-box'
    } as React.CSSProperties,
    storeBox: {
        width: "100%",
        height: "190px",
        borderRadius: '12px',
        padding: '15px',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        marginTop: '44px',
    } as React.CSSProperties,
    second_container:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
            height: "700px"
        } as React.CSSProperties,
    arrowBack: {
        width: "15.8px",
        height: "15.17px"
    },
    commisionText: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '24px',
        marginLeft:'20px'
    },
    secondMainContainer: {
        padding:"40px",
        paddingTop:"30px"
    },
    backText: {
        color: "#0000000",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        marginLeft: "8px",
    },
    backIcon: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: '10px'
    },
    text: {
        color: "#000000",
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        marginLeft: '5px'
    },
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
