import React from "react";

import {
    Box,
    Card,
    CardContent,
    Modal,
    Typography,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web"
import { storeicon, BackIcn, DeleteIcn, CloseIcn } from './assets';
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { Toaster } from "react-hot-toast"


const RightBoxLandingCommonOper = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const AddContainerOper = styled(Box)({
    '@media(max-width: 700px)': {
        flexWrap:"wrap"
    }
})

const AddCardOper = styled(Box)({
    '@media(max-width: 700px)': {
        maxWidth: "100% !important"
    }
})

const SubmitBtnResOper = styled(Box)({
    '@media(max-width: 700px)': {
        maxWidth: "100% !important",
        margin:"10px 0"
    }
})

const CardTextOper = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"14px !important"
    }
})

const CardSubTextOper = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"12px !important"
    }
})

const RefTextOper = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"12px !important"
    }
})

const OperatorNameOper = styled(Box)({
    '@media(max-width: 800px)': {
       width:"50% !important"
    }
})

const OperatorButtonOper = styled(Box)({
    '@media(max-width: 800px)': {
        width:"50% !important"
     }
})

export default class AddOperatorsOp extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        let dataOp = [
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
            {

                OperatorOper: "Operator name",
                OperatorEmailOper: "Email Id",
            },
        ]

        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <OperatorSideBar />
                <RightBoxLandingCommonOper style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                <Box style={webStyleOpper.drawerBoxOpper }>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <OperatorHeader />
                    <Box style={webStyleOpper.addsMainOpper} >
                    <Box style={{ cursor:"pointer",marginBottom:"30px",maxWidth:"80px"}} onClick={this.navigateToOperatorSettingsOperator}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center",fontFamily: 'Poppins',fontWeight: 500, }}>
                                <img src={BackIcn} alt="back-icn" />
                                Back
                            </Typography>
                        </Box>
                        <AddContainerOper style={{ display: "flex", justifyContent: "space-between", alignItems: "end",}}>
                            <AddCardOper style={{ width: "100%", maxWidth: "50%" }}>
                                <Card>

                                    <CardContent style={{ padding: "10px" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>

                                            <Box sx={{ marginBottom: 2, height: '110px', width: "220px" }}>
                                                <img src={storeicon} alt="Store" style={{ width: '100%', height: "100%",objectFit:"contain" }} />
                                            </Box>
                                            <Box style={{width:"100%"}}>
                                                <Box sx={{ marginLeft: "20px" }}>
                                                    <CardTextOper style={webStyleOpper.productTitleOpper as React.CSSProperties}>Khandal saree store, 403</CardTextOper>
                                                    <Box style={webStyleOpper.cardTexts2Opper as React.CSSProperties}>
                                                        <CardSubTextOper style={webStyleOpper.productDescriptionOpper as React.CSSProperties}>Reference Number</CardSubTextOper>
                                                        <RefTextOper style={webStyleOpper.referencenoOpper as React.CSSProperties}>A3D4D7</RefTextOper>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </AddCardOper>
                            <SubmitBtnResOper data-test-id="addNewOperator" onClick={this.navigateToRegistorNewOperatorOp} style={webStyleOpper.settingTabDocOpper as React.CSSProperties}>
                                <Typography style={webStyleOpper.tabTextDocOpper as React.CSSProperties}>Add New Operator</Typography>
                            </SubmitBtnResOper>
                        </AddContainerOper>
                        {dataOp.map((item, index) => (
                            <Box style={webStyleOpper.operatorListMainOpper as React.CSSProperties}>
                                <OperatorNameOper data-test-id={`operator-${index}`} style={{ width: "80%",cursor:"pointer"}} onClick={this.navigateToAddOperatorSettingsOperator}>
                                    <Typography style={webStyleOpper.nameMainOpper as React.CSSProperties}>
                                        {item.OperatorOper}
                                    </Typography>
                                    <Typography style={webStyleOpper.emailIdOpper as React.CSSProperties}>
                                        {item.OperatorEmailOper}
                                    </Typography>
                                </OperatorNameOper>
                                <OperatorButtonOper style={webStyleOpper.ButtonsMainOpper as React.CSSProperties}>
                                    <Box style={{ cursor: "pointer", height: "40px", marginRight: "15px" }} onClick={this.handleDeleteOprat}>
                                        <img src={DeleteIcn} alt="delete-icn" style={{ height: "100%", width: "100%" }} />
                                    </Box>
                                    <Box data-test-id={`Approve-${index}`} style={webStyleOpper.ApproveMainOpper as React.CSSProperties} onClick={()=>this.handleApprove('testId')}>
                                        <Typography style={webStyleOpper.approveBtnTextOpper as React.CSSProperties}>Approve</Typography>
                                    </Box>
                                </OperatorButtonOper>
                                <Box>

                                </Box>
                            </Box>
                        ))}
                    </Box>
                    
                    <Modal
                        open={this.state.removeOprat}
                        onClose={this.handleHide}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={{
                            backgroundColor: "white",
                            padding: "10px",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            border: '2px solid white',
                            borderRadius: "8px 8px 32px 8px",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 500,
                            outline: "none",
                            width:"100%",
                            maxWidth:"600px"
                        }}>
                            <Box style={{display:"flex",justifyContent:"flex-end",marginTop:"5px",marginRight:"10px",cursor:"pointer"}} onClick={this.handleHide}>
                                <img src={CloseIcn} alt="close-icn"/>
                            </Box>
                            <Box style={webStyleOpper.modalTextOpper as React.CSSProperties}>
                                Are you sure you want to remove operator?
                            </Box>
                            <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
                            <Box style={webStyleOpper.buttonContainerOpper as React.CSSProperties}>
                                <Box style={webStyleOpper.noButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:700}}>No</Typography>
                                </Box>
                                <Box style={webStyleOpper.yesButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:700}}>Yes</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Toaster position="top-center" />

                </RightBoxLandingCommonOper>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyleOpper = {
    productTitleOpper: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    cardTexts2Opper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: "10px 0"
    },
    productDescriptionOpper: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    },
    approveTextOpper: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2Opper: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referencenoOpper: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    },
    settingTabDocOpper: {
        padding: "15px",
        borderRadius: "8px",
        border: "1px solid #C12030",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"
    },
    tabTextDocOpper: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '30px',
        fontSize: '16px',
        color: "#C12030",
    },
    operatorListMainOpper: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "15px 0"
    },
    ButtonsMainOpper: {
        display: "flex",
        alignItems: "center",
        width: "20%",
        justifyContent: "flex-end"
    },
    nameMainOpper: {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "#000000",
        marginBottom:"5px"
    },
    emailIdOpper: {
        color: "#848FAC",
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "18px",
    },
    ApproveMainOpper: {
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "80px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer"
    },

    modalTextOpper:{
        fontSize:"16px",
        fontFamily:"Poppins",
        fontWeight:"600",
        lineHeight:"24px",
        marginLeft:"30px",
        marginTop:"10px"
    },


    approveBtnTextOpper: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '30px',
        fontSize: '16px',
        color: "#ffffff",
    },

    buttonContainerOpper: {
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-end",
        marginRight:"20px"

    },

    yesButtonOpper: {
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#ffffff",
    },

    noButtonOpper: {
        borderRadius: "8px",
        border:"1px solid #848FAC",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#848FAC",
        marginRight:"10px"
    },
    addsMainOpper:{
        padding: "20px 40px", 
        backgroundColor: "rgb(226, 232, 240)"
    }as React.CSSProperties,

    drawerBoxOpper:{ width: '100%',backgroundColor: "rgb(226, 232, 240)" }as React.CSSProperties,


}
// Customizable Area End