import React from "react";

import {
    Box,
    TableHead,
    TableRow,
    TableCell,
    RadioGroup,
    FormControlLabel,
    TableBody,
    Slider,
    ThemeProvider,
    Typography,
    Button,
    Modal,
    styled,
    Paper,
    TableContainer,
    Table,
    TableSortLabel,
} from "@material-ui/core";
import { createTheme, withStyles } from '@material-ui/core/styles'
import Tooltip from "@material-ui/core/Tooltip";

import VisualAnalyticsController, {
    configJSON,
    Props
} from "./VisualAnalyticsController";

import StyleRadio from '../../../components/src/StyleRadio.web';
import SideNavbar from "../../../components/src/SideBar.web";

import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, downIcon, printIcon, check } from "./assets";
import { Pagination } from "@material-ui/lab";
import ReactToPrint from "react-to-print";

interface providerSKUItem {
    id: number;
    text: string;
    checked: boolean;
}

interface Data {
    category: string;
    sku: string;
    qty: string;
    costOfSales: string;
    sellingValue: string;
    grossProfit: string;
    provider: string
}

interface Column {
    id:
    | "provider"
    | "category"
    | "sku"
    | "qty"
    | "costOfSales"
    | "sellingValue"
    | "grossProfit"
    label: string;
    minWidth?: number;
    align?: "right";
    sortable: boolean;
  }

const ProviderBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const ProviderCustomBox = styled(Box)({
    display: 'flex',
    padding: "0 40px 20px",
    gap: 16,

    '@media(max-width: 600px)': {
        justifyContent: 'center',
        marginLeft: '0px',
    }
})
const CustomWalletBoxProvider = styled(Box)({
    position:"relative",
    display:"flex",
    gap:"16px",
    '@media(max-width: 375px)': {
        flexWrap: 'wrap'
    }
})
const ProviderWallet = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    '@media(max-width: 600px)': {
        padding: '0px'
    }
})

const ShowProviderBox = styled(Box)({
    marginTop:"4px !important",
    '@media(max-width: 400px)': {
        width: '200px !important',
        "& .MuiSlider-root":{
            width:"145px !important",
            marginLeft:"43px !important"
        }
    },
    '@media(max-width: 375px)': {
        width: '200px !important',
        flexWrap: 'wrap'
    }
})

const SliderCustomDiv = styled('div')({
    '@media(max-width: 400px)': {
        width: '160px !important'
    }
})

const ProviderBackMain = styled(Box)({
    display: 'flex',
    margin: "10px 0"
})

const ProviderBox = styled(Box)({
    display: 'flex',
    flexWrap: "wrap",
    gap: '8px',
    backgroundColor: "#FFFFFF",
    padding: "17px 24px 17px",
    borderRadius: "4px",
    justifyContent: 'space-between',
    margin: "20px 0",
    maxWidth: "100%",
    alignItems: 'center'

})

const SortFilterBox = styled(Box)({
    '@media(max-width: 360px)': {
        width:'100%'
    }


})
const ProviderCustomButton2 = styled(Button)({
    alignItems: 'center',
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})
const ProviderButtonCust = styled(Button)({
    border: '1px solid #C12030',
    height: '44px',
    padding: '4px 16px 4px 20px',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})
const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#C12030",
            },
            rail: {
                color: 'pink'
            },
            track: {
                color: 'red'
            },

        },
    },

});

const ProviderFilterText = styled(Typography)({
    marginRight:"10px",
    '@media(max-width: 600px)': {
        marginLeft: 'unset !important'
    }
})

const FileSelect = styled(FormControlLabel)({
    margin: "auto !important",
    "& .MuiFormControlLabel-root": {
        margin: "auto !important"
    }
})

const MainFilterButtonWrapper = styled(Box)({
    display:"flex",
    alignItems:"center",
    '@media(max-width: 480px)': {
        flexDirection:"column",
        alignItems:"flex-start",
        gap:"5px"
    }
})

const ButtonWrapper = styled(Box)({
    '@media(max-width: 375px)': {
        width:"135px"
    }
})

const SortFilterParentBox = styled(Box)({
    '@media(max-width: 480px)': {
        width:"297px !important"
     },
    '@media(max-width: 375px)': {
       left: "0px !important",
       top:"108px !important",
       width:"220px !important"
    }
})



const CustomTooltip = withStyles(({
    tooltip: {
        marginTop:10,
        backgroundColor: '#F3D2D6'
    },
}))(Tooltip);

const StyledPrintHeaderRow = styled(TableRow)({
    backgroundColor: "#2980ba",
    color:"white",
    fontWeight:700
 });
  
const StylePrintBodyCell = styled(TableCell)({
    padding:"6px",
    color:"#626161",
    borderBottom: "none"
});

const EvenPrintStyledBodyRow = styled(TableRow)({
    backgroundColor: "#f5f5f5",
    color:"white",
    fontWeight:700,
    borderBottom: "none",
    borderTop: "none"
});

const StylePrintHeaderCell = styled(TableCell)({
    fontWeight:700,
    padding:"6px",
    borderBottom: "none",
    color:"white !important"
});

export default class ProductSalesByProvider extends VisualAnalyticsController {
    constructor(props: Props) {
        super(props);
    }

    ValueLabelComponent(props: any) {
        const { children, open, value } = props;
        return (
            <CustomTooltip data-test-id={`value-${value}`} open={open} interactive enterTouchDelay={0} title={value} id='dateModal' className="dateModal">
                {children}
            </CustomTooltip>
        );
    }

    profitValuerangeSlider = () => {

        const valueLabelFormat = (value: any) => {
            return (

                <div id='dateModal' className="dateModal" style={webStyle.sliderValue}>
                    {`R${value}`}
                </div>

            );
        };

        return (

            <ThemeProvider theme={muiTheme}>

                <Slider
                    id='dateModal' className="dateModal"
                    data-test-id="providerProfitValueSliderTestID"
                    valueLabelDisplay='on'
                    ValueLabelComponent={this.ValueLabelComponent}
                    style={webStyle.sliderStyle}
                    valueLabelFormat={valueLabelFormat}
                    value={this.state.providerProfitRange}
                    onChange={this.handleProviderProfitValueSliderChange}
                    aria-labelledby="discrete-slider-always"
                    min={0}
                    max={1000}
                />

                <SliderCustomDiv id='dateModal' className="dateModal" style={webStyle.rangeFilterMainDiv}>
                    <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal">0</Typography>
                    <Typography style={this.state.profitValues[1] >= 800 ? webStyle.filterNoValue : { ...webStyle.filterTextStyle, marginLeft: '10px' }} id='dateModal' className="dateModal">

                        {this.state.profitValues[1] >= 800 ? 0 : 1000}

                    </Typography>
                </SliderCustomDiv>
            </ThemeProvider>
        );
    }
    rangeSlider = () => {


        const valueLabelFormat = (value: any) => {
            return (

                <div 
                data-test-id="innerTestIdQTY"
                id='dateModal' className="dateModal" style={webStyle.sliderValue}>
                    {value}
                </div>

            );
        };

        return (
<ThemeProvider theme={muiTheme}>

                <Slider
                    id='dateModal' className="dateModal"
                    data-test-id="providerQuantitySliderTestID"
                    value={this.state.providerQuantityRange}
                    onChange={this.handleProviderQuantityRange}
                    valueLabelDisplay='on'
                    ValueLabelComponent={this.ValueLabelComponent}
                    style={webStyle.sliderStyle}
                    valueLabelFormat={valueLabelFormat}
                    aria-labelledby="discrete-slider-always"
                    min={0}
                    max={1000}
                />

                <SliderCustomDiv id='dateModal' className="dateModal" style={webStyle.rangeFilterMainDiv}>
                    <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal">0</Typography>

                    <Typography style={this.state.values[1] >= 800 ? webStyle.filterNoValue : { ...webStyle.filterTextStyle, marginLeft: '10px' }} id='dateModal' className="dateModal">

                        {this.state.values[1] >= 800 ? 0 : 1000}

                    </Typography>
                </SliderCustomDiv>

</ThemeProvider>
        );
    }

    subFilter = () => {
        return (

            <ShowProviderBox id='dateModal' className="dateModal" style={webStyle.subFilterBoxStyle} >
                <div style={webStyle.catogoryFilterMainDiv} id='dateModal' className="dateModal">
                    {this.state.providerMainFilter.map((item: any, index: any) => (
                        <div key={index} id='dateModal' className="dateModal">
                            <div style={webStyle.filterDiv} className="dateModal" id='dateModal'>
                                <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" onClick={() => this.handleProviderMainFilterChange(index)} style={webStyle.checkImage} data-test-id={`testIdSelectCategoryFilter${index}`} />
                                <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" onClick={() => this.handleProviderMainFilterChange(index)} data-test-id={`testIdSelectCategoryFilterText${index}`}>{item.text}</Typography>
                            </div>
                            {(item.text === 'Provider' && item.isShow) &&
                                this.state.providerArray.map((item: any, index: any) => (
                                    <div key={item.id} style={webStyle.subFilterView} id='dateModal' className="dateModal">
                                        <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} data-test-id={`testIdFilter${index}`} onClick={() => this.handleProviderCheckboxChange(index)} style={webStyle.checkImage} />
                                        <Typography style={webStyle.filterTextStyle} data-test-id={`testIdFilterText${index}`} id='dateModal' className="dateModal" onClick={() => this.handleProviderCheckboxChange(index)}>{item.text}</Typography>
                                    </div>
                                ))}
                            {(item.text === 'SKU' && item.isShow) &&
                                this.state.providerSKUArray.map((item: providerSKUItem, index: number) => (
                                    <div style={webStyle.subFilterView} key={item.id} data-test-id="divyang" id='dateModal' className="dateModal">
                                        <img src={this.checkedImage(item.checked)} data-test-id={`skutestIdFilter${index}`} id='dateModal' className="dateModal" style={webStyle.checkImage} onClick={() => this.handleProviderSKUChange(index)} />
                                        <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" onClick={() => this.handleProviderSKUChange(index)} data-test-id={`skutestIdFilterText${index}`}>{item.text}
                                        </Typography>
                                    </div>
                                ))}
                            {(item.isShow && item.text === 'Qty') && this.rangeSlider()}

                            {(item.isShow && item.text === 'Profit Value') && this.profitValuerangeSlider()}

                        </div>
                    ))}
                </div>

            </ShowProviderBox>

        )
    }

    handleSortFilterValue = (title: string, value: boolean, testId: string, order:string) => {
        return (
            <Box id='dateModal' data-test-id={`sortingDataTestID${value}`}  className="dateModal" style={{ ...webStyle.labelBoxStyle, backgroundColor: this.state.orderByProvi==="grossProfit"&&value ? '#CDF0EE' : 'white', }} onClick={()=>this.handleSortingOrderChange(order)} >
                {this.state.orderByProvi==="grossProfit"&&value ? <img src={check} style={webStyle.checkImageStyle} id='dateModal' className="dateModal" /> : <div style={{ marginLeft: '5px', height: '24px', width: '24px' }} id='dateModal' className="dateModal" />}
                <label id='dateModal' className="dateModal" style={webStyle.labelStyle as React.CSSProperties}>{title}</label>
            </Box>
        )
    }

    sortFilter = () => {
        return (
            <SortFilterParentBox style={webStyle.sortFilterBox} id='dateModal' className="dateModal">
                <SortFilterBox sx={webStyle.inputBoxProviderStyle} id='dateModal' className="dateModal">
                    {this.handleSortFilterValue('Sort by Ascending to Descending', this.state.selectAsending, 'handleSortFilterAcending', "desc")}
                    {this.handleSortFilterValue('Sort by Descending to Ascending', !this.state.selectAsending, 'handleSortFilterDecending', "asc")}
                </SortFilterBox>
            </SortFilterParentBox>
        )
    }

    renderCellContent = (value: string, valueName: string) => {
        return (
          <TableCell
            key={valueName}
            style={
              valueName === "provider" || valueName === "category"
                ? webStyle.columnCellTwoProvider
                : webStyle.columnCellTwo
            }
          >
            {valueName === "costOfSales" ||
            valueName === "sellingValue" ||
            valueName === "grossProfit" ? (
              <>
                <span style={{ fontWeight: 700, fontSize: "16px" }}>R</span>
                <span>{value.slice(1)}</span>
              </>
            ) : (
              <span>{value}</span>
            )}
          </TableCell>
        );
      };

    renderPrintableTable = () => {
        return (
          <Box style={{ display: "none" }}>
            <TableContainer ref={this.printableDivRef}  style={webStyle.printTableContainerStyle}>
                <Box style={webStyle.printTableMainHeading}>Product Sales By Provider</Box>
                <Table style={webStyle.printMainTable}>
                    <TableHead>
                    <StyledPrintHeaderRow>
                        {this.state.providercolumns.map((column:Column) => (
                        <StylePrintHeaderCell
                            key={column.id}
                            align={column.align}
                            style={webStyle.columnCell}
                        >
                            <span>{column.label}</span>
                        </StylePrintHeaderCell>
                        ))}
                    </StyledPrintHeaderRow>
                    </TableHead>
                    <TableBody>
                    {this.state.sortedData.map((Item: Data, index: number) => (
                        index % 2 !== 0 ? 
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {this.state.providercolumns.map((column:Column) => (
                                <StylePrintBodyCell
                                key={column.id}
                                align={column.align}
                                >
                                {Item[column.id]}
                                </StylePrintBodyCell>
                            ))}
                        </TableRow>:
                        <EvenPrintStyledBodyRow role="checkbox" tabIndex={-1} key={index}>
                            {this.state.providercolumns.map((column:Column) => (
                                <StylePrintBodyCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {Item[column.id]}
                                </StylePrintBodyCell>
                            ))}
                        </EvenPrintStyledBodyRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </Box>
        );
      };

      async componentDidMount() {
    this.getSalesByProvider()
      }

    render() {
        return (
            <Box 
                style={webStyle.userSettingContainer} 
                data-test-id={'handleProviderBodyClickOnScreen'}
                onClick={(event)=>this.handleProviderBodyClick(event)}
            >
                <SideNavbar />
                <ProviderBoxLanding style={webStyle.providerSecondContainer}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ padding: "0px 40px 40px 40px" }}>
                        <ProviderBackMain >
                            <img src={leftArrow} onClick={this.handleLandingPageFirst} style={webStyle.backArrow} />
                            <Typography data-test-id='backBtn' onClick={this.handleLandingPageFirst} style={webStyle.backText}>Back</Typography>
                        </ProviderBackMain>
                        <ProviderBox >
                            <ProviderWallet>
                                Product Sales by Category - Provider
                            </ProviderWallet>
                            <MainFilterButtonWrapper>
                            <ProviderFilterText style={webStyle.filterbyText as React.CSSProperties}>
                                Filter by:
                            </ProviderFilterText>
                            <CustomWalletBoxProvider>
                                <ButtonWrapper>
                                <Button style={webStyle.timelinebuttonStyle} onClick={this.SortByCategoryModal} id='dateModal' className="dateModal" data-test-id={'sortByCategoryModalId'}>
                                    <div style={webStyle.providerCatogoryBox} id='dateModal' className="dateModal">
                                        <Typography style={webStyle.timelineText} id='dateModal' className="dateModal">Provider</Typography>
                                        <img src={downIcon} id='dateModal' className="dateModal" style={{ marginLeft: '10px' }} />
                                    </div>
                                </Button>
                                </ButtonWrapper>
                                <ButtonWrapper >
                                    <Button style={webStyle.providerTimelinebutton2} onClick={this.SortByModal} id='dateModal' className="dateModal" data-test-id={'SortByModalId'}>
                                        <div style={webStyle.sortBoxStyle}>
                                            <Typography style={{ ...webStyle.timelineText, whiteSpace: 'nowrap' }} id='dateModal' className="dateModal">
                                                Sort <span style={{ textTransform: 'lowercase' }} id='dateModal' className="dateModal">by</span>
                                            </Typography>
                                            <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' className="dateModal" />
                                        </div>
                                    </Button>
                                </ButtonWrapper>
                                {this.state.showCategory && this.subFilter()}
                                {this.state.isOpenDateModal && this.sortFilter()}
                            </CustomWalletBoxProvider>
                            </MainFilterButtonWrapper>
                        </ProviderBox>
                        <Paper>
                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {this.state.providercolumns.map((column:any) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={webStyle.columnCell}
                                                >
                                                   {column.sortable ? (
                                                        <TableSortLabel
                                                            data-test-id={`columnSort${column.id}TestID`}
                                                            active={this.state.orderByProvi === column.id}
                                                            direction={this.state.orderByProvi === column.id ? this.state.orderProvi : 'asc'}
                                                            onClick={this.handleRequestSortProvi.bind(this, column.id)}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <span>{column.label}</span>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                       {this.state.sortedData.length!==0?this.state.providerShowData.map((value:Data, index:number) => {
                                            return (
                                                <TableRow data-test-id={`sortedDataTableRow${index}TestID`} hover role="checkbox" tabIndex={-1} key={index}>
                                                    {this.state.providercolumns.map((column:Column) => this.renderCellContent(value[column.id], column.id)
                                                    )}
                                                </TableRow>

                                            );
                                        }):
                                        <TableRow>
                                            <TableCell 
                                                colSpan={7} 
                                                style={webStyle.noResultFoundTextTableCell}
                                            >
                                                {configJSON.noResultFoundText}
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>

                                </Table>

                            </TableContainer>

                            {this.state.sortedData.length!==0?<div style={webStyle.bottomBox}>

                                <div />

                                <Pagination 
                                    data-test-id="paginationTestID"
                                    page={this.state.providerSelectedPage}
                                    count={Math.ceil(this.state.sortedData.length / 10)}
                                    onChange={(event:React.ChangeEvent<unknown>, newPage: number)=>
                                    this.handlePagination(event,newPage,"providerPagination")} 
                                    style={webStyle.pagination}
                                />

                                <Typography style={webStyle.pagesText}>
                                    {this.state.providerSelectedPage*10-9} -{" "}
                                    {(this.state.providerSelectedPage*10-9)+(this.state.providerShowData.length-1)}&nbsp;
                                    of {this.state.sortedData.length} results
                                </Typography>
                            </div>:null}
                        </Paper>
                        {this.renderPrintableTable()}
                    </Box>
                    <Modal
                        data-test-id="fileTypeModalTestID"
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-Category"
                        style={webStyle.modelViewStyle}
                    >
                        <Box style={webStyle.modelViewBoxStyle}>
                            <Typography style={webStyle.modeMsgStyle}>
                                Select the file type you would like to download :
                            </Typography>
                            <Box style={webStyle.newUserBox}>
                                <Box 
                                    data-test-id="pdfSelectButtonTestID"
                                    onClick={this.handleNewUserChange} 
                                    style={{ ...webStyle.pdfButton, backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white' }}
                                >
                                    <Typography style={webStyle.pdfTextStyle}>PDF</Typography>
                                    <div style={webStyle.smallBoxStyle}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <FileSelect value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>
                                </Box>
                                <Box 
                                    data-test-id="xlsxSelectButtonTestID"
                                    onClick={this.handleExistingUserChange} 
                                    style={{ ...webStyle.pdfButton, backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white' }}
                                >

                                    <Typography style={webStyle.xlcssTextStyle}>XLSX</Typography>

                                    <div style={webStyle.smallBoxStyle}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <FileSelect value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>
                                </Box>
                            </Box>
                            <Button 
                                data-test-id="confirmDownloadButtonTestID"
                                onClick={()=>this.handleProviderExportConfirm()} 
                                style={webStyle.submitButton2Style}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Modal>
                    <ProviderCustomBox>
                        <ProviderCustomButton2 data-test-id="exportAllButtonTestID" onClick={this.handleButtonClick}>
                            <Typography style={{ ...webStyle.exportText, textAlign: 'center' }}>Export All</Typography>
                        </ProviderCustomButton2>
                        <style>
                        {`
                            @media print {
                                html, body {
                                    margin: 0;
                                    padding: 0;
                                    height: initial !important;
                                    overflow: initial !important;
                                }
                                @page {
                                    margin: 0.5cm;
                                }
                            }
                        `}
                        </style>
                        {this.state.sortedData.length!==0?
                        <ReactToPrint
                            data-test-id="print"
                            trigger={() => (
                                <ProviderButtonCust data-test-id="print1" >
                                    <img src={printIcon} />
                                    <Typography style={{ ...webStyle.exportText, marginLeft: '5px' }}>Print</Typography>
                                </ProviderButtonCust>
                            )}
                            documentTitle="product_sales_by_provider"
                            content={() => this.printableDivRef.current}
                        /> :
                        <ProviderButtonCust  data-test-id="print2">
                            <img src={printIcon} />
                            <Typography style={{ ...webStyle.exportText, marginLeft: '5px' }}>Print</Typography>
                        </ProviderButtonCust>}                       
                    </ProviderCustomBox>
                </ProviderBoxLanding>
            </Box>
        );
    }
}

// Customizable Area Start
const webStyle = {
    printTableContainerStyle:{
        margin: "20px auto 0 auto", 
        width: "750px"
    },
    printTableMainHeading:{
        fontSize:"25px", 
        fontFamily:"sans-serif"
    },
    printMainTable:{
        marginTop: "30px"
    },
    userSettingContainer: {
        backgroundColor: "#E2E8F0"
    },
    providerSecondContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    filterNoValue: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white'
    },
    columnCellTwo: {
        color: "#475569",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        whiteSpace:"nowrap"
    } as React.CSSProperties,
    columnCellTwoProvider:{
        color: "#475569",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        flex:1,
        maxWidth:"auto",
        minWidth:"100px"
    } as React.CSSProperties,
    valueLabelStyle: {
        textAlign: "center",
        color: '#C12030',
        fontWeight: 400,
        fontSize: '14px'

    } as React.CSSProperties,
    backArrow: {
        width: '24px',
        height: '24px',
        cursor: 'pointer'
    },
    sliderstyle: {
        marginLeft: '50px',
        width: '222px',
        marginBottom: '10px',
    } as React.CSSProperties,
    filterText: {
        display: 'flex',
        gap: 16,
        width: "60%"
    },
    backText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer',
        marginLeft:"5px"
    },
    columnCell: {
        fontSize: '14px',
        fontWeight: 700, width: '100px',
        color: '#475569',
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    filterbyText: {
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: 400,
        fontSize: '14px',
        marginLeft: 'auto',
        lineHeight: '22px'
    },
    timelineText: {
        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    exportText: {
        fontFamily: 'Poopins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize',
    } as React.CSSProperties,
    timelinebuttonStyle: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',

    } as React.CSSProperties,

    bottomBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: '20px',
    } as React.CSSProperties,
    pagination: {
        marginBottom: '20px'
    } as React.CSSProperties,
    pagesText: {
        color: '#64748B',
        fontFamily: "Poppins",
        fontSize: '12px',
        marginRight: '15px',
        marginBottom: '20px'
    } as React.CSSProperties,
    providerCatogoryBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    providerTimelinebutton2: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center'
    } as React.CSSProperties,
    modelViewStyle: {
        overflowY: 'auto',
        width: '90%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
    } as React.CSSProperties,
    sliderValue: {
        textAlign: "center",
        color: '#C12030',
        fontWeight: 400,
        fontSize: '14px',
    } as React.CSSProperties,
    modelViewBoxStyle: {
        maxWidth: '550px',
        width: '100%',
        padding: 30,
        backgroundColor: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius:"7px"
    } as React.CSSProperties,
    pdfTextStyle: {
        fontSize: '20px',
        fontWeigth: '400',
        fontFamily: 'Poppins',
        color: '#0F172A',
        margin: 'auto'
    } as React.CSSProperties,
    smallBoxStyle: {
        display: 'flex',
        alignItems: 'center'
    } as React.CSSProperties,
    modeMsgStyle: {
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        maxWidth: '373px',
        margin: 'auto',
    },
    newUserBox: {
        display: 'flex',
        gap: 26,
        marginTop: 60,
        justifyContent: 'center'
    } as React.CSSProperties,
    pdfButton: {
        maxWidth: '143px',
        height: '117px',
        width: '100%',
        padding: '20px 30px',
        borderRadius: '4px',
        border: '1px solid #E6E6E6',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',


    } as React.CSSProperties,
    sortBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    xlcssTextStyle: {
        fontSize: '20px',
        fontWeigth: '400',
        fontFamily: 'Poppins',
        color: '#0F172A',
        margin: 'auto'
    } as React.CSSProperties,

    inputBoxProviderStyle: {
        width: '100%',
        flexDirection: 'column',
        gap: '16px',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    sortFilterBox: {
        position: 'absolute',
        background: 'white',
        right: 0,
        width:"310px",
        zIndex: 999999,
        top:"49px",
        borderRadius:"8px",
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        overflow:"hidden"
        
    } as React.CSSProperties,
    submitButton2Style: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        maxWidth: '550px',
        width: "100%",
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400
    } as React.CSSProperties,

    labelBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '10px',
        paddingTop: '5px',
        width: "100%",
        height: '48px',
        alignItems: 'center',
    } as React.CSSProperties,
    labelStyle: {
        fontFamily: 'Lato',
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
    },
    checkImageStyle: {
        height: '24px',
        width: '24px'
    } as React.CSSProperties,
    inputStyle: {
        color: '#64748B',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    catogoryFilterBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '60px'
    },
    catogoryFilterMainDiv: {
        width: '90%',
        backgroundColor: 'white',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    subFilterBoxStyle: {
        position: 'absolute',
        background: 'white',
        zIndex: 999999,
        padding: '20px',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        width: "250px",
        borderRadius: "8px",
        left:"0",
        top:"44px"
    } as React.CSSProperties,
    filterDiv: {
        cursor: 'pointer',
        display: 'flex',
        margin: '10px',
        gap: '10px',
    },
    checkImage: {
        width: '24px',
        height: '24px',
    },
    filterTextStyle: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',


    },
    sliderStyle: {
        marginBottom: '10px',
        width: '180px',
        marginLeft: '50px',

    },
    subFilterView: {
        cursor: 'pointer',
        display: 'flex',
        margin: '20px',
        gap: '10px',
        marginLeft: '50px'
    },
    rangeFilterMainDiv: {
        width: '180px',
        marginLeft: '50px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    noResultFoundTextTableCell:{
        textAlign:"center",
        fontFamily:"Poppins",
        fontSize:"20px",
        color:"gray",
        padding:"80px 0"
    } as React.CSSProperties
}
// Customizable Area End
