Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "signuplogin";
exports.labelBodyText = "signuplogin Body";
exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
exports.MesErrProfile = {
  imageUplode:"Please upload valid image.",
  imageUrl: "Please upload your image.",
  gender: "Please specify your gender.",
  firstName: "Please enter your first name.",
  lastName: "Please enter your last name.",
  email: "Please enter your email.",
  country: "Please enter your country.",
  phoneNumber: "Please enter a valid phone number.",
  streetAddress: "Please enter the street address.",
  flatNo: "Please enter your flat Number.",
  city: "Please enter your city.",
  postCode: "Please enter your post code.",
  phoneCountry: "Please enter your phone number's country code.",
};
exports.btnExampleTitle = "CLICK ME";
exports.stateTextLabel = "State" ;
exports.selectStateText = "Select the State" ;
exports.statesList = [
  "Eastern Cape",
  "Free State",
  "Gauteng",
  "KwaZulu-Natal",
  "Limpopo",
  "Mpumalanga",
  "Northern Cape",
  "North West",
  "Western Cape"
];
// Customizable Area End