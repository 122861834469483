import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    IconButton,
    styled,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { background_image,a2payImage } from './assets';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const BackimgNew = styled('img')({
  height:"100%",
  '@media (max-width: 600px)': {
      width:'100%',
      height: '400px ',
      objectFit: 'fill',

  },
  '@media (min-width: 600px) and (max-width: 960px)': {
    objectFit: 'fill',

      height: '500px ',
      width: '100%',
    },
})
const LogoimgNew = styled('img')({
    '@media (max-width: 600px)': {
        left: 'unset !important',
        top: '250px !important'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        left: 'unset !important',
        top: '250px !important'
      },
})
const CustomGridNew = styled(Grid)({
    padding:'0px 80px',
    '@media (max-width: 600px)': {
      padding: '0px 20px'
    }
  })
  const MobTypographyNew = styled(Typography)({
    fontSize:28,
    '@media (max-width: 600px)': {
      fontSize:20
    }
  })
  const MobSubTypographyNew = styled(Typography)({
    fontSize:18,
    '@media (max-width: 600px)': {
      fontSize:16
    }
  })
  const ImageContainer = styled(Grid)({
    justifyContent: "center",
    height: '100%',
    display: "flex",
    width:'50%',
    position:'fixed',
    left:0,
    '@media (min-width: 600px) and (max-width: 960px)': {
      width: '100%',
      height: '500px ',
      marginBottom:'20px',
      position:'relative',
    },
  
    '@media (max-width: 600px)': {
      position:'relative',
      height: '400px ',
      width:'100%',
      marginBottom:'20px',
    },
  })
  
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";

export default class NewPasswordFlow extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <Grid container justifyContent={"flex-end" }  style={{  height: "100vh" }}>
                <ImageContainer item xs={12}
            sm={12} lg={6}
            md={6}>
                    <LogoimgNew src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top: 50, left: 242 }} />
                    <BackimgNew src={background_image} style={{ width: '100%'}} />
                </ImageContainer>
                <CustomGridNew item xs={12}
                sm={12} lg={6}
                md={6} >
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box style={{ width: "100%" }}>
                            <MobTypographyNew style={webStyle.welcomeText as React.CSSProperties}>New Password</MobTypographyNew>
                            <MobSubTypographyNew variant="body1" style={webStyle.merchantText as React.CSSProperties}>
                                Enter a new password for xyz@gmail.com
                            </MobSubTypographyNew>
                            <label
                                style={webStyle.labelStyle as React.CSSProperties}
                            >
                                Password
                            </label>
                            <div style={{ ...webStyle.passworddiv } as React.CSSProperties}>
  <input
    type={this.state.showPassword ? 'text' : 'password'}
    name="password"
    style={{
      ...webStyle.inputStyle,
      paddingRight: '40px',
      borderColor: this.state.conpassvalid.includes('password') ? 'red' : '#273567'
    } as React.CSSProperties}
    data-test-id="testpassword"
    onChange={this.handleInputChangesTwo}
    value={this.state.password}
  />
  <IconButton
    onClick={this.handleTogglePassword}
    edge="end"
    style={{ position: 'absolute', right: '15px', top: '42%', transform: 'translateY(-50%)' }}
  >
    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
  </IconButton>
</div>
{this.state.passwordError && (
  <Typography style={{ color: "#FF0404", fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins',marginBottom:"10px" }}>
    {this.state.passwordError}
  </Typography>
)}

                            <label
                                style={webStyle.labelStyle as React.CSSProperties}
                            >
                                Confirm Password
                            </label>
                            <div style={{ ...webStyle.confirmpassdiv } as React.CSSProperties}>
  <input
    type={this.state.confirmPassword ? 'text' : 'password'}
    name="confirmPasswords"
    style={{
      ...webStyle.inputStyle,
      paddingRight: '40px',
      borderColor: this.state.conpassvalid.includes('confirmPasswords') ? 'red' : '#273567'
    } as React.CSSProperties}
    onChange={this.handleInputChangesTwo}
    value={this.state.confirmPasswords}
  />
  <IconButton
    edge="end"
    onClick={this.handleClickConfirmPassword}
    style={{ position: 'absolute', right: '15px', top: '42%', transform: 'translateY(-50%)' }}
  >
    {this.state.confirmPassword ? <Visibility /> : <VisibilityOff />}
  </IconButton>
</div>
{this.state.confirmPasswordError && (
  <Typography style={{ color: "#FF0404", fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>
    {this.state.confirmPasswordError}
  </Typography>
)}
                            <Button style={webStyle.nextButton as React.CSSProperties} data-test-id="setnew" onClick={this.handleSubmitTwo}>Set new password</Button>
                        </Box>
                    </Box>


                </CustomGridNew>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        width: "100%", // Full width on all devices
        border: "1px solid #273567",
        borderRadius: "8px",
        color: "#273567",
        height: "50px",
        boxSizing: "border-box",
        padding: "4%", // Adjust padding as needed
        marginBottom: "10px", // Adjust margin as needed
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    welcomeText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize: '28px',
        lineHeight: '42px'
    },
    merchantText: {
        maxWidth: '491px',
        fontWeight: 400,
        // fontSize: '18px',
        fontStyle: 'normal',
        color: '#848FAC',
        lineHeight: '24px',
        fontFamily: 'Graphik',
        marginBottom: 30,

    },
    nextButton: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        width: '100%',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'poppins'
    },
    inputStyleTwo: {
        width: '100%',
        height: '40px',
        color: '#273567',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid #273567',
        padding: 15,
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500
    },
    labelStyle: {
        letterSpacing: "0em",
        fontFamily: 'Poppins',
        fontWeight: "400",
        fontSize: "16px",
        color: "#273567",
        lineHeight: "24px",
    },
    Modal1: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '400px',
        height: 'auto',
        margin: 'auto',
        marginTop: '100px',
        width: '50%',
        flexShrink: '0',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)'
    },
    verificationText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
        fontFamily: " Poppins",
        fontSize: "40px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%"
    },
    cardOtp: {
        whiteSpace: 'nowrap',
        color: '#848FAC',
        textAlign: "center",
        fontSize: "12px",
        fontFamily: " Poppins",
        fontStyle: "normal",
        lineHeight: "140%",
        fontWeight: 400
    },
    resendOtpText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#273567',
        marginTop: '10',
        fontFamily: " Poppins",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "140%"
    },
    userVerifiedTextTwo: {
        fontSize: "33px",
        lineHeight: "140%",
        fontWeight: 400,
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: "center",
        color: '#030F2D',
        fontFamily: " Poppins",
        fontStyle: "normal"
    },
    confirmpassdiv:
    {
        position: 'relative'
    },
    passworddiv:
    {
        position: 'relative'
    }
};
// Customizable Area End
