import React from "react";

import {
    Box,
    Button,
    Typography,
    TableContainer,
    styled,
    Paper,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import SalesReportingController, {
    Props
} from "./Cfloanapplication2Controller";
import { leftArrowImg } from "./assets";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';

const RightSideBox = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: '150px'
    }
})

const CustomBoxNew = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: 16,
    marginTop: '50px',
    paddingBottom: 20,
    marginLeft: "-26px",
    [theme.breakpoints.down('sm')]: {
        marginTop:"30px",
        flexWrap:"wrap",
        marginLeft:0
    }
}));

const CustomWalletTypography1 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
        padding: 0
    }
}));

const CustomBackBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: '40px',
    [theme.breakpoints.down('xs')]: {
        paddingLeft: '39px',
    }
}));

const CustomUserBoxNew = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    padding: '20px 45px',
    justifyContent: 'space-between',
    margin: '30px 40px ',
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
        display: 'unset',
    }
}));


const CustomRedButton = styled(Button)(({ theme }) => ({
    border: '1px solid #C12030',
    borderRadius: theme.spacing(1),
    maxWidth: theme.spacing(25),
    width: '100%',
    height: theme.spacing(5.5),
    padding: theme.spacing(0.5, 2, 0.5, 2.5),
    alignItems: 'center',
    marginLeft: '5%',
}));

const CustomRedButton2 = styled(Button)(({ theme }) => ({
    border: '1px solid #C12030',
    borderRadius: theme.spacing(1),
    maxWidth: theme.spacing(25),
    backgroundColor: "#C12030",
    width: '100%',
    height: theme.spacing(5.5),
    padding: theme.spacing(0.5, 2, 0.5, 2.5),
    alignItems: 'center',
    '&:hover': {
        backgroundColor: "#C12030 !important"
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '5%',
    }
}));

interface Column {
    id:
    | "loanId"
    | "storeName"
    | "status"
    | "amount";
    label: string;
    minWidth?: number;
    align?: "right";
}

interface Data {
    storeName: string;
    loanId: string;
    status: string;
    amount: string;
}


const column: Column[] = [
    { id: "loanId", label: "Loan ID", },
    { id: "storeName", label: "Store Name" },
    { id: "status", label: "Status" },
    { id: "amount", label: "Amount" }
];

const rowsDatas: Data[] = [
    {
        storeName: "KVM Store",
        status: "IN PROCESS",
        loanId: "125CCC4444",
        amount: "R500",
    },
    {
        storeName: "KK Store",
        status: "APPROVED",
        loanId: "125CCC4444",
        amount: "R500",
    },
    {
        storeName: "Azad Store",
        status: "REJECTED",
        loanId: "125CCC4444",
        amount: "R500",
    },
    {
        storeName: "Mega Store",
        status: "IN PROCESS",
        loanId: "126DDD5555",
        amount: "R1000",
    },
    {
        storeName: "Quick Mart",
        status: "APPROVED",
        loanId: "127EEE6666",
        amount: "R1500",
    },
    {
        storeName: "SaveMore",
        status: "REJECTED",
        loanId: "128FFF7777",
        amount: "R2000",
    },
    {
        storeName: "Budget Store",
        status: "IN PROCESS",
        loanId: "129GGG8888",
        amount: "R2500",
    },
    {
        storeName: "Discount Depot",
        status: "APPROVED",
        loanId: "130HHH9999",
        amount: "R3000",
    },
    {
        storeName: "Super Saver",
        status: "REJECTED",
        loanId: "131III0000",
        amount: "R3500",
    },
    {
        storeName: "Value Shop",
        status: "IN PROCESS",
        loanId: "132JJJ1111",
        amount: "R4000",
    }
];


export default class SalesReportingPageOperator extends SalesReportingController {
    constructor(props: Props) {
        super(props);
    } 
    async componentDidMount(){
        this.getOperatorLoanList()
    }

    render() {
        const { pageData, rowsPerPageData } = this.state;
        return (
            // Customizable Area Start
            <Box style={webStyleoperat.userContainerSettingoperat}  data-test-id='user-setting-container'>
                <OperatorSideBar />
                <RightSideBox style={webStyleoperat.containerSecondoperat}>
                    <Box style={{ width: '100%' }}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <CustomBackBox data-test-id="backLanding" onClick={this.navigateToOperatorLandingPage}>
                        <img src={leftArrowImg} onClick={this.goBackClick} style={{ width: '24px', height: '24px', cursor: 'pointer',marginRight:'10px' }} />
                        <Typography onClick={this.goBackClick} style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500,  cursor: 'pointer' }}>Back</Typography>
                    </CustomBackBox>
                    <CustomUserBoxNew>
                        <CustomWalletTypography1>
                            MY LOAN
                        </CustomWalletTypography1>


                    </CustomUserBoxNew>
                    <Box style={{ padding: "0 40px",marginBottom:"20px",}}>
                        <Paper style={{ overflowX: "auto", height: "" }}>
                            <TableContainer style={webStyleoperat.tableConoperat}>
                                <Table stickyHeader aria-label="using sticky table">
                                    <TableHead>

                                        <TableRow>
                                            {column.map((columnLine) => (
                                                <TableCell
                                                    align={columnLine.align}
                                                    key={columnLine.id}
                                                    style={webStyleoperat.tableCellStyleoperat}
                                                >
                                                    {columnLine.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {rowsDatas
                                            .slice(pageData * rowsPerPageData, pageData * rowsPerPageData + rowsPerPageData)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        {column.map((columnLine) => {
                                                            const originalValue = row[columnLine.id];
                                                            const isStatusColumn = columnLine.id === "status";
                                                            const isCommissionColumn = columnLine.id === "amount";
                                                            return (
                                                                <TableCell
                                                                    key={columnLine.id}
                                                                    align={columnLine.align}
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                        fontSize: '14px',
                                                                        fontWeight: 400,
                                                                        fontFamily: 'Poppins',
                                                                    }}
                                                                >
                                                                    {isCommissionColumn ? (
                                                                        <div>
                                                                            {originalValue.includes("R") ? (
                                                                                <>
                                                                                    <span style={{ fontWeight: "bold" }}>R</span>
                                                                                    <span>{originalValue.slice(1)}</span>
                                                                                </>
                                                                            ) : (
                                                                                originalValue
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        typeof originalValue === 'string' ? (
                                                                            isStatusColumn ? (
                                                                                <div style={{
                                                                                    color: originalValue === "APPROVED" ? "#059669" : originalValue === "IN PROCESS" ? "#FFBA08" : "#EF4444",
                                                                                    backgroundColor: originalValue === "APPROVED" ? "#D1FAE5" : originalValue === "IN PROCESS" ? "#FEF3C7" : "#FEE2E2",
                                                                                    borderRadius: "10px",
                                                                                    height: "20px",
                                                                                    width: "100px",
                                                                                    fontWeight: 700,
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                }}>{originalValue}</div>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <span>
                                                                                        {originalValue}
                                                                                    </span>
                                                                                </React.Fragment>
                                                                            )
                                                                        ) : (
                                                                            <span>{originalValue}</span>
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomBoxNew>
                                <CustomRedButton data-test-id="loancal" onClick={this.navigateToLoanCalculator}>
                                    <Typography style={webStyleoperat.exportTxtoperat}>Loan Calculator</Typography>
                                </CustomRedButton>
                                <CustomRedButton2 data-test-id="applyForLoan"onClick={this.navigateToLoanApplicationOperator}>
                                    <Typography style={{ ...webStyleoperat.exportTxtoperat, color: "white" }}>Apply for Loan</Typography>
                                </CustomRedButton2>
                            </CustomBoxNew>
                        </Paper>

                    </Box>




                </RightSideBox>
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyleoperat = {
    userContainerSettingoperat: {
        backgroundColor: "#E2E8F0"
    },
    containerSecondoperat:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,

    exportTxtoperat: {
        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize',
    } as React.CSSProperties,
    tableConoperat: {
        maxHeight: 500
    },
    tableCellStyleoperat: {
        fontSize: '14px', fontWeight: 700, width: '100px', color: '#475569', fontFamily: 'Poppins'
    },
}
// Customizable Area End
