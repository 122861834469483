import React, {ChangeEvent} from "react";

import {
    Box,
    Typography,
    Button,
    Modal,
    styled,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Slider,
    TableCell,
    RadioGroup,
    FormControlLabel,
    TableBody,
    ThemeProvider,
    TableSortLabel,
    Snackbar
} from "@material-ui/core";
import SalesReportingController, {
    Props, configJSON
} from "./SalesReportingController";
import { leftArrow, downIcon, printIcon, checkImg } from "./assets";
import SideNavbar from "../../../components/src/SideBar.web";
import StyleRadio from '../../../components/src/StyleRadio.web';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { createTheme, withStyles } from '@material-ui/core/styles'
import { Pagination } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from '@material-ui/icons/Cancel';
import ReactToPrint from "react-to-print";

interface SalesReportItem {
    date: string;
    sku: string;
    value: string;
    provider: string;
    commission: string;
    qty: string;
};

const RightSideBox = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomBoxNew = styled(Box)({
    display: 'flex',
    gap: 16,
    marginTop: '40px',
    paddingBottom: 20,
    '@media(max-width: 600px)': {
        marginLeft: '0px',
        justifyContent: 'center'
    },
    '@media(max-width: 360px)': {
        paddingLeft:"40px",
        paddingRight:"40px"
    }
});

const CustomWalletTypography1 = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    display:"flex",
    alignItems:"center"
});

const CustomBackBox = styled(Box)({
    display: 'flex',
    margin: "10px 0 0",
    paddingLeft: '40px',
});

const CustomUserBoxNew = styled(Box)({
    display: 'flex',
    gap: 8,
    backgroundColor: "#FFFFFF",
    padding: "17px 24px",
    borderRadius: 4,
    justifyContent: 'space-between',
    margin: "20px 40px",
    flexWrap:"wrap"
});

const CustomDataBox = styled(Box)({
    display:"flex",
    gap:"16px",
    position:"relative",
    '@media(max-width: 400px)': {
        flexWrap: 'wrap'
    }
})

const CustomRedButton = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: 8,
    maxWidth: 152,
    width: '100%',
    height: 44,
    padding: "4px 16px 4px 20px",
    alignItems: 'center',
    marginLeft: '40px',
    '@media(max-width: 600px)': {
        maxWidth: '130px',
        marginLeft:0
    }
});

const CustomRedButton2 = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: 8,
    maxWidth: 152, 
    width: '100%',
    height: 44,
    padding: "4px 16px 4px 20px", 
    alignItems: 'center',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    },
    '@media(max-width: 360px)': {
        width:"100% !important"
    }
});

const themMui = createTheme({
    overrides: {
        MuiSlider: {
            track: {
                color: 'red'
            },
            thumb: {
                color: "#C12030",
            },
            rail: {
                color: 'pink'
            },

        },
    }
});

const DataModal = styled(Box)({
    backgroundColor: 'white',
    width: '45%',
    padding: "0 0 0 42px",
    borderRadius: '8px'
});

export const RangeBubbleContainer = styled(Slider)({

    
    width: '180px !important',
    marginLeft: '50px !important',
    marginBottom: '10px !important',
    '@media(max-width: 400px)': {
        width: '145px !important',
        marginLeft: "40px !important"
    },
    "& .PrivateValueLabel-offset-32": {
        marginTop: '65px !important',
        "& .PrivateValueLabel-circle-33": {
            borderRadius: '5px !important',
            transform: 'rotate(0deg) !important',
            height: 0,
            width: 0,
            padding: '12px 16px !important',
            margin: '2px !important',
            backgroundColor: '#F3D2D6 !important',

            "& .PrivateValueLabel-label-34": {
                color: '#C12030 !important',
                transform: 'rotate(0deg) !important',
            }
        }
    }

});

const CustomFormRadioLabel = styled(FormControlLabel)({
    margin: "auto !important",
    "& .MuiFormControlLabel-root": {
        margin: "auto !important"
    }
});

const ExportBox1 = styled(Box)({

    '@media(max-width: 600px)': {
        height: "100px !important",
        maxWidth: "100px !important"
    },
});

const ExportContainer1 = styled(Box)({
    '@media(max-width: 600px)': {
        gap: "20px !important"
    },
});

const DateModelBox = styled(Typography)({
    right:0,
    top:49,
    width:"310px",
    borderRadius:"8px",
    overflow:"hidden",
    '@media(max-width: 475px)': {
        width:"220px"
    },
    '@media(max-width: 400px)': {
        left:0,
        top:109
    }
});

const DateModelSecondBox = styled(Typography)({
    '@media(max-width: 475px)': {
        width: '220px !important'
    },
});

const ShowCategoryBox = styled(Box)({
    padding: '20px',
    width:"250px",
    background: 'white',
    position: 'absolute',
    zIndex: 999999,
    borderRadius: "8px",
    left:0,
    top:49,
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 400px)': {
        width: '200px !important'
    },
});

const SubcategoryBox = styled(Typography)({
    '@media(max-width: 600px)': {
        marginLeft: '10px',
    },
});

const RangeFilterMaxAmount = styled(Box)({
    marginLeft: '43px',
    '@media(max-width: 400px)': {
    marginLeft: '40px'
    }
});

const TimelineInputStyle = styled(Box)({
    width: "100%",
    '@media(max-width: 600px)': {
        width: "144px"
    },
});

interface Column {
    id:
    | "provider"
    | "sku"
    | "qty"
    | "value"
    | "commission";
    label: string;
    minWidth?: number;
    align?: "right";
    sortable: boolean;
}

interface Data {
    sku: string;
    provider: string;
    value: string;
    qty: string;
    commission: string;
}


const column: Column[] = [
    { id: "provider", label: "Provider", sortable: false },
    { id: "sku", label: "SKU", sortable: false },
    { id: "qty", label: "Qty", sortable: true },
    { id: "value", label: "Value", sortable: true },
    { id: "commission", label: "Commission", sortable: true }
];

const TooltipCustom = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#F3D2D6',
        marginTop:"10px"
    },
}))(Tooltip);

const StyledHeaderRow = styled(TableRow)({
    backgroundColor: "#2980ba !important",
    color:"white !important",
    fontWeight:700
 });
  
const EvenStyledBodyRow = styled(TableRow)({
    backgroundColor: "#f5f5f5 !important",
    color:"white !important",
    fontWeight:700,
    borderBottom: "none !important",
    borderTop: "none !important"
});
  
const StyleHeaderCell = styled(TableCell)({
    color:"white !important",
    fontWeight:700,
    padding:"6px",
    borderBottom: "none !important"
});
  
const StyleBodyCell = styled(TableCell)({
    padding:"6px",
    color:"#626161",
    borderBottom: "none !important"
});

const MainButtonWrapper = styled(Box)({
    display:"flex",
    gap:"10px",
    alignItems:"center",
    flexWrap:"wrap"
});

export default class SalesReportingPage extends SalesReportingController {
    constructor(props: Props) {
        super(props);
    }
    ValueComponentLabel(props: any) {
        const { children, open, value } = props;
        return (
            <TooltipCustom open={open} interactive enterTouchDelay={0} title={value} id='leftFilterBox'>
                {children}
            </TooltipCustom>
        );
    }
    rangeSlider = (
            values: number[], 
            handleChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void, 
            max: number, 
            name:string 
        ) => {
        return (
            <ThemeProvider theme={themMui}>
                <RangeBubbleContainer
                    data-test-id={`${name}SliderTestID`}
                    id='leftFilterBox'
                    value={values}
                    ValueLabelComponent={this.ValueComponentLabel}
                    onChange={handleChange}
                    min={0}
                    aria-labelledby="discrete-slider-always"
                    valueLabelDisplay='on'
                    max={max}
                    valueLabelFormat={(value) => {
                        return (
                            <div style={{ textAlign: "center", color: '#C12030', fontWeight: 400, fontSize: '14px' }} id='leftFilterBox' >
                                {name!=='quantity'?`R${value}`:value}
                            </div>
                        );
                    }}
                />
                <RangeFilterMaxAmount style={webStyle.rangeFilterDivMain} id='leftFilterBox'>
                    <Typography style={webStyle.filterStyleText} id='leftFilterBox'>{name!=="quantity"?`R${0}`:0}</Typography>
                    <Typography style={webStyle.filterStyleText} id='leftFilterBox'>{name!=="quantity"?`R${max}`:max}</Typography>
                </RangeFilterMaxAmount>
            </ThemeProvider>
        )
    }

    handleSortFilterOption = (value: boolean, title: string, order:string) => {
        return (
            <Box className="sortBySalesR" id='leftFilterBox' style={{ ...webStyle.labelStyleBoxSa, backgroundColor: this.state.orderBy==="value"&&value ? '#CDF0EE' : 'white', cursor: "pointer" }} onClick={()=>this.handleSortFilterCategory(order)} data-test-id={'handleSortFilterAcending'}>
                <img src={checkImg} style={{visibility:this.state.orderBy==="value"&&value?"visible":"hidden",  height: '24px', width: '24px', marginLeft: '5px', }} />
                <label id='leftFilterBox' className="sortBySalesR" style={{ ...webStyle.labelStyle as React.CSSProperties, fontFamily: "Lato", fontSize: "16px", fontWeight: 400, cursor: "pointer" }}>{title}</label>
            </Box>
        )
    }

    renderSubFilterOptions(text: string) {
        switch (text) {
            case 'Provider':
                return this.state.providerArray.map((item: any, index: any) => (
                    <SubcategoryBox data-test-id={`providerOption${index}TestID`} onClick={() => this.handleCheckboxChange(index)} key={item.id} style={webStyle.subViewFilter} id='leftFilterBox'>
                        <img src={this.imageChecked(item.checked)} style={webStyle.imageCheck} data-test-id={`testIdSelectCategoryFilter${index}`} id='leftFilterBox' />
                        <Typography style={webStyle.filterStyleText} data-test-id={`testIdSelectCategoryFilterText${index}`} id='leftFilterBox'>{item.text}</Typography>
                    </SubcategoryBox>
                ));
            case 'SKU':
                return this.state.skuArray.map((item: any, index: any) => (
                    <SubcategoryBox data-test-id={`skuOption${index}TestID`} style={webStyle.subViewFilter} onClick={() => this.handleSKUBoxChange(index)} key={item.id} id='leftFilterBox'>
                        <img src={this.imageChecked(item.checked)} style={webStyle.imageCheck} data-test-id={`testIdSelectSKuFilter${index}`} id='leftFilterBox' />
                        <Typography style={webStyle.filterStyleText} data-test-id={`testIdSelectSKuFilterText${index}`} id='leftFilterBox'>{item.text}</Typography>
                    </SubcategoryBox>
                ));
            case 'Value':
                return this.rangeSlider(this.state.values, this.handleChangeSlider, 10000, "value");
            case 'Qty':
                return this.rangeSlider(this.state.values2, this.handleChangeSlider2, 1000, "quantity");
            case 'Commission':
                return this.rangeSlider(this.state.values3, this.handleChangeSlider3, 1000, "comission");
            case 'Timeline':
                return this.renderTimelineFilter();
            default:
                return null;
        }
    }

    renderTimelineFilter() {
        return (
            <DataModal style={webStyle.datemodalStyle} id='leftFilterBox'>
                <Box sx={webStyle.inputBox} id='leftFilterBox'>
                    <Box sx={webStyle.labelBox} id='leftFilterBox'>
                        <label style={webStyle.labelStyle as React.CSSProperties} id='leftFilterBox'>Start Date</label>
                        <TimelineInputStyle>
                            <input
                                data-test-id="startDateTestID"
                                type="date"
                                value={this.state.startdate}
                                onChange={this.onChangeStart}
                                style={{
                                    ...webStyle.inputStyle,
                                    padding: "0",
                                }}
                                id='leftFilterBox' />
                        </TimelineInputStyle>
                    </Box>
                    <Box sx={webStyle.labelBox} id='leftFilterBox'>
                        <label style={webStyle.labelStyle as React.CSSProperties} id='leftFilterBox'>End Date</label>
                        <TimelineInputStyle>
                            <input
                                data-test-id="endDateTestID"
                                type="date"
                                value={this.state.enddate}
                                onChange={this.onChangeEnd}
                                style={{
                                    ...webStyle.inputStyle,
                                    padding: "0",
                                }}
                                id='leftFilterBox' />
                        </TimelineInputStyle>
                    </Box>
                </Box>
            </DataModal>
        );
    }

    renderTableBody() {
        const { salesReportSorted, salesReportShow } = this.state;
    
        if (salesReportSorted.length === 0) {
          return (
            <TableRow>
              <TableCell colSpan={12} style={webStyle.noResultFoundTextStyle}>
                {configJSON.noResultFoundText}
              </TableCell>
            </TableRow>
          );
        }
    
        return salesReportShow.map((row: SalesReportItem, index: number) => (
          <TableRow
            data-test-id={`tableRow${index}TestID`}
            hover
            role="checkbox"
            tabIndex={-1}
            key={index}
          >
            {column.map((columnLine) => {
              const originalValue = row[columnLine.id];
              const isCommissionColumn = columnLine.id === "commission";
    
              return (
                <TableCell
                  key={columnLine.id}
                  align={columnLine.align}
                  style={webStyle.mainBodyCell}
                >
                  {isCommissionColumn ? (
                    <div style={{ color: "green" }}>
                      <span style={{ fontWeight: "bold" }}>R</span>
                      <span>{originalValue.slice(1)}</span>
                    </div>
                  ) : (
                    this.renderInnerData(columnLine.id, originalValue)
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ));
      };
    
      renderInnerData = (valueName: string, value: string) => {
        return valueName !== "value" ? value : this.renderApprovedColumn(value);
      };
    
      renderApprovedColumn = (value: string) => {
        return value === "APPROVED" ? (
          <div
            style={webStyle.valueApprovedCell}
          >
            {value}
          </div>
        ) : (
          <div>
            <span style={{ fontWeight: "bold" }}>R</span>
            <span>{value.slice(1)}</span>
          </div>
        );
      };

    renderPrintableTable = () => {
        return (
          <Box style={{ display: "none" }}>
            <TableContainer ref={this.printableDivRef}  style={webStyle.printTableContainerStyle}>
                <Box style={webStyle.printTableMainHeading}>
                    {configJSON.salesReportingHeading}
                </Box>
                <Table style={webStyle.printMainTable}>
                    <TableHead>
                    <StyledHeaderRow>
                        {column.map((column) => (
                        <StyleHeaderCell
                            key={column.id}
                            align={column.align}
                            style={webStyle.columnCell}
                        >
                            <span>{column.label}</span>
                        </StyleHeaderCell>
                        ))}
                    </StyledHeaderRow>
                    </TableHead>
                    <TableBody>
                    {this.state.salesReportSorted.map((Item: SalesReportItem, index: number) => (
                        index % 2 !== 0 ? 
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {column.map((column) => (
                                <StyleBodyCell
                                key={column.id}
                                align={column.align}
                                >
                                {Item[column.id]}
                                </StyleBodyCell>
                            ))}
                        </TableRow>:
                        <EvenStyledBodyRow role="checkbox" tabIndex={-1} key={index}>
                            {column.map((column) => (
                                <StyleBodyCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {Item[column.id]}
                                </StyleBodyCell>
                            ))}
                        </EvenStyledBodyRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </Box>
        );
      };

    render() {

        return (
            // Customizable Area Start
            <Box style={webStyle.userContainerSetting} data-test-id='user-setting-container'>
                <SideNavbar />
                <RightSideBox style={webStyle.containerSecond}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <CustomBackBox >
                        <img src={leftArrow} onClick={this.goBackClick} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                        <Typography onClick={this.goBackClick} style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, paddingLeft: 5, cursor: 'pointer' }}>Back</Typography>
                    </CustomBackBox>
                    <CustomUserBoxNew style={webStyle.userSettingMainFields}>
                        <CustomWalletTypography1 style={webStyle.userSettingText as React.CSSProperties}>
                            VAS Total Sales & Commission
                        </CustomWalletTypography1>
                        <MainButtonWrapper>
                            <Typography style={webStyle.filterTextby as React.CSSProperties}>
                                Filter by:
                            </Typography>
                            <CustomDataBox>

                                <Button data-test-id="valueButtonTestID" style={webStyle.timelinebtn} onClick={this.SortByModalCategory} id="leftFilterBox">
                                    <Typography style={webStyle.timelineTxt} id="leftFilterBox">Value</Typography>
                                    <img src={downIcon} style={{ marginLeft: '10px' }} id="leftFilterBox" />
                                </Button>
                                <Button style={webStyle.timelinebtn2} data-test-id={"randomBtn"} onClick={() => this.handleSortDropdown()} className="sortBySalesR" id="leftFilterBox">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="leftFilterBox">
                                            <Typography style={{ ...webStyle.timelineTxt, whiteSpace: 'nowrap' }} id="leftFilterBox">
                                                Sort <span id="leftFilterBox" style={{ textTransform: 'lowercase' }}>by</span>
                                            </Typography>
                                            <img src={downIcon} style={{ marginLeft: '10px' }} id="leftFilterBox" />
                                        </div>
                                </Button>

                                {this.state.isOpenSortBySalesR &&
                                    <DateModelBox style={webStyle.dateStyleModal} id="leftFilterBox" className="sortBySalesR">
                                            <DateModelSecondBox id="leftFilterBox" style={webStyle.boxInput2} >
                                                {this.handleSortFilterOption(this.state.isSelectAscending, 'Sort by Ascending to Descending', 'desc')}
                                                {this.handleSortFilterOption(!this.state.isSelectAscending, 'Sort by Descending to Ascending', 'asc')}
                                            </DateModelSecondBox>
                                    </DateModelBox>
                                }

                                {this.state.providerShow &&
                                    <ShowCategoryBox 
                                            data-test-id="valueButtonOptionsTestID" 
                                            id="leftFilterBox"
                                        >
                                            <div>
                                                <div style={webStyle.catogoryMainFilterDiv} id='leftFilterBox'>
                                                    {this.state.filterMain.map((item: any, index: any) => (
                                                        <div key={item.id}>
                                                            <div data-test-id={`valueMainOptions${item.text}TestID`} onClick={() => this.handleMainChangeFilter(index)} style={webStyle.divFilter} >
                                                                <img src={this.imageChecked(item.checked)} style={webStyle.imageCheck} data-test-id='testIdMainFilter' id='leftFilterBox' />
                                                                <Typography style={webStyle.textFilterStyle} data-test-id='testIdMainFilter2' id='leftFilterBox'>{item.text}</Typography>
                                                            </div>
                                                            {item.isShow && this.renderSubFilterOptions(item.text)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                    </ShowCategoryBox>}
                            </CustomDataBox>
                        </MainButtonWrapper>
                    </CustomUserBoxNew>
                    <Box style={{ padding: "0 40px" }}>

                        <Paper style={{ overflowX: "auto" }}>
                            <TableContainer>
                                <Table  aria-label="using sticky table">
                                    <TableHead>

                                        <TableRow>
                                            {column.map((columnLine) => (
                                                <TableCell
                                                    align={columnLine.align}
                                                    key={columnLine.id}
                                                    style={webStyle.tableCellStyle}
                                                >
                                                    {columnLine.sortable ? (
                                                        <TableSortLabel
                                                            data-test-id={`${columnLine.id}HeaderTestID`}
                                                            active={this.state.orderBy === columnLine.id}
                                                            direction={this.state.orderBy === columnLine.id ? this.state.order : 'asc'}
                                                            onClick={this.handleRequestSort.bind(this, columnLine.id)}
                                                        >
                                                            {columnLine.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <span>{columnLine.label}</span>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {this.renderTableBody()}



                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.state.salesReportSorted.length!==0?
                                <div style={webStyle.paginationWrapper}>
                                    <div />
                                    <Pagination 
                                        data-test-id="salesReportingPaginationTestID"
                                        style={{ fontFamily: "InterReguular", fontWeight: 400, color: "#0F172A", fontSize: "12px" }} 
                                        count={Math.ceil(this.state.salesReportSorted.length / 10)} 
                                        page={this.state.page} 
                                        onChange={this.handleChangePage} />
                                    <Typography 
                                        style={webStyle.resultCountText}
                                    >
                                    {this.state.page*10-9} -{" "}
                                    {(this.state.page*10-9)+(this.state.salesReportShow.length-1)}&nbsp;
                                    of {this.state.salesReportSorted.length} results
                                    </Typography>
                                </div>:null
                            }
                        </Paper>
                    </Box>


                    <CustomBoxNew>
                        <CustomRedButton data-test-id="salesExportAllButtonTestID" style={webStyle.exportbtn} onClick={this.handleButtonClickAct}>
                            <Typography style={webStyle.exportTxt}>Export All</Typography>
                        </CustomRedButton>
                        <style>
                            {`
                                @media print {
                                    html, body {
                                        margin: 0; 
                                        overflow: initial !important;
                                        padding: 0;
                                        height: initial !important;
                                    }
                                    @page {
                                        margin: 0.5cm;
                                    }
                                }
                            `}
                        </style>
                        {this.state.salesReportSorted.length!==0?
                        <ReactToPrint
                            documentTitle="product_sales_by_category"
                            trigger={() => (
                                <CustomRedButton2 style={webStyle.exportbtn}>
                                    <img src={printIcon} />
                                    <Typography style={webStyle.exportTxt}>Print</Typography>
                                </CustomRedButton2>
                            )}
                            content={() => this.printableDivRef.current}
                        /> :
                        <CustomRedButton2 style={webStyle.exportbtn}>
                            <img src={printIcon} />
                            <Typography style={webStyle.exportTxt}>Print</Typography>
                        </CustomRedButton2>}
                    </CustomBoxNew>

                    <Modal
                        data-test-id="salesFileTypeModalTestID"
                        open={this.state.openModal}
                        onClose={this.handleModalClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        style={{
                            overflowY: 'auto',
                            width: '90%',
                            height: '90%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 'auto'
                        }}
                    >
                        <Box style={webStyle.modalBoxStyle as React.CSSProperties}>
                            <Typography style={{
                                fontSize: '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                maxWidth: '373px',
                                margin: 'auto',
                            }}>
                                Select the file type you would like to download :
                            </Typography>
                            <ExportContainer1 style={{ display: 'flex', gap: 55, marginTop: 45, justifyContent: 'center' }}>
                                <ExportBox1
                                    data-test-id="pdfButtonTestID" 
                                    onClick={this.handleNewUserChangeAct} style={{
                                    maxWidth: '143px',
                                    height: '117px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    padding: '20px 30px',
                                    border: '1px solid #E6E6E6',
                                    display: 'flex',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white',
                                    cursor: "pointer",
                                    flexDirection: 'column',
                                }}>
                                    <Typography style={{ fontSize: '20px', fontWeigth: '400', fontFamily: 'Poppins', color: '#0F172A', margin: 'auto' } as React.CSSProperties}>PDF</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <CustomFormRadioLabel value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>
                                </ExportBox1>
                                <ExportBox1 
                                    data-test-id="xlsxButtonTestID"
                                    onClick={this.handleExistingUserChangeAct} style={{
                                    maxWidth: '143px',
                                    height: '117px',
                                    padding: '20px 30px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    border: '1px solid #E6E6E6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                    backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white',
                                    cursor: "pointer"
                                }}>

                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeigth: '400', margin: 'auto', color: '#0F172A' } as React.CSSProperties}>XLSX</Typography>

                                    <div style={webStyle.divStyleNew}>
                                        <RadioGroup
                                            aria-label="gender"
                                            defaultValue="female"
                                            name="customized-radios"
                                            style={webStyle.radioGroupContainer}
                                        >
                                            <CustomFormRadioLabel value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>
                                </ExportBox1>
                            </ExportContainer1>
                            <Button data-test-id="confirmButtonTestID" onClick={()=>this.handleSalesExportConfirm()} style={webStyle.submitBtn2}>
                                Confirm
                            </Button>
                        </Box>
                    </Modal>

                </RightSideBox>
                <Snackbar 
                    data-test-id="snackbarTestID"
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    onClose={this.handleSnackbarClose}
                    open={this.state.isSnackbarOpen} 
                    autoHideDuration={2000} 
                >
                    <Box  style={webStyle.snackbarBox}>
                        <CancelIcon  style={{ color: 'red' }}/>
                        <Typography style={{fontFamily:"Poppins"}}>{this.state.dateError}</Typography>
                    </Box>
                </Snackbar>
                {this.renderPrintableTable()}
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBodyCell:{
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
    } as React.CSSProperties,
    valueApprovedCell:{
        color: "#059669",
        backgroundColor: "#D1FAE5",
        borderRadius: "10px",
        height: "20px",
        width: "100px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as React.CSSProperties,
    resultCountText:{ 
        fontFamily: "Poppins", 
        fontWeight: 400, 
        color: "#64748B", 
        marginRight: "10px", 
        fontSize: "12px" 
    },
    paginationWrapper:{
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '20px 10px' 
    },
    modalBoxStyle: {
        maxWidth: '550px',
        width: '100%',
        padding: 30,
        backgroundColor: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius:"7px"
    },
    columnCell: {
        fontSize: '14px',
        fontWeight: 700, width: '100px',
        color: '#475569',
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    printTableContainerStyle:{
        margin: "20px auto 0 auto", 
        width: "750px"
    },
    printTableMainHeading:{
        fontSize:"25px", 
        fontFamily:"sans-serif"
    },
    printMainTable:{
        marginTop: "30px"
    },
    noResultFoundTextStyle:{
        fontSize:"20px",
        color:"gray",
        padding:"80px 0",
        textAlign:"center",
        fontFamily:"Poppins",
    } as React.CSSProperties,
    snackbarBox:{
        background:"white", 
        display:"flex", 
        borderRadius:"8px", 
        gap:"16px", 
        padding:"14px"
    },
    userContainerSetting: {
        backgroundColor: "#E2E8F0"
    },
    containerSecond:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    userSettingMainFields: {
    },

    userSettingText: {

    },
    filterTextby: {
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '22px',
    },
    timelineTxt: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize',

    } as React.CSSProperties,
    exportTxt: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize',
    } as React.CSSProperties,
    timelinebtn: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',
        width:"130px"
    } as React.CSSProperties,
    exportbtn: {


    } as React.CSSProperties,
    timelinebtn2: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',
        width:"130px"
    } as React.CSSProperties,
    boxInput: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        // Additional responsive adjustments
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    submitBtn2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        maxWidth: '550px',
        width: "100%",
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400
    } as React.CSSProperties,
    datemodalStyle: {

    },
    boxLabel: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    styleLabel: {
        fontFamily: 'Poppins',
        color: "0F172A",
        fontSize: "12px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    inputStyle: {
        color: '#64748B',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    tableCellStyle: {
        fontSize: '14px', fontWeight: 700, width: '100px', color: '#475569', fontFamily: 'Poppins'
    },
    divStyleNew: {
        display: 'flex', alignItems: 'center'
    },
    radioGroupContainer: {
        display: "flex",
        alignItems: "center"
    },
    dateModel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        flexWrap:"wrap",
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    labelStyle: {
        fontFamily: 'Poppins',
        color: "0F172A",
        fontSize: "12px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: 400,
    },
    categoryFilterBox: {
        display: 'flex',
        //  alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '60px'
    },
    imageCheck: {
        width: '24px',
        height: '24px',
    },
    textFilterStyle: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',

    },
    divFilter: {
        cursor: 'pointer',
        display: 'flex',
        margin: '10px',
        gap: '10px',
        paddingBottom: "9px",
    },
    filterStyleText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',

    },
    subViewFilter: {
        cursor: 'pointer',
        display: 'flex',
        margin: '20px',
        gap: '10px',
        marginLeft: '50px'
    },
    textZero: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white',
    },
    rangeFilterDivMain: {
        width: '210px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    labelStyleBoxSa: {
        justifyContent: 'center',
        height: '48px',
        display: 'flex',
        paddingTop: '5px',
        width: "100%",
        alignItems: 'center',
    } as React.CSSProperties,
    dateStyleModal: {
        background: 'white',
        position: 'absolute',
        zIndex: 999999,
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    } as React.CSSProperties,
    boxInput2: {
        flexDirection: 'column',
        gap: '16px',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
        width: '310px',
    } as React.CSSProperties,
    catogoryMainFilterDiv: {
        width: '90%',
        backgroundColor: 'white',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
}
// Customizable Area End
