import React from "react";

import {
    Box, Button, Card, CardContent, Fade, FormControl, FormControlLabel, MenuItem, Modal, RadioGroup, Select, Typography, styled,
} from "@material-ui/core";
import MultipageFormsController, {
    Props
} from "./MultipageFormsController";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { BackIcn, AttachMent, CloseIcn, FileIcn, RightIcn } from './assets';
import LoanCustomRadio from "./LoanCustomRadio.web";
import { Toaster } from "react-hot-toast"


const LayoutMain = styled(Box)({
    height: "100%",
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const LoanText = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#334155',
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: '20px',
    alignSelf: 'center',
    padding: "20px 45px",
})
const TimeTypograph = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})

const radioLabel = (value: any) => ({
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#273567'
});

const ModalBoxForgot = styled(Box)({
    height: '360px',
    width: '100%',
    maxWidth: '542px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

const CloseButton2 = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
    '@media (max-width: 600px)': {
        top: '70px',
    },
    '@media (max-width: 400px)': {
        top: '70px',
    },
});

const CloseButton3 = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
});

const CloseButton = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
    '@media (max-width: 600px)': {
        top: '70px',
    },
    '@media (max-width: 385px)': {
        top: '93px',
    },
});

const ModalText = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize:"20px !important"
    }
})

const RightImg = styled("img")({
    '@media (max-width: 600px)': {
        height:"90px !important",
        width:"90px !important"
    }
})

export default class LoanApplicationForm extends MultipageFormsController {
    constructor(props: Props) {
        super(props);
    }
    renderCommunitySelect = () => {
        return (
            <FormControl variant="outlined" required style={{
                width: '100%', maxWidth: "550px", borderRadius: "18px", fontFamily: "Poppins", fontWeight: 400, border: `0.5px #273567`, marginBottom: "25px"
            }}>

                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={"Selectcommunityofproperty"}

                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}

                    style={{
                        width: '100%',
                        height: '55px',
                        color: "#273567",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        borderRadius: '8px',
                        fontSize: "14px",
                    }}

                    value={this.state.communityofproperty}
                    onChange={this.handleSelectCommunityProperty}
                >
                    <MenuItem value={"Selectcommunityofproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select community of property</MenuItem>
                    <MenuItem value={"Marriedincommunityofproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }} >Married in community of property</MenuItem>
                    <MenuItem value={"Marriedoutofcommunityproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Married out of community of property</MenuItem>
                </Select>

            </FormControl>
        )
    }

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <LayoutMain style={{ width: "100%" }}>
                    <Box style={webStyle.LoanHeader}>

                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />

                    </Box>
                    <Box style={{ padding: "0 40px", backgroundColor: "rgb(226, 232, 240)" }}>

                        <Box data-test-id="backapplyLoan" onClick={this.navigateToApplyForLoan} style={{ cursor: "pointer", marginBottom: "30px", maxWidth: "80px" }}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500, }} >
                                <img src={BackIcn} alt="back-icn" style={{ marginRight: "10px" }} />
                                Back
                            </Typography>
                        </Box>

                        <LoanText>
                            Loan Application Form
                        </LoanText>

                        <Card style={{ padding: "30px", margin: "30px 0" }}>
                            <CardContent style={{ maxWidth: "100%", display: "flex", gap: 32, flexDirection: "column" }}>
                                <FormControl variant="outlined" required style={{
                                    width: '100%', maxWidth: "550px", borderRadius: "18px", border: `0.5px #273567`, fontFamily: "Poppins", fontWeight: 400,
                                }}>

                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={"SelectTypeOfLoan"}

                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}

                                        style={{
                                            width: '100%',
                                            height: '55px',
                                            color: "#273567",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            borderRadius: '8px',
                                            fontSize: "14px",
                                        }}

                                        value={this.state.setLoanType}
                                        onChange={this.handleSelectTypeOfLoan}
                                    >
                                        <MenuItem value={"SelectTypeOfLoan"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select type of loan</MenuItem>
                                        <MenuItem value={"CashAdvance"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }} >Cash Advance</MenuItem>
                                        <MenuItem value={"StockonCredit"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Stock on Credit</MenuItem>
                                        <MenuItem value={'Loantoexpandthebusiness'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", }}>Loan to expand the business</MenuItem>
                                        <MenuItem value={'Loantopurchaseequipment'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Loan to purchase equipment</MenuItem>
                                        <MenuItem value={'Loantoassistoperations'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Loan to assist operations</MenuItem>
                                        <MenuItem value={'Other'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Other</MenuItem>
                                    </Select>

                                </FormControl>
                                <FormControl variant="outlined" required style={{
                                    fontFamily: "Poppins", fontWeight: 400, border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px"
                                }}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={"Selectthereasonforwantingfinance"}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%',
                                            height: '55px',
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            color: "#273567",
                                            fontWeight: 400,
                                            borderRadius: '8px',
                                        }}
                                        value={this.state.setFinanceReason}
                                        onChange={this.handleSelectReason}
                                    >
                                        <MenuItem value={"Selectthereasonforwantingfinance"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select the reason for wanting finance</MenuItem>
                                        <MenuItem value={"SelectthereasonforwantingfinanceI"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To invest in expanding business operations</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinance1'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", }}>To cover unexpected medical expenses</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinance2'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To purchase a new home or property</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinancerty3'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To consolidate and pay off existing debts</MenuItem>
                                    </Select>

                                </FormControl>
                                <Box sx={webStyle.maritalradioMain}>
                                    <Box>
                                        <Typography style={webStyle.maritalText as React.CSSProperties}>
                                            Are you married community of property?
                                        </Typography>
                                    </Box>
                                    <RadioGroup aria-label="gender" name="customized-radios"
                                        style={{ display: 'flex', flexDirection: 'row', gap: 50, fontFamily: "Poppins", fontWeight: 400, marginTop: "5px" }}
                                        value={this.state.maritalStatus.isCheckedYes ? 'yes' : (this.state.maritalStatus.isCheckedNo ? 'no' : "")}
                                        onChange={this.handleMaritalStatus}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<LoanCustomRadio />}
                                            label={<span style={radioLabel('yes')}>Yes</span>}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<LoanCustomRadio />}
                                            label={<span style={radioLabel('no')}>No</span>}
                                        />
                                    </RadioGroup>
                                    {this.state.validateMaritalStatus == true && <Typography style={{ color: "#DC2626", fontSize: "12px", fontFamily: "Poppins", fontWeight: 400, marginTop: "10px" }}>*You must select an input before proceeding.</Typography>}
                                </Box>
                                {this.state.maritalStatus.isCheckedYes && (
                                    <div>
                                        {this.renderCommunitySelect()}

                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                marginTop: '5px',
                                                maxWidth: "550px",
                                                position: 'relative',
                                            }}
                                        >
                                            {/* Customized File input */}
                                            <label htmlFor="vacc" style={{ display: 'block' }}>
                                                <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}>Upload spouse's ID</TimeTypograph>
                                                <div
                                                    style={{
                                                        ...webStyle.uploadInputDoc,
                                                        borderColor: '#273567',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    {this.state.uploadedDocNamespouse ? (

                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: "5px" }}>
                                                            <img src={FileIcn} alt="back-icn" />
                                                            <span style={{
                                                                marginLeft: '15px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                fontSize: "14px", color: "#273567"
                                                            }}>
                                                                {this.state.uploadedDocNamespouse.length > (window.innerWidth <= 600 ? 10 : 30)
                                                                    ? `${this.state.uploadedDocNamespouse.slice(0, window.innerWidth <= 600 ? 10 : 30)}...`
                                                                    : this.state.uploadedDocNamespouse}
                                                            </span>
                                                        </div>


                                                    ) : (
                                                        <div>

                                                            <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", width: "100%" }}>
                                                            <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        name="compliance"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            opacity: 0,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onChange={(e) => this.handleDocUpload("spouse", e)}
                                                                    />
                                                                <div style={webStyle.uploadText}>
                                                                    <span style={{
                                                                        marginLeft: '5px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 400,
                                                                        fontSize: '14px',
                                                                        marginTop: "5px", color: "#273567"
                                                                    }}>
                                                                        Upload Document
                                                                    </span>
                                                                </div>
                                                                <div style={{ position: 'relative' }}>
                                                                    
                                                                    <label htmlFor="fileInput">
                                                                        <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </label>
                                            <div style={webStyle.validationMessage}>
                                                {this.state.errorMsgspouse}
                                            </div>
                                            {/* Close button positioned absolutely */}
                                            {this.state.uploadedDocNamespouse && (
                                                <CloseButton3 onClick={this.removeUploadedDoc.bind(this, "spouse")}>
                                                    <img src={CloseIcn} alt="attachment" />
                                                </CloseButton3>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: '5px',
                                        maxWidth: "550px",
                                        position: 'relative', // Ensure relative positioning for absolute positioning of the close button
                                    }}
                                >
                                    {/* Customized File input */}
                                    <label htmlFor="vacc">
                                        <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}> Upload personal bank statement (3 months)</TimeTypograph>
                                        <div
                                            style={{
                                                ...webStyle.uploadInputDoc,
                                                borderColor: '#273567',
                                                position: 'relative',
                                            }}
                                        >

                                            {this.state.uploadedDocNamestatement ? (
                                                <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", width: "100%" }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "5px" }}>
                                                        <img src={FileIcn} alt="back-icn" />
                                                        <span style={{
                                                            marginLeft: '15px', fontFamily: 'Poppins',
                                                            fontWeight: 400,
                                                            fontSize: '14px', color: "#273567"
                                                        }}>
                                                            {window.matchMedia("(max-width: 600px)").matches
                                                                ? (this.state.uploadedDocNamestatement.length > 3
                                                                    ? this.state.uploadedDocNamestatement.slice(0, 10) + '...'
                                                                    : this.state.uploadedDocNamestatement)
                                                                : (this.state.uploadedDocNamestatement.length > 20
                                                                    ? this.state.uploadedDocNamestatement.slice(0, 30) + '...'
                                                                    : this.state.uploadedDocNamestatement)
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", width: "100%",position: 'relative'  }}>
                                                    <input
                                                            type="file"
                                                            id="fileInput"
                                                            name="compliance"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                height: '100%',
                                                                width: '100%',
                                                                opacity: 0,
                                                                cursor: 'pointer'
                                                            }}
                                                            onChange={(e) => this.handleDocUpload("statement", e)}
                                                        />
                                                    <div style={webStyle.uploadText}>
                                                        <span style={{
                                                            marginLeft: '5px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 400,
                                                            fontSize: '14px',
                                                            marginTop: "5px",
                                                            color: "#273567"
                                                        }}>
                                                            Upload Document
                                                        </span>
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                        
                                                        <label htmlFor="fileInput">
                                                            <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </label>
                                    <div style={webStyle.validationMessage}>
                                     { this.state.errorMsgstatement}
                                    </div>
                                    {/* Close button positioned absolutely */}
                                    {this.state.uploadedDocNamestatement && (
                                        <CloseButton2 onClick={this.removeUploadedDoc.bind(this, 'statement')}>
                                            <img src={CloseIcn} alt="attachment" />
                                        </CloseButton2>
                                    )}
                                </div>
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: '5px',
                                        maxWidth: "550px",
                                        position: 'relative',
                                    }}
                                >
                                    {/* Customized File input */}
                                    <label htmlFor="vacc">
                                        <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}>Upload Proof of Residence (not other than 3 months)</TimeTypograph>
                                        <div
                                            style={{
                                                ...webStyle.uploadInputDoc,
                                                borderColor: '#273567',
                                                position: 'relative',
                                            }}
                                        >
                                            {this.state.uploadedDocNameproof ? (
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: "5px" }}>
                                                    <img src={FileIcn} alt="back-icn" />
                                                    <span style={{
                                                        marginLeft: '15px', fontFamily: 'Poppins',
                                                        fontWeight: 400,
                                                        fontSize: '14px', color: "#273567",
                                                    }}>
                                                        {window.matchMedia("(max-width: 600px)").matches
                                                            ? (this.state.uploadedDocNameproof.length > 3
                                                                ? this.state.uploadedDocNameproof.slice(0, 10) + '...'
                                                                : this.state.uploadedDocNameproof)
                                                            : (this.state.uploadedDocNameproof.length > 20
                                                                ? this.state.uploadedDocNameproof.slice(0, 30) + '...'
                                                                : this.state.uploadedDocNameproof)
                                                        }

                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", width: "100%",position: 'relative'  }}>
                                                     <input
                                                            type="file"
                                                            id="fileInput"
                                                            name="compliance"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                height: '100%',
                                                                width: '100%',
                                                                opacity: 0,
                                                                cursor: 'pointer'
                                                            }}
                                                            onChange={(e) => this.handleDocUpload("proof", e)}
                                                        />
                                                    <div style={webStyle.uploadText}>
                                                        <span style={{
                                                            marginLeft: '5px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 400,
                                                            fontSize: '14px',
                                                            marginTop: "5px",
                                                            color: "#273567"
                                                        }}>
                                                            Upload Document
                                                        </span>
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                       
                                                            <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </label>
                                    <div style={webStyle.validationMessage}>
                                     { this.state.errorMsgproof}
                                    </div>
                                    {/* Close button positioned absolutely */}
                                    {this.state.uploadedDocNameproof && (
                                        <CloseButton onClick={this.removeUploadedDoc.bind(this, 'proof')}>
                                            <img src={CloseIcn} alt="attachment" />
                                        </CloseButton>
                                    )}
                                </div>

                                <Button style={webStyle.submitLoanButton} onClick={this.loanNextStep}>
                                    <Typography style={webStyle.submitLoanText as React.CSSProperties}>Submit</Typography>
                                </Button>
                            </CardContent>
                        </Card>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            closeAfterTransition
                            open={this.state.loanSuccessModa}
                            onClose={this.handleCloseLoanModal}
                            aria-describedby="transition-modal-description"
                            BackdropProps={{
                                style: {
                                    backdropFilter: "blur(4px)",
                                },
                            }}
                        >
                            <Fade in={this.state.loanSuccessModa}>
                                <ModalBoxForgot sx={webStyle.greentickModal}>
                                    <RightImg src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '119px', width: '100%', height: '119px' }} />
                                    <ModalText variant="h5" style={webStyle.userVerifiedText2 as React.CSSProperties}>Your application has been submitted successfully.</ModalText>
                                </ModalBoxForgot>
                            </Fade>
                        </Modal>
                    </Box>
                    <Toaster position="top-center" />
                </LayoutMain>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    LoanHeader: { width: '100%', backgroundColor: "rgb(226, 232, 240)", } as React.CSSProperties,
    maritalText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
    },
    maritalradioMain: {
        fontFamily: 'Poppins',
        marginLeft: "15px"
    },
    uploadInput: {
        color: '#273567',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        height: '50px',
        padding: "15px",
        fontColor: "#273567",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadInputDoc: {
        width: '100%',
        height: '50px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxSizing: 'border-box',
        padding: '8px 20px 8px 10px', // Adjust padding for text and image
        // backgroundImage: `url(${AttachMent})`, // Set the image as the background
        backgroundSize: '24px', // Adjust the size of the image
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center', // Adjust the position of the image
        borderColor: '#273567',
    } as React.CSSProperties,
    uploadText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    submitLoanButton: {
        maxWidth: '260px',
        width: '100%',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'C12030',
        marginTop: "70px"
    },
    submitLoanText: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: '#fff'
    },
    greentickModal: {
        position:"absolute",
        top:"50%",
        left:"50%",
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    validationMessage: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    } ,
    userVerifiedText2: {
        fontSize: "29px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    }
}
// Customizable Area End