Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "Sign in your account";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Welcome back to A2pay";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.valueA1 = "1234567890123"; 
exports.valueA2 = "Test@123456";
exports.valueB1 = "4829472849284";
exports.valueB2= "Test@123456";
exports.AUTH_URL= "https://dev-6jb7x2jb5dn2rptl.us.auth0.com/authorize?audience=https%3A%2F%2Fportal1-api.dev-portals.a2pay.biz";
exports.CLIENT_ID= "Pnv97wKAclN1v1E6cZ8Xvz5iUNidWWWd";
exports.REDIRECT_URI= "https://a2paygitesdubai2023-422400-react.b422400.stage.eastus.az.svc.builder.ai";
exports.ACCESS_TOKEN_URL="https://dev-6jb7x2jb5dn2rptl.us.auth0.com/oauth/token"
exports.RESPONSE_TYPE= "code";
// Customizable Area End