import React from "react";
import {
    Box,
    Typography,
    Link,
    Modal,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { check_icon } from "./assets";
import { upload_icn } from "./assets";

const TimeTypograph = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})



const StyledFileInput = styled("input")({
    border: "none",
    outline: "none",
    '::placeholder': {
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        marginTop: '10px'
    }

})


export default class UserProfileDocumentWeb extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount = async () => {
        this.handleGetUserDocument();
    }


    renderDocumentUploadField = (label: string, fileName: string, errorField: string, handleFileChange: any, handleFileBlur: any, testId: string) => (
        <Box style={{ ...webStyle.fieldsMainDoc } as React.CSSProperties}>
            <Box style={webStyle.userSettingDocTextMain2 as React.CSSProperties}>
                <label style={webStyle.labelStyleDoc as React.CSSProperties}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ width: 'max-content' }}>{label}</span>
                        {fileName && (
                            <img
                                src={check_icon}
                                alt="checkbox"
                                style={{ width: '17px', height: '17px', marginLeft: 10 }}
                            />
                        )}
                    </div>
                </label>
                <Box style={{ marginTop: 40 }}>
                    {fileName && (
                        <span style={{ ...webStyle.rejectedTextDoc, color: '#059669', marginLeft: '0', marginRight: '0px' }}>
                            In-Progress
                        </span>
                    )}
                </Box>
            </Box>

            <div>
                <div style={{ cursor: 'pointer', marginTop: '5px' }}>
                    <label htmlFor="vacc" style={{ display: 'block' }}>
                        <div
                            style={{
                                ...webStyle.inputStyleDoc,
                                border: this.state.errorMessages[errorField] ? "1px solid red" : "1px solid #273567",
                                position: 'relative',
                            }}
                        >
                            {fileName ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '95%',
                                    }}
                                >
                                    <span style={webStyle.documentName}>
                                        {fileName}
                                    </span>
                                </div>
                            ) : (
                                <div style={webStyle.style16Doc}>
                                    <span style={webStyle.documentName}>
                                        Upload Document
                                    </span>
                                </div>
                            )}

                            <StyledFileInput
                                type="file"
                                id="vacc"
                                style={{
                                    width: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    cursor: 'pointer',
                                    opacity: 0,
                                }}
                                data-test-id={testId}
                                onChange={handleFileChange}
                                onBlur={handleFileBlur}
                            />
                        </div>
                    </label>
                </div>
                <div style={webStyle.validationMessageDoc}>
                    {this.state.errorMessages[errorField]}
                </div>
            </div>
        </Box>
    )
    handleFileChangeField1 = (e:any) => {
        this.handleMultipleFileChangeVac(1, e);
      }
      
      handleFileChangeField2 = (e:any) => {
        this.handleMultipleFileChangeVac(2, e);
      }
      
      handleFileChangeField3 = (e:any) => {
        this.handleMultipleFileChangeVac(3, e);
      }
      
      handleFileChangeField4 = (e:any) => {
        this.handleMultipleFileChangeVac(4, e);
      }
      

    render() {
        return (
            <Box style={webStyle.userSettingDocContainer as React.CSSProperties}>
                <Box style={webStyle.userSettingDocFeildsMian as React.CSSProperties}>
                    <Box style={webStyle.userSettingDocTextMain as React.CSSProperties}>
                        <Typography style={webStyle.userSettingDocText as React.CSSProperties}>Documents</Typography>
                        <Typography style={webStyle.userSettingDocText as React.CSSProperties}>Status</Typography>
                    </Box>

                    {localStorage.getItem("Role") == "Merchant" ? (
                        <Box>
                            {this.renderDocumentUploadField("Compliance Report", this.state.uploadedFileName1, "field1", this.handleFileChangeField1, this.handleFileBlur, "testinguploadfunction")}
                            {this.renderDocumentUploadField("Municipal Permit", this.state.uploadedFileName2, "field2", this.handleFileChangeField2, this.handleFileBlur, "testinguploadfunction2")}
                            {this.renderDocumentUploadField("Registered Business", this.state.uploadedFileName3, "field3", this.handleFileChangeField3, this.handleFileBlur, "testinguploadfunction3")}
                            {this.renderDocumentUploadField("VAT Registration", this.state.uploadedFileName4, "field4", this.handleFileChangeField4, this.handleFileBlur, "testinguploadfunction4")}
                        </Box>
                    ) : (
                        this.renderDocumentUploadField("ID/Passport", this.state.uploadedFileName1, "field1", this.handleFileChangeField1, this.handleFileBlur, "testoperator")
                    )}
                </Box>

                <Modal
                    open={this.state.showUploadModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "10px",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        border: '2px solid #000',
                        textAlign: 'center',
                        borderRadius: "12px",
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: 500
                    }}>
                        Uploading.....
                    </Box>
                </Modal>
            </Box>
        );
    }
}


// Customizable Area Start
const webStyle = {
    userSettingDocContainer: {

    },
    userSettingDocFeildsMian: {
        backgroundColor: "#FFFFFF",
        maxWidth: "940px",
        borderRadius: "4px",
        margin: "40px",
        padding: "20px",
    },
    userSettingDocTextMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: "20px"
    },
    userSettingDocTextMain2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    userSettingDocText: {
        fontFamily: 'Poppins',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '40px',
        fontSize: '20px'
    },
    fieldsMainDoc: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    labelStyleDoc: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
        marginTop: 40
    },
    inputStyleDoc: {
        width: '100%',
        maxWidth: "550px",
        height: '40px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '8px 40px 8px 8px', 
        backgroundImage: `url(${upload_icn})`, 
        backgroundSize: '24px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center',
    } as React.CSSProperties,
    cordinateBoxDoc: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
    captureIconDoc: {
        width: 'auto',
        height: '30px',
        right: '10',
        top: '30',
        position: 'absolute',
    },
    settingTabMainDoc: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "20px 40px",
        flexWrap: "wrap",
    },
    activesettingTabDoc: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"

    },
    activesettingTab2Doc: {
        backgroundColor: 'rgba(193, 32, 48, 0.03)',
        padding: '15px',
        borderRadius: '16px',
        width: '100%',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #C12030',
    },
    settingTabDoc: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"
    },
    settingTab2Doc: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },
    activetabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
    checkboxTextDoc: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
        marginTop: 16
    },
    radioMainDoc: {
        display: 'flex',
        flexDirection: 'row',
        gap: 50
    },
    submitButtonDoc: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
    },
    rejectedTextDoc: {
        color: '#FF0404',
        fontFamily: 'Poppins',
        fontWeight: 500,
        alignSelf: 'flex-end',
        fontSize: '16px',
        width:'max-content',
        display:'block'
    },
    validationMessageDoc: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    style16Doc: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%"
    },
    documentName:{
        fontFamily: 'Poppins',
        marginLeft: '5px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#273567',
    }
}
// Customizable Area End