import React from "react";

import {
    Box,
    Button,
    Typography,
    styled,
    Paper,
    Select,
    MenuItem,
    FormControl,
    Fade,
    Modal,
} from "@material-ui/core";
import Cfloanapplication2Controller, {
    Props
} from "./Cfloanapplication2Controller";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { leftArrowImg, RightIcn } from "./assets";
import { Toaster } from "react-hot-toast"


const RightSideBox = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 166
    }
})

const CustomWalletTypography1 = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',

});

const CustomWalletTypography2 = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 400,
    fontSize: '14px',
});


const CustomBackBox = styled(Box)({
    display: 'flex',
    margin: "3px 0",
    paddingLeft: '40px',

});

const CustomUserBoxNew = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(0.5),
    justifyContent: 'space-between',
    margin: theme.spacing(3, 5),
    marginLeft: '39px',
    padding: "30px",
    [theme.breakpoints.down('sm')]: {
        display: 'unset',
    }
}));

const CustomTypography1 = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 500,
    fontSize: '20px',
});

const CustomButton = styled(Button)({
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "35px",
    color: "#fff",
    width: "100%",
    maxWidth: '260px',
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: 400,
    padding: "10px",
    '@media(max-width: 600px)': {
        marginTop: "25px",
    },
});
const CustomInput = styled("input")({
    outline:"none !important",
});

const PhoneNumber = styled('input')({
    outline:"none !important",
    '&::-webkit-outer-spin-button':{
        WebkitAppearance:"none",
        margin:0
       },
       '&::-webkit-inner-spin-button':{
        WebkitAppearance:"none",
        margin:0
       },
       '&[type=number]':{
        MozAppearance:"textfield"
       }
})

const CustomSelect = styled(Select)({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#273567',
      },
    outline:"none !important",
    border:"none !important"
})

const FormModal = styled(Box)({
    backgroundColor: "white",
    padding: "10px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid white',
    borderRadius: "16px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500,
    outline: "none",
    width: "100%",
    maxWidth: "440px",
    '@media(max-width: 600px)': {
        width: '80%',
        marginTop: '20px',
    }
})

const FieldsMain = styled(Box)({
    '@media(max-width: 600px)': {
       flexWrap:"wrap"
    }
})

export default class ApplicationFormOperator extends Cfloanapplication2Controller {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            // Customizable Area Start
            <Box style={webStyleOper.userContainerSettingOper}>
                <OperatorSideBar />
                <RightSideBox style={webStyleOper.containerSecondOper}>
                    <Box style={{ width: '100%' }}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <CustomBackBox data-test-id="backloancal" onClick={this.navigateToLoanCalculator} >
                        <img src={leftArrowImg} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                        <Typography style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, paddingLeft: 4, cursor: 'pointer' }}>Back</Typography>
                    </CustomBackBox>
                    <CustomUserBoxNew >
                        <CustomWalletTypography1 >
                            Apply Now!!
                        </CustomWalletTypography1>

                        <CustomWalletTypography2 >
                            Complete the form provided, and a representative will contact you shortly.
                        </CustomWalletTypography2>

                    </CustomUserBoxNew>
                    <Box style={{ padding: "0 40px", marginBottom: "20px" }}>

                        <Paper style={{ padding: "30px" }}>

                            <CustomTypography1>
                                Enter Details
                            </CustomTypography1>

                            <FieldsMain sx={webStyleOper.inputBoxOper}>

                                <Box sx={webStyleOper.labelBoxOper}>
                                    <CustomInput
                                        data-test-id="firstName"
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder="Name"
                                        style={
                                            webStyleOper.inputStyleOper
                                        }
                                        onChange={this.handleSetName}
                                        value={this.state.setName}
                                    />
                                </Box>
                                <Box sx={webStyleOper.labelBoxOper}>
                                    <CustomInput
                                        data-test-id="surname"
                                        name="surname"
                                        type="text"
                                        id="surname"
                                        placeholder="Surname"
                                        style={
                                            webStyleOper.inputStyleOper
                                        }
                                        onChange={this.handleSurname}
                                        value={this.state.setSurname}
                                    />
                                </Box>

                            </FieldsMain>

                            <FieldsMain sx={webStyleOper.inputBoxOper}>

                                <Box sx={webStyleOper.labelBoxOper}>
                                    <PhoneNumber
                                        data-test-id="phoneNumber"
                                        name="phoneNumber"
                                        type="text"
                                        id="phoneNumber"
                                        placeholder="Phone no."
                                        style={
                                           {... webStyleOper.inputStyleOper,appearance: "textfield", 
                                           MozAppearance: "textfield",
                                           WebkitAppearance: "none"
                                        }
                                    }   
                                        onChange={this.handlePhonenumber}
                                        value={this.state.setPhoneNumber}
                                    />
                                     {this.state.errorPhoneMessage && <div style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.errorPhoneMessage}</div>}
                                </Box>
                                <Box sx={webStyleOper.labelBoxOper}>
                                    <CustomInput
                                        data-test-id="email"
                                        name="email"
                                        type="text"
                                        id="email"
                                        placeholder="Email"
                                        style={
                                            webStyleOper.inputStyleOper
                                        }
                                        onChange={this.handleEmail}
                                        value={this.state.setEmail}
                                    />
                                     {this.state.errorMessage && <div style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.errorMessage}</div>}
                                </Box>

                            </FieldsMain>

                            <FieldsMain sx={webStyleOper.inputBoxOper}>
                                <FormControl variant="outlined" style={{...webStyleOper.labelBoxOper,borderColor:" #273567",borderRadius:8} as React.CSSProperties} >
                                    <div style={{ display: this.state.setStore ? 'none' : 'block', position: "absolute", top: 14, left: 15, fontFamily: "Poppins", fontSize: "14px", color: "rgb(39, 53, 103)", fontWeight: 400,}}>
                                        Select Store
                                    </div>
                                    <CustomSelect
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        data-test-id={'selectStoreId'}
                                        value={this.state.setStore}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{ ...webStyleOper.inputStyleOper, padding: 0,border: "1px solid red",width:"100%", }}
                                        onChange={this.handleSelectStore}
                                    >
                                        <MenuItem value={'Azad Store'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Azad Store</MenuItem>
                                    </CustomSelect>
                                </FormControl>

                                <Box sx={webStyleOper.labelBoxOper}>
                                    <CustomInput
                                        data-test-id="storeRefNumber"
                                        name="storeRefNumber"
                                        type="text"
                                        id="storeRefNumber"
                                        placeholder="Store reference number"
                                        style={
                                            webStyleOper.inputStyleOper
                                        }
                                        onChange={this.storeRefNumber}
                                        value={this.state.setRefNumber}
                                    />
                                </Box>
                            </FieldsMain>
                            <Box sx={webStyleOper.inputBoxOper}>
                                <Box sx={{...webStyleOper.labelBoxOper}}>
                                    <CustomInput
                                        data-test-id="storeAddress"
                                        name="storeAddress"
                                        type="text"
                                        id="storeAddress"
                                        placeholder="Store address"
                                        style={
                                            {...webStyleOper.inputStyleFullOper,
                                            }
                                        }
                                        onChange={this.handleStoreAdd}
                                        value={this.state.setStoreAdd}
                                    />
                                </Box>
                            </Box>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                closeAfterTransition
                                open={this.state.handleSuccessModal}
                                onClose={this.handleApplicationSucc}
                                aria-describedby="transition-modal-description"
                                BackdropProps={{
                                    style: {
                                        backdropFilter: "blur(4px)"
                                    },
                                }}
                            >
                                <Fade in={this.state.handleSuccessModal}>
                                    <FormModal sx={webStyleOper.greentickModalOper}>
                                        <img src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '90px', width: '100%', height: '90px' }} />
                                        <Typography variant="h5" style={webStyleOper.userVerifiedText2Oper as React.CSSProperties}>Loan Details has been sent to Admin for approval, Hang Tight!"</Typography>
                                    </FormModal>
                                </Fade>
                            </Modal>

                            <CustomButton data-test-id="submitapplication" style={webStyleOper.buttonFirstOper} onClick={this.handleLoanApplicationForm}>
                                Submit
                            </CustomButton>
                        </Paper>
                    </Box>
                </RightSideBox>
                <Toaster position="top-center" />
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyleOper = {
    userContainerSettingOper: {
        backgroundColor: "#E2E8F0"
    },
    containerSecondOper:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    labelBoxOper: {
        display: 'flex',
        flexDirection: 'column',
        width:"100%",
    },
    labelStyleOper: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    inputBoxOper: {
        marginTop: "27px",
        display: 'flex',
        gap: '25px',
        alignItems: 'flex-start', // Adjusted alignment
        width:"100%"
    } as React.CSSProperties,
    inputStyleOper: {
        color: '#273567',
        borderRadius: '8px',
        border: "1px solid #273567",
        height: '50px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        width:"100%"
    },
    inputStyleFullOper: {
        color: '#273567',
        borderRadius: '8px',
        border: '1px solid',
        height: '50px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    selectStoreStyleOper: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,
    formStyle: {
        border: `0.5px #273567`,
        borderRadius: "18px",
        width: '100%'
    },
    buttonFirstOper: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        color: "#fff",
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
        fontWeight: 400
    } as React.CSSProperties,
    greentickModalOper: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: "540px",
        height: "204px",
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2Oper: {
        fontSize: "18px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal",
    }
}
// Customizable Area End