import React from "react";

import {
    Box,
    Typography,
    Button,
    Modal,
    styled,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    RadioGroup,
    FormControlLabel,
    TableBody,
    Slider,
    ThemeProvider,
    TableSortLabel,
} from "@material-ui/core";
import { createTheme, withStyles } from '@material-ui/core/styles'
import Tooltip from "@material-ui/core/Tooltip";

import VisualAnalyticsController, {
    Props, configJSON
} from "./VisualAnalyticsController";

import StyleRadio from '../../../components/src/StyleRadio.web';
import SideNavbar from "../../../components/src/SideBar.web";

import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, downIcon, printIcon, check } from "./assets";
import { Pagination } from "@material-ui/lab";
import ReactToPrint from "react-to-print";

const InventoryBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const InventoryCustomBox = styled(Box)({
    display: 'flex',
    gap: 16,
    padding:"0 40px 20px",
    '@media(max-width: 600px)': {
        marginLeft: '0px',
        justifyContent: 'center'
    }
})

const InventoryWallet = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    display:"flex",
    alignItems:"center",
    '@media(max-width: 600px)': {
        padding: '0px'
    }
})


const BackMain = styled(Box)({
    display: 'flex',
    margin: "10px 0"
})


const UserBox = styled(Box)({
    display: 'flex',
    flexWrap: "wrap",
    gap: '8px',
    backgroundColor: "#FFFFFF",
    padding: "17px 24px 17px",
    borderRadius: "4px",
    justifyContent: 'space-between',
    margin: "20px 0",
    maxWidth: "100%",
    '@media(max-width: 600px)': {

    }

})

const CustomButton2 = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})
const ButtonCust = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    padding: '4px 16px 4px 20px',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})
const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#C12030",
            },
            track: {
                color: 'red'
            },
            rail: {
                color: 'pink'
            },

        },
    },

});


const FileSelect = styled(FormControlLabel)({
    margin: "auto !important",
    "& .MuiFormControlLabel-root": {
        margin: "auto !important"
    }
})
const CustomWalletBox = styled(Box)({
    position:"relative",
    '@media(max-width: 400px)': {
        flexWrap: 'wrap'
    }
})
const FilterText = styled(Typography)({
    '@media(max-width: 600px)': {
        marginLeft: 'unset !important'
    }
})

const ShowCategoryBox = styled(Box)({
    position:"absolute",
    top:"49px",
    "@media(max-width:400px)":{
        width:"200px !important",
        "& .MuiSlider-root":{
            width:"145px !important",
            marginLeft:"43px !important"
        }
    }
})

const SubcategoryBox = styled(Typography)({
    '@media(max-width: 375px)': {
        marginLeft: '10px',
    }
})
const DateModelBox = styled(Typography)({
    '@media(max-width: 400px)': {
        left: '0px',
        top:"108px !important"
    }
})

const DateModelSecondBox = styled(Typography)({
    '@media(max-width: 600px)': {
        width: '100% !important',
    },

})

const FilterCustomButtonWrapper = styled(Box)({
    display:'flex',
    gap:"10px",
    flexWrap:"wrap"
})

const FilterButton = styled(Button)({
    '@media(max-width: 400px)': {
        width: '160px !important'
    }
})

const StyledHeaderRow = styled(TableRow)({
    backgroundColor: "#2980ba !important",
    color:"white !important",
    fontWeight:700
 });
  
const EvenStyledBodyRow = styled(TableRow)({
    backgroundColor: "#f5f5f5 !important",
    color:"white !important",
    fontWeight:700,
    borderBottom: "none !important",
    borderTop: "none !important"
});
  
const StyleHeaderCell = styled(TableCell)({
    color:"white !important",
    fontWeight:700,
    padding:"6px",
    borderBottom: "none !important"
});
  
const StyleBodyCell = styled(TableCell)({
    padding:"6px",
    color:"#626161",
    borderBottom: "none !important"
});

interface Column {
    id:
    | "category"
    | "sku"
    | "qty"
    | "costOfSales"
    | "sellingValue"
    | "grossProfit"
    label: string;
    minWidth?: number;
    align?: "right";
    sortable: boolean;
}

interface Data {
    category: string;
    sku: number;
    qty: string;
    costOfSales: any;
    sellingValue: any;
    grossProfit: any;
}

interface ProductSalesByCateItem {
    sku: string;
    qty: number;
    costOfSales: string;
    sellingValue: string;
    grossProfit: string;
    category: string
  }

interface ProviderSKUItem {
    id: number;
    text: string;
    checked: boolean
}

const columns: Column[] = [
    { id: "category", label: "Category", sortable: false },
    { id: "sku", label: "SKU", sortable: false },
    { id: "qty", label: "Qty", sortable: true },
    { id: "costOfSales", label: "Cost of Sales", sortable: true },
    { id: "sellingValue", label: "Selling Value", sortable: true },
    { id: "grossProfit", label: "Gross Profit", sortable: true }
];

const rows: Data[] = [
    {
        sku: 20,
        qty: "Description",
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
        category: "Category1",

    },
    {
        qty: "Description",
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
        category: "Category1",
        sku: 20,

    },
    {
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
        category: "Category1",
        sku: 20,
        qty: "Description",
    },
    {
        sellingValue: "R500",
        grossProfit: "R120",
        category: "Category1",
        sku: 20,
        qty: "Description",
        costOfSales: "R250",
    },
    {
        category: "Category1",
        sku: 20,
        qty: "Description",
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
    },
    {
        category: "Category1",
        sku: 20,
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
        qty: "Description",
    },
    {
        category: "Category1",
        qty: "Description",
        costOfSales: "R250",
        sellingValue: "R500",
        sku: 20,
        grossProfit: "R120",
    },
    {
        category: "Category1",
        sku: 20,
        qty: "Description",
        costOfSales: "R250",
        sellingValue: "R500",
        grossProfit: "R120",
    },
    {
        costOfSales: "R250",
        sellingValue: "R500",
        category: "Category1",
        sku: 20,
        qty: "Description",
        grossProfit: "R120",
    },
    {

        sellingValue: "R500",
        category: "Category1",
        sku: 20,
        qty: "Description",
        costOfSales: "R250",
        grossProfit: "R120",
    },
    {
        category: "Category1",
        costOfSales: "R250",
        sku: 20,
        qty: "Description",
        sellingValue: "R500",
        grossProfit: "R120"
    },

];

const CustomTooltip = withStyles(({
    tooltip: {
        marginTop:"10px",
        backgroundColor: '#F3D2D6'
    },
}))(Tooltip);

const SliderCustomDiv = styled('div')({
    '@media(max-width: 395px)': {
        width: '160px !important'
    }
})
export default class ProductSalesByCategory extends VisualAnalyticsController {
    constructor(props: Props) {
        super(props);
    }

    ValueLabelComponent(props: any) {
        const { children, open, value } = props;
        return (
            <CustomTooltip open={open} interactive enterTouchDelay={0} title={value} id='dateModal' className="dateModal">
                {children}
            </CustomTooltip>
        );
    }
    handleSortFilterValue = (value: boolean, title: string,order:string) => {
        return (
            <Box id='dateModal' className="dateModal" style={{ ...webStyle.labelBoxStyle, backgroundColor:this.state.orderByCate==="grossProfit"&&value ? '#CDF0EE' : 'white',cursor:"pointer" }} onClick={()=>this.handleSortFilterCategory(order)} data-test-id={'handleSortFilterAcending'}>
                {this.state.orderByCate==="grossProfit"&&value ? <img src={check} style={{ marginLeft: '5px', height: '24px', width: '24px',cursor:"pointer" }} id='dateModal' className="dateModal" /> : <div style={{ marginLeft: '5px', height: '24px', width: '24px',cursor:"pointer" }} id='dateModal' className="dateModal" />}
                <label id='dateModal' className="dateModal" style={webStyle.labelStyle as React.CSSProperties}>{title}</label>
            </Box>
        )
    }


    rangeSlider = (type: any) => {
        const valueLabelFormat = (value: any) => {
            return (
                <div id='dateModal' className="dateModal" style={webStyle.sliderValue}>
                    {value}
                </div>
            );
        };

        return (
            <ThemeProvider theme={muiTheme} >
                <Slider
                    data-test-id="quantitySliderTestId"
                    id='dateModal' className="dateModal"
                    value={this.state.providerQuantityRange}
                    onChange={this.handleCategoryQuantitySliderChange}
                    aria-labelledby="discrete-slider-always"
                    min={0}
                    max={1000}
                    valueLabelDisplay='on'
                    ValueLabelComponent={this.ValueLabelComponent}
                    style={webStyle.sliderStyle}
                    valueLabelFormat={valueLabelFormat}
                />

                <SliderCustomDiv style={webStyle.rangeFilterMainDiv} id='dateModal' className="dateModal">
                    <Typography id='dateModal' className="dateModal" style={webStyle.filterTextStyle}>0</Typography>
                    <Typography style={this.state.values[1] >= 800 ? webStyle.filterNoValue : webStyle.filterTextStyle} id='dateModal' className="dateModal">
                        {this.state.values[1] >= 800 ? 0 : 1000}
                    </Typography>
                </SliderCustomDiv>
            </ThemeProvider>
        );
    }

    profitValuerangeSlider = (type: any) => {
        const valueLabelFormat = (value: any) => {
            return (
                <div id='dateModal' className="dateModal" style={webStyle.sliderValue}>
                    {`R${value}`}
                </div>
            );
        };

        return (
            <ThemeProvider theme={muiTheme}>
                <Slider
                    data-test-id="profitSliderTestID"
                    id='dateModal' className="dateModal"
                    value={this.state.providerProfitRange}
                    onChange={this.handleCategoryProfitValueSliderChange}
                    aria-labelledby="discrete-slider-always"
                    min={0}
                    max={1000}
                    valueLabelDisplay='on'
                    ValueLabelComponent={this.ValueLabelComponent}
                    style={webStyle.sliderStyle}
                    valueLabelFormat={valueLabelFormat}
                />

                <SliderCustomDiv style={webStyle.rangeFilterMainDiv} id='dateModal' className="dateModal">
                    <Typography id='dateModal' className="dateModal" style={webStyle.filterTextStyle}>0</Typography>
                    <Typography style={this.state.profitValues[1] >= 800 ? webStyle.filterNoValue : webStyle.filterTextStyle} id='dateModal' className="dateModal">
                        {this.state.profitValues[1] >= 800 ? 0 : 1000}
                    </Typography>
                </SliderCustomDiv>
            </ThemeProvider>
        );
    }
    renderFileSelect = (isChecked: any, handleChange: any) => (
        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
            <FileSelect value="female" control={<StyleRadio checked={isChecked} />} label="" onChange={handleChange} />
        </RadioGroup>
    )

    renderCellContent = (valueName: string, value: string | number) => {
        return (
          <TableCell
            key={valueName}
            style={
              valueName === "category"
                ? webStyle.columnCellTwoCategory
                : webStyle.columnCellTwo
            }
          >
            {valueName === "costOfSales" ||
            valueName === "sellingValue" ||
            valueName === "grossProfit" ? (
              <>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  R
                </span>
                <span>{value.toString().slice(1)}</span>
              </>
            ) : (
              <span>{value}</span>
            )}
          </TableCell>
        );
      };

    showCategoryBox = () => {
        return (
            <ShowCategoryBox data-test-id="mainCategoryDropDownTestID" id='dateModal' className="dateModal" style={webStyle.subFilterBoxStyle} >
                <div style={webStyle.catogoryFilterMainDiv} id='dateModal' className="dateModal">
                    {this.state.mainFilter.map((item: any, index: any) => (
                        <div key={index} id='dateModal' className="dateModal">
                            <div style={webStyle.filterDiv} id='dateModal' className="dateModal">
                                <img id='dateModal' className="dateModal" src={this.checkedImage(item.checked)} onClick={() => this.handleCategoryMainFilterChange(index)} style={webStyle.checkImage} data-test-id={`testIdSelectCategoryFilter${index}`} />
                                <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" onClick={() => this.handleCategoryMainFilterChange(index)} data-test-id={`testIdSelectCategoryFilterText${index}`}>{item.text}</Typography>
                            </div>
                            {(item.text === 'Category' && item.isShow) &&
                                this.state.categoryArray.map((item: any, index: any) => (
                                    <SubcategoryBox key={item.id} style={webStyle.subFilterView} id='dateModal' className="dateModal">
                                        <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" data-test-id={`testIdFilter${index}`} onClick={() => this.handleCategoryCheckboxChange(index)} style={webStyle.checkImage} />
                                        <Typography id='dateModal' className="dateModal" style={webStyle.filterTextStyle} data-test-id={`testIdFilterText${index}`} onClick={() => this.handleCategoryCheckboxChange(index)}>{item.text}</Typography>
                                    </SubcategoryBox>
                                ))}
                            {(item.text === 'SKU' && item.isShow) &&
                                this.state.providerSKUArray.map((item: ProviderSKUItem, index: number) => (
                                    <SubcategoryBox style={webStyle.subFilterView} key={item.id} data-test-id="divyang" id='dateModal' className="dateModal">
                                        <img src={this.checkedImage(item.checked)} id='dateModal' className="dateModal" data-test-id={`skutestIdFilter${index}`} onClick={() => this.handleCategorySKUChange(index)} style={webStyle.checkImage} />
                                        <Typography style={webStyle.filterTextStyle} id='dateModal' className="dateModal" onClick={() => this.handleCategorySKUChange(index)} data-test-id={`skutestIdFilterText${index}`}>{item.text}
                                        </Typography>
                                    </SubcategoryBox>
                                ))}
                            {(item.isShow && item.text === 'Qty') && this.rangeSlider(item.text)}

                            {(item.isShow && item.text === 'Profit Value') && this.profitValuerangeSlider(item.text)}
                        </div>
                    ))}
                </div>

            </ShowCategoryBox>
        )
    }

    renderPrintableTable = () => {
        return (
          <Box style={{ display: "none" }}>
            <TableContainer ref={this.printableDivRef}  style={webStyle.printTableContainerStyle}>
                <Box style={webStyle.printTableMainHeading}>Product Sales By Category</Box>
                <Table style={webStyle.printMainTable}>
                    <TableHead>
                    <StyledHeaderRow>
                        {columns.map((column) => (
                        <StyleHeaderCell
                            key={column.id}
                            align={column.align}
                            style={webStyle.columnCell}
                        >
                            <span>{column.label}</span>
                        </StyleHeaderCell>
                        ))}
                    </StyledHeaderRow>
                    </TableHead>
                    <TableBody>
                    {this.state.productByCategorySortedData.map((Item: ProductSalesByCateItem, index: number) => (
                        index % 2 !== 0 ? 
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <StyleBodyCell
                                key={column.id}
                                align={column.align}
                                >
                                {Item[column.id]}
                                </StyleBodyCell>
                            ))}
                        </TableRow>:
                        <EvenStyledBodyRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <StyleBodyCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {Item[column.id]}
                                </StyleBodyCell>
                            ))}
                        </EvenStyledBodyRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </Box>
        );
      };

    render() {

        return (
            // Customizable Area Start

            <Box 
                style={webStyle.userSettingContainer} 
                onClick={(event)=>this.handleProviderBodyClick(event)}
                data-test-id={'handleCategoryBodyClickOnScreen'}
            >
                <SideNavbar />
                <InventoryBoxLanding style={webStyle.secondContainer} >
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ padding: "0px 40px 40px 40px" }}>
                        <BackMain>
                            <img src={leftArrow} style={webStyle.backArrow} onClick={this.handleLandingPageFirst} />
                            <Typography style={webStyle.backText} onClick={this.handleLandingPageFirst}>Back</Typography>
                        </BackMain>

                        <UserBox style={webStyle.userSettingFeildsMian}>
                            <InventoryWallet style={webStyle.userSettingText as React.CSSProperties} >
                                Product Sales by Category - Category
                            </InventoryWallet>
                            <FilterCustomButtonWrapper>
                            <FilterText style={webStyle.filterbyText as React.CSSProperties}>
                                Filter by:
                            </FilterText>
                            <CustomWalletBox style={webStyle.walletBoxtStyle}>
                                <FilterButton style={webStyle.timelinebutton} onClick={this.SortByCategoryModal} className="dateModal" id='dateModal' data-test-id={'SortByCategoryModalId'}>
                                    <div style={webStyle.catogoryBox} id='dateModal' className="dateModal">
                                        <Typography style={webStyle.timelineText} id='dateModal' className="dateModal">Category</Typography>
                                        <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' className="dateModal" />
                                    </div>
                                </FilterButton>
                                <div>
                                    <FilterButton style={webStyle.timelinebutton2} className="dateModal" id='dateModal' onClick={() => this.filterSortBy()} data-test-id={'filterSortBy'}>
                                        <div style={webStyle.sortBox} id='dateModal' className="dateModal">
                                            <Typography style={{ ...webStyle.timelineText, whiteSpace: 'nowrap' }} className="dateModal" id='dateModal' >
                                                Sort <span style={{ textTransform: 'lowercase' }} id='dateModal' className="dateModal">by</span>
                                            </Typography>
                                            <img src={downIcon} style={{ marginLeft: '10px' }} id='dateModal' className="dateModal" />
                                        </div>
                                    </FilterButton>
                                </div>
                                {this.state.showCategory && this.showCategoryBox()}
                                {this.state.isOpenDateModal &&
                                        <DateModelBox data-test-id="sorting" style={webStyle.dateModelStyle} id='dateModal' className="dateModal">
                                            <DateModelSecondBox style={webStyle.inputBox} id='dateModal' className="dateModal">
                                                {this.handleSortFilterValue(this.state.selectAsending, 'Sort by Ascending to Descending',"desc")}
                                                {this.handleSortFilterValue(!this.state.selectAsending, 'Sort by Descending to Ascending',"asc")}
                                            </DateModelSecondBox>
                                        </DateModelBox>

                                    }
                            </CustomWalletBox>
                            </FilterCustomButtonWrapper>
                        </UserBox>
                        <Paper style={{ cursor: 'pointer' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={webStyle.columnCell}
                                                >
                                                    {column.sortable ? (
                                                        <TableSortLabel
                                                            data-test-id={`columnSort${column.id}TestID`}
                                                            active={this.state.orderByCate === column.id}
                                                            direction={this.state.orderByCate === column.id ? this.state.orderCate : 'asc'}
                                                            onClick={this.handleRequestSortCate.bind(this, column.id)}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <span>{column.label}</span>
                                                    )}

                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        
                                    {this.state.productByCategorySortedData.length!==0?this.state.productByCategoryShowData.map((value:ProductSalesByCateItem, index:number) =>
                                                <TableRow data-test-id={`sortedDataTableRow${index}TestID`} hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column) => 
                                                        this.renderCellContent(column.id, value[column.id])
                                                    )}
                                                </TableRow>):
                                        <TableRow>
                                            <TableCell 
                                                colSpan={7} 
                                                style={webStyle.noResultFoundTextTableCell}
                                            >
                                                {configJSON.noResultFoundText}
                                            </TableCell>
                                        </TableRow>}
                                            
                                    </TableBody>

                                </Table>
                            </TableContainer>

                            {this.state.productByCategorySortedData.length?<div style={webStyle.bottomBox}>
                                <div />
                                 <Pagination
                                    data-test-id="categoryPaginationTestID"
                                    page={this.state.providerSelectedPage}
                                    count={Math.ceil(this.state.productByCategorySortedData.length / 10)}
                                    onChange={(event: React.ChangeEvent<unknown>, newPage: number) =>
                                    this.handlePagination(event, newPage, "categoryPagination")
                                    }
                                    style={webStyle.pagination}
                                />
                                <Typography style={webStyle.pagesText}>
                                    {this.state.providerSelectedPage*10-9} -{" "}
                                    {(this.state.providerSelectedPage*10-9)+(this.state.productByCategoryShowData.length-1)}&nbsp;
                                    of {this.state.productByCategorySortedData.length} results
                                </Typography>
                            </div>:null}

                        </Paper>
                        {this.renderPrintableTable()}
                    </Box>

                    <InventoryCustomBox>
                        <CustomButton2 data-test-id="exportAllButtonTestID" onClick={this.handleButtonClick}>
                            <Typography style={{ ...webStyle.exportText, textAlign: 'center' }}>Export All</Typography>
                        </CustomButton2>
                        <style>
                            {`
                                @media print {
                                    html, body {
                                        margin: 0; 
                                        overflow: initial !important;
                                        padding: 0;
                                        height: initial !important;
                                    }
                                    @page {
                                        margin: 0.5cm;
                                    }
                                }
                            `}
                        </style>
                        {this.state.productByCategorySortedData.length!==0?
                        <ReactToPrint
                            data-test-id="print"
                            documentTitle="product_sales_by_category"
                            trigger={() => (
                                <ButtonCust data-test-id="print1" style={webStyle.exportbutton}>
                                    <img src={printIcon} />
                                    <Typography style={{ ...webStyle.exportText, marginLeft: "5px" }}>
                                        Print
                                    </Typography>
                                </ButtonCust>
                            )}
                            content={() => this.printableDivRef.current}
                        /> : 
                        <ButtonCust data-test-id="print2" style={webStyle.exportbutton}>
                            <img src={printIcon} />
                            <Typography style={{ ...webStyle.exportText, marginLeft: "5px" }}>
                                Print
                            </Typography>
                        </ButtonCust>}
                    </InventoryCustomBox>
                    <Modal
                        data-test-id="fileTypeModalTestID"
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-Category"
                        style={webStyle.modelView}>
                        <Box style={webStyle.modelViewBox}>
                            <Typography style={webStyle.modeMsg}>
                                Select the file type you would like to download :
                            </Typography>
                            <Box style={webStyle.modalBox}>
                                <Box data-test-id="pdfSelectButtonTestID" onClick={this.handleNewUserChange} style={{ ...webStyle.newuserStyle, backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white' }}>
                                    <Typography style={webStyle.pdfText}>PDF</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {this.renderFileSelect(this.state.isNewUserChecked, this.handleNewUserChange)}
                                    </div>
                                </Box>
                                <Box data-test-id="xlsxSelectButtonTestID" onClick={this.handleExistingUserChange} style={{ ...webStyle.newuserStyle, backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white' }}>
                                    <Typography style={webStyle.xlcssText}>XLSX</Typography>
                                    <div style={webStyle.boxStyle}>
                                        {this.renderFileSelect(this.state.isExistingUserChecked, this.handleExistingUserChange)}
                                    </div>
                                </Box>
                            </Box>

                            <Button data-test-id="confirmDownloadButtonTestID" onClick={()=>this.handleCategoryExportConfirm()} style={webStyle.submitButton2}>
                                Confirm
                            </Button>
                        </Box>
                    </Modal>

                </InventoryBoxLanding>
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    printTableContainerStyle:{
        margin: "20px auto 0 auto", 
        width: "750px"
    },
    printTableMainHeading:{
        fontSize:"25px", 
        fontFamily:"sans-serif"
    },
    printMainTable:{
        marginTop: "30px"
    },
    userSettingContainer: {
        backgroundColor: "#E2E8F0"
    },
    labelBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '5px',
        paddingTop: '5px',
        width: "100%",
        height: '48px',
        alignItems: 'center',
        boxSizing:"border-box"
    } as React.CSSProperties,
    secondContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0"
        } as React.CSSProperties,
    userSettingFeildsMian: {

    },
    boxStyle: {
        display: 'flex',
        alignItems: 'center'
    },
    subFilterBoxStyle: {
        borderRadius: "8px",
        background: 'white',
        zIndex: 999999,
        padding: '20px',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        width: "250px"
    } as React.CSSProperties,
    bottomBoxStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: '20px',
    } as React.CSSProperties,
    paginationStyle: {
        marginBottom: '20px'
    } as React.CSSProperties,
    pagesTextStyle: {
        color: '#64748B',
        fontFamily: "Poppins",
        fontSize: '12px',
        marginRight: '15px',
        marginBottom: '20px'
    } as React.CSSProperties,
    zeroText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white',
    },

    userSettingText: {

    },
    columnCellTwo: {
        color: "#475569",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    columnCellTwoCategory: {
        color: "#475569",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        minWidth: "100px"
    }  as React.CSSProperties,
    backArrow: {
        width: '24px',
        height: '24px',
        cursor: 'pointer'
    },
    filterText: {
        display: 'flex',
        gap: 16,
        width: "60%"
    },
    backText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer',
        marginLeft: '5px'
    },
    columnCell: {
        fontSize: '14px',
        fontWeight: 700, width: '100px',
        color: '#475569',
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    filterbyText: {
        marginLeft: 'auto',
        lineHeight: '22px',
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: '400',
        fontSize: '14px',
        alignSelf: 'center'
    },
    walletBoxtStyle: {
        display: 'flex',
        gap: 16,
        alignSelf: 'center'
    } as React.CSSProperties,
    timelineText: {
        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    exportText: {
        fontFamily: 'Poopins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    timelinebutton: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        alignItems: 'center',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
    } as React.CSSProperties,
    exportbutton: {


    } as React.CSSProperties,
    catogoryBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    timelinebutton2: {
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
    } as React.CSSProperties,
    dateModel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modelView: {
        overflowY: 'auto',
        width: '90%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
    } as React.CSSProperties,
    modelViewBox: {
        maxWidth: '550px',
        width: '100%',
        padding: 30,
        backgroundColor: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius:"7px"
    } as React.CSSProperties,
    modalBox: {
        display: 'flex',
        gap: 26,
        marginTop: 60,
        justifyContent: 'center'
    },
    newuserStyle: {
        maxWidth: '143px',
        height: '117px',
        width: '100%',
        padding: '20px 30px',
        borderRadius: '4px',
        border: '1px solid #E6E6E6',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    } as React.CSSProperties,
    pdfText: {
        fontSize: '20px',
        fontWeigth: '400',
        fontFamily: 'Poppins',
        color: '#0F172A',
        margin: 'auto'
    } as React.CSSProperties,

    modeMsg: {
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        maxWidth: '373px',
        margin: 'auto',
    },
    sortBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    xlcssText: {
        fontSize: '20px',
        fontWeigth: '400',
        fontFamily: 'Poppins',
        color: '#0F172A',
        margin: 'auto'
    } as React.CSSProperties,

    inputBox: {
        width: '320px',
        flexDirection: 'column',
        gap: '16px'
    } as React.CSSProperties,
    dateModelStyle: {
        position: 'absolute',
        background: 'white',
        right: "0",
        zIndex: 999999,
        top:"49px",
        overflow:"hidden",
        borderRadius:"8px",
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',

    } as React.CSSProperties,
    position: 'absolute',
    marginLeft: '20px',
    background: 'white',
    right: 50,
    zIndex: 999999,
    marginTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    categaryBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    submitButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        maxWidth: '550px',
        width: "100%",
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400
    } as React.CSSProperties,
    datemodalStyle: {

    },
    labelBox: {
        display: 'flex',
        width: "100%",
        height: '48px',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '10px',
        paddingTop: '5px'
    },
    filterImageStyle: {
        marginLeft: '5px',
        height: '24px',
        width: '24px'
    },
    dummyDev: {
        marginLeft: '5px',
        height: '24px',
        width: '24px'
    },
    labelStyle: {
        fontFamily: 'Lato',
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginLeft: '5px',
        cursor:"pointer"
    },
    inputStyle: {
        color: '#64748B',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    catogoryFilterBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '60px'
    },
    catogoryFilterMainDiv: {
        width: '90%',
        backgroundColor: 'white',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    filterDiv: {
        cursor: 'pointer',
        display: 'flex',
        margin: '10px',
        gap: '10px',
    },
    checkImage: {
        width: '24px',
        height: '24px',
    },
    filterTextStyle: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',


    },
    sliderStyle: {
        width: '180px',
        marginLeft: '50px',
        marginBottom: '10px',
    },
    sliderValue: {
        textAlign: "center",
        color: '#C12030',
        fontWeight: 400,
        fontSize: '14px'
    } as React.CSSProperties,
    filterNoValue: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white'
    },
    subFilterView: {
        cursor: 'pointer',
        display: 'flex',
        margin: '20px',
        gap: '10px',
        marginLeft: '50px'
    },
    rangeFilterMainDiv: {
        width: '180px',
        marginLeft: '50px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    pagination: {
        fontFamily: "InterReguular", 
        fontWeight: 400, 
        color: "#0F172A", 
        fontSize: "12px" 
    } as React.CSSProperties,
    bottomBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: '20px',
        paddingBottom:"20px"
    } as React.CSSProperties,
    pagesText:{
        fontFamily: "Poppins", 
        fontWeight: 400, 
        color: "#64748B", 
        marginRight: "10px", 
        fontSize: "12px" 
    },
    noResultFoundTextTableCell:{
        textAlign:"center",
        fontFamily:"Poppins",
        fontSize:"20px",
        color:"gray",
        padding:"80px 0"
    } as React.CSSProperties
}
// Customizable Area End