import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Toaster } from "react-hot-toast"

const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

const CustomBox = styled(Box)({
  display: 'flex',
  borderRadius: '12px', 
  border: '1px solid #273567', 
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '340px', 
  paddingLeft: '10px', 
  paddingRight: '10px',
  boxSizing:'border-box',
  height: '50px', 
  "@media (max-width: 430px)":{
    width:"230px !important"
  }
});

const CustomBox4 = styled(Box)({
  backgroundColor: '#ffffff',
  width: "80%",
  boxSizing: 'border-box',
  margin:"30px",
  padding: "30px",
  borderRadius: '12px',
  paddingLeft: '25px',
  paddingRight: '25px',
  flexWrap: 'wrap',
  "@media (max-width: 430px)":{
    marginLeft:"46px !important"
  }
});
const CustomBox2 = styled(Box)({
  display: 'flex',
  borderRadius: '12px', 
  border: '1px solid #273567', 
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '340px',
  marginTop:"0px",
  paddingLeft: '10px', 
  paddingRight: '10px',
  height: '50px', 
  boxSizing:'border-box',
  "@media (max-width: 830px)":{
    margin:"20px 0 0 0 !important",
    width:"230px !important"
  },
});
const CustomButton = styled(Button)({
  backgroundColor: "#C12030",
  fontSize: "16px",
  marginTop: "50px",
  color: "#fff",
  width: '340px',
  height: "50px",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontFamily: 'Poppins',
  "@media (max-width: 430px)":{
    marginTop: "30px",
    width: '240px',
  }
});

const CustomTypography = styled(Typography)({
  color: '#273567',
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: 'Poppins',
  "@media (max-width: 430px)":{
    fontSize: "13px",
  }
});

const CustomTypography2 = styled(Typography)({
  color: '#273567',
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: 'Poppins',
  "@media (max-width: 430px)":{
    fontSize: "11px",
  }
});


// Customizable Area End

import Cfcashfloat2Controller, {
  Props,
} from "./Cfcashfloat2Controller";
import { calenderIcon } from "./assets";

const AmountError = styled(Typography)({
  "@media (max-width: 830px)":{
    marginLeft:"0 !important"
  },
});

export default class CashFlotDetailsOperator extends Cfcashfloat2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    let selectStore = await getStorageData('selectStore')
    let terminal = await getStorageData('terminal')
    let amount = await getStorageData('amount')
    removeStorageData('startCashFloatProcess')

    this.setState({ selectedStore: selectStore, selectedTerminal: terminal, startAmount: amount })
  }
  // Customizable Area End

  render() {
    let { selectedStore } = this.state;
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-IN', options);
    const startAmount = parseInt(this.state.startAmount.replace(/,/g, ''), 10);
    const endAmount = parseInt(this.state.endAmount.replace(/,/g, ''), 10);
    const difference = endAmount - startAmount;
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.mainBox}>
          <SideNavbar />
          <RightBoxLanding2 style={webStyle.secondContainer}>
            <Box style={{ width: '100%' }}>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
            <Box style={{ width: "100%", }}>
              <Box style={webStyle.secondStyle}>
                <Box style={webStyle.backArrowBox} data-test-id={'back-arrow'}>
                  <Typography style={webStyle.titleStyle}>Cash Float</Typography>
                </Box>
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>

                  <CustomBox4 style={webStyle.boxStyle}>

                    <Box style={webStyle.cashBoxStyle}>
                      <Typography style={webStyle.cashTitle}>Cash float details</Typography>
                      <Box style={{ display: 'flex', }}>
                        <img src={calenderIcon} style={webStyle.calenderIcon} />
                        <Typography style={webStyle.dateTitle}>{formattedDate}</Typography>
                      </Box>
                    </Box>
                    <Box flexWrap={'wrap'} display={'flex'} width={'100%'} mt={'30px'} style={webStyle.inputBox}>
                      <CustomBox>
                        <Typography style={webStyle.leftTextStyle}>{selectedStore}</Typography>
                        <Typography style={webStyle.rightTextStyle}>Store</Typography>
                      </CustomBox>
                      <CustomBox2>
                        <Typography style={webStyle.leftTextStyle}>{this.state.selectedTerminal}</Typography>
                        <Typography style={webStyle.rightTextStyle}>POS terminal</Typography>
                      </CustomBox2>
                    </Box>
                    {/* third row */}
                    <Box flexWrap={'wrap'} display={'flex'} width={'100%'} mt={'30px'} style={webStyle.inputBox}>
                        <div style={webStyle.errordiv}>
                        <CustomBox >
                          <Box style={{ display: 'flex' }}>
                            <Typography style={webStyle.TextRStyle}>R</Typography>
                            <Typography style={{ ...webStyle.leftTextStyle, marginLeft: '5px' }}>{this.state.startAmount}</Typography>
                          </Box>
                          <CustomTypography>Start amount</CustomTypography>
                        </CustomBox>
                        {this.state.endAmount.length > 0 &&
                          <Box style={{ display: 'flex' }}>
                            <Typography style={webStyle.differenceText}>“R {Number(difference).toLocaleString("en-IN")}”</Typography>
                            <Typography style={webStyle.amountText}>Difference Amount</Typography>
                          </Box>
                        }
                         </div>
                      {/* third right row */}
                      <div style={webStyle.errordiv}>
                        <CustomBox2 >
                          <Box style={{ display: 'flex', }}>
                            <Typography style={webStyle.TextR}>R</Typography>
                            <input
                              className="no-outline"
                              type="text"
                              name="username"
                              placeholder="Enter amount"
                              data-test-id={'inputText'}
                              value={this.state.endAmount}
                              onChange={this.handleSetEndAmount}
                              style={webStyle.inputBoxStyle}></input>
                          </Box>
                          <CustomTypography2>End Day Amount</CustomTypography2>
                        </CustomBox2>
                        {this.state.endAmountError && <AmountError style={webStyle.amountError}>Enter an appropriate amount.</AmountError>}
                      </div>
                      </Box>


                    <CustomButton
                      data-test-id={'btnSubmit'}
                      style={webStyle.buttonStyle} onClick={() => this.EndDay()}>
                      Submit
                    </CustomButton>

                  </CustomBox4>
                </Grid>
              </Box>
            </Box>
            <Toaster position="top-center" />
          </RightBoxLanding2>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  secondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,
    errordiv:{
      display:"flex",
      flexDirection: 'column',
    }as React.CSSProperties,
  secondStyle: {
      paddingLeft:'40px',
    width: "100%",
    backgroundColor: "#E2E8F0",
  } as React.CSSProperties,
  buttonStyle:{
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "30px",
    height: "50px", // Adjust height as needed
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
  }as React.CSSProperties,
  backArrowBox: {
    marginLeft: '35px',
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  titleStyle: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
  } as React.CSSProperties,
  thirdrightRow: {
    width: '335px',
    display: 'flex',
    boxSizing: 'border-box',
    margin: '10px',
    flexDirection: 'column'
  } as React.CSSProperties,
  thirdRightSmallBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '10px 15px',
    border: '1px solid #273567',
    borderRadius: '12px',
    boxShadow: '1px 1px 1px #273567',
  } as React.CSSProperties,
  inputBoxStyle: {
    color: "#273567",
    fontFamily: 'Poppins',
    border: 0,
    borderColor: 'white',
    fontWeight: 500,
    fontSize: '14px',
    alignSelf: 'center',
    outline: 'none',
    width: '100px',
    margin: '0px 5px'
  } as React.CSSProperties,
  TextR: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  differenceText: {
    alignSelf: 'center',
    fontWeight: 600,
    color: '#059669',
    fontFamily: 'Poppins',
    maxWidth:"190px"
  } as React.CSSProperties,
  amountText: {
    color: '#0F172A94',
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginLeft: '5px'
  } as React.CSSProperties,
  mainBoxStyle: {
    backgroundColor: '#ffffff',
    width: "80%",
    boxSizing: 'border-box',
    marginLeft: '30px',
    marginTop: '30px',
    padding: "30px",
    borderRadius: '12px',
    paddingLeft: '25px',
    paddingRight: '25px',
    flexWrap: 'wrap',
  } as React.CSSProperties,
  cashBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  } as React.CSSProperties,
  cashTitle: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '20px',
  } as React.CSSProperties,
  calenderIcon: {
    width: '18.18px',
    height: '20px',
    justifyContent: 'center'
  } as React.CSSProperties,
  dateTitle: {
    color: '#000000',
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginLeft: '5px'
  } as React.CSSProperties,
  mainBox: {
    display: "flex",
    backgroundColor: "#F6F7FA",
  } as React.CSSProperties,
  inputStyle: {
    color: '#273567',
    width: '100%',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  inputBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap:'30px'
  } as React.CSSProperties,
  leftTextStyle: {
    color: '#273567',
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  rightTextStyle: {
    color: '#273567',
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  amountError:{
    color: 'red', 
    fontSize: "12px", 
    fontWeight: 400, 
    fontFamily: 'Poppins', 
    marginLeft: '20px',
     marginTop: '10px'
  }as React.CSSProperties,
  thirdRow: {
    display: 'flex',
    flexWrap: 'wrap',
  } as React.CSSProperties,
  thirdRowSecond: {
    width: '335px',
    display: 'flex',
    boxSizing: 'border-box',
    margin: '10px',
    flexDirection: 'column'
  } as React.CSSProperties,
  thirdBoxStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '10px 15px',
    border: '1px solid #273567',
    borderRadius: '12px',
    boxShadow: '1px 1px 1px #273567',
  } as React.CSSProperties,
  TextRStyle: {
    fontWeight: 'bold',
    alignSelf: 'center',
     fontFamily: 'Poppins',
      marginRight: '2%', 
  } as React.CSSProperties,
  boxStyle: {
    backgroundColor: '#ffffff',
    width: "80%",
    boxSizing: 'border-box',
    margin:"30px",
    padding: "30px",
    borderRadius: '12px',
    paddingLeft: '25px',
    paddingRight: '25px',
    flexWrap: 'wrap',
  }as React.CSSProperties,
  squareBox: {
    width: '300px',
    padding: '12px 15px',
    border: '1px solid #273567',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '12px',
    boxShadow: '1px 1px 1px #273567',
    margin: '10px'
  } as React.CSSProperties,

};
// Customizable Area End
