export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const capture_icon = require("../assets/group_icon.svg");
export const check_icon = require("../assets/Check.png");
export const upload_icn = require("../assets/Upload_icn.png");
export const upload  = require("../assets/upload.png");
export const accepted  = require("../assets/accepted.png");
export const arrow_back  = require("../assets/arrow_back.png");
export const leftArrow  = require("../assets/leftArrow.png");
export const greentick  = require("../assets/greentick.png");
export const storeicon = require("../assets/Store_Icon.png");
export const BackIcn = require("../assets/BackIcn.png");
export const DeleteIcn = require("../assets/Deleteicn.png");
export const CloseIcn = require("../assets/CloseIcn.png");


