import React from "react";

import {
  Box,
  Typography,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start


const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 993px)': {
    marginLeft: '180px'
  }
})
const CustomBox = styled(Box)({
  display: "flex",
  flexWrap:'wrap',
  marginTop:50,
  gap:'30px',
  boxSizing:'border-box',
  '@media(max-width:1280px)':{
    gap:'20px',

  },
  '@media(max-width: 992px)': {
    margin:'0 10px',
  flexDirection: "column",
    alignItems:'center',
    justifyContent:'center'
  }
})
const CustomImage = styled("img")({
  cursor: 'pointer',
  maxWidth:'500px',
  height:'240px',
  width:'100%',
  '@media(max-width:1200px)':{
    maxWidth:'400px',
    height:'auto'
  },
  '@media(max-width:1000px)':{
    maxWidth:'390px',
    height:'auto'
  },
  '@media(max-width: 992px)': {
  width:'100%',
  maxWidth:'500px',
    display:'flex',
    marginTop:24
  }
})
const CustomImage2 = styled("img")({
  cursor: 'pointer',
  maxWidth:'500px',
  height:'240px',
  width:'100%',
  '@media(max-width: 600px)': {
    maxWidth:'350px',
    width:'100%',
    display:'flex',
    margin:'auto',
    marginTop:24
  }
})
const CustomImage3 = styled("img")({
  cursor: 'pointer',
  maxWidth:'500px',
  height:'240px',
  width:'100%',
  '@media(max-width: 600px)': {
    maxWidth:'350px',
    width:'100%',
    display:'flex',
    margin:'auto',
    marginTop:24
  }
})
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
  } from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import {redwallet,whitewallet,redcommision,whitecommision,redpaybills,whitepaybills} from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class WalletTransact extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { selected } = this.state;
    console.log("this.state.setImage",this.state.setImage)
    return (
      // Customizable Area Start

      <Box>
        <SideNavbar />
        <RightBoxLanding2 style={webStyle.secondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>
          <Box>
            <CustomBox>

              <CustomImage src={this.state.setImage === true ? redwallet : whitewallet} onMouseEnter={this.handleChangeImage} onMouseLeave={this.handleChangeImage} data-test-id="redwallet" onClick={() => this.handleClick('redwallet')} />

              <CustomImage src={this.state.setCommissionImage === true ? redcommision : whitecommision} onMouseEnter={this.handleChangeImageCommission} onMouseLeave={this.handleChangeImageCommission} data-test-id="redcommision" onClick={() => this.handleClick('redcommision')} />
              <CustomImage src={this.state.setPayBillImage === true ? redpaybills : whitepaybills} onMouseEnter={this.handlePayBillChangeImage} onMouseLeave={this.handlePayBillChangeImage} data-test-id="PayBillToStore" onClick={() => this.handleClick('PayBillToStore')} />
            </CustomBox>
            {/* <Box style={{ marginTop: '24px' }}>
            </Box> */}
          </Box>
        </RightBoxLanding2>
      </Box>
  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  secondContainer:
  {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "#E2E8F0",
  } as React.CSSProperties,
  mainContainer: {
    display: "flex",
    flexDirection: "column", // This will stack the child elements vertically
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "1173px",
    width: '100%',
    height: "851px",
    backgroundColor: '#fff'
  } as React.CSSProperties,
 
};
// Customizable Area End
