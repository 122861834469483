import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Box, styled } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from "@material-ui/icons/Refresh";
export const RedDashboard = require('./redhome.png')
export const RedBell = require('./redbell.png');
export const RedProfile = require('./redprofile.png');
export const RedSearch = require('./redsearch.png');
export const a2payNew = require('./a2PayWhiteLogo.svg');

export const cut = require('./cut.png');

interface Props {
  anchor: "right";
  style: any;
}

const merchantSupplierAccount = [
  "Proof of Banking (Banking Confirmation Letter)",
  "ID",
  "Proof of Business Address",
  "Business Registration"
]

const drawerWidth = 450; // Set your drawer width
const InnerBox = styled(Box)({
  marginTop: '20px',
  marginLeft: '20px',
  '@media (max-width: 992px)': {
    marginLeft: '10px',
  },
})
const WidthSidebar = styled('div')({
  '@media (min-width: 992px)': {
    display: 'none'
  }
})
const Customimg = styled('img')({
  padding: "15px 20px",
  '@media (min-width: 992px)': {
    display: 'none',
  }
})
const CustomTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 600,
  paddingLeft: "0",
  '@media (max-width: 992px)': {
    fontSize: '12px'
  }
});

const CustomLineDiv = styled(Typography)({

  '@media (max-width: 600px)': {
    height: 1,
    backgroundColor: 'lightgray',
    marginTop: 20
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    height: 1,
    backgroundColor: 'lightgray',
    marginTop: 20
  }
})
const CustomDiv = styled(Box)({
  marginTop: 20,
  '@media (max-width: 600px)': {
    marginTop: 0
  },
})

const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    width: drawerWidth,
    boxSizing: 'border-box'
  },
  fullList: {
    width: '500px',
    '@media (min-width: 450px) and (max-width: 992px)': {
      width: '350px !important'
    },
    '@media (max-width: 450px)': {
      width: '280px !important'
    },
  },
  selected: {
    color: '#C12030', // Red color for selected item
    '&:hover': {
      backgroundColor: '#FCF4F5', // Light pink background during hover
    },
  },
  listItem: {
    padding: '15px',
    '@media (max-width: 992px)': {
      paddingLeft: "6px"
    }
  },
  supplierMenuList: {
    paddingLeft: "15px",
    '@media (max-width: 992px)': {
      paddingLeft: "6px"
    }
  },
  menuListItem: {
    paddingLeft: theme.spacing(2),
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    '&:hover': {
      backgroundColor: '#0000000A'
    },
    '@media (max-width: 992px)': {
      paddingLeft: "6px"
    }
  },
  bullet: {
    display: 'inline-block',
    boxSizing: "border-box",
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: "black",
    '@media (max-width: 992px)': {
      width: '6px',
      height: '6px'
    }
  },
  listItemTextStyle: {
    fontFamily: "Poppins",
    fontSize: "1rem",
    '@media (max-width: 992px)': {
      fontSize: "12px"
    }
  },

  changeBG: {
    display: "flex",
    justifyContent: 'space-between',
    padding: '20px 0',
    '@media (max-width: 992px)': {
      backgroundColor: "black",
      padding: 0,
      marginBottom: 20
    }
  },


  refreshBtn: {
    marginRight: '-20px',
    '@media (max-width: 992px)': {
      color: "white"
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuBtn: {
    '@media (max-width: 992px)': {
      color: "white"
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const SupplierMenu = styled(Box)({
  display: "flex",
  flexDirection: "column"
})


const CommonDrawer: React.FC<Props> = ({ anchor }) => {
  const classes = useStyles();
  let path = window.location.pathname;
  const [state, setState] = useState<{ [key: string]: boolean }>({
    right: false,
  });
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [supplierMenuOpen, setSupplierMenuOpen] = useState(false)
  const [supplierSelectedItem, setSupplierSelectedItem] = useState<string | null>(null);

  const handleCutClick = () => {
    setState({ ...state, [anchor]: false });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleListItemClick = (text: string) => {
    setSelectedItem(text);
    switch (text) {
      case "Cash Float":
        window.location.href = "/MerchantCashFloat";
        break;
      case "Open a Bank Account":
        window.location.href = "/ApplyForBankAccount";
        break;
      case "Transact":
        window.location.href = "/WalletTransact";
        break;
      case `Shopper's Account`:
        window.location.href = "/ShoppersAccount";
        break;
      case "Apply for a Virtual Assistant":
        window.location.href = "/VirtualAssistance";
        break;
      case "Apply for Finance":
        window.location.href = "/ApplyForLoan";
        break;
      case "A2Z Orders":
        window.open('https://shop.a2pay.co.za/test');
        break;
      case "Apply for CIPRO Registration":
        window.open('https://www.cipc.co.za/?page_id=149');
        break;
      case "Require Support":
        window.open('https://a2pay.co.za/contact-us/');
        break;
      case "E-Learning":
        window.open('https://learning.a2pay.co.za/');
        break;
      default:
        break;
    }
    if (text === "Open a Supplier Account") {
      handleSupplierMenuOpen()
    } else {
      setState({ ...state, [anchor]: false });
    }
  };
  const setRole = localStorage.getItem('Role');

  const allItems = [
    'A2Z Orders', 'Transact', 'Cash Float', `Shopper's Account`, 'Apply for Finance',
    'Apply for CIPRO Registration', 'Open a Bank Account', 'Open a Supplier Account',
    'Buy a new Device', 'Apply for a Virtual Assistant', 'Require Support',
    'Pay POS License', 'E-Learning'
  ];

  const operatorItems = [
    'A2Z Orders', 'Cash Float', `Shopper's Account`, 'Open a Bank Account',
    'Apply for Finance', 'Apply for a Virtual Assistant', 'Open a Supplier Account',
    'Require Support', 'E-Learning'
  ];
  const displayItems = setRole === 'Operator' ? operatorItems : allItems;

  const handleSupplierMenuOpen = () => {
    setSupplierMenuOpen((prevState) => !prevState)
  };

  const handleSupplierSub = (value: string) => {
    setSupplierSelectedItem(value);
    handleCutClick();
  };

  const list = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "right",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Box style={{ display: 'flex', justifyContent: 'end', marginRight: '19px', cursor: "pointer" }}>
          <img src={cut} onClick={handleCutClick} alt="Cut" style={{ width: '28px', height: '28px' }} />
        </Box>
        <InnerBox>

          <WidthSidebar>
            <div style={{ color: "#848FAC" }}>
              <Link to="/Dashboard" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center',cursor:"pointer" }}>
                {path == "/Dashboard" ?
                  <img alt="logo" src={RedDashboard} style={{ marginLeft: '6px' }} />
                  : <HomeOutlinedIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/Dashboard" ? "" : "Dashboard"}</span>
              </Link>
            </div>
            <div style={{ color: "#848FAC", marginTop: 20 }}>
              <Link to="/Notifications" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center',cursor:"pointer" }}>
                {path == "/Notifications" ?
                  <img alt="logo" src={RedBell} />
                  : <NotificationsNoneIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/Notifications" ? "" : "Notification"}</span>
              </Link>
            </div>

            <div style={{ color: "#848FAC", marginTop: 20 }}>
              <Link to="/UserProfile" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center',cursor:"pointer" }}>
                {path == "/UserProfile" ?
                  <img alt="logo" src={RedProfile} />
                  : <PersonOutlineIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/UserProfile" ? "" : "Profile"}</span>
              </Link>
            </div>

          </WidthSidebar>
          <CustomLineDiv />

          {displayItems.map((text, index) =>
            text !== "Open a Supplier Account" ? (
              <ListItem
                button
                onClick={() => handleListItemClick(text)}
                className={clsx(classes.listItem, {
                  [classes.selected]: selectedItem === text
                })}
                key={index}
              >
                <CustomTypography variant="h6">
                  {text}
                </CustomTypography>
              </ListItem>
            ) : (
              <SupplierMenu key={index}>
                <ListItem
                  button
                  onClick={() => handleListItemClick(text)}
                  className={clsx(classes.listItem, {
                    [classes.selected]: selectedItem === text
                  })}
                >
                  <CustomTypography variant="h6">
                    {text}
                  </CustomTypography>
                </ListItem>
                {supplierMenuOpen && (
                  <List className={classes.supplierMenuList}>
                    {merchantSupplierAccount.map((value: string, idx: number) => (
                      <ListItem
                        button
                        key={idx}
                        onClick={() => handleSupplierSub(value)}
                        className={clsx(classes.menuListItem, {
                          [classes.selected]: supplierSelectedItem === value
                        })}
                      >
                        <span className={classes.bullet}></span>
                        <Typography className={classes.listItemTextStyle}>{value}</Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              </SupplierMenu>
            )
          )}
        </InnerBox>
      </List>
    </div>
  );

  return (
    <div>
      {["right"].map((drawerAnchor) => (
        <React.Fragment key={drawerAnchor}>
          <div className={classes.changeBG}>
            <div style={{ cursor: "pointer" }}>
              <Link to="/Dashboard">
                <Customimg alt="logo" src={a2payNew} style={{ width: '84px', height: '41px' }} />
              </Link>
            </div>
            <CustomDiv style={{ display: "flex", alignItems: 'center', margin: '0 20px 0 0' }}>
              <Button className={classes.refreshBtn}>
                <RefreshIcon />
              </Button>
              <Button onClick={toggleDrawer(true)} className={classes.menuBtn}>
                <MenuIcon />
              </Button>
            </CustomDiv>
          </div>

          <SwipeableDrawer
            anchor={drawerAnchor as "right"}
            open={state[drawerAnchor]}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            {list()}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommonDrawer;
