import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  // Customizable Area Start
  Select,
  FormControl,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import { configJSON } from "./SignuploginController";
import { background_image, group_icon, a2payImage } from './assets';
import CustomStyleRadio from '../../../components/src/CustomStyleRadio.web';
import { Toaster } from "react-hot-toast"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const getLabelStyle = (value: any) => ({
  fontSize: '16px',
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#273567'
});

const TimeTypography = styled(Typography)({
  '@media (max-width: 600px)': {
    fontSize: "18px !important"
  }
})


const SignUpLogo = styled('img')({
  height:'100%',
  //tablet and small screens
  '@media (min-width: 600px) and (max-width: 960px)': {

    height: '500px ',

    objectFit: 'fill',


    width: '100%'

  },


  //mobile device
  '@media (max-width: 600px)': {
    width:'100%',

    height: '400px ',

    objectFit: 'fill',


  },



})

const SignUpMainImage = styled('img')({
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },

})

const GenderDropSignup = styled(Select)({
  border: "1px solid #273567",
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  '&.Mui-focused': {
    border: "1.5px solid #273567",
    boxShadow: "0 0 0px 2px #3990e7"
  }
})

const GridContainer = styled(Grid)({
  justifyContent: "center",
  position:'fixed',
  display: "flex",
  left:0,
  height: '100%',
  width:'50%',
  '@media (min-width: 600px) and (max-width: 960px)': {

    height: '500px ',


    width: '100%',
    position:'relative'

  },


  //mobile device
  '@media (max-width: 600px)': {

    height: '400px ',
    position:'relative',
    width:'100%'

  },
})
// Customizable Area End

import SignuploginController, {
  Props
} from "./SignuploginController";

export default class SignupNewUser extends SignuploginController {
  constructor(props: Props) {
    super(props);
  }
  getBorderStyle(inputName: string) {
    return {
      // Return the border style based on the inputName and invalidInputs
      ...webStyle.inputStyle,
      borderColor: this.state.invalidInputs.includes(inputName) ? 'red' : '#273567',
    };
  }
  renderInputField(label: string, name: string, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) {
    return (
      <Box sx={webStyle.labelBox}>
        <label style={webStyle.labelStyle as React.CSSProperties}>{label}</label>
        <input
          name={name}
          type="text"
          value={value}
          onChange={onChange}
          style={this.getBorderStyle(name)}
          title={name == "getcoordinates" ? this.state.getcoordinates : "" }
        />
      </Box>
    );
  }

  renderDropdown = (label: string, name: string, value: any, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, options: any, disabled = false) => (
    <Box sx={webStyle.selectStoreBox}>
      <label style={webStyle.labelStyle as React.CSSProperties}>{label}</label>
      <FormControl variant="outlined" required style={webStyle.formControlStyle}>
        <GenderDropSignup
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name={name}
          value={value}
          data-test-id="newUser"
          onChange={this.handleInputChanges}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
          }}
          style={{
            ...webStyle.dropDownStyle,
            borderColor: this.state.invalidInputs.includes(name) ? 'red' : '#273567',
          }}
        >
          {disabled && <MenuItem disabled value={1} style={webStyle.selectStyleSign}>{configJSON.selectStateText}</MenuItem>}
          {options.map((option: any, index: any) => (
            <MenuItem key={index} value={option.value} style={webStyle.selectStyleSign}>
              {option.label}
            </MenuItem>
          ))}
        </GenderDropSignup>
      </FormControl>
    </Box>
  );
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container justifyContent="flex-end" style={{  height: "100vh" }}>
          <GridContainer item
            xs={12}
            sm={12} lg={6}
            md={6}
          >
            <SignUpMainImage src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top:50, left: 242 }} />
            <SignUpLogo src={background_image} style={{ width: '100%' }} />

          </GridContainer>
          <Grid item xs={12}
            sm={12} lg={6}
            md={6}>
            <Box style={{ margin: '10%' }}>
              <Box>
                <TimeTypography style={webStyle.registerMerchanteText as React.CSSProperties}>Register as Merchant</TimeTypography>
                <Typography variant="body1" style={webStyle.subHeader2 as React.CSSProperties}>Enter the fields below to get started</Typography>
              </Box>
              <Box style={{ marginTop: 42 }}>
                <Box sx={webStyle.inputBox}>
                  {this.renderInputField('First Name', 'firstName', this.state.firstName, this.handleInputChanges)}
                  {this.renderInputField('Last Name', 'lastName', this.state.lastName, this.handleInputChanges)}
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  {this.renderInputField('Citizen Status', 'citizenStatus', this.state.citizenStatus, this.handleInputChanges)}
                  {this.renderInputField('Store Name', 'storeName', this.state.storeName, this.handleInputChanges)}
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  {this.renderInputField('ID Number/Passport ID', 'passportIdNumberId', this.state.passportIdNumberId, this.handleInputChanges)}



                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '100%'
                  }}>
                    {this.renderInputField('Store Coordinates', 'getcoordinates', this.state.getcoordinates && this.state.getcoordinates.slice(0, 20) + "....", this.handleInputChanges)}


                    <img src={group_icon} alt="Image" style={webStyle.groupIcon as React.CSSProperties} onClick={this.handleCoordinatesClick} />

                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.labelBox}>
                    <label style={webStyle.labelStyle as React.CSSProperties}>Date of Birth</label>
                    <input 
  name="birthday" 
  type="date" 
  value={this.state.birthday} 
  onChange={this.onNativeChange} 
  max={new Date().toISOString().split('T')[0]}
  style={{
    ...webStyle.inputStyle,
    borderColor: this.state.invalidInputs.includes("birthday")
      ? "red"
      : "#273567",
    padding: "0 8px",
  }} 
/>
                  </Box>


                  <Box sx={webStyle.labelBox}>

                    <label style={webStyle.labelStyle as React.CSSProperties}>Phone Number</label>
                    <input
                      name="phoneNumber"
                      type="text"
                      id="phoneNumber"
                      value={this.state.phoneNumber}
                      onInput={this.handleWhatsappNumberNumber}
                      onChange={this.handlePhoneNumberChange}
                      style={{
                        ...webStyle.inputStyle,
                        borderColor: this.state.invalidInputs.includes('phoneNumber') ? 'red' : '#273567'
                      }}
                    />
                    {this.state.errorPhoneMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>{this.state.errorPhoneMessage}</div>}
                  </Box>

                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  {this.renderInputField('Ethnicity', 'ethinicity', this.state.ethinicity, this.handleInputChanges)}

                  {this.renderDropdown('Gender', 'sex', this.state.sex, this.handleInputChanges, [
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                    { value: 'Others', label: 'Others' },
                  ])}


                </Box>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <Box sx={webStyle.inputBox}>
                  <Box sx={webStyle.selectStoreBox} >
                    <label style={webStyle.labelStyle as React.CSSProperties}>
                      {configJSON.stateTextLabel}
                    </label>
                    <FormControl variant="outlined" required style={webStyle.formControlStyle}>
                      <GenderDropSignup
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="state"

                        onChange={this.handleInputChanges}
                        value={this.state.state}

                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },

                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}

                        style={{
                          ...webStyle.dropDownStyle,
                          borderColor: this.state.invalidInputs.includes('state') ? 'red' : '#273567',
                        }}
                      >

                        <MenuItem disabled={true} value={1} style={webStyle.selectStyleSign}>{configJSON.selectStateText}</MenuItem>
                        {
                          configJSON.statesList.map((value: string, index: number) => (
                            <MenuItem key={index} value={value} style={webStyle.selectStyleSign}>
                              {value}
                            </MenuItem>
                          ))}

                      </GenderDropSignup>
                    </FormControl>
                  </Box>
                  {this.renderInputField('Country', 'country', "South Africa", this.handleInputChanges)}
                </Box>
              </Box>

              <Box sx={webStyle.labelBox}>
                <label style={webStyle.labelStyleTwo}>Address Line 1</label>
                <input
                  name="addressline1"
                  type="text"
                  style={{
                    ...webStyle.inputStyle,
                    borderColor: this.state.invalidInputs.includes('addressline1') ? 'red' : '#273567',
                  }}
                  onChange={this.handleInputChanges}
                  value={this.state.addressline1}
                />
              </Box>
              <Box sx={webStyle.labelStyleTwo}>
                {this.renderInputField('Address Line 2', 'addressline2', this.state.addressline2, this.handleInputChanges)}
              </Box>
              <div style={webStyle.labelStyleTwo}>
                {this.renderInputField('Email Address', 'email', this.state.email, this.handleEmailChange)}
                {this.state.errorMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>{this.state.errorMessage}</div>}
              </div>


              <div style={webStyle.labelStyleTwo}>
                {this.renderInputField('Alternate Phone Number/WhatsApp number', 'alternateNumber', this.state.alternateNumber, this.handleAlternateNumberChange)}
                {this.state.errorAlternatePhoneMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: 'poppins', fontSize: '12px', fontWeight: 400 }}>{this.state.errorAlternatePhoneMessage}</div>}
              </div>

              <Box>
                <Typography style={webStyle.registerdButton as React.CSSProperties}>Compliance Report</Typography>
              </Box>
              <Box sx={webStyle.labelBoxTwo}>
                <RadioGroup aria-label="gender" name="customized-radios" value={this.state.complianceReport.isCheckedYes ? 'yes' : 'no'}
                  onChange={this.handleComplianceReportChange} style={{ display: 'flex', flexDirection: 'row', gap: 80 }}>
                  <FormControlLabel
                    value="yes"
                    control={<CustomStyleRadio />}
                    label={<span style={getLabelStyle('yes')}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<CustomStyleRadio />}
                    label={<span style={getLabelStyle('no')}>No</span>}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography style={webStyle.registerdButton as React.CSSProperties}>Municipal Permit</Typography>
              </Box>
              <Box sx={webStyle.labelBoxTwo}>
                <RadioGroup aria-label="gender" name="customized-radios" value={this.state.municipalPermit.isCheckedYes ? 'yes' : 'no'}
                  onChange={this.handleMunicipalPermitChange} style={{ display: 'flex', flexDirection: 'row', gap: 80 }}>
                  <FormControlLabel
                    value="yes"
                    control={<CustomStyleRadio />}
                    label={<span style={getLabelStyle('yes')}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<CustomStyleRadio />}
                    label={<span style={getLabelStyle('no')}>No</span>}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography style={webStyle.registerdButton as React.CSSProperties}>Registered Business</Typography>
              </Box>
              <Box sx={webStyle.labelBoxTwo}>
                <RadioGroup onChange={this.handleRegisteredBusinessChange} name="customized-radios" value={this.state.registeredBusiness.isCheckedYes ? 'yes' : 'no'}
                  style={{ display: 'flex', flexDirection: 'row', gap: 80 }} aria-label="gender">

                  <FormControlLabel
                    control={<CustomStyleRadio />}
                    value="yes"
                    label={<span style={getLabelStyle('yes')}>Yes</span>}
                  />
                  <FormControlLabel
                    control={<CustomStyleRadio />}
                    value="no"
                    label={<span style={getLabelStyle('no')}>No</span>}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography style={this.state.registeredBusiness.isCheckedYes ? webStyle.vatText : { ...webStyle.vatText, color: 'gray' } as React.CSSProperties}>Then VAT Registration</Typography>
              </Box>
              <Box sx={webStyle.labelBoxTwo}>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row', gap: 80 }} aria-label="gender" name="customized-radios" value={this.state.vatRegistration.isCheckedYes ? 'yes' : 'no'}
                  onChange={this.handleVatRegistrationChange}>
                  <FormControlLabel
                    label={
                      <span style={{ ...webStyle.vatText, ...(this.state.registeredBusiness.isCheckedYes ? {} : { color: 'gray' }) }}>
                        Yes
                      </span>
                    }
                    control={<CustomStyleRadio disabled={!this.state.registeredBusiness.isCheckedYes} />} // Disable if "Registered Business" is not "Yes"

                    value="yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<CustomStyleRadio disabled={!this.state.registeredBusiness.isCheckedYes} />} // Disable if "Registered Business" is not "Yes"
                    label={
                      <span style={{ ...webStyle.vatText, ...(this.state.registeredBusiness.isCheckedYes ? {} : { color: 'gray' }) }}>
                        No
                      </span>
                    }
                  />
                </RadioGroup>
              </Box>
              <Button style={{
                ...webStyle.registerButton,
                width: "100%",
                // backgroundColor: this.state.email && !this.state.errorMessage ? '#C12030' : '#E08F97'
              } as React.CSSProperties} data-test-id="signUpBtn" onClick={this.RegisterUser} >Register</Button>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
                <Typography style={webStyle.alreadyButton as React.CSSProperties}>Already have an account?</Typography>
                <a href="/Login" style={{ ...webStyle.loginButton, textDecoration: 'none' } as React.CSSProperties}>
                  Log in
                </a>

              </Box>
            </Box>
          </Grid>
          <Toaster position="top-center" />
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    border: "none",
    width: "100%",
    marginTop: "40px",
    height: "45px",
    backgroundColor: "rgb(98, 0, 238)"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
    alignItems: "center"
  },
  inputStyle: {
    color: '#273567',
    width: '100%',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  inputStyleStore: {
    color: '#273567',
    width: '100%',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  labelStyle: {
    fontFamily: 'Poppins',
    color: "#273567",
    fontSize: "16px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  labelStyleTwo: {
    color: "#273567",
    fontFamily: 'Poppins',
    lineHeight: " 24px",
    letterSpacing: "0em",
    marginTop: "16px",
    fontSize: "16px",
    fontWeight: 400
  } as React.CSSProperties,
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%"
  },
  inputStyleTwo: {
    color: '#273567',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid #273567',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },

  registerButton: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    fontFamily: 'Poppins',
    marginTop: '16px',
    color: '#fff',
    width: '412px',
    height: '50px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    '@media (min-width: 600px)': {
      width: '2',
    },
  },
  registerMerchanteText: {
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: '600',
    lineHeight: '42px',
    fontSize: '28px'
  },
  subHeader2: {
    fontSize: '14px',
    color: '#848FAC',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    marginBottom: 30,
    fontWeight: '400',
    fontFamily: 'Poppins',
  },
  groupIcon: {
    width: 'auto',
    height: '30px',
    top: '29',
    right: '3px',
    position: 'absolute',
    background: "white",
    cursor: "pointer"
  },
  loginButton: {
    color: '273567',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    fontStyle: 'normal',
    marginLeft: '4',
    cursor: 'pointer'
  },
  alreadyButton: {
    color: '273567',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    fontStyle: 'normal'
  },

  registerdButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#273567',
    marginTop: 16
  },
  labelBoxTwo: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50
  },
  vatText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#273567',
    marginTop: 16
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'space-between',
    // Additional responsive adjustments
    '@media (min-width: 600px)': {
      flexDirection: 'row',
    },
  } as React.CSSProperties,
  groupIconMobile: {
    width: '60%',
    height: '60%',
    position: 'relative',
    right: '5px',
    bottom: '25%',
    transform: 'translateY(-55%)',
    alignSelf: 'flex-end',
    marginBottom: '-100%',
    marginRight: '-15%',
  },
  backgroundImage:
  {
    width: "100%",
    height: '100%'
  },
  selectStoreBox: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%"
  } as React.CSSProperties,
  formControlStyle: {
    borderRadius: "8px",
    background: "white"
  } as React.CSSProperties,
  dropDownStyle: {
    width: '100%',
    height: '40px',
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#273567",
    fontWeight: 500,
    borderRadius: '8px',
    background: "white"
  } as React.CSSProperties,
  selectStyleSign: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,
};
// Customizable Area End
