import React from "react";

// Customizable Area Start
import {
    Box,
    Card,
    styled,
} from "@material-ui/core";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";

import { searchpage } from './assets';
// Customizable Area End

import NotificationsController, {
    Props
} from "./NotificationsController";
import CommonSearchBar from "../../../components/src/commonSearchBar.web";

const SearchCardOprat = styled(Card)({
    width: '90%',
    height: '918px',
    marginTop: "30px",
    marginBottom: "30px",
    alignItems: 'flex-start',
    padding: '20px',
    borderRadius: '12px',

    '@media(max-width: 600px)': {
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '300px',
        height: '938px',
        padding: '20px',
        borderRadius: '12px',
    },

    '@media (min-width: 600px) and (max-width: 992px)': {
        borderRadius: '12px',
        width: '90%',
        maxWidth: '700px',
        height: '938px',
        padding: '20px',
        alignItems: 'flex-start',
    },
})
const RightBoxSearchOprat = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})



export default class OperatorSearch extends NotificationsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Customizable Area Start
            <Box style={webStyle.firstContainerOprat}>
                <OperatorSideBar />
                <RightBoxSearchOprat style={webStyle.ContainerOprat}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ marginLeft: '30px' }}>
                        <SearchCardOprat style={webStyle.cardStyleOprat}>
                            <CommonSearchBar />
                            <Box style={webStyle.boxStyleOPrat}>
                                <img src={searchpage} style={webStyle.imageSearchOprat} />
                            </Box>
                        </SearchCardOprat>
                    </Box>
                </RightBoxSearchOprat>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    firstContainerOprat: {
        backgroundColor: '#fff',
        display: 'flex',
        height: '100vh',
    } as React.CSSProperties,
    boxStyleOPrat: {
        marginTop: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    } as React.CSSProperties,
    cardStyleOprat: {
        // maxWidth: '1189px',
        // width:'100%',
    } as React.CSSProperties,
    ContainerOprat:
        {
            backgroundColor: "#E2E8F0",
            flexGrow: 1,
            alignItems: 'center',
            flexDirection: 'column',
            height: 'fit-content'
        } as React.CSSProperties,
    imageSearchOprat: {
        maxWidth: '550px',
        width: '100%'
    }
};
// Customizable Area End
