import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  TextField,
  Grid,
  Link,
  Modal,
  Fade,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { a2payImage, background_image, CloseIcn } from "./assets";
import { Toaster } from "react-hot-toast"
const LoginPageStyle = styled("div")({
  "& .errorColor": {
    color: "#FF0404"
  },
})
const ModalBoxForgot = styled(Box)({
  width: '100%',
  maxWidth: '600px',
  '@media (max-width: 600px)': {
    height: 'auto',
    width: '70%',
    maxWidth: '400px',
  }
})
const Backimg = styled('img')({
  height:"100%",
  '@media (max-width: 600px)': {
      width:'100%',
      height: '400px ',
      objectFit: 'fill',

  },
  '@media (min-width: 600px) and (max-width: 960px)': {
      height: '500px ',
      width: '100%',
      objectFit: 'fill',
    },
})

const Logoimg = styled('img')({
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  // '@media(min-width:992px) and (max-width: 1280px) ':{
  //   left: 'unset !important',
  //   top: '600px !important',
  // }
})
const ImageContainer = styled(Grid)({
  justifyContent: "center",
  height: '100%',
  display: "flex",
  width:'50%',
  position:'fixed',
  left:0,
  '@media (min-width: 600px) and (max-width: 960px)': {
    width: '100%',
    height: '500px ',
    marginBottom:'20px',
    position:'relative',
  },

  '@media (max-width: 600px)': {
    position:'relative',
    height: '400px ',
    width:'100%',
    marginBottom:'20px',
  },
})
const ModalBox = styled(Box)({
  display:'flex',
  flexDirection:'column',
  backgroundColor: "white",
  padding: "10px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid white',
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: 500,
  outline: "none",
  width: "100%",
  maxWidth: "600px",
  height:'80%',
  overflow:'hidden',
  '@media(max-width: 600px)': {
      width: '80%',
  }
})
const ModalTitle = styled(Box)({
  '@media(max-width: 600px)': {
   fontSize:"20px"
}
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  renderPrivacyModal() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        open={this.state.privacyOpen}
        onClose={this.handleCloseModalPrivacy}
        BackdropProps={{
          style: {
            backdropFilter: "blur(4px)",
            outline: "unset",
            margin: '0 auto',

          },
        }}
      >
        <Fade in={this.state.privacyOpen}>
        <ModalBox>

                 <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <ModalTitle style={{ fontSize: '30px',fontFamily:"Poppins",fontWeight:500 }}>
                 {this.state.setValue ? "Terms of Use" : "Privacy Policy"}
                </ModalTitle>
                <Box style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }} data-test-id="closeModal" onClick={this.handleCloseModalPrivacy} >
                  <img src={CloseIcn} alt="close-icn" />
                </Box>
              </Box>
              <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
            {this.renderModalContent()}
          </ModalBox>
        </Fade>
      </Modal>
    );
  }

  renderModalContent() {
    return (
      <div style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
        height: "100%",
       overflowX:"hidden",
       overflowY:"scroll"
      }}>
        <p style={{ marginBottom: '20px', fontFamily:"Poppins",
                   fontWeight:400,fontSize:"18px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        {this.renderSection("Section 1: General Terms", [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          "Suspendisse euismod, lorem eget bibendum gravida, urna massa cursus lorem, ac tristique eros orci sed leo."
        ])}
        {this.renderSection("Section 2: User Obligations", [
          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        ])}
        {this.renderSection("Section 3: Excepteur sint", [
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        ])}
      </div>
    );
  }
  renderSection(title:any, items:any) {
    return (
      <>
        <h2 style={{
                   fontSize: '16px',
                   fontFamily:"Poppins",
                   fontWeight:500,
                   marginTop: '30px',
                   color: '#666',
                  
                }}>{title}</h2>
        <ul style={{
                  listStyleType: 'disc',
                  marginLeft: '20px'
                }}>
          {items.map((item:any, index:any) => (
            <li key={index} style={{ marginBottom: '10px',fontFamily:"Poppins",
            fontWeight:400, fontSize:"16px"}}>{item}</li>
          ))}
        </ul>
      </>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LoginPageStyle>
          <Grid container justifyContent="flex-end" style={{  height: "100vh" }}>
            <ImageContainer item xs={12}
            sm={12} lg={6}
            md={6}>
              <Logoimg src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top:50, left: 242 }} />
              <Backimg src={background_image} style={{ width: '100%' }} />
           
            </ImageContainer>
            <Grid item xs={12}
                sm={12} lg={6}
                md={6}  spacing={2} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: '550px',
                  padding: 20
                }}
              >
                <Box>
                  <Typography variant="h4" component="h2" style={{
                    fontFamily: "Poppins",
                    fontSize: "28px",
                    fontWeight: "600",
                    letterSpacing: "0em",
                    color: "#000000"
                  }}>
                    {this.labelTitle}
                  </Typography>
                  <Typography variant="subtitle1" component="div" style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    letterSpacing: "0em",
                    color: "#848FAC"
                  }}>
                    {this.state.labelHeader} {/*UI Engine::From Sketch*/}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", paddingTop: "20px" }}>
                  <Box sx={{ padding: "0" }}>
                    <label style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "#273567",
                      marginBottom: '2px',
                      display: 'block',
                    }}>
                      Username (ID/Passport)
                    </label>
                    <input
                      data-test-id="username"
                      type="text"
                      name="username"
                      value={this.state.passportIdNumberId}
                      onChange={this.handleChange}
                      onInput={this.handleWhatsappNumberNumber}
                      required
                      style={{
                        width: "100%", // Full width on all devices
                        border: `1px solid ${this.state.passportIdNumberId.trim() === '' && this.state.loginButtonClicked && !this.state.initialLoad ? 'red' : '#273567'}`,
                        borderRadius: "8px",
                        color: "#273567",
                        height: "50px",
                        boxSizing: "border-box",
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: "4%", // Adjust padding as needed
                        marginBottom: "10px", // Adjust margin as needed,
                      } as React.CSSProperties}
                    />
                    {(this.state.passportError && !this.state.passportErrorInvalid) && (
                      <Typography
                        style={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: "12px",
                          fontFamily: 'Poppins',
                          marginTop: "-0px",  // Adjust the negative marginTop to bring it closer
                        }}
                      >
                        {this.state.errorMessage}
                      </Typography>
                    )}

                    {(this.state.passportErrorInvalid && !this.state.passportError) && (
                      <Typography
                        style={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: "12px",
                          fontFamily: 'Poppins',
                          marginTop: "-0px",  // Adjust the negative marginTop to bring it closer
                        }}
                      >
                        Username (ID/Passport) is not in the correct format.
                      </Typography>
                    )}

                  </Box>


                  <Box>
                    <label style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "#273567",
                      marginBottom: '2px', // Adjust margin-bottom for reduced space
                      display: 'block',  // Ensure the label is displayed as a block element
                    }}>
                      Password
                    </label>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <input
                        data-test-id="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        name="password"
                        style={{
                          width: "100%", // Full width on all devices
                          border: `1px solid ${this.state.password?.trim() === '' ? 'red' : '#273567'}`,
                          borderRadius: "8px",
                          color: "#273567",
                          name: "password",
                          height: "50px",
                          boxSizing: "border-box",
                          padding: "4%", // Adjust padding as needed
                          marginBottom: "10px", // Adjust margin as needed
                          paddingRight: '40px',
                          fontFamily: 'Poppins',
                          fontWeight: 500,
                          fontSize: '14px',
                          borderColor: this.state.conpassvalid.includes('password') ? 'red' : '#273567',
                        } as React.CSSProperties}
                        onChange={this.handleInputChangesTwo}
                        value={this.state.password}
                      />
                      <IconButton
                        onClick={this.handleTogglePassword}
                        edge="end"
                        style={{ position: 'absolute', right: '15px', top: '42%', transform: 'translateY(-50%)' }}
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </div>

                    {((this.state.passwordError || this.state.passwordErrorinvalid) && this.state.password.length === 0) && (
                      <Typography
                        style={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: "12px",
                          fontFamily: 'Poppins',
                          marginTop: "5px",  // Adjust the negative marginTop to bring it closer
                        }}
                      >
                        {this.state.passwordError
                          ? " "
                          : "Password is incorrect."}
                      </Typography>
                    )}

                  </Box>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 16
                  }}>

                    <Box
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        letterSpacing: "0em",
                        color: "#273567",
                      }}
                    >
                      <Checkbox
                        data-test-id={"btnRememberMe"}
                        onChange={(e) => this.setRememberMe(e.target.checked)}
                        style={{
                          padding: 0,
                          color: "#273567",
                          borderRadius: '4px',
                          marginRight: 10,
                        }}
                      />{" "}
                      Remember Me
                    </Box>
                    <Box
                    >
                      <Link href="/ForgotPassword" underline="none">
                        <Typography style={{
                          color: "#848FAC", fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 400
                        } as React.CSSProperties}> Forgot Password ? </Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Box>
                    <Typography style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      letterSpacing: "0em",
                      color: '#000',
                      marginTop: -10,
                      marginLeft: '3px'
                    }}>
                      For Further assistance, Contact us at xxxxxx
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginBottom: 35,
                      color: this.state.agreeToTerms ? "#273567" : (this.state.loginButtonClicked && !this.state.initialLoad) ? "red" : "inherit", // Set color based on conditions// Change color based on checkbox state
                    }}>
                      <Checkbox
                        onChange={(e) => this.setAgreeTerms(e.target.checked)}
                        checked={this.state.agreeToTerms}
                        data-test-id={"checkboxAgreeToTerms"}
                        style={{
                          color: this.state.agreeToTerms ? "#273567" : (this.state.loginButtonClicked && !this.state.initialLoad) ? "red" : "inherit",
                          borderRadius: '4px',
                          padding: 0,
                          marginRight: 10,
                          marginTop: '10px',
                        }}
                      />
                      <Typography style={{
                        fontFamily: "Poppins",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        letterSpacing: "0em",
                        textAlign: "left",
                        borderRadius: "8px",
                        marginTop: '10px',
                        color: (this.state.loginButtonClicked && !this.state.agreeToTerms && !this.state.initialLoad) ? "red" : "#273567", // Set color based on conditions
                      }}>
                        <span>
                          I hereby consent to the <span data-test-id="privacy" onClick={()=>this.handlePrivacy("privacy")} style={{
                            color: (this.state.agreeToTerms || !this.state.loginButtonClicked || this.state.initialLoad) ? "#0000EE" : "red", // Set color based on conditions
                            textDecoration: 'underline', cursor: "pointer"

                          }}>Privacy Policy </span> and <span data-test-id="terms" onClick={()=>this.handlePrivacy("terms")} style={{
                            color: (this.state.agreeToTerms || !this.state.loginButtonClicked || this.state.initialLoad) ? "#0000EE" : "red", // Set color based on conditions
                            textDecoration: 'underline', cursor: "pointer"

                          }}> Terms of Use.</span>
                        </span>
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#C12030",
                      fontSize: '16px',
                      fontWeight: 400,
                      color: "#FFFFFF",
                      textTransform: 'none',
                      height: '50px',
                      borderRadius: '8px',
                      fontFamily: 'Poppins'
                    }}
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Log in
                  </Button>
                  <Typography style={{ color: "#848FAC", fontSize: '14px', fontFamily: 'Poppins', fontWeight: 400, marginTop: 22 }}>
                    Don’t have any account? <a href="/SignIn" style={{ color: "#273567", fontWeight: 600, fontFamily: 'Poppins' }}>Register</a>
                  </Typography>
                  <Button
                    data-test-id={"btnOAuthLogIn"}
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#0084f9",
                      fontSize: '16px',
                      fontWeight: 400,
                      color: "#FFFFFF",
                      textTransform: 'none',
                      height: '50px',
                      borderRadius: '8px',
                      fontFamily: 'Poppins',
                      marginTop: '20px'
                    }}
                    type="submit"
                    onClick={this.handleSubmitOAuth2}
                  >
                    Log in with OAuth
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Toaster position="top-center" />
          </Grid>
        </LoginPageStyle>
       {this.renderPrivacyModal()}
       <Backdrop
        open={this.state.isAuthenticating}
        style={{
          zIndex:10,
          color:"#fff"
        }}
      >
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',fontSize:'20px'}}>
        <CircularProgress color="inherit" />
        <p>Authenticating...</p>
        </div>
      </Backdrop>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
