import React from "react";

import {
    Box, Typography, styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import UserProfileBasicBlockWeb from "./UserProfileBasicBlockWeb.web";
import UserProfileDocumentWeb from "./UserProfileDocumentWeb.web";
import Header from "../../../components/src/Header.web"
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";

const TimeTypographyUser = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})
const RightBoxLandingUser = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const CustomBox = styled(Box)({
    '@media(max-width:600px)': {
        justifyContent: 'center'
    }
})

export default class UserSettingCommon extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            // Customizable Area Start
            <>
                <Box style={{ display: "flex" }}>
                    <SideNavbar />
                    <RightBoxLandingUser style={webStyle.secondContainer}>
                        <Box style={{ width: '100%' }}>
                            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                        </Box>
                        <Box style={{ width: "100%", backgroundColor: "#E2E8F0" }}>
                            <Header />
                            <Box style={webStyle.userSettingDocContainer as React.CSSProperties}>
                                <CustomBox style={webStyle.settingTabMainDoc as React.CSSProperties}>
                                    <Box
                                        style={{width:"100%",minWidth:"200px",...this.state.settinTab === 'UserInformation' ? (webStyle.activesettingTab2Doc as React.CSSProperties) : (webStyle.settingTab2Doc as React.CSSProperties)}}
                                        onClick={this.handleSettingTabs.bind(this, 'UserInformation')}
                                    >

                                        <TimeTypographyUser style={this.state.settinTab === 'UserInformation' ? (webStyle.activetabTextDoc as React.CSSProperties) : (webStyle.tabTextDoc as React.CSSProperties)}>
                                            User Information
                                        </TimeTypographyUser>

                                    </Box>
                                    <Box
                                        style={this.state.settinTab === 'Documents' ? (webStyle.activesettingTab2Doc as React.CSSProperties) : (webStyle.settingTab2Doc as React.CSSProperties)}
                                        onClick={this.handleSettingTabs.bind(this, 'Documents')}
                                    >

                                        <TimeTypographyUser style={this.state.settinTab === 'Documents' ? (webStyle.activetabTextDoc as React.CSSProperties) : (webStyle.tabTextDoc as React.CSSProperties)}>
                                            Documents
                                        </TimeTypographyUser>

                                    </Box>
                                </CustomBox>
                            </Box>

                            {this.state.settinTab === "UserInformation" && (

                                <UserProfileBasicBlockWeb navigation="" id="" />
                            )}
                            {this.state.settinTab === "Documents" && <UserProfileDocumentWeb navigation="" id="" />}
                        </Box>
                    </RightBoxLandingUser>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    userSettingDocContainer: {
        backgroundColor: "#E2E8F0",
    },
    settingTabMainDoc: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "20px 40px",
        flexWrap: "wrap",

    },
    activesettingTab2Doc: {
        backgroundColor: 'rgba(193, 32, 48, 0.03)',
        padding: '15px',
        borderRadius: '16px',
        width: '100%',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #C12030',
    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
    activetabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    settingTab2Doc: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },
    secondContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#E2E8F0"
    } as React.CSSProperties,
}
// Customizable Area End