import React from "react";

import {
  Box,
  Typography,
  styled,
  Button
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { leftArrow, bagIcon } from './assets';

import { Link } from 'react-router-dom';
// Customizable Area Start


const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const CustomTypography = styled(Typography)({
  paddingLeft: '54px',
  '@media(max-width: 600px)': {
    paddingLeft: '20px'
  }
})
const CustomBox = styled(Box)({
  backgroundColor: '#ffffff',
  maxWidth: "757px",
  height: "542px",
  paddingTop: "30px",
  borderRadius: '12px',
  marginTop: '25px',
  marginLeft: '54px',
  '@media(max-width: 600px)': {
    margin: '10px',
    height: "500px",
  }
})
const CustomButton = styled(Button)({
  backgroundColor: "#C12030",
  fontSize: "16px",
  marginTop: 100,
  color: "#fff",
  width: "100%", // Full width on all devices
  height: "50px", // Adjust height as needed
  borderRadius: "8px",
  textTransform: "capitalize",
  fontFamily: 'Poppins',
  '@media(max-width: 600px)': {
    marginTop: 30,
  }
})
const WalletTop = styled(Box)({
  '@media(max-width: 990px)': {
      marginTop: "0 !important",
      display:"none"
    }
})

const WalletText = styled(Typography)({
  '@media(max-width: 900px)': {
      fontSize: "20px !important"
    }
})
const HeaderBox = styled(Box)({
  '@media(max-width: 990px)': {
    display:"unset !important"
  }
})
const WalletTopForMobile = styled(Box)({
  display:"none",
  '@media(max-width: 990px)': {
    display:"block"
  }
})
// Customizable Area End

import Cfwallet53Controller, {
  Props,
  configJSON,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class BuxPaymentFirstPages extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
   
    let selectStore = localStorage.getItem('selectStore');
    let amount = localStorage.getItem('amount');
    this.state = {
      ...this.state, 
      selectedStore: selectStore,
      amount: amount
    };

    // Customizable Area End
  }



  render() {
    return (
      // Customizable Area Start

      <Box>
        <SideNavbar />
        <RightBoxLanding2 style={webStyle.secondContainer}>
        <HeaderBox style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>BUX</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </HeaderBox>
          <WalletTopForMobile style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>BUX</WalletText>
            </WalletTopForMobile>
          <CustomBox>
              <Box style={{ display: 'flex', marginLeft: 20 }} >
                <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} data-test-id="backArrowbux" onClick={() => this.handleGoBackBux()}/>
                <Typography style={webStyle.backIcon} data-test-id="backArrowbuxsecond" onClick={() => this.handleGoBackBux()}>Back</Typography>
              </Box>
            <Box style={webStyle.cardStyle}>
              <Typography style={webStyle.textOne as React.CSSProperties}>Kahndal Saree Store</Typography>
              <Typography style={webStyle.subText as React.CSSProperties}>Adding R{this.state.amount} to Kahndal Saree Store's Wallet</Typography>
              <Typography style={webStyle.amountStyle as React.CSSProperties}>R {this.state.amount}</Typography>
              <Link to="/BuxPaymentSecondPages">
                <CustomButton style={webStyle.payButton as React.CSSProperties}>
                  Pay
                </CustomButton>
              </Link>
            </Box>
          </CustomBox>

        </RightBoxLanding2>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  secondContainer:
    {
      backgroundColor: "#E2E8F0",
      minHeight: '100vh',
      marginBottom:'30'
    } as React.CSSProperties,

  BoxContainer: {
    borderRadius: '12px',
    border: '1px solid #848FAC',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    maxWidth: '283px',
    padding: '16px',
    width: '100%',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  } as React.CSSProperties,
  WalletTopText:
  {
    marginTop: '8px',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    display:'block',
    justifyContent:'center'
  },
  chooseBank: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    justifyContent: 'center'
  },
  backIcon: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    paddingLeft: 8,
    cursor: 'pointer'
  },

  cardStyle: {
    maxWidth: "630px",
    height: "378px",
    top: "282px",
    borderRadius: '12px',
    marginBottom: '20px',
    marginTop: '25px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '45px',
    marginRight: '30px',
    paddingTop: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
  },
  textOne: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    textAlign: 'center',
    marginTop: 15
  },
  subText: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center'
  },
  amountStyle: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    marginTop: 100,
    textAlign: 'center'
  },
  payButton: {
    backgroundColor: "#C12030",
  },
  HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
