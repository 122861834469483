import React from "react";
import {
    Box,
    FormControlLabel,
    RadioGroup,
    Typography,
    Button,Modal, styled, Select, MenuItem
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import {capture_icon,upload_icn} from "./assets"
import CustomStyledRadio from "../../../components/src/CustomStyleRadio.web";
import toast, {Toaster} from "react-hot-toast"

const getLabelStyle = ({
  fontSize: '16px',
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#273567'
});
const StyledSelect = styled(Select)({
  '&>.MuiSelect-select:focus':{
  backgroundColor:'transparent',
  },
  '&>#simple-select-outline':{
    display:'flex',
    paddingLeft:'15px',
    justifyContent:'center',
    height:"100%",
    color:'#273567',
    flexDirection:'column',
    fontSize:'14px',
    fontWeight:500,
  }
})

export default class UserProfileBasicBlockWeb extends UserProfileBasicController {
  constructor(props: Props) {
      super(props);
  }
  // Customizable Area Start
  componentDidMount = async () => {
    this.handleGetUserInformation()
    this.handleGetUserInfo()
   }
  generateInputStyle(field:string) {
    const borderColor = '#273567';
  
    return {
      ...webStyle.inputStyle,
      borderColor: borderColor,
    };
  }
  renderErrorMessage(condition: boolean, errorMsg: string,type:string) {
    return condition && <div className={`errorMsg-${type}`} style={webStyle.errorMessage}>{errorMsg}</div>
  }
  getValueYesOrNo(condition:boolean){
    return condition?'yes':'no'
  }
  commonFields = () => (
    <>
    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Name & Surname</label>
                  <input
                    name="surName"
                    type="text"
                    style={this.generateInputStyle('surName')}
                    onChange={this.handleInputChanges}
                    value={this.state.surName}
                  />
              </Box>
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>ID/Passport</label>
                  <input
                    name="passport"
                    type="text"
                    style={this.generateInputStyle('passport')}
                    onChange={this.handleInputChanges}
                    maxLength={13}
                    value={this.state.passport}
                  />
                   {this.renderErrorMessage(!!this.state.passportError,this.state.passportError,'passport')}
              </Box> 
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Email Address</label>
                  <input
                  name="email"
                  type="text"
                  style={this.generateInputStyle('email')}
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                  />
                  {this.renderErrorMessage(!!this.state.errorMessage,this.state.errorMessage,'email')}
              </Box>
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Next of Kin</label>
                  <input
                    name="nextOfKin"
                    type="text"
                    style={this.generateInputStyle("nextOfKin")}
                    onChange={this.handleInputChanges}
                    value={this.state.nextOfKin}
                    maxLength={50}
                  />
                  {this.renderErrorMessage(!!this.state.nextOfKinError,this.state.nextOfKinError,'nextOfKin')}
              </Box> 
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Home Address</label>
                  <input
                    name="homeAddress"
                    type="text"
                    style={this.generateInputStyle('homeAddress')}
                    onChange={this.handleInputChanges}
                    value={this.state.homeAddress}
                  />
              </Box> 
    </>
  )
  merchantFields = ()=> (
    <>
      <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Contact Details</label>
                  <input
                    name="phoneNumber"
                    data-test-id="phoneNumber"
                    type="text"
                    style={this.generateInputStyle('phoneNumber')}
                    onChange={this.handlePhoneNumberChangeUserProfile}
                    value={this.state.phoneNumber}
                  />
                  {this.renderErrorMessage(!!this.state.errorPhoneMessage, "Invalid Phone Number",'phoneNumber')}
              </Box>           
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                <label style={webStyle.labelStyle as React.CSSProperties}>Copy of ID Image</label>
                <div id="idImage">
                  <div
                    style={{
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                  >
                    <label htmlFor="idImageFile" style={{ display: "block" }}>
                      <div
                        style={{
                          ...webStyle.inputStyleFile,
                          borderColor:
                            (this.state.showValidationMessage &&
                              !this.state.idImage) ||
                              (this.state.showValidationMessage &&
                                this.state.fileInputTouched)
                              ? "red"
                              : "", // Set border color to red when no file is uploaded and showValidationMessage is true
                          position: "relative", // Make the label a block-level element
                        }}
                      >
                        <div
                          id="idImageFileName"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            marginLeft: '5px',
                            color: '#273567',
                          }}>
                            {this.state.idImage ? this.state.idImage : 'Upload Document'}
                          </span>
                        </div>
                        {/*  Custom browse button  */}
                        <input
                          type="file"
                          id="idImageFile"
                          data-test-id="idImage"
                          name={'idImage'}
                          style={{
                            width: "100%",
                            top: 0,
                            position: "absolute",
                            height: "100%",
                            cursor: "pointer",
                            opacity: 0,
                            left: 0,
                          }}
                          // accept=".jpg, .pdf , .png"
                          onChange={this.handleUploadIdImage}
                          onBlur={this.handleFileBlur}
                        />
                      </div>
                    </label>
                  </div>
                  {this.state.showValidationMessage &&
                    !this.state.idImage && (
                      // Validation message if showValidationMessage is true and no file is  uploaded
                      <div id="errorMessageFileUpload" style={webStyle.validationMessageFile}>
                        Please upload in correct format (.PDF / .JPG / .PNG){" "}
                      </div>
                    )}
                </div>
              </Box>    
              <Box style={webStyle.fieldsMain as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Merchant Agreement</label>
                  <input
                    name="merchantAgreement"
                    type="text"
                    style={this.generateInputStyle("merchantAgreement")}
                    onChange={this.handleInputChanges}
                    maxLength={250}
                    value={this.state.merchantAgreement}
                  />
                  {this.renderErrorMessage(!!this.state.merchantAgreement,this.state.merchantAgreementError,'merchantAgreement')}
              </Box>
               <Box sx={webStyle.cordinateBox as React.CSSProperties}>
                  <label style={webStyle.labelStyle as React.CSSProperties}>Change/ Update Coordinates </label>
                  <input
                    type="text"
                    name="updateCoordinates"
                    style={{...this.generateInputStyle("updateCoordinates"),paddingRight:'50'}}
                    onChange={this.handleInputChanges}
                    value={this.state.updateCoordinates}
                    title={this.state.updateCoordinates}
                    readOnly
                  />
                  <img src={capture_icon} alt="Image" style={webStyle.captureIcon as React.CSSProperties} onClick={this.handleCoordinatesClick}/>
                </Box>
                <Box>
              <Typography style={webStyle.checkboxText as React.CSSProperties}>Compliance Report</Typography>
            </Box>
            <Box sx={webStyle.radioMain}>
              <RadioGroup aria-label="gender" name="customized-radios" value={this.getValueYesOrNo(this.state.complianceReport.isCheckedYes)}
                 style={{ display: 'flex', flexDirection: 'row', gap: 80,fontFamily:"Poppins", fontWeight:400 }}
                 onChange={this.handleComplianceReportsChange}>
                <FormControlLabel
                  value="yes"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>No</span>}
                />
              </RadioGroup>
            </Box>
            <Box>
              <Typography style={webStyle.checkboxText as React.CSSProperties}>
                  Municipal Permit
              </Typography>
            </Box>
            <Box sx={webStyle.radioMain}>
              <RadioGroup aria-label="gender" name="customized-radios" value={this.getValueYesOrNo(this.state.municipalPermit.isCheckedYes )}
                 style={{ display: 'flex', flexDirection: 'row', gap: 80, fontFamily:"Poppins", fontWeight:400}}
                 onChange={this.handleMunicipalPermitChanges}>
                <FormControlLabel
                  value="yes"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>No</span>}
                />
              </RadioGroup>
            </Box>
            <Box>
              <Typography style={webStyle.checkboxText as React.CSSProperties}>
                  Registered Business
              </Typography>
            </Box>
            <Box sx={webStyle.radioMain}>
              <RadioGroup aria-label="gender" name="customized-radios" value={ this.getValueYesOrNo(this.state.registeredBusiness.isCheckedYes)}
                 style={{ display: 'flex', flexDirection: 'row', gap: 80, fontFamily:"Poppins", fontWeight:400 }}
                 onChange={this.handleRegisteredBusinessChange2}>
                <FormControlLabel
                  value="yes"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>Yes</span>}
                />
                <FormControlLabel
                  value="no"
                  control={<CustomStyledRadio />}
                  label={<span style={getLabelStyle}>No</span>}
                />
              </RadioGroup>
            </Box>
            <Box>
              <Typography style={webStyle.checkboxText as React.CSSProperties}>
                Then VAT Registration
              </Typography>
            </Box>
            <Box sx={webStyle.radioMain}>
              <RadioGroup aria-label="gender" name="customized-radios" value={this.getValueYesOrNo(this.state.vatRegistration.isCheckedYes )}
                 style={{ display: 'flex', flexDirection: 'row', gap: 80,fontFamily:"Poppins", fontWeight:400 }}
                 onChange={this.handleThenVatRegistrationChange}>
                <FormControlLabel
                  value="yes"
                  control={<CustomStyledRadio disabled={!this.state.registeredBusiness.isCheckedYes } />}
                  label={<span style={getLabelStyle}>Yes</span>}
                  style={{fontFamily: 'Poppins !important'}}
                />
                <FormControlLabel
                  value="no"
                  control={<CustomStyledRadio disabled={!this.state.registeredBusiness.isCheckedYes } />}
                  label={<span style={getLabelStyle}>No</span>}
                />
              </RadioGroup>
            </Box>
    </>
  )
  operatorFields = () => (
    <>
    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Citizen Status</label>
                        <input
                            data-test-id="citizenStatus"
                            name="citizenStatus"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.citizenStatus}
                            onChange={this.handleInputChanges}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={{...webStyle.labelStyle as React.CSSProperties,color:"#27356780"}}>Store Name(Non Editable)</label>
                        <input
                            name="storeName"
                            type="text"
                            style={{...webStyle.inputStyle,color:"rgba(39, 53, 103, 0.5)", borderColor:"#27356780"}}
                            value="Khandala Store"
                            disabled
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={{...webStyle.labelStyle as React.CSSProperties,color:"#27356780"}}>Coordinates(Non Editable)</label>
                        <input
                            name="coordinates"
                            type="text"
                            style={{...webStyle.inputStyle,color:"rgba(39, 53, 103, 0.5)",borderColor:"#27356780"}}
                            value="1234"
                            disabled
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Phone Number</label>
                        <input
                            data-test-id='phoneNumber'
                            name="phoneNumber"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                            value={this.state.phoneNumber}
                            onChange={this.handlePhoneNumberChangeUserProfile}
                        />
                        {this.state.errorPhoneMessage && <div style={{ color: 'red', marginTop: '5px',fontFamily:'poppins',fontSize: '12px',fontWeight:400 }}>{this.state.errorPhoneMessage}</div>}
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Ethnicity</label>
                        <input
                            data-test-id='ethnicity'
                            name="ethnicity"
                            type="text"
                            style={{
                                ...webStyle.inputStyle,
                                borderColor:'#273567',
                              }}
                              onChange={this.handleInputChanges}
                            value={this.state.ethnicity}
                        />
                    </Box>
                    <Box style={webStyle.fieldsMain as React.CSSProperties}>
                        <label style={webStyle.labelStyle as React.CSSProperties}>Gender</label>
                        <StyledSelect
                           data-test-id='gender'
                           name="gender"
                           value={this.state.gender}
                            id="simple-select-outline"
                            onChange={this.handleInputChanges}
                            style={{
                                ...webStyle.inputStyle,
                                top: 0,
                                padding: 0,
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                transformOrigin: { vertical: "top", horizontal: "left" },
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            }}
                            disableUnderline
                        >
                            <MenuItem value={'male'} style={webStyle.menuItem}>Male</MenuItem>
                            <MenuItem value={'female'} style={webStyle.menuItem}>Female</MenuItem>
                            <MenuItem value={'other'} style={webStyle.menuItem}>Other</MenuItem>
                        </StyledSelect>
                    </Box>
    </>
  )
  // Customizable Area End
render() {
  return (
      // Customizable Area Start
     
   
      <Box style={webStyle.userSettingContainer as React.CSSProperties}>
          <Box style={webStyle.userSettingFeildsMian as React.CSSProperties}>

          <Box style={webStyle.userSettingTextMain as React.CSSProperties}>

              <Typography style={webStyle.userSettingText as React.CSSProperties}>
                User Settings
                </Typography>
          </Box>  
            {this.commonFields()}
            {localStorage.getItem("Role") == "Merchant" && this.merchantFields()}
            {localStorage.getItem("Role") == "Operator" && this.operatorFields()}
            <Button 
            onClick={localStorage.getItem("Role") == "Merchant" ? this.handleSubmit : this.handleOperatorSubmit}
            data-test-id="btnSubmit"
            style={webStyle.submitButton as React.CSSProperties}
            >
              Submit
            </Button>
          </Box>
          <Toaster position="top-center"/>
          <Modal
                    open={this.state.showUploadModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{
                      color: "white",
                      backgroundColor: "black",
                      position: 'absolute',
                      padding: "10px",
                      left: '50%',
                      top: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: '2px solid #000',
                        width: 300,
                        borderRadius: "12px",
                        textAlign: 'center',
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "24px",
                    }}>
                        Uploading.....
                    </Box>
                </Modal>
      </Box>
      
      
      // Customizable Area End
  );
}
}

// Customizable Area Start
const webStyle = {
    userSettingContainer: {
        backgroundColor: "#E2E8F0",
    },
    userSettingFeildsMian:{
        backgroundColor:"#FFFFFF",
        maxWidth:"610px",
        borderRadius:"4px",
        padding:"20px",
        margin:"40px"
    },
    userSettingTextMain:{
        marginBottom:"40px"
    },
    userSettingText:{
        fontFamily: 'Poppins',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '40px',
        fontSize: '20px'
    },
    // div styling
    div : {
      display : 'block'

    },
    fieldsMain:{
        display:"flex",
        flexDirection:"column",
        maxWidth : '100%',
        width:"100%",


        margin:"20px 0",
    },
    // label styling
    labelStyle:{
        fontFamily: 'Poppins',
        color: "#273567",


        fontSize: "16px",
        
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",

    },
// input styling
    inputStyle:{
        color: '#273567',
        width: '100%',

        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: "15px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },

    // coordinate-box styling
    cordinateBox:{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
    captureIcon:{
        cursor:'pointer',
        width: 'auto', 
        height: '30px',
        right: '10',
        top: '30',
        position: 'absolute',
        backgroundColor:'#fff',
        zIndex:10
    },
    settingTabMain:{
        display:"flex",
        alignItems:"center",
        flexWrap:"wrap",
        gap:"12px",
        padding:"20px 40px",
        
    },
    activesettingTab:{
        backgroundColor:"rgba(132, 143, 172, 0.1)",
        padding:"15px",
        borderRadius:"16px",
        width:"100%",
        maxWidth:"200px",
        textAlign:"center",
        cursor:"pointer"
        
    },
    activesettingTab2:{
        backgroundColor:"rgba(193, 32, 48, 0.03)",
        padding:"15px",
        borderRadius:"16px",
        width:"100%",
        maxWidth:"200px",
        textAlign:"center",
        cursor:"pointer",
        borderBottom:"2px solid #C12030"
    },
    settingTab:{
        backgroundColor:"rgba(132, 143, 172, 0.1)",
        padding:"15px",
        borderRadius:"16px",
        width:"100%",
        maxWidth:"200px",
        textAlign:"center",
        cursor:"pointer"
    },
    settingTab2:{
        backgroundColor:"rgba(132, 143, 172, 0.1)",
        padding:"15px",
        borderRadius:"16px",
        width:"100%",
        maxWidth:"200px",
        textAlign:"center",
        cursor:"pointer",
        borderBottom:"2px solid #848FAC"
    },
    activetabText:{
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color:"#C12030",
    },
    spani:{},
    tabText:{
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color:"#848FAC",
    },
    checkboxText:{
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
        marginTop: 16
    },
    radioMain:{
        display: 'flex',
        flexDirection: 'row',
        gap: 50,
        margin:"10px 0",
        fontFamily: 'Poppins',
    },
    submitButton:{
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width:"100%",
        fontFamily: 'Poppins',
    },
    validationMessageFile: {
      color: "red",
      fontSize: "12px",
      marginTop: "5px",
      fontWeight: 400,
      fontFamily: "Poppins",
    } ,
    inputStyleFile: {
      width: "100%",
      height: "40px",
      borderRadius: "8px",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      padding: "8px 40px 8px 8px", // Adjust padding for text and image
      backgroundImage: `url(${upload_icn})`, // Set the image as the background
      backgroundSize: "24px", // Adjust the size of the image
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 8px center", // Adjust the position of the image
    }as React.CSSProperties,
    snackbarStyle:{
      position: 'fixed',
      bottom: '16px',
      left: '50%',
      transform: 'translateX(-50%)',
      minWidth: '300px',
      padding: '16px',
      backgroundColor: '#333',
      color: '#fff',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
  },
  errorMessage: { color: 'red', marginTop: '5px', fontSize: '12px', fontWeight: 400, fontFamily: "Poppins" } as React.CSSProperties,
  menuItem: {
    fontSize: '14px',
    color: '#273567',
    fontFamily: "Poppins",
    fontWeight: 500,
  },
}
// Customizable Area End