import React from "react";

import {
  Box,
  Card,
  CardContent,
  Typography,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import OperatorHeader from "../../../components/src/OperatorHeader.web";
import { storeicon, arrow_back } from "./assets";
import CommonHeaderOperator from "../../../components/src/CommonHeaderOperator.web";
const RightBoxLanding2 = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});
// Customizable Area End

import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";


const CustomBox = styled(Box)({
  backgroundColor: '#ffffff',
  maxWidth: "592px",
  width: '100%',
  height: "592px",
  // paddingTop: "30px",
  borderRadius: '12px',
  marginTop: '25px',
  marginLeft: '15px',
  '@media(max-width: 600px)': {
    width: '90%',
    margin: '20px auto'
  }
})
export default class BillingSettingsOperator extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { showBillingSettingForm } = this.state;
    let data3 = [
      {
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        CommissionsOpe: "Over all Commissions",
        ImageOpe: storeicon
      },
      {
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        CommissionsOpe: "Over all Commissions",
        ImageOpe: storeicon
      }, {
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefValueOpe: "A45DF3",
        CommissionsOpe: "Over all Commissions",
        CommiValueOpe: "3456",
        RefrenceOpe: "Reference Number",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon
      }, {
        CommissionsOpe: "Over all Commissions",
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon
      }, {
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        CommissionsOpe: "Over all Commissions",
        CommiValueOpe: "3456",
        ImageOpe: storeicon
      }, {
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        CommissionsOpe: "Over all Commissions",
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon
      },
      {
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon,
        CommissionsOpe: "Over all Commissions",

      }, {
        StoreTItleOpe: "Khandal saree store, 403 ",
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon,
        CommissionsOpe: "Over all Commissions",
        CommiValueOpe: "3456",

      },
      {
        StoreTItleOpe: "Khandal saree store, 403 ",
        CommiValueOpe: "3456",
        StatusOpe: "Loan status",
        StatusNameOpe: "Approve",
        ImageOpe: storeicon,
        RefrenceOpe: "Reference Number",
        RefValueOpe: "A45DF3",
        CommissionsOpe: "Over all Commissions",
      },
    ]
    return (
      // Customizable Area Start
      <>
        <Box style={{ backgroundColor: "#F6F7FA" }}>
          <OperatorSideBar />
          <RightBoxLanding2 style={webStyleOperat.secondContainerOperat}>
            <Box style={{ width: "100%" }}>
              <CommonHeaderOperator anchor={"right"} style={{ width: "100%" }} />
            </Box>
            <Box style={{ width: "100%" }}>
              <OperatorHeader />
              {!showBillingSettingForm ?
                <Box style={{ backgroundColor: "#E2E8F0" }}>
                  <Box style={webStyleOperat.mainBoxStyleOperat}>

                    {data3.map((item, index) => (
                      <Card key={index} style={webStyleOperat.cardSmallStyleOperat} onClick={() => this.handleCardClick(item.CommiValueOpe)} data-test-id={`billing-card${index}`}>

                        <CardContent>
                          <Box sx={{ marginBottom: 2 }}>
                            <img src={item.ImageOpe} alt="Store" style={webStyleOperat.srcImageOperat} />
                          </Box>
                          <Typography style={webStyleOperat.productTitleOperat}>{item.StoreTItleOpe}</Typography>
                          <Box style={webStyleOperat.cardTexts2Operat}>
                            <Typography style={webStyleOperat.productDescriptionOperat}>{item.RefrenceOpe}</Typography>
                            <Typography style={webStyleOperat.referencenoOperat}>{item.RefValueOpe}</Typography>
                          </Box>
                          <Box style={webStyleOperat.cardTexts2Operat}>
                            <Typography style={webStyleOperat.productDescriptionOperat}>{item.CommissionsOpe}</Typography>
                            <Typography style={webStyleOperat.productDescriptionOperat}>{item.CommiValueOpe}</Typography>
                          </Box>
                          <Box style={webStyleOperat.cardTexts2Operat}>
                            <Typography style={webStyleOperat.productDescriptionOperat}>{item.StatusOpe}</Typography>
                            <Typography style={webStyleOperat.approveTextOperat}>{item.StatusNameOpe}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}

                  </Box>
                </Box>
                :
                <Box style={webStyleOperat.secondPageDivOperat}>
                  <Box style={webStyleOperat.backAroowBoxOperat}>
                    <img
                      src={arrow_back}
                      style={webStyleOperat.backArrowImageOperat} onClick={() => this.handleBillingCyclegoBak()}
                      data-test-id={"backArrowImage"}
                    />
                    <Typography
                      style={webStyleOperat.backTextOperat}
                      onClick={() => this.handleBillingCyclegoBak()}
                      data-test-id={"backArrowText"}
                    >
                      Back
                    </Typography>
                  </Box>
                  <CustomBox>
                    <Box style={webStyleOperat.mainBoxOperat} >
                      <Box style={webStyleOperat.smallBoxOperat}>
                        <Typography style={webStyleOperat.billingOperat}>
                          Billing Settings
                        </Typography>
                        <Typography style={webStyleOperat.bankDetailsTextOperat}>
                          Bank Details
                        </Typography>
                        <Box sx={{ marginTop: "15px" }}>
                          <label style={webStyleOperat.headerTextOperat}>
                            Account Name
                          </label>
                          <Typography style={webStyleOperat.inputBoxStyleOperat} >
                            123456
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          <label style={webStyleOperat.headerTextOperat}>
                            Account Number
                          </label>
                          <Typography style={webStyleOperat.inputBoxStyleOperat} >
                            123456
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          <label style={webStyleOperat.headerTextOperat}>
                            License Subscription
                          </label>
                          <Box style={webStyleOperat.inputBoxWebStyleOperat}>
                            <Typography
                              style={
                                {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  color: "#273567",
                                  fontWeight: 500,
                                  border: 0,
                                  alignSelf: "center",
                                } as React.CSSProperties
                              }
                            >
                              {this.state.licenseSubscription}
                            </Typography>
                            <Typography style={webStyleOperat.expireTextOperat}>
                              Expiry Date- 31/12/20023
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: "20px" }}>
                          <label style={webStyleOperat.headerTextOperat}>
                            Maintenance Subscription
                          </label>
                          <Box style={webStyleOperat.inputBoxWebStyleOperat}>
                            <Typography
                              style={webStyleOperat.licTextOperat}>
                              {this.state.maintenanceSubscription}
                            </Typography>
                            <Typography style={webStyleOperat.expireTextOperat}>
                              Expiry Date- 31/12/20023
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </CustomBox>
                </Box>
                // </Grid>

              }

            </Box>
          </RightBoxLanding2 >
        </Box >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleOperat = {
  userSettingDocContainerOperat: {
    backgroundColor: "#F6F7FA",
    display: "flex",
    flexWrap: "nowrap",
  },
  secondPageDivOperat: {
    backgroundColor: "#E2E8F0",
    marginLeft: '20px',
  } as React.CSSProperties,
  backAroowBoxOperat: {
    margin: "30px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  } as React.CSSProperties,
  backArrowImageOperat: {
    width: "15.8px",
    height: "15.17px"
  } as React.CSSProperties,
  backTextOperat: {
    color: "#0000000",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    marginLeft: "8px",
  } as React.CSSProperties,
  headerTextOperat: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#273567",
    marginBottom: "2px", // Adjust margin-bottom for reduced space
    display: "flex", // Ensure the label is displayed as a block element
  } as React.CSSProperties,
  inputBoxWebStyleOperat: {
    width: "100%", // Full width on all devices
    border: `1px solid #273567`,
    borderRadius: "8px",
    height: "50px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    paddingLeft: "4%", // Adjust padding as needed
    marginBottom: "10px", // Adjust margin as needed,
    display: "flex",
  } as React.CSSProperties,
  licTextOperat: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#273567",
    fontWeight: 500,
    border: 0,
    alignSelf: "center"
  } as React.CSSProperties,
  productTitleOperat: {
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  mainBoxStyleOperat: {
    padding: "40px",
    display: "flex",
    gap: "30px",
    flexWrap: "wrap",
    maxWidth: "100%"
  } as React.CSSProperties,
  cardSmallStyleOperat: {
    maxWidth: "321px",
    cursor: "pointer"
  } as React.CSSProperties,
  srcImageOperat: {
    width: '100%',
    height: '228px'
  } as React.CSSProperties,
  cardTexts2Operat: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as React.CSSProperties,
  productDescriptionOperat: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400
  } as React.CSSProperties,
  bankDetailsTextOperat: {
    color: "#273567",
    fontSize: "18px",
    fontWeight: 500,
    marginTop: "35px",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  billingOperat: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
  } as React.CSSProperties,
  smallBoxOperat: {
    backgroundColor: "#ffffff",
    paddingTop: "59px",
    marginLeft: "20px",
    marginRight: "20px",
    gap: "32px",
  } as React.CSSProperties,
  mainBoxOperat: {
    backgroundColor: "#ffffff",
    margin: '15px',
  } as React.CSSProperties,
  subscriptionBoxOperat: {
    width: "100%",
    border: `1px solid '#273567'`,
    borderRadius: "8px",
    height: "50px",
    boxSizing: "border-box",
    paddingLeft: "4%",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },

  cardTexts3Operat: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as React.CSSProperties,
  refrenceOperat: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400
  } as React.CSSProperties,
  expireTextOperat: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    alignItems: "flex-end",
    color: "#848FAC",
    alignSelf: "center",
    paddingRight: "4%",
  },
  inputBoxStyleOperat: {
    width: '100%',
    border: `1px solid #273567`,
    borderRadius: "8px",
    fontWeight: 500,
    paddingLeft: "4%",
    marginBottom: "10px",
    color: "#273567",
    height: "50px",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: "14px",
    alignItems: 'center',
    display: 'flex'
  } as React.CSSProperties,
  referenceno2Operat: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#C12030'
  } as React.CSSProperties,
  referencenoOperat: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#C12030",
  } as React.CSSProperties,
  topHeader2Operat: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  mainContainerOperat: {
    display: 'flex',
    flexDirection: 'row', // Display cards in a row
    flexWrap: 'wrap', // Allow cards to wrap to the next row if needed
    borderRadius: '12px',
    border: '10px',
    marginTop: '40px', // Add some top margin if needed
    maxWidth: '1173px', // Set a max width if needed,
    width: '100%',
    marginLeft: '40px',
    //height: '381px',
  } as React.CSSProperties,
  cardStyleOperat: {
    marginRight: '25px',
    maxWidth: 345,
    marginTop: '15px',
    cursor: "pointer",
  },
  secondContainerOperat: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E2E8F0",
    height: "1100px",
  } as React.CSSProperties,
  cardTextsOperat: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
  referenceThree: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#C12030",
  } as React.CSSProperties,
  approveTextOperat: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    color: "#059669",
  },
  approveText2Operat: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    color: "#059669",
  },
};
// Customizable Area End
