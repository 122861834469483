import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkBox, selectedCheckbox } from "./assets";
import { ChangeEvent, createRef } from "react";
import * as XLSX from "xlsx";
import jsPDF, { jsPDFAPI } from "jspdf";
import "jspdf-autotable";

interface Data {
  category: string;
  sku: string;
  qty: string;
  costOfSales: string;
  sellingValue: string;
  grossProfit: string;
  provider: string
}

interface ProductSalesByCateItem {
  sku: string;
  qty: number;
  costOfSales: string;
  sellingValue: string;
  grossProfit: string;
  category: string
}

interface MainFilterItem {
  id: number;
  text: string;
  checked: boolean;
  isShow: boolean 
}

interface ProviderMainFilterItem {
  id: number;
  text: string;
  checked: boolean;
  isShow: boolean;
  count: number;
}

interface ProviderItem {
  id: number;
  text: string;
  checked: boolean;
  isShow: boolean;
}

interface ProviderSKUItem {
  id: number;
  text: string;
  checked: boolean;
}

type AutoTableOptions = {
  startY:number;
  head: string[][];
  body: string[][];
  columnStyles:{};
  styles:{};
};

interface ExtendedJsPDF extends jsPDFAPI {
  autoTable: (options: AutoTableOptions) => jsPDFAPI;
  save: (filename: string) => void;
  setTextColor(arg0: number, arg1: number, arg2: number): unknown;
  text(arg0: string, arg1: number, arg2: number): unknown;
  setFontSize(arg0: number): unknown;
};

interface Column {
  id:
  | "provider"
  | "category"
  | "sku"
  | "qty"
  | "costOfSales"
  | "sellingValue"
  | "grossProfit"
  label: string;
  minWidth?: number;
  align?: "right";
  sortable: boolean;
}

interface InventoryDataItem {
  category: string;
  sku: string;
  description: string;
  stockQuantity: number;
  stockValue: string;
  reorderPoint: number;
  reorderQuantity: number;
};

interface WalletDataItem { 
  date:string;
  timestamp: string;
  id: string;
  type: string;
  status: string;
  description:string;
  amount: string;
  balance:string;
  productCode: number,
  productDescription: string;
  terminal: string;
  user: string;
  comment: string;
};

const providercolumns: Column[] = [
  { id: "provider", label: "Provider",sortable: false},
  { id: "category", label: "Category" ,sortable: false},
  { id: "sku", label: "SKU" ,sortable: false},
  { id: "qty", label: "Qty",sortable: true },
  { id: "costOfSales", label: "Cost of Sales", sortable: true },
  { id: "sellingValue", label: "Selling Value", sortable: true },
  { id: "grossProfit", label: "Gross Profit", sortable: true }
];
const providerrows: Data[] = [
  {
    sku: "SKU 1",
    qty: "200",
    costOfSales: "R100",
    sellingValue: "R500",
    grossProfit: "R100",
    category: "Category1",
    provider: "Provider 1",
  },
  {
    qty: "310",
    costOfSales: "R250",
    provider: "Provider 2",
    sellingValue: "R100",
    grossProfit: "R200",
    category: "Category1",
    sku: "SKU 2",

  },
  {
    costOfSales: "R250",
    sellingValue: "R500",
    provider: "Optimum Merchandising Solutions",
    grossProfit: "R310",
    category: "Heritage Handloom Suppliers of Authentic Regional Fabrics",
    sku: "SKU 3",
    qty: "420",
  },
  {
    sellingValue: "R500",
    grossProfit: "R420",
    category: "Majestic Silks and Cottons Importers of Quality and Authenticity",
    sku: "SKU 4",
    provider: "Premium Provider Partnerships for Merchant Prosperity",
    qty: "100",
    costOfSales: "R250",
  },
  {
    category: "Category1",
    sku: "SKU 3",
    qty: "555",
    costOfSales: "R250",
    sellingValue: "R500",
    provider: "Provider 5",
    grossProfit: "R530",
  },
  {
    category: "Category1",
    sku: "SKU 2",
    costOfSales: "R250",
    sellingValue: "R500",
    provider: "Provider 6",
    grossProfit: "R650",
    qty: "650",
  },

  {
    costOfSales: "R250",
    sellingValue: "R500",
    category: "Category1",
    sku: "SKU 3",
    provider: "Provider 1",
    qty: "748",
    grossProfit: "R716",
  },
  {

    sellingValue: "R500",
    category: "Category1",
    sku: "SKU 4",
    qty: "870",
    costOfSales: "R250",
    grossProfit: "R870",
    provider: "Provider 2",
  },
  {
    provider: "Provider 3",
    category: "Category1",
    costOfSales: "R250",
    sku: "SKU 1",
    qty: "910",
    sellingValue: "R500",
    grossProfit: "R980",
  },
  {
    category: "Category1",
    qty: "990",
    costOfSales: "R250",
    sellingValue: "R500",
    provider: "Provider 4",
    sku: "SKU 2",
    grossProfit: "R930",
  },
  {
    category: "Category1",
    sku: "SKU 3",
    qty: "490",
    costOfSales: "R250",
    sellingValue: "R500",
    grossProfit: "R990",
    provider: "Provider 5",
  },
  {
    category: "Category1",
    sku: "SKU 4",
    qty: "720",
    costOfSales: "R250",
    sellingValue: "R500",
    grossProfit: "R150",
    provider: "Provider 6",
  },

];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  page: number;
  rowsPerPage: number;
  openModal: any;
  isNewUserChecked: boolean;
  isExistingUserChecked: boolean;
  showDatePickers: boolean;
  isOpenDateModal: boolean;
  isWalletSort:boolean;
  startdate: string;
  enddate: string;
  showCategory: boolean;
  categoryArray: any;
  providerArray: any;
  mainFilter: any;
  providerMainFilter: any;
  skuArray: any;
  values: any;
  profitValues: any;
  isOpen: boolean;
  selectAsending: boolean;
  InvtmainFilter: any;
  searchText: any
  Stockvalues: any;
  Reordvalues: any;
  Quantityvalues: any;
  stockQuantity: any;
  selectedProviders: any;
  rowData: any;
  providercolumns: any;
  originalData: any
  sorting: any;
  sortDirection: any;
  rowsData: any;
  inventorySortedData: InventoryDataItem[];
  inventoryShowData: InventoryDataItem[];
  rowsPerPageTable: any;
  walletRowsData: any;
  walletSortedData: WalletDataItem[]
  walletShowData: WalletDataItem[]
  orderBy: any;
  order: any;
  selectAsendingWallet: boolean;
  orderByWallet: any;
  orderWallet: any;
  ProductSalesByCate: any;
  orderByCate: any;
  orderCate: any;
  orderByProvi:any;
  orderProvi:any;
  sortedData: Data[];
  providerShowData: Data[];
  providerSKUArray: ProviderSKUItem[]
  providerSelectedPage: number;
  providerQuantityRange: [number, number];
  providerProfitRange: [number, number];
  isAscendingOrder: number|boolean;
  productByCategorySortedData:ProductSalesByCateItem[];
  productByCategoryShowData:ProductSalesByCateItem[];
  isSnackbarOpen: boolean;
  dateError:string,
  salesByProvider:string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  apiCallMessageGetWalletHistory:string = '';
  apiCallMessageGetInventoryTrend:string = '';
  apiCallMessageGetSalesByProvider:string = ''


  protected printableDivRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      rowData: providerrows,
      originalData: providerrows,
      providercolumns: providercolumns,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      values: [0, 0],
      profitValues: [0, 0],
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },

        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
          sortBy: 'date',
        },
      },
      categoryArray: [
        { id: 1, text: 'Category1', checked: false, isShow: false },
        { id: 2, text: 'Category2', checked: false, isShow: false },
        { id: 3, text: 'Olivia Davis', checked: false, isShow: false },
        { id: 4, text: 'Noah Thompson', checked: false, isShow: false },
        { id: 5, text: 'Lucas Brown', checked: false, isShow: false },
        { id: 6, text: ' Mia Rodriguez', checked: false, isShow: false },
      ],
      providerArray: [
        { id: 1, text: 'Provider 1', checked: false, isShow: false },
        { id: 2, text: 'Provider 2', checked: false, isShow: false },
        { id: 3, text: 'Provider 3', checked: false, isShow: false },
        { id: 4, text: 'Provider 4', checked: false, isShow: false },
        { id: 5, text: 'Provider 5', checked: false, isShow: false },
        { id: 6, text: 'Provider 6', checked: false, isShow: false },
      ],

      skuArray: [
        { id: 1, text: 'SKU 1', checked: false },
        { id: 2, text: 'SKU 2', checked: false },
        { id: 3, text: 'SKU 3', checked: false },
        { id: 4, text: 'SKU 4', checked: false },
      ],
      googleChartData: ["Title", "Value"],
      page: 0,
      searchText: '',
      showCategory: false,
      rowsPerPage: 10,
      openModal: false,
      isNewUserChecked: false,
      isExistingUserChecked: false,
      showDatePickers: false,
      isOpenDateModal: false,
      isWalletSort:false,
      startdate: '',
      enddate: '',
      selectAsending: true,
      mainFilter: [
        { id: 1, text: 'Category', checked: false, isShow: false },
        { id: 2, text: 'SKU', checked: false, isShow: false },
        { id: 3, text: 'Qty', checked: false, isShow: false },
        { id: 4, text: 'Profit Value', checked: false, isShow: false },
      ],
      providerMainFilter: [
        { id: 1, text: 'Provider', checked: false, isShow: false, count: 0 },
        { id: 2, text: 'SKU', checked: false, isShow: false, count: 0 },
        { id: 3, text: 'Qty', checked: false, isShow: false, count: 0 },
        { id: 4, text: 'Profit Value', checked: false, isShow: false, count: 0 },
      ],
      isOpen: false,
      InvtmainFilter: [
        { id: 1, text: 'Category', checked: false, isShow: false },
        { id: 2, text: 'Stock Quantity', checked: false, isShow: false },
        { id: 3, text: 'SKU', checked: false },
        { id: 4, text: 'Reorder Quantity', checked: false, isShow: false },
        { id: 5, text: 'Stock Value', checked: false, isShow: false },
        { id: 6, text: 'Reorder Point', checked: false, isShow: false },
      ],
      Quantityvalues: [0, 1000],
      Stockvalues: [0, 1000],
      Reordvalues: [0, 1000],
      stockQuantity: [0, 1000],
      selectedProviders: [],
      sorting: "",
      sortDirection: 'asc',
      rowsData: configJSON.inventoryRowData,
      inventorySortedData: configJSON.inventoryRowData,
      inventoryShowData: configJSON.inventoryRowData.slice(0,10),
      rowsPerPageTable: 10,
      walletRowsData: configJSON.walletHistoryData,
      walletSortedData: configJSON.walletHistoryData,
      walletShowData: configJSON.walletHistoryData.slice(0,10),
      orderBy: "",
      order: "asc",
      selectAsendingWallet: true,
      orderByWallet: "",
      orderWallet: "asc",
      ProductSalesByCate:configJSON.productSalesByCateData, 
      orderByCate: "",
      orderCate: "asc",
      orderByProvi:"",
      orderProvi:"asc",

      sortedData: providerrows,
      providerShowData: providerrows.slice(0, 10),
      providerSelectedPage: 1,
      providerQuantityRange: [0, 1000],
      providerProfitRange: [0, 1000],
      isAscendingOrder: 0,
      providerSKUArray:configJSON.providerSKUArrayValues,
      productByCategorySortedData:configJSON.productSalesByCateData,
      productByCategoryShowData:configJSON.productSalesByCateData.slice(0, 10),
      isSnackbarOpen:false,
      dateError:"",
      salesByProvider:[],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.dayRavenue();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== null &&
      this.apiGetDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiGetDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        let webData: [any] = [["Title", "Value"]];
        for (
          var iIndex = 0;
          iIndex < responseJson.data?.labels.length;
          ++iIndex
        ) {
          let label = responseJson.data?.labels[iIndex];
          let value = responseJson.data?.data[iIndex][0];
          webData.push([label, value]);
        }
        this.setState({ info: responseJson.data, googleChartData: webData });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    
    if ( getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestAPIResponseMessage(message);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.body.addEventListener('click', this.handleBodyClick);
    // Customizable Area End
  }

  async componentWillUnmount(): Promise<void> {
    document.body.removeEventListener('click', this.handleBodyClick);
  }
    handleBodyClick(event: any) {
    if (event?.target?.id != 'dateModal') {
      this.setState({ isOpen: false });
    }
  }
  handleBodyClickOnScreen(event: any) {
    if (event?.target?.id != 'dateModal') {
      this.setState({ showCategory: false, isOpenDateModal: false });
    }
  }
  handleBodyClickOfWalletH(event: any) {
    if (event?.target?.id != 'sortByWalletH') {
      this.setState({ isOpenDateModal: false });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.apiCallMessageGetWalletHistory:
          this.handleMessageGetWalletHistory(responseJson)
          break;
          case this.apiCallMessageGetInventoryTrend:
            this.handleMessageGetInventoryTrend(responseJson)
            break;
            case this.apiCallMessageGetSalesByProvider:
            this.handleMessageGetSalesByProvider(responseJson)
            break;
        default:
          break;
      }
    }
  }
  handleMessageGetWalletHistory(res:any){
    if (res != null && res.error === undefined) {
      return
    } else {
      this.parseApiErrorResponse(res);
    }
  }
  handleMessageGetInventoryTrend(responseJson:any){
    if (responseJson != null && responseJson.error === undefined) {
      return
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetSalesByProvider(responseJson:any){
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({ salesByProvider: responseJson.data });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleProviderBodyClick(event:React.MouseEvent<HTMLElement, MouseEvent>){
    const target = event.target as HTMLElement;
    if (target && target.id !== 'dateModal' && !target.closest(".MuiSlider-root")){
      this.setState({ showCategory: false, isOpenDateModal: false, isWalletSort:false });
    }
  }
  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goBack = () => {
    this.props.navigation.navigate('StoreSettings');
  };
  handleLandingPageFirst = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleButtonClick = () => {
    this.setState({ openModal: true, showCategory: false });

  };
  handlePrintButton = () => {
    this.setState({ showCategory: false });

  };
  handleCloseModal = () => {
    this.setState({ openModal: false, showCategory: false });
  };
  handleNewUserChange = () => {
    this.setState({
      isNewUserChecked: !this.state.isNewUserChecked,
      isExistingUserChecked: false,
    });
  };
  handleExistingUserChange = () => {
    this.setState({
      isExistingUserChecked: !this.state.isExistingUserChecked,
      isNewUserChecked: false,
    });
  };

  handleDateOpener = () => {
    this.setState(prevState => ({
      showDatePickers: !prevState.showDatePickers
    }));
  };
  filterSortBy = () => {
    this.setState({ showCategory: false })
    this.setState(prevState => ({
      isOpenDateModal: !prevState.isOpenDateModal
    }))
  }

  SortByModal = () => {
    this.setState(prevState => ({
      isOpenDateModal: !prevState.isOpenDateModal,
      showCategory: false ,
      isWalletSort:false
    }));
  };
  SortByCategoryModal = () => {
    this.setState(prevState => ({
      showCategory: !prevState.showCategory
    }));
    this.setState({ isOpenDateModal: false });
  };

  handleWalletSortToggle =()=>{
    this.setState(prevState => ({
      isWalletSort: !prevState.isWalletSort,
      isOpen: false 
    }));
  }

  handleSnackbar=()=>{
    this.setState({isSnackbarOpen:true})
  };

  handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isSnackbarOpen:false})
  };

  onStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    let today = new Date();
    const { value } = event.target;
    const startDate = new Date(value);
    const endDate = new Date(this.state.enddate);
    today = new Date(today);
    let invalidCheck = false;
    if (endDate && startDate > endDate) {
      this.setState({dateError:"Start date cannot be greater than End date"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    }
    if (today<startDate){
      this.setState({dateError:"Start date cannot be greater than today"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    } 
    if(!invalidCheck){
      this.setState({ startdate: value }, ()=>this.walletDataSorting());
    }
  };


  onEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    let today = new Date();
    const { value } = event.target;
    const startDate = new Date(this.state.startdate);
    const endDate = new Date(value);
    today = new Date(today);
    let invalidCheck = false;
    if (endDate < startDate) {
      this.setState({dateError:"End date cannot be less than Start date"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    }
    if (today<endDate){
      this.setState({dateError:"End date cannot be greater than today"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    } 
    if(!invalidCheck){
      this.setState({ enddate: value }, ()=>this.walletDataSorting());
    }
  };




  handleCheckboxChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.categoryArray.map(
          (value1: ProviderItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const categoryChecked = updatedSelection.some(
          (value: ProviderItem) => value.checked
        );
        const updateMainButton = prevState.InvtmainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 0
              ? { ...value2, checked: categoryChecked }
              : value2;
          }
        );
        return {
          categoryArray: updatedSelection,
          InvtmainFilter: updateMainButton,
        };
      },
      () => this.inventoryDataSorting()
    );

  };
  handleProviderCheckboxChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.providerArray.map(
          (value1: ProviderItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: ProviderItem) => value.checked
        );
        const updateMainButton = prevState.providerMainFilter.map(
          (value2: ProviderMainFilterItem, index2: number) => {
            return index2 === 0
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          providerArray: updatedSelection,
          providerMainFilter: updateMainButton,
        };
      },
      () => this.dataSorting()
    );
  };

  handleProviderChange = (itemId: any) => {
    let updatedItems = this.state?.providerArray.map((item: any) =>
      item.id === itemId ? { ...item, checked: true } : { ...item, checked: false }
    );
    this.setState({ providerArray: updatedItems });
  };

  handleMainFilterChange = (index: any) => {
      let updatedItems = this.state?.mainFilter?.map((item: any, i: any) =>
      index === i ? { ...item, isShow: !item.isShow, checked: !item.checked } : { ...item, checked: false }
        );
      this.setState({ mainFilter: updatedItems });

  };

  handleCategoryMainFilterChange = (index: number) => {
    this.setState((prevState) => {
      let updatedMainButton;
      if(index===0||index==1){ updatedMainButton = prevState.mainFilter.map(
        (value: MainFilterItem, indexItem: number) => {
          return indexItem === index
            ? { ...value, isShow: !value.isShow }
            : { ...value, isShow: false };
        }
      );
    }
      else{
        updatedMainButton = prevState.mainFilter.map(
          (value: MainFilterItem, indexItem: number) => {
            return indexItem === index
              ? { ...value, checked: !value.isShow, isShow: !value.isShow }
              : { ...value, isShow: false };
          }
        );
      }
      return {
        mainFilter: updatedMainButton,
      };
    }, () => this.categoryDataSorting());
  };

  handleProviderMainFilterChange = (index: number) => {
    this.setState((prevState) => {
      let updatedMainButton;
      if(index===0||index==1){ updatedMainButton = prevState.providerMainFilter.map(
        (value: ProviderMainFilterItem, indexItem: number) => {
          return indexItem === index
            ? { ...value, isShow: !value.isShow }
            : { ...value, isShow: false };
        }
      );}
      else{
        updatedMainButton = prevState.providerMainFilter.map(
          (value: ProviderMainFilterItem, indexItem: number) => {
            return indexItem === index
              ? { ...value, checked: !value.isShow, isShow: !value.isShow }
              : { ...value, isShow: false };
          }
        );
      }
      return {
        providerMainFilter: updatedMainButton,
      };
    }, () => this.dataSorting());
  };

  handleSKUBoxChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.skuArray.map(
          (value1: ProviderSKUItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: ProviderSKUItem) => value.checked
        );
        const updateMainButton = prevState.InvtmainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 2
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          skuArray: updatedSelection,
          InvtmainFilter: updateMainButton,
        };
      },
      () => this.inventoryDataSorting()
    );
  };

  handleCategoryQuantitySliderChange = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ providerQuantityRange: [newValue[0], newValue[1]] }, () =>
        this.categoryDataSorting()
      );
    }
  };

  handleCategoryProfitValueSliderChange = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ providerProfitRange: [newValue[0], newValue[1]] }, () =>
        this.categoryDataSorting()
      );
    }
  };

  handleQuantitySliderChange = (event:  ChangeEvent<{}>, newValue: number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ Quantityvalues:[newValue[0], newValue[1]] }, () => this.inventoryDataSorting());
    }
  };

  handleStockSliderChange = (event:  ChangeEvent<{}>, newValue: number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ Stockvalues: [newValue[0], newValue[1]] }, () => this.inventoryDataSorting());
    }
  };

  handleReorderSliderChange = (event:  ChangeEvent<{}>, newValue: number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ Reordvalues: [newValue[0], newValue[1]] }, () => this.inventoryDataSorting());
    }
  };

  handleStockQuantitySliderChange = (event: ChangeEvent<{}>, newValue: number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ stockQuantity: [newValue[0], newValue[1]] }, () => this.inventoryDataSorting());
    }
  };

  checkedImage = (checked: any) => {
    return checked ? selectedCheckbox : checkBox
  }

  handleWalletBack = () => {
    this.props.navigation.navigate('Dashboard')
  }
  handleOpenState = () => {
    this.setState((prevState)=>({isOpen:!prevState.isOpen, isWalletSort:false}));

  }
  handleInventoryBack = () => {
    this.props.navigation.navigate('Dashboard')
  }
 
  handleMainInvtFilterChange = (index: number) => {
    this.setState((prevState) => {
      let updatedMainButton;
      if(index===0||index==2){ updatedMainButton = prevState.InvtmainFilter.map(
        (value: MainFilterItem, indexItem: number) => {
          return indexItem === index
            ? { ...value, isShow: !value.isShow }
            : { ...value, isShow: false };
        }
      );}
      else{
        updatedMainButton = prevState.InvtmainFilter.map(
          (value: MainFilterItem, indexItem: number) => {
            return indexItem === index
              ? { ...value, checked: !value.isShow, isShow: !value.isShow }
              : { ...value, isShow: false };
          }
        );
      }
      return {
        InvtmainFilter: updatedMainButton,
      };
    }, () => this.inventoryDataSorting());
  };

  inventoryDataSorting = () => {
      let categories = this.state.categoryArray;
      let inventoryStockQuantity = this.state.stockQuantity;
      let inventorySku = this.state.skuArray;
      let inventoryReorderQuantity = this.state.Quantityvalues;
      let inventoryStockValue = this.state.Stockvalues;
      let inventoryReoderPoint = this.state.Reordvalues;
      let dataSorting = this.state.rowsData;
      let mainButton = this.state.InvtmainFilter
      if (mainButton[0].checked) {
        dataSorting = dataSorting.filter((value2: InventoryDataItem) =>
          categories.some(
            (value: ProviderItem) => value2.category == value.text && value.checked
          )
        );
      }
       if (mainButton[1].checked) {
        dataSorting = dataSorting.filter(
          (value2: InventoryDataItem) => 
          value2.stockQuantity >= inventoryStockQuantity[0] && 
          value2.stockQuantity <= inventoryStockQuantity[1]
        );
      }
      if (mainButton[2].checked) {
        dataSorting = dataSorting.filter((value2: InventoryDataItem) =>
        inventorySku.some(
            (value: ProviderSKUItem) => value2.sku == value.text && value.checked
          )
        );
      }
      if (mainButton[3].checked) {
        dataSorting = dataSorting.filter(
          (value2: InventoryDataItem) =>
            value2.reorderQuantity >= inventoryReorderQuantity[0] &&
            value2.reorderQuantity <= inventoryReorderQuantity[1]
        );
      }
      if (mainButton[4].checked) {
        dataSorting = dataSorting.filter(
          (value2: InventoryDataItem) =>
            parseInt(value2.stockValue.slice(1)) >= inventoryStockValue[0] &&
            parseInt(value2.stockValue.slice(1)) <= inventoryStockValue[1]
        );
      }
      if (mainButton[5].checked) {
        dataSorting = dataSorting.filter(
          (value2: InventoryDataItem) =>
            value2.reorderPoint >= inventoryReoderPoint[0] &&
            value2.reorderPoint <= inventoryReoderPoint[1]
        );
      }
      this.handleInventoryChangePageOnSorting(dataSorting)
  };



  //common code for sortig and pagination start

  handleRequestSort = (property: string) => {
    
  const isAsc =
  this.state.orderBy === property ? this.state.order === 'desc' : true;

  const sortingFunction = (valuea: InventoryDataItem, valueb: InventoryDataItem) => {
    let profitValueA: number, profitValueB: number;

    switch (property) {
      case 'stockQuantity':
        profitValueA = valuea.stockQuantity;
        profitValueB = valueb.stockQuantity;
        break;
      case 'stockValue':
        profitValueA = parseFloat(valuea.stockValue.substring(1));
        profitValueB = parseFloat(valueb.stockValue.substring(1));
        break;
      case 'reorderPoint':
        profitValueA = valuea.reorderPoint
        profitValueB = valueb.reorderPoint
        break;
      case 'reorderQuantity':
        profitValueA = valuea.reorderQuantity;
        profitValueB = valueb.reorderQuantity;
        break;
      default:
        profitValueA = 0;
        profitValueB = 0;
        break;
    }

    return isAsc ? profitValueA - profitValueB : profitValueB - profitValueA;
  };

  const changeData = [...this.state.rowsData].sort(sortingFunction);

  this.setState(
    {
      selectAsending: isAsc,
      order: isAsc ? 'asc' : 'desc',
      orderBy: property !== this.state.orderBy ? property : this.state.orderBy,
      rowsData: changeData,
    },
    () => {
      this.inventoryDataSorting();
    }
  );
  };

  handleRequestSortWalletColumns = (property: string) => {
    const isAsc =
      this.state.orderByWallet === property ? this.state.orderWallet === 'desc' : true;
  
    const sortingFunction = (valuea: WalletDataItem, valueb: WalletDataItem) => {
      let profitValueA: number, profitValueB: number;
  
      switch (property) {
        case 'amount':
          profitValueA = parseFloat(valuea.amount.substring(1));
          profitValueB = parseFloat(valueb.amount.substring(1));
          break;
        case 'balance':
          profitValueA = parseFloat(valuea.balance.substring(1));
          profitValueB = parseFloat(valueb.balance.substring(1));
          break;
        default:
          profitValueA = 0;
          profitValueB = 0;
          break;
      }
      return isAsc ? profitValueA - profitValueB : profitValueB - profitValueA;
    };
    const changeData = [...this.state.walletRowsData].sort(sortingFunction);
    this.setState(
      {
        selectAsending: isAsc,
        orderWallet: isAsc ? 'asc' : 'desc',
        orderByWallet: property !== this.state.orderByWallet ? property : this.state.orderByWallet,
        walletRowsData: changeData,
      },
      () => {
        this.walletDataSorting();
      }
    );
  };

  handleRequestSortCate = (property: string) => {
    const isAsc =
      this.state.orderByCate === property ? this.state.orderCate === 'desc' : true;
  
    const sortingFunction = (valuea: ProductSalesByCateItem, valueb: ProductSalesByCateItem) => {
      let profitValueA: number, profitValueB: number;
  
      switch (property) {
        case 'qty':
          profitValueA = valuea.qty;
          profitValueB = valueb.qty;
          break;
        case 'costOfSales':
          profitValueA = parseFloat(valuea.costOfSales.substring(1));
          profitValueB = parseFloat(valueb.costOfSales.substring(1));
          break;
        case 'sellingValue':
          profitValueA = parseFloat(valuea.sellingValue.substring(1));
          profitValueB = parseFloat(valueb.sellingValue.substring(1));
          break;
        case 'grossProfit':
          profitValueA = parseFloat(valuea.grossProfit.substring(1));
          profitValueB = parseFloat(valueb.grossProfit.substring(1));
          break;
        default:
          profitValueA = 0;
          profitValueB = 0;
          break;
      }
  
      return isAsc ? profitValueA - profitValueB : profitValueB - profitValueA;
    };
  
    const changeData = [...this.state.ProductSalesByCate].sort(sortingFunction);
  
    this.setState(
      {
        selectAsending: isAsc,
        orderCate: isAsc ? 'asc' : 'desc',
        orderByCate: property !== this.state.orderByCate ? property : this.state.orderByCate,
        ProductSalesByCate: changeData,
      },
      () => {
        this.categoryDataSorting();
      }
    );
  };

  handleRequestSortProvi = (property: string) => {
    const isAsc =
      this.state.orderByProvi === property ? this.state.orderProvi === 'desc' : true;
    
    const sortingFunction = (valuea: Data, valueb: Data) => {
      const parseValue = (value: string) => parseFloat(value.substring(1));
      let profitValueA: number, profitValueB: number;
      
      switch (property) {
        case 'qty':
          profitValueA = parseFloat(valuea.qty);
          profitValueB = parseFloat(valueb.qty);
          break;
        case 'costOfSales':
          profitValueA = parseValue(valuea.costOfSales);
          profitValueB = parseValue(valueb.costOfSales);
          break;
        case 'sellingValue':
          profitValueA = parseValue(valuea.sellingValue);
          profitValueB = parseValue(valueb.sellingValue);
          break;
        case 'grossProfit':
          profitValueA = parseValue(valuea.grossProfit);
          profitValueB = parseValue(valueb.grossProfit);
          break;
        default:
          profitValueA = 0;
          profitValueB = 0;
          break;
      }
  
      return isAsc ? profitValueA - profitValueB : profitValueB - profitValueA;
    };
  
    const changeData = [...this.state.rowData].sort(sortingFunction);
  
    this.setState(
      {
        selectAsending: isAsc,
        orderProvi: isAsc ? 'asc' : 'desc',
        orderByProvi: property !== this.state.orderByProvi ? property : this.state.orderByProvi,
        rowData: changeData,
      },
      () => {
        this.dataSorting();
      }
    );
  };
  

  setSortState = (orderBy: keyof S, order: keyof S, property: string) => {
    const isAsc = this.state[orderBy] === property ? this.state[order] === 'desc' : true;
    this.setState({
      selectAsending: isAsc,
      [order]: isAsc ? 'asc' : 'desc',
      [orderBy]: property !== this.state[orderBy] ? property : this.state[orderBy],
    } as Pick<S, keyof S>);
  };

  sortedRows = () => {
    return this.sortData(this.state.rowsData, this.state.orderBy, this.state.order);
  };

  sortedRowsWallet = () => {
    return this.sortData(this.state.walletRowsData, this.state.orderByWallet, this.state.orderWallet);
  };

  sortedRowsCate = () => {
    return this.sortData(this.state.ProductSalesByCate, this.state.orderByCate, this.state.orderCate);
  };

  sortedRowsProvi = () => {
    return this.sortData(providerrows, this.state.orderByProvi, this.state.orderProvi);
  };

  sortData = (data: any[], orderBy: string, order: 'asc' | 'desc') => {
    return data.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (order === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    
    this.setState({ page: newPage - 1 });
  };

  handleChangePageWallet = (event: any, newPage: number) => {
    this.setState({ page: newPage - 1 });
  };

  handleChangePageCate = (newPage: number) => {
    this.setState({ page: newPage - 1 });
  };

  handleSortFilterCategory = (value:string) => {
    this.setState(
      {
        orderByCate:"grossProfit",
        orderCate:value
      },
      () => {
        this.handleRequestSortCate("grossProfit")
      }
    );
  };

  handleSortFilterWallet = (value:string) => {
    this.setState(
      {
        orderByWallet:"balance",
        orderWallet:value
      },
      () => {
        this.handleRequestSortWalletColumns("balance")
      }
    );
  };

  handleSortFilterProvider = (title: string) => {
    this.handleSortFilters(title, 'orderByProvi', 'orderProvi',"grossProfit");
  };

  handleSortFilter = (order:string) => {
    this.setState(
      {
        orderBy:"stockValue",
        order:order
      },
      () => {
        this.handleRequestSort("stockValue")
      }
    );
  }

  handleSortFilters = (title: string, orderByKey: keyof S, orderKey: keyof S, property: string) => {
    switch (title) {
      case 'Sort by Ascending to Descending':
        if (this.state.selectAsending) return;
        this.setState({ selectAsending: true });
  
        const isAscAsc = this.state[orderByKey] === property ? this.state[orderKey] === 'desc' : true;
        this.setState({
          [orderKey]: isAscAsc ? 'asc' : 'desc',
          [orderByKey]: this.state[orderByKey] !== property ? property : this.state[orderByKey],
        } as Pick<S, keyof S>);
        break;
  
      case 'Sort by Descending to Ascending':
        if (!this.state.selectAsending) return;
        this.setState({ selectAsending: false });
  
        const isAscDesc = this.state[orderByKey] === property ? this.state[orderKey] === 'desc' : false;
        this.setState({
          [orderKey]: isAscDesc ? 'asc' : 'desc',
          [orderByKey]: this.state[orderByKey] !== property ? property : this.state[orderByKey],
        } as Pick<S, keyof S>);
        break;
  
      default:
        break;
    }
  };
  
  
  
  //product sales by provider sorting end

  handleCategoryCheckboxChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.categoryArray.map(
          (value1: ProviderItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: ProviderItem) => value.checked
        );
        const updateMainButton = prevState.mainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 0
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          categoryArray: updatedSelection,
          mainFilter: updateMainButton,
        };
      },
      () => this.categoryDataSorting()
    );
  };

  handleCategorySKUChange=(index: number)=>{
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.providerSKUArray.map(
          (value1: ProviderSKUItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: ProviderSKUItem) => value.checked
        );
        const updateMainButton = prevState.mainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 1
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          providerSKUArray: updatedSelection,
          mainFilter: updateMainButton,
        };
      },
      () => this.categoryDataSorting()
    );
  };

  categoryDataSorting = () => {
    let categories = this.state.categoryArray;
    let providerSku = this.state.providerSKUArray;
    let quantity = this.state.providerQuantityRange;
    let profit = this.state.providerProfitRange;
    let dataSorting = this.state.ProductSalesByCate;
    let mainButton = this.state.mainFilter
    if (mainButton[1].checked) {
      dataSorting = dataSorting.filter((value2: ProductSalesByCateItem) =>
      providerSku.some(
          (value: ProviderSKUItem) => value2.sku == value.text && value.checked
        )
      );
    }
    if (mainButton[2].checked) {
      dataSorting = dataSorting.filter(
        (value2: ProductSalesByCateItem) => value2.qty>= quantity[0] && value2.qty <= quantity[1]
      );
    }
    if (mainButton[3].checked) {
      dataSorting = dataSorting.filter(
        (value2: ProductSalesByCateItem) =>
          parseInt(value2.grossProfit.slice(1)) >= profit[0] &&
          parseInt(value2.grossProfit.slice(1)) <= profit[1]
      );
    }
    if (mainButton[0].checked) {
      dataSorting = dataSorting.filter((value2: ProductSalesByCateItem) =>
        categories.some(
          (value: ProviderItem) => value2.category == value.text && value.checked
        )
      );
    }
    this.handleCategoryChangePageOnSorting(dataSorting)
  };

  handleInventoryChangePageOnSorting=(changedData:InventoryDataItem[])=>{
    const pageCount=Math.ceil(changedData.length / 10);
    let currentPage=this.state.providerSelectedPage;
    if(currentPage>pageCount){
      currentPage=1;
    }
    let startingIndex = (currentPage - 1) * 10;
    this.setState({
      inventorySortedData: changedData,
      inventoryShowData: changedData.slice(startingIndex, startingIndex + 10),
      providerSelectedPage: currentPage
    });
  }

  handleCategoryChangePageOnSorting=(changedData:ProductSalesByCateItem[])=>{
    const pageCount=Math.ceil(changedData.length / 10);
    let currentPage=this.state.providerSelectedPage;
    if(currentPage>pageCount){
      currentPage=1;
    }
    let startingIndex = (currentPage - 1) * 10;
    this.setState({
      productByCategorySortedData: changedData,
      productByCategoryShowData: changedData.slice(startingIndex, startingIndex + 10),
      providerSelectedPage: currentPage
    });
  }

  handleProviderSKUChange=(index: number)=>{
      this.setState(
        (prevState) => {
          const updatedSelection = prevState.providerSKUArray.map(
            (value1: ProviderSKUItem, index1: number) => {
              return index1 === index
                ? { ...value1, checked: !value1.checked }
                : value1;
            }
          );
          const providerChecked = updatedSelection.some(
            (value: ProviderSKUItem) => value.checked
          );
          const updateMainButton = prevState.providerMainFilter.map(
            (value2: ProviderMainFilterItem, index2: number) => {
              return index2 === 1
                ? { ...value2, checked: providerChecked }
                : value2;
            }
          );
          return {
            providerSKUArray: updatedSelection,
            providerMainFilter: updateMainButton,
          };
        },
        () => this.dataSorting()
      );
    };

  handleProviderQuantityRange = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ providerQuantityRange: [newValue[0], newValue[1]] }, () =>
        this.dataSorting()
      );
    }
  };

  handleProviderProfitValueSliderChange = (event: ChangeEvent<{}>, newValue: number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ providerProfitRange: [newValue[0], newValue[1]] }, () => this.dataSorting());
    }
  };

  handleSortingOrderChange = (value: string) => {
    this.setState(
      {
        orderByProvi:"grossProfit",
        orderProvi:value
      },
      () => {
        this.handleRequestSortProvi("grossProfit")
      }
    );
  };

  dataSorting = () => {
    let providers = this.state.providerArray;
    let providerSku = this.state.providerSKUArray;
    let quantity = this.state.providerQuantityRange;
    let profit = this.state.providerProfitRange;
    let dataSorting = this.state.rowData;
    let mainButton = this.state.providerMainFilter
    if (mainButton[1].checked) {
      dataSorting = dataSorting.filter((value2: Data) =>
      providerSku.some(
          (value: ProviderSKUItem) => value2.sku == value.text && value.checked
        )
      );
    }
    if (mainButton[2].checked) {
      dataSorting = dataSorting.filter(
        (value2: Data) => parseInt(value2.qty) >= quantity[0] && parseInt(value2.qty) <= quantity[1]
      );
    }
    if (mainButton[3].checked) {
      dataSorting = dataSorting.filter(
        (value2: Data) =>
          parseInt(value2.grossProfit.slice(1)) >= profit[0] &&
          parseInt(value2.grossProfit.slice(1)) <= profit[1]
      );
    }
    if (mainButton[0].checked) {
      dataSorting = dataSorting.filter((value2: Data) =>
        providers.some(
          (value: ProviderItem) => value2.provider == value.text && value.checked
        )
      );
    }
    this.handleChangePageOnSorting(dataSorting)
  };

  handleChangePageOnSorting=(changedData:Data[])=>{
    const pageCount=Math.ceil(changedData.length / 10);
    let currentPage=this.state.providerSelectedPage;
    if(currentPage>pageCount){
      currentPage=1;
    }
    let startingIndex = (currentPage - 1) * 10;
    this.setState({
      sortedData: changedData,
      providerShowData: changedData.slice(startingIndex, startingIndex + 10),
      providerSelectedPage: currentPage
    });
  }

  handlePagination = (event: React.ChangeEvent<unknown>, newPage: number, paginationID:string) => {
    let startingIndex = (newPage - 1) * 10;
    let dataAvailable;
    if(paginationID==="providerPagination"){
      dataAvailable = this.state.sortedData;
      this.setState({
        providerShowData: dataAvailable.slice(startingIndex, startingIndex + 10),
        providerSelectedPage: newPage
      });
    }
    if(paginationID==="categoryPagination"){
      dataAvailable = this.state.productByCategorySortedData;
      this.setState({
        productByCategoryShowData: dataAvailable.slice(startingIndex, startingIndex + 10),
        providerSelectedPage: newPage
      });
    }
    if(paginationID==="inventoryPagination"){
      dataAvailable = this.state.inventorySortedData;
      this.setState({
        inventoryShowData: dataAvailable.slice(startingIndex, startingIndex + 10),
        providerSelectedPage: newPage
      });
    }
    if(paginationID==="walletPagination"){
      dataAvailable = this.state.walletSortedData;
      this.setState({
        walletShowData: dataAvailable.slice(startingIndex, startingIndex + 10),
        providerSelectedPage: newPage
      });
    }
  };

  handleCategoryExportConfirm=()=>{
    if(this.state.productByCategorySortedData.length!==0)
    {
        if(this.state.isNewUserChecked){
        this.exportCategoryToPDF()
    this.setState({ openModal: false});
      }
      if(this.state.isExistingUserChecked){
        this.exportCategoryToExcel();
    this.setState({ openModal: false});

      }
    }
    this.setState({
      isNewUserChecked:false,
      isExistingUserChecked:false,
    })
  }

  exportCategoryToExcel() {
    let newdata = this.state.productByCategorySortedData;
    let data = newdata.map((item:ProductSalesByCateItem) => {
      return {
        "Category": item.category,
        "SKU": item.sku,
        "Qty": item.qty.toString(),
        "Cost Of Sales": item.costOfSales,
        "Selling Value": item.sellingValue,
        "Gross Profit": item.grossProfit
      };
    });
    let columnsWidth=[15,15,15,15,15,15];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!rows'] = Array.from({length:data.length + 1}).map(()=>{return { hpx: 20 }})
    worksheet['!cols'] = columnsWidth.map(width => ({ width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Product Sales By Category");
    XLSX.writeFile(workbook, "product_sales_by_category.xlsx");
  }

  exportCategoryToPDF = () => {
    const data = this.state.productByCategorySortedData;;
    const doc = new jsPDF() as unknown as ExtendedJsPDF;
    const columns: string[] = [
      "Category",
      "SKU",
      "Qty",
      "Cost of Sales",
      "Selling Value",
      "Gross Profit",
    ];
    const rows: string[][] = data.map((obj:ProductSalesByCateItem) => [
      obj.category,
      obj.sku,
      obj.qty.toString(),
      obj.costOfSales,
      obj.sellingValue,
      obj.grossProfit,
    ]);
    doc.setFontSize(18);
    doc.text('Product Sales By Category', 13.5, 15);
    const options: AutoTableOptions = {
      startY: 25,
      head: [columns],
      body: rows,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 30 },
        2: { cellWidth: 30 }
      },
      styles: {
        overflow: 'linebreak'
      }
    };
    doc.autoTable(options);
    doc.save('product_sales_by_category.pdf');
  };

  handleProviderExportConfirm=()=>{
    if(this.state.productByCategorySortedData.length!==0)
    {
        if(this.state.isNewUserChecked){
        this.exportProviderToPDF()
        this.setState({openModal:false})
      }
      if(this.state.isExistingUserChecked){
        this.exportProviderToExcel();
        this.setState({openModal:false})
      }
    }
    this.setState({
      isNewUserChecked:false,
      isExistingUserChecked:false,
    })
  }

  exportProviderToExcel() {
    let newdata = this.state.sortedData;
    let data = newdata.map((item:Data) => {
      return {
        "Provider": item.provider,
        "Category": item.category,
        "SKU": item.sku,
        "Qty": item.qty.toString(),
        "Cost Of Sales": item.costOfSales,
        "Selling Value": item.sellingValue,
        "Gross Profit": item.grossProfit
      };
    });
    let columnsWidth=[15,15,15,15,15,15,15];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!rows'] = Array.from({length:data.length + 1}).map(()=>{return { hpx: 20 }})
    worksheet['!cols'] = columnsWidth.map(width => ({ width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Product Sales By Provider");
    XLSX.writeFile(workbook, "product_sales_by_provider.xlsx");
  }

  exportProviderToPDF = () => {
    const data = this.state.sortedData;;
    const doc = new jsPDF() as unknown as ExtendedJsPDF;
    const columns: string[] = [
      "Provider",
      "Category",
      "SKU",
      "Qty",
      "Cost of Sales",
      "Selling Value",
      "Gross Profit",
    ];
    const rows: string[][] = data.map((obj:Data) => [
      obj.provider,
      obj.category,
      obj.sku,
      obj.qty.toString(),
      obj.costOfSales,
      obj.sellingValue,
      obj.grossProfit,
    ]);
    doc.setFontSize(18);
    doc.text('Product Sales By Provider', 13.5, 15);
    const options: AutoTableOptions = {
      startY: 25, 
      head: [columns],
      body: rows,
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 35 },
        2: { cellWidth: 20 },
        3: { cellWidth: 20 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 20 }
      },
      styles: {
        overflow: 'linebreak'
      }
    };
    doc.autoTable(options);
    doc.save('product_sales_by_provider.pdf');
  };

  handleInventoryExportConfirm=()=>{
    if(this.state.inventorySortedData.length!==0)
    {
        if(this.state.isNewUserChecked){
        this.exportInventoryToPDF()
        this.setState({openModal:false})
      }
      if(this.state.isExistingUserChecked){
        this.exportInventoryToExcel();
        this.setState({openModal:false})
      }
    }
    this.setState({
      isNewUserChecked:false,
      isExistingUserChecked:false,
    })
  }

  exportInventoryToExcel() {
    let newData = this.state.inventorySortedData;
    let data = newData.map((item:InventoryDataItem) => {
      return {
        "Category": item.category,
        "SKU": item.sku,
        "Description": item.description,
        "Stock Quantity": item.stockQuantity.toString(),
        "Stock Value": item.stockValue,
        "Reorder Point": item.reorderPoint.toString(),
        "Reorder Quantity": item.reorderQuantity.toString()
      };
    });
    let columnsWidth=[20,20,20,20,20,20,20];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!rows'] = Array.from({length:data.length + 1}).map(()=>{return { hpx: 20 }})
    worksheet['!cols'] = columnsWidth.map(width => ({ width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Level Overview");
    XLSX.writeFile(workbook, "stock_level_overview.xlsx");
  }

  exportInventoryToPDF = () => {
    const data = this.state.inventorySortedData;;
    const doc = new jsPDF() as unknown as ExtendedJsPDF;
    const columns: string[] = [
      "Category",
      "SKU",
      "Description",
      "Stock Quantity",
      "Stock Value",
      "Reorder Point",
      "Reorder Quantity"
    ];
    const rows: string[][] = data.map((obj:InventoryDataItem) => [
      obj.category,
      obj.sku,
      obj.description,
      obj.stockQuantity.toString(),
      obj.stockValue,
      obj.reorderPoint.toString(),
      obj.reorderQuantity.toString(),
    ]);
    doc.setFontSize(18);
    doc.text('Stock Level Overview', 13.5, 15);
    const options: AutoTableOptions = {
      startY: 25, 
      head: [columns],
      body: rows,
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 20 },
        2: { cellWidth: 35 },
        3: { cellWidth: 20 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 25 }
      },
      styles: {
        overflow: 'linebreak'
      }
    };
    doc.autoTable(options);
    doc.save('stock_level_overview.pdf');
  };

  walletDataSorting=()=>{
    let data = this.state.walletRowsData;
    const startDate = this.state.startdate;
    const endDate = this.state.enddate;
    if(startDate&&endDate)
    data = data.filter((item:WalletDataItem) => {
      return item.date >= startDate && item.date <=endDate;
    });
    this.handleWalletChangePageOnSorting(data)
  };

  handleWalletChangePageOnSorting=(changedData:WalletDataItem[])=>{
    const pageCount=Math.ceil(changedData.length / 10);
    let currentPage=this.state.providerSelectedPage;
    if(currentPage>pageCount){
      currentPage=1;
    }
    let startingIndex = (currentPage - 1) * 10;
    this.setState({
      walletSortedData: changedData,
      walletShowData: changedData.slice(startingIndex, startingIndex + 10),
      providerSelectedPage: currentPage
    });
  };

  handleWalletExportConfirm=()=>{
    if(this.state.walletSortedData.length!==0)
    {
        if(this.state.isNewUserChecked){
        this.exportWalletToPDF()
        this.setState({openModal:false})
      }
      if(this.state.isExistingUserChecked){
        this.exportWalletToExcel();
        this.setState({openModal:false})
      }
    }
    this.setState({
      isNewUserChecked:false,
      isExistingUserChecked:false,
    })
  }

  exportWalletToExcel() {
    let newData = this.state.walletSortedData;
    let data = newData.map((item:WalletDataItem) => {
      return {
        "ID": item.id,
        "Timestamp": item.timestamp,
        "Type": item.type,
        "Status": item.status,
        "Description": item.description,
        "Amount": item.amount,
        "Balance": item.balance,
        "Product Code": item.productCode.toString(),
        "Product Description": item.productDescription,
        "Terminal": item.terminal,
        "User": item.user,
        "Comment": item.comment
      };
    });
    let columnsWidth=[8,15,12,12,20,12,12,20,22,12,18,20];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!rows'] = Array.from({length:data.length + 1}).map(()=>{return { hpx: 20 }})
    worksheet['!cols'] = columnsWidth.map(width => ({ width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Wallet History");
    XLSX.writeFile(workbook, "wallet_history.xlsx");
  };

  exportWalletToPDF = () => {
    const data = this.state.walletSortedData;
    const doc = new jsPDF({
      orientation: 'landscape'
  }) as unknown as ExtendedJsPDF;
    const columns: string[] = [
      "ID",
      "Timestamp",
      "Type",
      "Status",
      "Description",
      "Amount",
      "Balance",
      "Product Code",
      "Product Description",
      "Terminal",
      "User",
      "Comment"
    ];
    const rows: string[][] = data.map((obj:WalletDataItem) => [
      obj.id,
      obj.timestamp,
      obj.type,
      obj.status,
      obj.description,
      obj.amount,
      obj.balance,
      obj.productCode.toString(),
      obj.productDescription,
      obj.timestamp,
      obj.user,
      obj.comment
    ]);
    doc.setFontSize(18);
    doc.text('Wallet History',13.5, 15);
    const options: AutoTableOptions = {
      startY:25,
      head: [columns],
      body: rows,
      columnStyles: {
        4: { cellWidth: 35 },
        8: { cellWidth: 35 },
        11: { cellWidth: 35 }
      },
      styles: {
        overflow: 'linebreak'
      }
    };
    doc.autoTable(options);
    doc.save('wallet_history.pdf');
  };
  getWalletHistory = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetWalletHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetWalletHistory = requestGetWalletHistoryMessage.messageId;
    requestGetWalletHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "walletHistory/get"
    );

    requestGetWalletHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetWalletHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetWalletHistoryMessage.id, requestGetWalletHistoryMessage);
  }
  getInventoryTrend= ()=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetInventoryTrendMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetInventoryTrend = requestGetInventoryTrendMessage.messageId;
    requestGetInventoryTrendMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "inventoryTrend/get"
    );

    requestGetInventoryTrendMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetInventoryTrendMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetInventoryTrendMessage.id, requestGetInventoryTrendMessage);
  }
  productSalesByProvider:string = "";

  getSalesByProvider = () => {
  
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.productSalesByProvider = requestMessage.messageId;
  
    const endPoint = "Productsalesbyprovider"; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiCallMessageGetSalesByProvider = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
}
// Customizable Area End