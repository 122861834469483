import React from "react";

// Customizable Area Start
import {
  Box,
  Card,
  styled,
} from "@material-ui/core";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";

import { searchpage } from './assets';
// Customizable Area End

import NotificationsController, {
  Props
} from "./NotificationsController";
import CommonSearchBar from "../../../components/src/commonSearchBar.web";
const SearchCard = styled(Card)({
    width: '90%',
    height: '918px',
    padding: '20px',
    borderRadius: '12px',
    marginTop:"30px",
    marginBottom:"30px",
    alignItems: 'flex-start',
  '@media(max-width: 600px)': {
    maxWidth: '300px',
    width:'80%',
    height: '938px',
    padding: '20px',
    borderRadius: '12px',
    alignItems: 'flex-start', 
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    maxWidth: '700px',
    width:'80%',
    height: '938px',
    padding: '20px',
    borderRadius: '12px',
    alignItems: 'flex-start',
  },
})
const RightBoxSearch = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})



export default class SearchItem extends NotificationsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.firstContainer}>
            <SideNavbar />
        <RightBoxSearch style={webStyle.secondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>
        <Box style={{ marginLeft: '30px' }}>
          <SearchCard style={webStyle.cardStyle}>
            <CommonSearchBar />
            <Box style={webStyle.boxStyle}>
              <img src={searchpage} style={webStyle.imageSearch} />
            </Box>
          </SearchCard>
        </Box>
        </RightBoxSearch>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  firstContainer: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#fff'
  } as React.CSSProperties,
  boxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: '100px'
  } as React.CSSProperties,
  cardStyle: {
    // maxWidth: '1189px',
    // width:'100%',
    // height: '938px',
    // padding: '20px',
    // borderRadius: '12px',
    // alignItems: 'flex-start',
    // marginTop: '30px'
  } as React.CSSProperties,
  secondContainer:
    {
      flexGrow: 1,
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
      height:'fit-content'
    } as React.CSSProperties,
    imageSearch:{
      maxWidth:'550px',
      width:'100%'
    }
};
// Customizable Area End
