import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkBox, selectedCheckbox } from "./assets";
import toast from "react-hot-toast";
import jsPDF, { jsPDFAPI } from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";

interface LoanDataItem {
  loadID: string;
  dateAndTime: string;
  transactionType: string;
  transactionStatus: string;
  transactionValue: string;
};

interface MainFilterItem {
  id: number;
  text: string;
  checked: boolean;
  isShow: boolean;
};

interface TransactionArrayItem {
  id: number;
  text: string;
  checked: boolean;
};

interface DateTime {
  date: Date;
  originalString: string;
};

type AutoTableOptions = {
  startY: number;
  head: string[][];
  body: string[][];
  columnStyles: {};
  styles: {}
};

interface ExtendedJsPDF extends jsPDFAPI {
  autoTable: (options: AutoTableOptions) => jsPDFAPI;
  save: (filename: string) => void;
  setTextColor(arg0: number, arg1: number, arg2: number): unknown;
  text(arg0: string, arg1: number, arg2: number): unknown;
  setFontSize(arg0: number): unknown;
};
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;/*  */
  // Customizable Area Start
  // Customizable Area End
}

const { baseURL: apiBaseUrl } = require('./../../../framework/src/config')

interface StateType {
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string,
  selectedGender: string,
  selectedPhoneCountry: string,
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;
  // Customizable Area Start
  page: number;
  rowsPerPage: number;
  isOpenDateModal: any;
  openModal: any;
  isNewUserChecked: any;
  isExistingUserChecked: any;
  values: any;
  selectAsending: any;
  isOpen: any;
  mainFilter: any;
  showmainFilter: any;
  selectedMainFilter: any;
  loanID: any
  trasactionArray: any
  statusArray: any
  date: any;
  showLoanFilter: any;
  showTrasactionArray: any;
  showStatusArray: any;
  showValuesRange: any;
  showDateFilter: any;
  docValidation: boolean;
  validateDoc: boolean;
  uploadedDoc1: any;
  uploadedDocNameproof: any;
  uploadedDocNamestatement: any;
  maritalStatus: any;
  uploadedDocNamespouse: any;
  loanSuccessModa: boolean;
  validateMaritalStatus: boolean;
  setFinanceReason: any;
  selectAsendingS: boolean;
  orderBy: any;
  order: any;
  loanData: any;
  loanDataSorted: LoanDataItem[];
  loanDataShow: LoanDataItem[];
  currentPage: number;
  dateError: string;
  isSnackbarOpen: boolean;
  setLoanType:any;
  communityofproperty:any;
  errorMsgproof:any;
  errorMsgstatement:any;
  errorMsgspouse:any;
  // Customizable Area End
}




interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  userSubmitLoanFormApiCallId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      errorMsgproof:'',
      errorMsgstatement:'',
      errorMsgspouse:'',
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      // Add more countries as neede
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      // Customizable Area Start
      page: 0,
      openModal: false,
      showmainFilter: false,
      rowsPerPage: 10,
      isOpenDateModal: false,
      isNewUserChecked: false,
      isExistingUserChecked: false,
      values: [0, 10000],
      selectAsending: false,
      isOpen: false,
      selectedMainFilter: 'Date',
      showLoanFilter: false,
      showTrasactionArray: false,
      showStatusArray: false,
      showValuesRange: false,
      showDateFilter: false,
      date: '',
      mainFilter: [
        { id: 1, text: 'Date', checked: false, isShow: false },
        { id: 2, text: 'Loan ID', checked: false, isShow: false },
        { id: 3, text: 'Transaction', checked: false, isShow: false },
        { id: 4, text: 'Status', checked: false, isShow: false },
        { id: 5, text: 'Value', checked: false, isShow: false }
      ],
      loanID: [
        { id: 1, text: 'AAA1112222', checked: false },
        { id: 2, text: 'BBB3334444', checked: false },
        { id: 3, text: 'CCC5556666', checked: false },
        { id: 4, text: 'DDD7778888', checked: false }
      ],
      statusArray: [
        { id: 1, text: 'Completed', checked: false },
        { id: 2, text: 'Pending', checked: false },
        { id: 3, text: 'Failed', checked: false }
      ],
      trasactionArray: configJSON.transactionArrayTypes,
      docValidation: false,
      validateDoc: false,
      uploadedDoc1: null,
      uploadedDocNameproof: null,
      uploadedDocNamestatement: null,
      maritalStatus: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      uploadedDocNamespouse: null,
      loanSuccessModa: false,
      validateMaritalStatus: false,
      setFinanceReason: 'Selectthereasonforwantingfinance',
      orderBy: "",
      order: "asc",
      selectAsendingS: true,
      loanData: configJSON.loanData,
      loanDataSorted: configJSON.loanData,
      loanDataShow: configJSON.loanData.slice(0, 10),
      currentPage: 1,
      dateError: "",
      isSnackbarOpen: false,
      setLoanType:"SelectTypeOfLoan",
      communityofproperty:"Selectcommunityofproperty"
    // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderIdFailureCallBack = async (responseJson: string) => {
    alert("@@@@====>");
  };

  // Api response
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors && apiRequestCallId === this.getOrderApiCallId) {
        this.getOrderIdFailureCallBack(responseJson);
      }
      if (responseJson && !responseJson.errors && apiRequestCallId === this.getOrderApiCallId) {

        const data = responseJson.data.attributes;
      }
      if(apiRequestCallId === this.userSubmitLoanFormApiCallId){
        this.handleResponseMessageSubmitLoanForm(responseJson)
      }
    }
    // Customizable Area End
  }
  async componentWillUnmount(): Promise<void> {
    document.body.removeEventListener('click', this.handleBodyClick);
  }
  handleBodyClick(event: any) {
    if (event?.target?.id != 'dateModal') {
      this.setState({ isOpen: false });
    }
  }
  handleBack = () => {
    this.setState({ activeSteps: this.state.activeSteps - 1 })
  };

  getSteps() {
    return ["Step 1", "Step 2", "Complete"];
  }

  LoginPage = () => {
    this.props.navigation.navigate('EmailAccountLogin')
  }

  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }


  // Customizable Area Start
  handleResponseMessageSubmitLoanForm(responseJson:any){
    if (responseJson != null && responseJson.errors === undefined) {
      return
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleBodyClickOnScreen(event: any) {
    if (event?.target?.id != 'dateModal') {

      this.setState({
        showmainFilter: false, isOpenDateModal: false,
      });
    }
  }

  handleMultipageFormBodyClickOnScreen(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const target = event.target as HTMLElement;
    if (target && target.id !== 'dateModal' && !target.closest(".MuiSlider-root")) {
      this.setState({ showmainFilter: false, isOpenDateModal: false });
    }
  };

  handleLandingPageFirst = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  SortByCategoryModal = () => {
    this.setState(prevState => ({
      showmainFilter: !prevState.showmainFilter
    }));
    this.setState({ isOpenDateModal: false, });
  };
  SortByModal = () => {
    this.setState(prevState => ({
      isOpenDateModal: !prevState.isOpenDateModal
    }));
    this.setState({ showmainFilter: false })
  };
  handleCloseModal = () => {
    this.setState({ openModal: false, showmainFilter: false });
  };
  handleNewUserChange = () => {
    this.setState({
      isNewUserChecked: !this.state.isNewUserChecked,
      isExistingUserChecked: false,
    });
  };
  handleExistingUserChange = () => {
    this.setState({
      isExistingUserChecked: !this.state.isExistingUserChecked,
      isNewUserChecked: false,
    });
  };
  handleButtonClick = () => {
    this.setState({ openModal: true, showmainFilter: false });

  };
  handleSortFilter = () => {
    this.setState({ selectAsending: !this.state.selectAsending })
  }
  checkedImage = (checked: any) => {
    return checked ? selectedCheckbox : checkBox
  }
  handleSliderChange = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ values: [newValue[0], newValue[1]] }, () =>
        this.multipageFormDataSorting()
      );
    }
  };

  handleMultipageMainFilterChange = (index: number) => {
    this.setState((prevState) => {
      let updatedMainButton;
      if (index === 4) {
        updatedMainButton = prevState.mainFilter.map(
          (value: MainFilterItem, indexItem: number) => {
            return indexItem === index
              ? { ...value, checked: !value.isShow, isShow: !value.isShow }
              : { ...value, isShow: false };
          }
        );
      }
      else {
        updatedMainButton = prevState.mainFilter.map(
          (value: MainFilterItem, indexItem: number) => {
            return indexItem === index
              ? { ...value, isShow: !value.isShow }
              : { ...value, isShow: false };
          }
        );
      }
      return {
        mainFilter: updatedMainButton
      };
    }, () => {
      this.handleButtonLabel(index);
      this.multipageFormDataSorting()
    });
  };

  handleButtonLabel = (index: number) => {
    const mainButtons = this.state.mainFilter;
    let lastSelectedButton;
    if (mainButtons[index].checked) {
      lastSelectedButton = mainButtons[index].text;
    } else {
      mainButtons.forEach((value: MainFilterItem, index: number) => {
        if (value.checked) {
          lastSelectedButton = value.text;
        }
      });
    }
    if (lastSelectedButton) {
      this.setState({ selectedMainFilter: lastSelectedButton });
    } else {
      this.setState({ selectedMainFilter: "Date" });
    }
  };

  multipageFormDataSorting = () => {
    let data = this.state.loanData;
    const selectDate = this.state.date;
    let loanIds = this.state.loanID;
    let transction = this.state.trasactionArray;
    let status = this.state.statusArray;
    let valueRange = this.state.values;
    let mainButton = this.state.mainFilter;
    if (mainButton[0].checked)
      data = data.filter((item: LoanDataItem) => {
        const changeDateFormat = this.handleFormat(item.dateAndTime);
        return changeDateFormat === selectDate;
      }
      );
    if (mainButton[1].checked) {
      data = data.filter((value2: LoanDataItem) =>
        loanIds.some(
          (value: TransactionArrayItem) => value2.loadID == value.text && value.checked
        )
      );
    }
    if (mainButton[2].checked) {
      data = data.filter((value2: LoanDataItem) =>
        transction.some(
          (value: TransactionArrayItem) => value2.transactionType == value.text && value.checked
        )
      );
    }
    if (mainButton[3].checked) {
      data = data.filter((value2: LoanDataItem) =>
        status.some(
          (value: TransactionArrayItem) =>
            value2.transactionStatus == (value.text).toUpperCase() && value.checked
        )
      );
    }
    if (mainButton[4].checked) {
      data = data.filter(
        (value2: LoanDataItem) => parseInt((value2.transactionValue).substring(1)) >= valueRange[0] && parseInt((value2.transactionValue).substring(1)) <= valueRange[1]
      );
    }
    this.handleChangePageOnSorting(data)
  };

  handleFormat = (date: string) => {
    const dateGet = date.slice(0, 2);
    const yearGet = date.slice(7, 11);
    const monthGet = date.slice(3, 6) as keyof typeof monthMap;
    const monthMap = {
      JAN: "01",
      FEB: "02",
      MAR: "03",
      APR: "04",
      MAY: "05",
      JUN: "06",
      JUL: "07",
      AUG: "08",
      SEP: "09",
      OCT: "10",
      NOV: "11",
      DEC: "12"
    };
    const formattedDate = `${yearGet}-${monthMap[monthGet]}-${dateGet}`;
    return formattedDate;
  };

  handleChangePageOnSorting = (changedData: LoanDataItem[]) => {
    const pageCount = Math.ceil(changedData.length / 10);
    let currentPage = this.state.currentPage;
    if (currentPage > pageCount) {
      currentPage = 1;
    }
    let startingIndex = (currentPage - 1) * 10;
    this.setState({
      loanDataSorted: changedData,
      loanDataShow: changedData.slice(startingIndex, startingIndex + 10),
      currentPage: currentPage
    });
  };

  handleMultipageCheckboxChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.loanID.map(
          (value1: TransactionArrayItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: TransactionArrayItem) => value.checked
        );
        const updateMainButton = prevState.mainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 1
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          loanID: updatedSelection,
          mainFilter: updateMainButton
        };
      },
      () => {
        this.handleButtonLabel(1);
        this.multipageFormDataSorting();
      }
    );
  };

  handleTransactionChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.trasactionArray.map(
          (value1: TransactionArrayItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: TransactionArrayItem) => value.checked
        );
        const updateMainButton = prevState.mainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 2
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          trasactionArray: updatedSelection,
          mainFilter: updateMainButton
        };
      },
      () => {
        this.handleButtonLabel(2);
        this.multipageFormDataSorting();
      }
    );
  };

  handleStatusChange = (index: number) => {
    this.setState(
      (prevState) => {
        const updatedSelection = prevState.statusArray.map(
          (value1: TransactionArrayItem, index1: number) => {
            return index1 === index
              ? { ...value1, checked: !value1.checked }
              : value1;
          }
        );
        const providerChecked = updatedSelection.some(
          (value: TransactionArrayItem) => value.checked
        );
        const updateMainButton = prevState.mainFilter.map(
          (value2: MainFilterItem, index2: number) => {
            return index2 === 3
              ? { ...value2, checked: providerChecked }
              : value2;
          }
        );
        return {
          statusArray: updatedSelection,
          mainFilter: updateMainButton
        };
      },
      () => {
        this.handleButtonLabel(3);
        this.multipageFormDataSorting();
      }
    );
  };

  handleSnackbarOpen = () => {
    this.setState({ isSnackbarOpen: true })
  };

  handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isSnackbarOpen: false })
  };

  onStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let today = new Date();
    today = new Date(today);
    const { value } = event.target;
    const startDate = new Date(value);
    let updatedMainButton = this.state.mainFilter;
    if (today < startDate) {
      this.setState({
        dateError: "Start date cannot be greater than today"
      },
        () => this.handleSnackbarOpen()
      );
    }
    else {
      if (value) {
        updatedMainButton = updatedMainButton.map(
          (value: MainFilterItem, index: number) => {
            return index === 0
              ? { ...value, checked: true }
              : value;
          }
        );
      } else {
        updatedMainButton = updatedMainButton.map(
          (value: MainFilterItem, index: number) => {
            return index === 0
              ? { ...value, checked: false }
              : value;
          }
        );
      }
      this.setState({
        date: value,
        mainFilter: updatedMainButton
      }, () => {
        this.handleButtonLabel(0);
        this.multipageFormDataSorting()
      }
      );
    }
  };


  handleSelectReason = (e: any) => {
    this.setState({ setFinanceReason: e?.target?.value })
  }

  handleDocUpload = (fieldName: any, e: any) => {
    const bankDoc = e?.target?.files ? e.target.files[0] : null;

    if (bankDoc) {
      const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/img'];
      if (!validTypes.includes(bankDoc.type)) {
        this.setState(prevState => ({
          ...prevState,
        [`errorMsg${fieldName}`]: 'Please upload in correct format (.PDF / .JPEG / .JPG / .PNG / .IMG)',
        validateDoc: false,
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        selctedCertificate: bankDoc,
        [`uploadedDocName${fieldName}`]: bankDoc.name,
        [`errorMsg${fieldName}`]: '',
        validateDoc: true,
      }));
    }

  };
  handleFileBlur = () => {
    // Assuming you want to show validation message if uploadedFileName is empty
    this.setState({
      // docValidation: true,
      validateDoc: true,
    });
  }
  handleMaritalStatus = (event: any) => {
    if (event && event.target) {
      const targetName = event.target.value;

      this.setState({
        maritalStatus: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },
      });
      this.setState({ validateMaritalStatus: false })

    }
  }
  loanNextStep = () => {
    if (this.state.setLoanType == 'SelectTypeOfLoan') {
    
      toast.error("Please select the type of loan", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      })
    }else if (this.state.setFinanceReason == 'Selectthereasonforwantingfinance') {
      toast.error("Please select the reason for wanting funds", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
          display: "flex",
          alignItems: "baseline"
        },
      })
    } 
    else if (this.state.maritalStatus.isCheckedYes === false && this.state.maritalStatus.isCheckedNo === false) {
      this.setState({ validateMaritalStatus: true })

    }else if (this.state.maritalStatus.isCheckedYes === true && this.state.communityofproperty == "Selectcommunityofproperty") {

      toast.error("Please select community of property", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    }else if (this.state.maritalStatus.isCheckedYes === true && this.state.uploadedDocNamespouse == null) {

      toast.error("Please upload spouse's ID", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    } else if (this.state.uploadedDocNamestatement == null) {

      toast.error("Please upload bank statement", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    } else if (this.state.uploadedDocNameproof == null) {

      toast.error("Please upload proof of residence", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    } else {
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
       data:{}
      }
      const requestUserSubmitLoanFormMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userSubmitLoanFormApiCallId = requestUserSubmitLoanFormMessage.messageId;
      requestUserSubmitLoanFormMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "loanForm/submit"
      );
  
      requestUserSubmitLoanFormMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUserSubmitLoanFormMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestUserSubmitLoanFormMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestUserSubmitLoanFormMessage.id, requestUserSubmitLoanFormMessage);
      this.setState({ loanSuccessModa: true })

    }

  }
  handleCloseLoanModal = () => {
    this.setState({
      loanSuccessModa: false, uploadedDocNamestatement: null, uploadedDocNamespouse: null, uploadedDocNameproof: null, setFinanceReason: 'Selectthereasonforwantingfinance', maritalStatus: {
        isCheckedYes: false,
        isCheckedNo: false,
      }
    })
  }
  removeUploadedDoc = (e: any) => {
    if (e == "statement") {
      this.setState({ uploadedDocNamestatement: null })
    } else if (e == "spouse") {
      this.setState({ uploadedDocNamespouse: null })
    } else if (e == "proof") {
      this.setState({ uploadedDocNameproof: null })
    }

  }


  navigateToApplyForLoan = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ApplyForLoan"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };


  navigateToApplyForLoanOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorApplyForLoan"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  //new code for sorting and pagination

  handleRequestSortLoanData = (property: string) => {
    const isAsc =
      this.state.orderBy === property ? this.state.order === 'desc' : true;
    let changeData;
    if (property === "dateAndTime") {
      changeData = this.handleDateSort(isAsc);
    }
    if (property === "transactionValue") {
      changeData = this.handleValueSort(isAsc);
    }
    this.setState(
      {
        selectAsendingS: isAsc,
        order: isAsc ? 'asc' : 'desc',
        orderBy: property !== this.state.orderBy ? property : this.state.orderBy,
        loanData: changeData,
      },
      () => {
        this.multipageFormDataSorting();
      }
    );
  };

  handleDateSort = (orderAscending: boolean) => {
    let data;
    const parseDateTime = (dateTimeStr: string): DateTime => {
      const [dateStr, timeStr] = dateTimeStr.split(" | ");
      const [days, month, year] = dateStr.split(" ");
      const [hour, minute] = timeStr.split(":");
      const date = new Date(`${month} ${days}, ${year} ${hour}:${minute}`);
      return { date, originalString: dateTimeStr };
    };

    data = [...this.state.loanData].sort((valuea, valueb) => {
      const dateA = parseDateTime(valuea.dateAndTime).date;
      const dateB = parseDateTime(valueb.dateAndTime).date;
      return orderAscending ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    });
    return data;
  };

  handleValueSort = (orderAscending: boolean) => {
    const data = [...this.state.loanData].sort((valuea, valueb) => {
      const transValueA = parseFloat(valuea.transactionValue.substring(1));
      const transValueB = parseFloat(valueb.transactionValue.substring(1));
      return orderAscending ? transValueA - transValueB : transValueB - transValueA;
    });
    return data;
  };


  sortLoanData = () => {
    const { order, orderBy, loanData } = this.state;
    return loanData.slice().sort((a: any, b: any) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (order === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  handleSortFilterLoanData = (order: string) => {
    this.setState(
      {
        orderBy: "transactionValue",
        order: order
      },
      () => {
        this.handleRequestSortLoanData("transactionValue")
      }
    );
  };

  handleMultipageFormPagination = (event: React.ChangeEvent<unknown>, newPage: number) => {
    const startingIndex = (newPage - 1) * 10;
    let dataAvailable = this.state.loanDataSorted;
    this.setState({
      loanDataShow: dataAvailable.slice(startingIndex, startingIndex + 10),
      currentPage: newPage
    });
  };

  handleLoanExportConfirm = () => {
    if (this.state.loanDataSorted.length !== 0) {
      if (this.state.isNewUserChecked) {
        this.exportLoanToPDF()
        this.setState({openModal:false})
      }
      if (this.state.isExistingUserChecked) {
        this.exportLoanToExcel();
      this.setState({openModal:false})
      }
    }
    this.setState({
      isNewUserChecked: false,
      isExistingUserChecked: false,
    })
  };

  exportLoanToExcel() {
    let newdata = this.state.loanDataSorted;
    let data = newdata.map((item: LoanDataItem) => {
      return {
        "Loan ID": item.loadID,
        "Date": item.dateAndTime,
        "Transaction Type": item.transactionType,
        "Transaction Status": item.transactionStatus,
        "Transaction Value": item.transactionValue
      };
    });
    let columnsWidth = [22, 28, 25, 25, 25];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!rows'] = Array.from({ length: data.length + 1 }).map(() => { return { hpx: 20 } })
    worksheet['!cols'] = columnsWidth.map(width => ({ width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Loan");
    XLSX.writeFile(workbook, "loan.xlsx");
  };

  exportLoanToPDF = () => {
    const data = this.state.loanDataSorted;
    const document = new jsPDF() as unknown as ExtendedJsPDF;
    const columns: string[] = [
      "Loan ID",
      "Date",
      "Transaction Type",
      "Transaction Status",
      "Transaction Value"
    ];
    const rows: string[][] = data.map((obj: LoanDataItem) => [
      obj.loadID,
      obj.dateAndTime,
      obj.transactionType,
      obj.transactionStatus,
      obj.transactionValue
    ]);
    document.setFontSize(18);
    document.text('Loan', 13.5, 15);
    const options: AutoTableOptions = {
      startY: 25,
      head: [columns],
      body: rows,
      columnStyles: {
        0: { cellWidth: 40 }
      },
      styles: {
        overflow: 'linebreak'
      },
    };
    document.autoTable(options);
    document.save('loan.pdf');
  };

  handleSelectTypeOfLoan = (e: any) => {
    this.setState({ setLoanType: e?.target?.value })
  }
  handleSelectCommunityProperty = (e: any) => {
    this.setState({ communityofproperty: e?.target?.value })
  }
  // Customizable Area End

}
