import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Boxcheck, Checkboxselected } from "./assets";
import { ChartData } from "react-native-chart-kit/dist/HelperTypes";
import moment, { Moment, unitOfTime } from "moment";
import { ChangeEvent, createRef } from "react";
import * as XLSX from "xlsx";
import jsPDF, { jsPDFAPI } from "jspdf";
import "jspdf-autotable";

interface Reporting {
  overview: { unit: number, revenue: number };
  sales: { [label: string]: number };
  salesCumulative: { [label: string]: number };
  revenueBreakdown: { [label: string]: number };
  salesComparison: { [label: string]: number };
  currency: string;
}
interface RevenueBreakdownUnit {
  name: string;
  value: number;
  color: string;
  legendFontColor: string;
  legendFontSize: number;
};

interface SalesReportItem {
  date: string;
  sku: string;
  value: string;
  provider: string;
  commission: string;
  qty: string;
};

interface MainButtonItem { 
  id: number; 
  text: string;
  checked: boolean;
  isShow:boolean;
};

interface ProviderArrayItem { 
  id: number; 
  text: string;
  checked: boolean; 
};

interface ExtendedJsPDF extends jsPDFAPI {
  autoTable: (options: AutoTableOptions) => jsPDFAPI;
  save: (filename: string) => void;
  setTextColor(arg0: number, arg1: number, arg2: number): unknown;
  text(arg0: string, arg1: number, arg2: number): unknown;
  setFontSize(arg0: number): unknown;
};

type AutoTableOptions = {
  startY:number;
  head: string[][];
  body: string[][];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  values: any;
  values2: any;
  values3: any;
  providerArray: any;
  selectedPeriod: { pkey: string, value: string };
  selectedDate: { from: Moment, through: Moment };
  selectedFrame: string;
  skuArray:any;
  providerShow: boolean;
  totalSaleUnit: number;
  filterMain: any;
  totalSaleRevenue: number;
  revenueBreakdown: RevenueBreakdownUnit[];
  mobileSalesData: ChartData;
  mobileSalesCumulativeData: { labels: string[], data: number[] };
  webSalesData: (string | number)[][];
  webSalesCumulativeData: (string | number)[][];
  webRevenueBreakdown: (string | number)[][];
  currency: string;
  pageData: number;
  rowsPerPageData: number;
  info: any;
  data: any;
  googleChartData: any;
  openModal: any;
  isNewUserChecked:boolean;
  isExistingUserChecked:boolean;
  showDatePickers: boolean;
  isOpenDateModal: boolean;
  isOpenSortBySalesR: boolean;
  startdate:  string;
  enddate: string;
  isOpen : boolean;
  selectAsendingS: boolean;
  salesReport:any;
  orderBy: any;
  order: any;
  page: number;
  rowsPerPage: number;
  salesReportSorted: SalesReportItem[];
  salesReportShow: SalesReportItem[];
  isSnackbarOpen: boolean;
  dateError: string;
  isSelectAscending: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  salesReportApiCallId: string = '';
  protected printableDivRef = createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleClickBody = this.handleClickBody.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    const chartData = this.prepareChartData(configJSON.sampleReporting)

    this.state = {
      token: "",
      // Customizable Area Start
      loading: false,
      selectedPeriod: configJSON.periods[1],
      selectedDate: { from: moment().startOf('M'), through: moment().endOf('M') },
      selectedFrame: 'week',
      totalSaleUnit: chartData.totalSaleUnit,
      totalSaleRevenue: chartData.totalSaleRevenue,
      revenueBreakdown: chartData.revenueBreakdown,
      mobileSalesData: chartData.mobileSalesData,
      mobileSalesCumulativeData: chartData.mobileSalesCumulativeData,
      webSalesData: chartData.webSalesData,
      webSalesCumulativeData: chartData.webSalesCumulativeData,
      webRevenueBreakdown: chartData.webRevenueBreakdown,
      currency: chartData.currency,
      filterMain:[
        { id: 4, text: 'Value', checked: false, isShow:false },
        { id: 3, text: 'Qty', checked: false, isShow:false },
        { id: 2, text: 'SKU', checked: false, isShow:false },
        { id: 1, text: 'Provider', checked: false, isShow:false },
        { id: 5, text: 'Timeline', checked: false, isShow:false },
        { id: 6, text: 'Commission', checked: false, isShow:false }
      ],
      providerArray: [
        { id: 1, text: 'Emmma Johnson', checked: false },
        { id: 2, text: 'Liam Smith', checked: false },
        { id: 3, text: 'Olivia Davis', checked: false },
        { id: 4, text: 'Noah Thompson', checked: false },
        { id: 5, text: 'Lucas Brown', checked: false },
        { id: 6, text: ' Mia Rodriguez', checked: false },
      ],
      skuArray: [
        { id: 1, text: 'SKU 1', checked: false },
        { id: 2, text: 'SKU 2', checked: false },
        { id: 3, text: 'SKU 3', checked: false },
        { id: 4, text: 'SKU 4', checked: false },
      ],
      pageData:0,
      rowsPerPageData:10,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
          sortBy: 'date',
        },
      },
      googleChartData: ["Title", "Value"],
      openModal: false,
      values: [0, 10000],
      values2: [0, 1000],
      values3: [0, 1000],
      providerShow: false,
      isNewUserChecked: false,
      isExistingUserChecked: false,
       showDatePickers: false,
       isOpenDateModal:false,
       isOpenSortBySalesR:false,
       startdate: '',
       enddate:'',
       isOpen : false,
       selectAsendingS:true,
    salesReport: configJSON.salesReportRowData,
    salesReportSorted: configJSON.salesReportRowData,
    salesReportShow: configJSON.salesReportRowData.slice(0,10),
    orderBy: "",
    order: "asc",
    page: 1,
    rowsPerPage: 10,
    isSnackbarOpen:false,
    dateError:"",
    isSelectAscending: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const getSalesReportResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", getSalesReportResponse);

      if (getSalesReportResponse && getSalesReportResponse.overview) {
        const chartData = this.prepareChartData(getSalesReportResponse);
        this.setState({ ...chartData, loading: false });
      } else {
        this.showAlert("Alert", "API Error", "");
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area End

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    document.body.removeEventListener('click', this.handleClickBody);
  }
  getAllStoresCallId:string = "";

  getSalesReportList = () => {
  
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllStoresCallId = requestMessage.messageId;
  
    const endPoint = "Salesreport"; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  handleClickBody(event : MouseEvent) {
    const target = event.target as HTMLElement;
    if (target && target.id !== 'leftFilterBox' && !target.closest(".MuiSlider-root")){
      this.setState({ providerShow: false, isOpenSortBySalesR: false });
    }
};

handleRequestSort = (property:string) => {
  const isAsc =
  this.state.orderBy === property ? this.state.order === 'desc' : true;
  let changeData;

  const sortingFunction = (valuea: SalesReportItem, valueb: SalesReportItem) => {
    let profitValueA: number, profitValueB: number;

    switch (property) {
      case 'qty':
        profitValueA = parseFloat(valuea.qty);
        profitValueB = parseFloat(valueb.qty);
        break;
      case 'value':
        profitValueA = parseFloat(valuea.value.substring(1));
        profitValueB = parseFloat(valueb.value.substring(1));
        break;
      case 'commission':
        profitValueA = parseFloat(valuea.commission.substring(1));
        profitValueB = parseFloat(valueb.commission.substring(1));
        break;
      default:
        profitValueA = 0;
        profitValueB = 0;
        break;
    }

    return isAsc ? profitValueA - profitValueB : profitValueB - profitValueA;
  };
  if(property==="value") {
    let modifyData = this.state.salesReport;
    modifyData = modifyData.map((item:SalesReportItem)=>item.value==="APPROVED"?{...item, value:"R0"}:item);
    modifyData = [...modifyData].sort(sortingFunction);
    changeData = modifyData.map((item:SalesReportItem)=>item.value==="R0"?{...item, value:"APPROVED"}:item);
  }
  else {
    changeData = [...this.state.salesReport].sort(sortingFunction);
  }

  this.setState(
    {
      isSelectAscending: isAsc,
      order: isAsc ? 'asc' : 'desc',
      orderBy: property !== this.state.orderBy ? property : this.state.orderBy,
      salesReport: changeData,
    },
    () => {
      this.saleReportDataSorting();
    }
  );
};

handleChangePage = (event:React.ChangeEvent<unknown>,newPage:number) => {
  let startingIndex = (newPage - 1) * 10;
    let dataAvailable = this.state.salesReportSorted;
    this.setState({
      salesReportShow: dataAvailable.slice(startingIndex, startingIndex + 10),
      page: newPage
    });
};

sortReports = () => {
  const { order, orderBy, salesReport } = this.state;
  return salesReport.slice().sort((a:any, b:any) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });
};

handleSortFilterCategory = (order:string) => {
  this.setState(
    {
      orderBy:"value",
      order:order
    },
    () => {
      this.handleRequestSort("value")
    }
  );
}


getToken = () => {
  const msg: Message = new Message(
    getName(MessageEnum.SessionRequestMessage)
  );
  this.send(msg);
};

  handleChangePageAction = (event: unknown, newPage: number) => {
    this.setState({ pageData: newPage });
  };
  handleChangeRowsPerPageAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPageData: +event.target.value, pageData: 0 });
  };
  goBackClick = () => {
    this.props.navigation.navigate('Dashboard');
  };
  handleButtonClickAct = () => {
    this.setState({ openModal: true });
  };
  handleModalClose = () => {
    this.setState({ openModal: false });
  };
  handleNewUserChangeAct = () => {
    this.setState({
      isNewUserChecked: !this.state.isNewUserChecked,
      isExistingUserChecked: false,
    });
  };
  handleExistingUserChangeAct = () => {
    this.setState({
      isExistingUserChecked: !this.state.isExistingUserChecked,
      isNewUserChecked: false,
    });
  };

  handleChangeSlider = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ values: [newValue[0], newValue[1]] }, () =>
        this.saleReportDataSorting()
      );
    }
  };

  handleChangeSlider2 = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ values2: [newValue[0], newValue[1]] }, () =>
        this.saleReportDataSorting()
      );
    }
  };

  handleChangeSlider3 = (event: ChangeEvent<{}>, newValue:number|number[]) => {
    if (
      typeof newValue !== "number" &&
      Array.isArray(newValue) &&
      newValue.length >= 2
    ) {
      this.setState({ values3: [newValue[0], newValue[1]] }, () =>
        this.saleReportDataSorting()
      );
    }
  };

  imageChecked=(checked:any)=>{
    return checked ? Checkboxselected : Boxcheck
  };

  handleSnackbar=()=>{
    this.setState({isSnackbarOpen:true});
  };

  handleSnackbarClose = (event: React.SyntheticEvent | Event, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isSnackbarOpen:false});
  };

  onChangeStart = (event: ChangeEvent<HTMLInputElement>) => {
    let today = new Date();
    const { value } = event.target;
    const startDate = new Date(value);
    const endDate = new Date(this.state.enddate);
    today = new Date(today);
    let invalidCheck = false;
    if (endDate && startDate > endDate) {
      this.setState({dateError:"Start date cannot be greater than End date"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    }
    if (today<startDate){
      this.setState({dateError:"Start date cannot be greater than today"}, ()=>this.handleSnackbar());
      invalidCheck=true;
    } 
    if(!invalidCheck){
      this.setState({ startdate: value }, ()=>this.handleTimelineSelect());
    }
  };

onChangeEnd = (event: ChangeEvent<HTMLInputElement>) => {
  let today = new Date();
  const { value } = event.target;
  const startDate = new Date(this.state.startdate);
  const endDate = new Date(value);
  today = new Date(today);
  let invalidCheck = false;
  if (endDate < startDate) {
    this.setState({dateError:"End date cannot be less than Start date"}, ()=>this.handleSnackbar());
    invalidCheck=true;
  }
  if (today<endDate){
    this.setState({dateError:"End date cannot be greater than today"}, ()=>this.handleSnackbar());
    invalidCheck=true;
  } 
  if(!invalidCheck){
    this.setState({ enddate: value }, ()=>this.handleTimelineSelect());
  }
};

handleTimelineSelect = () => {
  const startDate = this.state.startdate;
  const endDate = this.state.enddate;
  let updateMainButton  = this.state.filterMain
if(startDate&&endDate) {
  updateMainButton = updateMainButton.map(
    (value: MainButtonItem, indexItem: number) => {
      return indexItem === 4
        ? { ...value, checked: true}
        : value;
    }
  );
}
else {
  updateMainButton = updateMainButton.map(
    (value: MainButtonItem, indexItem: number) => {
      return indexItem === 4
        ? { ...value, checked: false}
        : value;
    }
  );
}
this.setState({filterMain:updateMainButton}, ()=>this.saleReportDataSorting());
};

handleSortDropdown = () => {
  this.setState((prevState)=>({isOpenSortBySalesR:!prevState.isOpenSortBySalesR, providerShow: false}))
}

handleMainChangeFilter = (index:number) => {
  this.setState((prevState) => {
    let updatedMainButton;
    if(index===2||index===3||index===4){ updatedMainButton = prevState.filterMain.map(
      (value: MainButtonItem, indexItem: number) => {
        return indexItem === index
          ? { ...value, isShow: !value.isShow }
          : { ...value, isShow: false };
      }
    );
  }
    else{
      updatedMainButton = prevState.filterMain.map(
        (value: MainButtonItem, indexItem: number) => {
          return indexItem === index
            ? { ...value, checked: !value.isShow, isShow: !value.isShow }
            : { ...value, isShow: false };
        }
      );
    }
    return {
      filterMain: updatedMainButton
    };
  }, () => this.saleReportDataSorting());
};

handleCheckboxChange = (index: number) => {
  this.setState(
    (prevState) => {
      const updatedSelection = prevState.providerArray.map(
        (value1: ProviderArrayItem, index1: number) => {
          return index1 === index
            ? { ...value1, checked: !value1.checked }
            : value1;
        }
      );
      const providerChecked = updatedSelection.some(
        (value: ProviderArrayItem) => value.checked
      );
      const updateMainButton = prevState.filterMain.map(
        (value2: MainButtonItem, index2: number) => {
          return index2 === 3
            ? { ...value2, checked: providerChecked }
            : value2;
        }
      );
      return {
        providerArray: updatedSelection,
        filterMain: updateMainButton,
      };
    },
    () => this.saleReportDataSorting()
  );
};

handleSKUBoxChange = (index: number) => {
  this.setState(
    (prevState) => {
      const updatedSelection = prevState.skuArray.map(
        (value1: ProviderArrayItem, index1: number) => {
          return index1 === index
            ? { ...value1, checked: !value1.checked }
            : value1;
        }
      );
      const providerChecked = updatedSelection.some(
        (value: ProviderArrayItem) => value.checked
      );
      const updateMainButton = prevState.filterMain.map(
        (value2: MainButtonItem, index2: number) => {
          return index2 === 2
            ? { ...value2, checked: providerChecked }
            : value2;
        }
      );
      return {
        skuArray: updatedSelection,
        filterMain: updateMainButton,
      };
    },
    () => this.saleReportDataSorting()
  );
};

saleReportDataSorting = () => {
  let mainButton = this.state.filterMain;
  let salesProviders = this.state.providerArray;
  let salesSku = this.state.skuArray;
  let salesValues = this.state.values;
  let salesQuantity = this.state.values2;
  let salesCommissions = this.state.values3;
  const startDate = this.state.startdate;
  const endDate = this.state.enddate;
  let dataSorting = this.state.salesReport;
  if (mainButton[3].checked) {
    dataSorting = dataSorting.filter((value2: SalesReportItem) =>
    salesProviders.some(
        (value: ProviderArrayItem) => value2.provider == value.text && value.checked
      )
    );
  }
   if (mainButton[0].checked) {
    dataSorting = dataSorting.filter(
      (value2: SalesReportItem) => 
      parseInt(value2.value.slice(1)) >= salesValues[0] &&
      parseInt(value2.value.slice(1)) <= salesValues[1]
    );
  }
  if (mainButton[1].checked) {
    dataSorting = dataSorting.filter(
      (value2: SalesReportItem) =>
      parseInt(value2.qty) >= salesQuantity[0] &&
      parseInt(value2.qty) <= salesQuantity[1]
    );
  }
  if (mainButton[2].checked) {
    dataSorting = dataSorting.filter((value2: SalesReportItem) =>
    salesSku.some(
        (value: ProviderArrayItem) => value2.sku == value.text && value.checked
      )
    );
  }
  if (mainButton[4].checked) {
    dataSorting = dataSorting.filter((item:SalesReportItem) => {
      return item.date >= startDate && item.date <=endDate;
    });
  }
  if (mainButton[5].checked) {
    dataSorting = dataSorting.filter(
      (value2: SalesReportItem) =>
      parseInt(value2.commission.slice(1)) >= salesCommissions[0] &&
      parseInt(value2.commission.slice(1)) <= salesCommissions[1]
    );
  }
  this.handleSalesChangePageOnSorting(dataSorting)
};

handleSalesChangePageOnSorting = (dataSorting:SalesReportItem[]) => {
  const pageCount=Math.ceil(dataSorting.length / 10);
  let currentPage=this.state.page;
  if(currentPage>pageCount){
    currentPage=1;
  }
  let startingIndex = (currentPage - 1) * 10;
  this.setState({
    salesReportSorted: dataSorting,
    salesReportShow: dataSorting.slice(startingIndex, startingIndex + 10),
    page: currentPage
  });
};

handleSalesExportConfirm=()=>{
  if(this.state.salesReportSorted.length!==0)
  {
      if(this.state.isNewUserChecked){
      this.exportSalesToPDF()
      this.setState({openModal:false})
    }
    if(this.state.isExistingUserChecked){
      this.exportSalesToExcel();
      this.setState({openModal:false})
    }
  }
  this.setState({
    isNewUserChecked:false,
    isExistingUserChecked:false,
  })
}

exportSalesToExcel() {
  let newData = this.state.salesReportSorted;
  let data = newData.map((item:SalesReportItem) => {
    return {
      "Provider": item.provider,
      "SKU": item.sku,
      "Quantity": item.qty,
      "Value": item.value,
      "Commission": item.commission,
    };
  });
  let columnsWidth=[23,20,20,20,20];
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  worksheet['!rows'] = Array.from({length:data.length + 1}).map(()=>{return { hpx: 20 }})
  worksheet['!cols'] = columnsWidth.map(width => ({ width }));
  XLSX.utils.book_append_sheet(workbook, worksheet, "VAS Total Sales & Commission");
  XLSX.writeFile(workbook, "VAS_total_sales_and_commission.xlsx");
};

exportSalesToPDF = () => {
  const data = this.state.salesReportSorted;
  const doc = new jsPDF as unknown as ExtendedJsPDF;
  const columns: string[] = [
    "Provider",
    "SKU",
    "Quantity",
    "Value",
    "Commission",
  ];
  const rows: string[][] = data.map((obj:SalesReportItem) => [
    obj.provider,
    obj.sku,
    obj.qty,
    obj.value,
    obj.commission,
  ]);
  doc.setFontSize(18);
  doc.text('VAS Total Sales & Commission',13.5, 15);
  const options: AutoTableOptions = {
    startY:25,
    head: [columns],
    body: rows,
  };
  doc.autoTable(options);
  doc.save('VAS_total_sales_and_commission.pdf');
};

SortByModal = () => {
  this.setState(prevState => ({
    isOpenDateModal: !prevState.isOpenDateModal
  }));
};
handleSortFilterSalesR = () => {
  this.setState({ selectAsendingS: !this.state.selectAsendingS })
}

SortByModalCategory = () => {
  this.setState(prevState => ({
    providerShow: !prevState.providerShow,
    isOpenSortBySalesR:false
  }));
};
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // this.getSalesReport();
    this.getSalesReportList();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    document.body.addEventListener('click', this.handleClickBody);
    // Customizable Area End
  }

  setSelectedPeriod = (period: { pkey: string, value: string }) => {
    let momentKey: unitOfTime.StartOf;
    switch (period.pkey) {
      case 'day':
        momentKey = 'week'
        break;
      case 'week':
        momentKey = 'month'
        break;
      case 'month':
        momentKey = 'year'
        break;
      default:
        momentKey = 'day'
    }
    const start = moment().startOf(momentKey);
    const ends = moment().endOf(momentKey);
    this.setState({ selectedFrame: period.pkey, selectedDate: { from: start, through: ends } });
    this.getSalesReport(this.state.token, period.pkey, start.valueOf(), ends.valueOf());
  }

  setSelectedDate = (direction: string = 'left') => {
    let momentKey: unitOfTime.StartOf;
    switch (this.state.selectedFrame) {
      case 'day':
        momentKey = 'week'
        break;
      case 'week':
        momentKey = 'month'
        break;
      case 'month':
        momentKey = 'year'
        break;
      default:
        momentKey = 'day'
    }

    const start = direction === 'left' ?
      this.state.selectedDate.from.subtract(1, momentKey) :
      this.state.selectedDate.from.add(1, momentKey);
    const ends = direction === 'left' ?
      this.state.selectedDate.through.subtract(1, momentKey) :
      this.state.selectedDate.through.add(1, momentKey);

    this.setState({ selectedDate: { from: start, through: ends } });
    this.getSalesReport(this.state.token, this.state.selectedFrame, start.valueOf(), ends.valueOf());
  };

  getSalesReport = (
    token: string = "",
    frame: string = this.state.selectedFrame,
    start: number = this.state.selectedDate.from.valueOf(),
    ends: number = this.state.selectedDate.through.valueOf()) => {
    const header = {
      "Content-Type": configJSON.getSalesReportApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let params = new URLSearchParams();
    params.append('frame', frame);
    params.append('start', start.toString());
    params.append('end', ends.toString());

    this.salesReportApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSalesReportApiEndPoint}?${params.toString()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ loading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  decideLabels = () => {
    const frameKey = this.state?.selectedFrame || 'week';
    let labels: string[] = [];
    if (frameKey !== 'week') {
      labels = configJSON.chartLabels[this.state?.selectedFrame]
    }

    if (labels.length === 0) {
      const from = this.state?.selectedDate.from || moment().startOf('month');
      const ends = this.state?.selectedDate.through || moment().endOf('month');
      let tempDate = moment(this.state?.selectedDate.from).startOf('week');
      while (tempDate.isBefore(ends) || tempDate.isSame(ends)) {
        if (tempDate.isAfter(from) || tempDate.isSame(from)) {
          labels.push(tempDate.format('DD.MM.YYYY'))
        }
        tempDate = moment(tempDate.add(1, 'w'));
      }
    }
    return labels;
  }

  prepareChartData = (reporting: Reporting) => {
    let labels: string[] = this.decideLabels();

    let hourLabels: string[] = [];

    const mobileSalesData: number[] = []
    const mobileSalesCumulativeData: number[] = []
    const webSalesData: (string | number)[][] = [configJSON.webSalesChartDataLabels]
    const webSalesCumulativeData: (string | number)[][] = [configJSON.webSalesChartDataLabels]
    
    let sums = 0;
    let hourGroupSum = 0;
    labels.forEach((label: string, l_index: number) => {
      sums += reporting.sales[label] || 0;
      if (this.state?.selectedFrame === 'hour' && l_index % 2 === 0) {
        hourLabels.push(label)
        hourGroupSum += reporting.sales[label] || 0;
        mobileSalesData.push(hourGroupSum);
        mobileSalesCumulativeData.push(sums)
        hourGroupSum = 0;
      }
      else if (this.state?.selectedFrame === 'hour' && l_index % 2 !== 0) {
        hourGroupSum += reporting.sales[label] || 0;
      }
      else {
        mobileSalesData.push(reporting.sales[label] || 0);
        mobileSalesCumulativeData.push(sums)
      }

      webSalesData.push([label, reporting.sales[label] || 0])
      webSalesCumulativeData.push([label, sums])
    })


    let _revenueBreakdown: RevenueBreakdownUnit[] = []
    Object.keys(reporting.revenueBreakdown).forEach((revenue, r_index) => {
      _revenueBreakdown.push({
        name: revenue,
        value: reporting.revenueBreakdown[revenue],
        color: configJSON.chartColors[r_index % configJSON.chartColors.length],
        legendFontColor: "#7F7F7F",
        legendFontSize: 15
      })
    })

    return {
      currency: reporting.currency,
      totalSaleUnit: reporting.overview.unit,
      totalSaleRevenue: reporting.overview.revenue,
      mobileSalesData: {
        labels: hourLabels.length === 0 ? labels : hourLabels,
        datasets: [
          {
            data: mobileSalesData
          }
        ]
      },
      mobileSalesCumulativeData: { labels: hourLabels.length === 0 ? labels : hourLabels, data: mobileSalesCumulativeData },
      revenueBreakdown: _revenueBreakdown,
      webSalesData,
      webSalesCumulativeData,
      webRevenueBreakdown: [configJSON.webRevenueBreakdownLabels, ...Object.keys(reporting.revenueBreakdown).map((report) => [report, reporting.revenueBreakdown[report]])],
    }
  }
  // Customizable Area End
}
