import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Box, styled } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from "@material-ui/icons/Refresh";
import { removeStorageData } from "../../framework/src/Utilities";
export const RedDashboard = require('./redhome.png')
export const RedBell = require('./redbell.png');
export const RedProfile = require('./redprofile.png');
export const RedSearch = require('./redsearch.png');
export const a2payNew = require('./a2PayWhiteLogo.svg');


export const cut = require('./cut.png');

interface Props {
  anchor: "right";
  style: any;
}

const drawerWidth = 450; // Set your drawer width

const supplierOptionArray = [
  "Proof of Banking (Banking Confirmation Letter)",
  "ID",
  "Proof of Business Address",
  "Business Registration"
];

const InnerBox = styled(Box)({
  marginTop: '20px',
  marginLeft: '20px',
  '@media (max-width: 992px)': {
    marginLeft: '10px',
  },
})
const WidthSidebar = styled('div')({
  '@media (min-width: 992px)': {
    display: 'none'
  }
})
const Customimg = styled('img')({
  padding: 20,
  '@media (min-width: 992px)': {
    display: 'none',
  }
})
const CustomTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: 600,
  '@media (max-width: 992px)': {
    fontSize: '12px',
  },
})
const CustomLineDiv = styled(Typography)({

  '@media (max-width: 600px)': {
    height: 1,
    backgroundColor: 'lightgray',
    marginTop: 20
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    height: 1,
    backgroundColor: 'lightgray',
    marginTop: 20
  }
})
const CustomDiv = styled(Box)({
  marginTop: 20,
  '@media (max-width: 600px)': {
    marginTop: 0
  },
})

const OperatorSupplierMenu = styled(Box)({
  display:"flex",
  flexDirection:"column"
});

const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    width: drawerWidth,
  },
  fullList: {
    width: '643px',
    '@media (min-width: 450px) and (max-width: 992px)': {
      width: '350px !important'
    },
    '@media (max-width: 450px)': {
      width: '280px !important'
    },
  },
  selected: {
    color: '#C12030', // Red color for selected item
    '&:hover': {
      backgroundColor: '#FCF4F5', // Light pink background during hover
    },
  },
  opSupplierMenuList:{
    paddingLeft: "15px",
    '@media (max-width: 992px)': {
      paddingLeft:"6px"
    }
  },
  opMenuListItem: {
    paddingLeft: theme.spacing(2),
    paddingTop:"0",
    paddingBottom:"0",
    cursor:"pointer",
    display:"flex",
    gap:"10px",
    '&:hover': {
      backgroundColor: '#0000000A'
    },
    '@media (max-width: 992px)': {
      paddingLeft:"6px"
    }
  },
  opBullet: {
    display: 'inline-block',
    boxSizing:"border-box",
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: "black",
    '@media (max-width: 992px)': {
      width: '6px',
      height: '6px'
    }
  },
  opListItemTextStyle:{
    fontSize: "1rem",
    fontFamily: "Poppins",
    '@media (max-width: 992px)': {
      fontSize:"12px"
    }
  },
  listItem: {
    '@media (max-width: 992px)': {
      paddingLeft:"6px"
    }
  },
  changeBG:{
    display: "flex", 
    justifyContent: 'space-between',
    padding:'28px 0',

    '@media (max-width: 992px)': {
      backgroundColor:"black",
    }
  },
  refreshBtn:{
    marginRight: '-20px' ,
    '@media (max-width: 992px)': {
    color:"white"
    }
  },
  menuBtn:{
    '@media (max-width: 992px)': {
    color:"white" 
    }
  }
}));

const CommonHeaderOperator: React.FC<Props> = ({ anchor }) => {
  const classes = useStyles();
  let path = window.location.pathname;
  const [state, setState] = useState<{ [key: string]: boolean }>({
    right: false,
  });
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [supplierItemSelected, setSupplierItemSelected] = useState<string | null>(null);
  const [operatorSupplierMenuOpen, setOperatorSupplierMenuOpen] = useState(false);

  const handleCutClick = () => {
    setState({ ...state, [anchor]: false });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleListItemClick = (text: string) => {
    setSelectedItem(text);
    switch (text) {
        case "Open a Bank Account- Personal":
          window.location.href = "/OperatorApplyForBankAccount";
          break;
          case "Cash Float":
          window.location.href = "/OperatorCashFloat";
          removeStorageData('terminal')
          removeStorageData('amount')
          removeStorageData('flag')
          break;
          case `Shopper's Account`:
          window.location.href = "/OperatorShopperAccount";
          break;
          case "Apply for Finance":
            window.location.href = "/OperatorApplyForLoan";
          break;
          case "Apply for a Virtual Assistant":
          window.location.href = "/VirtualAssistanceOperator";
          break;
          case "Require Support":
            window.open('https://a2pay.co.za/contact-us/');
            break;
          case "E-Learning":
            window.open('https://learning.a2pay.co.za/');
            break;
          case "A2Z Orders":
            window.open('https://shop.a2pay.co.za/test')
      default:
        break;
    }

    if(text!=="Open a Supplier Account"){
      setState({ ...state, [anchor]: false });
    } else {
      handleSupplierMenuOpen()
    }
  };

  const handleOperatorSupplierSub = (value:string) => {
    setSupplierItemSelected(value);
    handleCutClick();
  };

  const handleSupplierMenuOpen = () => {
    setOperatorSupplierMenuOpen((prevState)=>!prevState);
  };

  const list = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "right",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Box style={{ display: 'flex', justifyContent: 'end', marginRight: '19px', cursor:"pointer" }}>
          <img src={cut} onClick={handleCutClick} alt="Cut" style={{ width: '28px', height: '28px' }} />
        </Box>
        <InnerBox>

          <WidthSidebar>
            <div style={{ color: "#848FAC" }}>
              <Link to="/OperatorDashboard" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center' }}>
                {path == "/OperatorDashboard" ?
                  <img alt="logo" src={RedDashboard} style={{ marginLeft: '6px' }} />
                  : <HomeOutlinedIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/OperatorDashboard" ? "" : "Dashboard"}</span>
              </Link>
            </div>
            <div style={{ color: "#848FAC", marginTop: 20 }}>
              <Link to="/OperatorNotification" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center' }}>
                {path == "/OperatorNotification" ?
                  <img alt="logo" src={RedBell} />
                  : <NotificationsNoneIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/OperatorNotification" ? "" : "Notification"}</span>
              </Link>
            </div>
        
            <div style={{ color: "#848FAC", marginTop: 20 }}>
              <Link to="/OperatorUserProfile" style={{ all: "unset", color: "#848FAC", appearance: "none", display: 'flex', gap: 20, alignItems: 'center' }}>
                {path == "/OperatorUserProfile" ?
                  <img alt="logo" src={RedProfile} />
                  : <PersonOutlineIcon style={{ width: '24px', height: '24px' }} />}
                <span style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Poppins", marginLeft: '-10px' }}>{path == "/OperatorUserProfile" ? "" : "Profile"}</span>
              </Link>
            </div>

          </WidthSidebar>
          <CustomLineDiv />

          {['A2Z Orders','Cash Float',`Shopper's Account`,'Open a Bank Account- Personal','Apply for Finance','Apply for a Virtual Assistant', 'Open a Supplier Account', 'Require Support', 'E-Learning'].map((text, index) => (
            text!=="Open a Supplier Account"?
            <ListItem
              button
              key={text}
              onClick={() => handleListItemClick(text)}
              className={clsx(classes.listItem,{
                [classes.selected]: selectedItem === text,
              })}
            >

              <CustomTypography
                variant="h6"
              >
                {text}
              </CustomTypography>
            </ListItem>:
             <OperatorSupplierMenu>
             <ListItem
              button
              onClick={() => handleListItemClick(text)}
              className={clsx(classes.listItem,{
                [classes.selected]: selectedItem === text
              })}
            >
              <CustomTypography
                variant="h6"
              >
                {text}
              </CustomTypography>
            </ListItem >
                {operatorSupplierMenuOpen&&<List className={classes.opSupplierMenuList}>
                    {supplierOptionArray.map((item:string, indexNumber:number) => 
                    <ListItem 
                      onClick={()=>handleOperatorSupplierSub(item)}
                      key={indexNumber}
                      className={clsx(classes.opMenuListItem,{[classes.selected]: supplierItemSelected === item})}
                      button
                    >
                      <span className={classes.opBullet}></span>
                      <Typography className={classes.opListItemTextStyle}>{item}</Typography>
                    </ListItem>)}
                  </List>
                }
            </OperatorSupplierMenu>
          ))}
        </InnerBox>
      </List>
    </div>
  );

  return (
    <div>
      {["right"].map((drawerAnchor) => (
        <React.Fragment key={drawerAnchor}>
         <div className={classes.changeBG}>
            <div style={{ cursor: "pointer" }}>
              <Link to="/Dashboard">
                <Customimg alt="logo" src={a2payNew} style={{ width: '84px', height: '41px' }} />
              </Link>
            </div>
            <CustomDiv style={{  display: "flex", alignItems:'center',margin: '0 20px 0 0' }}>
            <Button className={classes.refreshBtn}>
                <RefreshIcon />
              </Button>
              <Button onClick={toggleDrawer(true)} className={classes.menuBtn}>
                <MenuIcon />
              </Button>
            </CustomDiv>
          </div>

          <SwipeableDrawer
            anchor={drawerAnchor as "right"}
            open={state[drawerAnchor]}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            {list()}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommonHeaderOperator;
