import React from "react";

import {
  Box,
  Typography,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { landingPage } from "./assets";

const LandImage = styled('img')({
  maxWidth: "302px",
  width: '100%',
  justifyContent: "center",
  alignItems: "center",
  '@media (max-width: 600px)': {
    maxWidth: "150px",
    width: '100%',
    justifyContent: "center",
    alignItems: "center",
  },
})

const CommonTypography = styled(Box)({
    fontSize: "23px",
    textAlign: "center",
    color: "#999BAC",
    marginTop: "22px",
    fontFamily: "Poppins",
    fontWeight: 400,
  '@media(max-width: 600px)': {
    fontSize: "12px",   
    marginTop: "10px",
 
  }
})
const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex",height:'100%' }}>
       <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                    <Box style={{ width: '100%'}}>
                            <CommonDrawer  anchor={"right"} style={{width:'100%'}}  />
                    </Box>         
       
       
       
        <Box style={{  width: '100%', justifyContent: 'center', display: 'flex', height: '100%', padding:'0 30px',boxSizing:'border-box',marginBottom:'30px' }}>
          <Box style={webStyle.mainContainer}>
            <Box style={webStyle.imageContainer}>
              <LandImage src={landingPage} style={webStyle.landingImage} />
            </Box>
            <Box style={webStyle.textContainer}>
              <CommonTypography style={webStyle.textHey}>
                <Box>
                  <Box>Howzit!</Box>
                  <Box>
                  Please go to User Settings to upload your document. 
                  For immediate assistance, call us at: "12xxxxxxx".
                  </Box>
                </Box>
              </CommonTypography>
            </Box>
          </Box>
          </Box>
          </RightBoxLanding2>
        </Box>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column", // This will stack the child elements vertically
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
    backgroundColor: '#fff'
  } as React.CSSProperties,
  landingImage: {
    // maxWidth: "302px",
    // width: '100%',
    // justifyContent: "center",
    // alignItems: "center",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  textContainer: {
    display: "flex",
    maxWidth: "682px",
    width:'100%',
    height: "128px",
  } as React.CSSProperties,
  textHey: {
    // fontSize: "23px",
    // textAlign: "center",
    // color: "#999BAC",
    // marginTop: "22px",
    // fontFamily: "Poppins",
    // fontWeight: 400,
  } as React.CSSProperties,
  secondContainer:
  {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0"
  } as React.CSSProperties,
 
};
// Customizable Area End
