import React from "react";

import {
    Box,
    Button,
    Typography,
    MenuItem,
    Select,
    FormControl,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"
const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import {  downArrow, leftArrow } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


const CustomTypography = styled(Typography)({
    paddingLeft: '54px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "690px",
    width: '100%',
    height: "max-content",
    boxSizing:'border-box',
    paddingTop: "30px",
    paddingBottom:'30px',
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        margin: '10px',
        width:"unset"
    }
})
const CustomButton = styled(Button)({
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "30px",
    color: "#fff",
    maxWidth: '600px',
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: 400,
    '@media(max-width: 600px)': {
        marginTop: "10px",
    },
    '&:hover': {
        backgroundColor: "#C12030",
    }
});

const StoreBox = styled(Box)({
    maxWidth: "600px",
    width: '100%',
    height: "200px",
    borderRadius: '12px',
    marginTop: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '30px',
    marginRight: '30px',
    paddingTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 600px)': {
        width: '80%',
        margin: '20px auto'
    }
})
const WalletBox = styled(Box)({
    maxWidth: "600px",
    width: '100%',
    height: "270px",
    borderRadius: '12px',
    marginTop: '25px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '30px',
    marginRight: '30px',
    paddingTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 600px)': {
        width: '80%',
        margin: '20px auto'
    }
})

const WalletTop = styled(Box)({
    '@media(max-width: 990px)': {
        marginTop: "0 !important",
        display:"none"
      }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "20px !important"
      }
  })
  const HeaderBox = styled(Box)({
    '@media(max-width: 990px)': {
      display:"unset !important"
    }
  })
  const WalletTopForMobile = styled(Box)({
    display:"none",
    '@media(max-width: 990px)': {
      display:"block"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";

export default class TransferToOtherWalletTwo extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        let showSuccess = await getStorageData('showSuccess')
        let clearAmount = await getStorageData('clearAmount') 
        let amount = await getStorageData('amount')
        console.log("selectStore::::====>",amount)
        if (showSuccess != null) {
            this.handleSuccess()
        }
        if(clearAmount){
           // this.setState({ amount: amount, })


        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
         
            <Box>
            <SideNavbar />
            <RightBoxLanding2 style={webStyle.secondContainer}>
            <HeaderBox style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </HeaderBox>
          <WalletTopForMobile style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</WalletText>
            </WalletTopForMobile>
                <CustomBox style={webStyle.backContainer}>
                    <Box style={webStyle.backStyle} 
                        data-test-id={"back-arrow"}>
                        <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={() => this.handleTransferToOtherWallet()} data-test-id={'backImageId'}/>
                        <Typography style={webStyle.backIcon} onClick={() => this.handleTransferToOtherWallet()} data-test-id={'backTextId'}>Back</Typography>
                    </Box>
                    <StoreBox style={webStyle.storeContainer}>
                        <label style={{ ...webStyle.storeLable, color: this.state.storeError ? 'red' : 'inherit' }}>
                        Store Name
                        </label>
                  
                    <Box style={webStyle.storeContainerStyle}>
                            <Box style={webStyle.textBox}>
                                <input
                                    className="no-outline"
                                    readOnly={true}
                                    type="text"
                                    name="username"
                                    value={'Kahndal Saree Store'}
                                    style={webStyle.inputStyle} />
                            </Box>
                        </Box>
                    </StoreBox>
                    <WalletBox style={webStyle.walletConatiner}>
                        <Box style={webStyle.boxStyle}>
                            <Typography style={webStyle.transferText}>Add Money to</Typography>
                            <Typography style={webStyle.transferText2}>Wallet</Typography>
                        </Box>
                        <Box style={webStyle.textContainer}>
                            <Box style={webStyle.textBox}>
                                <Typography style={webStyle.smalltext as React.CSSProperties}>R</Typography>
                                <input
                                    className="no-outline"
                                    type="text"
                                    name="username"
                                    placeholder="Enter amount"
                                    value={this.state.amount}
                                    onChange={this.handleSetEndAmount}
                                    style={webStyle.inputStyle} />
                            </Box>
                        </Box>
                        <Box style={webStyle.amountBox}>
                            <Button style={webStyle.amountBoxStyle}
                               onClick={() => this.onClickHandler(300)} data-test-id={'amountaddwalletfirst'}>
                                <Typography style={webStyle.amountText}>+R300</Typography>
                            </Button>
                            <Button style={webStyle.amountBoxStyle} onClick={() => this.onClickHandler(500)} data-test-id={'amountaddwalletSecond'}>
                                <Typography style={webStyle.amountText}>+R500</Typography>
                            </Button>
                            <Button style={webStyle.amountBoxStyle} onClick={() => this.onClickHandler(1000)} data-test-id={'amountaddwalletThird'}>
                                <Typography style={webStyle.amountText}>+R1000</Typography>
                            </Button>
                        </Box>

                        <CustomButton style={webStyle.firstButton} onClick={() => this.sendstore()} data-test-id={'buttonPress'}>
                            Proceed to Add
                        </CustomButton>


                    </WalletBox>

                </CustomBox>
                <Toaster position="top-center" toastOptions={{
                        success: {
                            style: {
                                padding: '16px',
                            },
                        },
                    }} />
            </RightBoxLanding2>
        </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {

    secondContainer:
        {
            backgroundColor: "#E2E8F0",
            marginBottom:'30px'
        } as React.CSSProperties,
    mainContainer: {
        display: "flex",
        flexDirection: "column", // This will stack the child elements vertically
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "1173px",
        width: '100%',
        height: "851px",
        backgroundColor: '#fff'
    } as React.CSSProperties,
    backContainer: {
        backgroundColor: '',

    },
    backStyle:{
        display: 'flex', 
        marginLeft: 40 ,
    },
    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },
    storeContainer: {
      
        height: "106px",
    },
    storeLable: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        color: "#273567",
         marginTop: '8px'
    },
    titleStyle:{
        fontFamily: 'Inter',
        fontWeight: 700,
         fontSize: '24px'
    },
    formStyle: {
        border: `0.5px #273567`,
        borderRadius: "18px",
        width: '100%'
    },
    storeDetailsText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        marginTop: '8px'
    },
    storeDetailsText2: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '24px',
        marginTop: '8px'
    },
    walletConatiner: {
        // maxWidth: "600px",
        // width: '100%',
        // height: "270px",
        // borderRadius: '12px',
        // marginTop: '25px',
        // paddingLeft: '15px',
        // paddingRight: '15px',
        // marginLeft: '30px',
        // marginRight: '30px',
        // paddingTop: '15px',
        // boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',

    },
    boxStyle: {
        display: 'flex',
        marginTop: '8px'
    },
    transferText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 500
    },
    transferText2: {
        color: '#C12030',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 500,
        marginLeft: '5px'
    },
    textContainer: {
        display: 'flex',
        borderRadius: '12px',
        justifyContent: 'space-between',
        border: '0.5px solid #273567',
        alignItems: 'center',
        width: '96%',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '50px',
        marginTop: 22
    },
    storeContainerStyle:{
        display: 'flex',
        borderRadius: '12px',
        justifyContent: 'space-between',
        border: '0.5px solid #273567',
        alignItems: 'center',
        width: '96%',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '50px',
        marginTop: '10px'
    },
    textBox: {
        display: 'flex'
    },
    smalltext: {
        alignSelf: 'center',
        fontWeight: '500',
        marginRight: '1%',
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#C12030'
    },
    inputStyle: {
        color: "#000000",
        fontFamily: 'Poppins',
        border: 'none',
        borderColor: 'white',
        fontWeight: 500,
        fontSize: '14px',
        alignSelf: 'center',
        outline: 'none',
        width: '70%',

    },
    amountBox: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'flex-end'
    },
    amountBoxStyle: {
        height: '32px',
        maxWidth: '73px',
        width: '100%',
        borderRadius: '12px',
        borderWidth: '5px',
        border: '1px solid #273567',
        marginRight: '25px'
    },
    amountText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,
    firstButton: {
        // backgroundColor: "#C12030",
        // fontSize: "16px",
        // marginTop: "30px",
        // color: "#fff",
        // maxWidth: '600px',
        // width: "100%",
        // height: "50px",
        // borderRadius: "8px",
        // textTransform: "capitalize",
        // fontFamily: 'Poppins',
        // fontWeight: 400
    } as React.CSSProperties,

    selectStoreStyle: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,

};
// Customizable Area End
