import React from "react";

import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { landingBack, storeicon, cross } from "./assets";
import { configJSON } from "./LandingPageController";

const Carousel = require("react-material-ui-carousel").default
interface CardItem {
  referenceNumber: string;
  PosStatus: string;
  walletBalance: number;
  overallCommissions: number;
  buxBalance: number;
  bonusBalance: number;
  numberTerminals: number;
  numberCardDevices: number;
  cardTransactionsMTD: string;
  cashDepositorMTD: string;
  loanStatus: string;
  outStandingLoanAmount: number;
}

const BackBox = styled(Box)({
  boxSizing:'border-box',
  maxWidth: "1173px",
  width: "100%",
  padding:'0 20px',
  "@media(max-width: 600px)": {
    maxWidth: "100%",
    width: "100%",
    height:'100px',
    "& .carouselWrapper":{
    height:'100%',
    overflow:'visible',
    "&>div":{
      overflow:'hidden',
      "&:first-child":{
        height:'100% !important',
        "& img":{
          height:'100%'
        }
      },}}
  },
  "& .carouselWrapper":{
    overflow:'visible',
    "&>div":{
      overflow:'hidden',
      borderRadius:'10px',
      "&:nth-child(2)":{
        right:'-20px',
        "@media(max-width: 992px)":{
          display:'none'
        },
        "&:hover>button":{
          filter:"brightness(1)",
          opacity:'1 !important'
        }
      },
      "&:nth-child(3)":{
        "@media(max-width: 992px)":{
          display:'none'
        },
        left:'-20px',
        "&:hover>button":{
          filter:"brightness(1)",
          opacity:'1 !important'
        }
      }
    }
  }
});
const RightBoxLanding = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});

const CustomBox = styled(Box)({
  padding: "40px",
  display: "flex",
  alignItems: "center",
  "@media(max-width: 800px)": {
    padding: "10px",
    flexWrap: "wrap",
  },
});

const CustomImage = styled("img")({
  height: "393px",
  borderRadius: "8px",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});

const ModalTypography = styled(Typography)({
  marginTop: "0px",
  "@media(max-width: 600px)": {
    marginTop: "10px",
    fontSize:"18px !important"
  },
});
const ContentTypography = styled(Typography)({
  marginLeft: 30,
  "@media(max-width: 600px)": {
    marginLeft: 20,
  },
});
const ContentBox = styled(Box)({
  marginLeft: 30,
  padding: 0,
  "@media(max-width: 600px)": {
    marginLeft: 0,
    padding: 10,
  },
});
const CardBox = styled(Box)({
  padding: "0px 40px",
  "@media(max-width: 600px)": {
    padding: "0px",
  },
});
const VasBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "20px",
  "@media(max-width: 1140px)": {
    flexWrap: "wrap",
  },
});
const SubTextVas = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: 400,
  "@media(max-width: 600px)": {
    marginTop: "20px",
  },
});
const RedButton = styled(Button)({
  textTransform: "capitalize",
  "@media(max-width: 600px)": {
    width: "100% !important",
  },
  "@media(max-width:780px)": {
    flexWrap: "wrap",
  },
});
const GrayButton = styled(Button)({
  textTransform: "capitalize",
  "@media(max-width: 600px)": {
    width: "100% !important",
  },
});
const CustomCrossImg = styled("img")({
  width: "32px",
  height: "32px",
  marginTop: "0px",
  cursor:"pointer",
  "@media(max-width:600px)": {
    marginTop: "8px",
  },
});

const ModalText = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  fontFamily: "Poppins",
  "@media(max-width:600px)": {
    fontSize: "14px",
  },
});

const ModalButtonMain = styled(Box)({
  "@media(max-width:780px)": {
    flexWrap: "wrap",
  },
});
const SingleBtnParent = styled(Box)({
  "@media(max-width:600px)": {
    width: "100% !Important",
  },
});
const NavBtn = styled('div')({
  backgroundColor:"#fff",
  color:'#C12030',
  width:'40px',
  height:'40px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  fontSize:'20px',
  fontWeight:400,
  fontFamily:'Poppins',
  borderRadius:'50%',
  left:'20px'
})
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";

export default class LandingPageFirst extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   async componentDidMount() {
    this.setState({listStore:configJSON.storeCardData})
  }

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex",boxSizing:'border-box' }}>
        <SideNavbar />
        <RightBoxLanding style={webStyle.secondContainer}>
          <Box style={{ width: "100%" }}>
            <CommonDrawer anchor={"right"} style={{ width: "100%" }} />
          </Box>

          <BackBox>
            <Carousel className="carouselWrapper" NextIcon={<NavBtn >{'>'}</NavBtn>} PrevIcon={<NavBtn>{"<"}</NavBtn>}
              indicators={false} navButtonsAlwaysVisible={true} swipe={true} animation="slide" duration={1000}
              fullHeightHover={false}     
              navButtonsProps={{          
                  style: {
                      backgroundColor: '#fff',
                      borderRadius: '50%',
                      width:'40px',
                      height:'40px',
                      margin:0,

                  }
              }} 
              navButtonsWrapperProps={{  
                  style: {
                      width:'40px',
                      height:'40px',
                    borderRadius:'50%',
                    top:'50%',
                      transform: 'translate(0, -50%)'
                    
                  }
              }} 
            >

              <img
                src={landingBack}
                style={{
                  ...webStyle.image1,
                }}
              />
              <img
                src={landingBack}
                style={{
                  ...webStyle.image1,
                }}
              />
              <img
                src={landingBack}
                style={{
                  ...webStyle.image1,
                }}
              />
            </Carousel>
          </BackBox>

          <Grid
            container
            style={{
              width: "100%",
              maxWidth: "1173px",
              // background: localStorage.getItem("Role") == "Operator" ? "" : "white",
              borderRadius: 12,
             display:'flex',
             justifyContent:'center',
             alignItems:"center"

            }}
          >
            <Box style={{margin: '20px',borderRadius:"10px",...webStyle.mainContainer}}>
            {this.state.listStore
    .slice(0, localStorage.getItem("Role") == "Operator" ? 1 : this.state.listStore.length)
    .map((value: CardItem, index: number) => (
                  <Card
                    key={index}
                    data-test-id={`storeCardTestID${index}`}
                    style={{
                      boxShadow: "0px 0px 5px lightgray",
                      maxWidth: 345,
                      cursor: "pointer",
                    }}
                    onClick={() => this.handleCardClick(value)}
                  >
                    <CardContent>
                      <Box sx={{ marginBottom: 2 }}>
                        <img
                          src={storeicon}
                          alt="Store"
                          style={{ height: "248px", width: "100%" }}
                        />
                      </Box>

                      <Typography style={webStyle.topHeader2}>
                        Khandal saree store, 403
                      </Typography>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Reference Number:
                        </Typography>

                        <Typography style={webStyle.referenceThree}>
                          {value.referenceNumber}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          POS License Status:
                        </Typography>

                        <Typography
                          style={{
                            ...webStyle.approveText2,
                            color:
                              value.PosStatus === "Active"
                                ? "#059669"
                                : "#C12030",
                          }}
                        >
                          {value.PosStatus}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Wallet Balance:
                        </Typography>

                        <Typography style={webStyle.refrence2}>
                          {value.walletBalance}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Overall Commissions:
                        </Typography>

                        <Typography style={webStyle.refrence2}>
                          {value.overallCommissions}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Bux Account Balance:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.buxBalance}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Bonus Account Balance:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.bonusBalance}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          No. Terminals:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.numberTerminals}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          No. Card Devices:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.numberCardDevices}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Card Transactions MTD:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.cardTransactionsMTD}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Cash Depositor MTD:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.cashDepositorMTD}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Loan Status:
                        </Typography>
                        <Typography
                          style={{
                            ...webStyle.approveText2,
                            textAlign:'right',
                            color: this.handleLoanStatusColor(value.loanStatus),
                          }}
                        >
                          {value.loanStatus}
                        </Typography>
                      </Box>

                      <Box style={webStyle.cardTexts}>
                        <Typography style={webStyle.refrence}>
                          Outstanding Loan Amount:
                        </Typography>
                        <Typography style={webStyle.refrence2}>
                          {value.outStandingLoanAmount}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )
              )}
            </Box>
            {localStorage.getItem("Role") !== "Operator" &&
            <Button
             style={this.state.isFullStore? {...webStyle.seeMoreButton,background:'grey',color:'white'} :webStyle.seeMoreButton}
             data-test-id="seeMoreButtonTestID"
             disabled={this.state.isFullStore}
             onClick={this.handleSeeMoreStore}
            >
              <span style={{fontSize:'16px'}}>{configJSON.seeMoreButtonText}</span>
            </Button>
  }
          </Grid>
          <Modal
            data-test-id="storeDeatailModalTestID"
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={
              {
                overflowY: "auto",
                backgroundColor: "#fff",
                width: "90%",
                height: "90%",
                display: "flex",
                margin: "auto",
              } as React.CSSProperties
            }
          >
            <div
              style={{
                width: "100%",
                background: "#fff",
                height: "fit-content",
              }}
            >
              <CustomBox style={{ ...webStyle.modalStyle }}>
              
                <Box style={{ flexDirection: "column", width: "100%" }}>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ModalTypography style={webStyle.ModalHeader}>
                      Khandal saree store, 403
                    </ModalTypography>
                    <CustomCrossImg
                      src={cross}
                      onClick={this.handleModalClose}
                    />
                  </Box>
                 
                </Box>
              </CustomBox>
              <CardBox>
                <Card
                  style={{
                    borderRadius: 0,
                    boxShadow: "unset",
                    padding: 15,
                  }}
                >
                  <CardContent
                    style={{
                      boxShadow:"0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <VasBox>
                      <Box>
                        <ModalText>
                          VAS Total Sales & Commission by provider
                        </ModalText>
                        <SubTextVas style={webStyle.secondHeaderModal}>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </SubTextVas>
                      </Box>
                      <SingleBtnParent>
                        <RedButton
                          style={{
                            border: "2px solid #C12030",
                            borderRadius: "5px",
                            padding: "10px",
                            width: "240px",
                          }}
                          onClick={this.handleSelectProviderBtn1}
                        >
                          <Typography
                            style={{
                              color: "#C12030",
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: 400,
                            }}
                          >
                            Select Provider
                          </Typography>
                        </RedButton>
                      </SingleBtnParent>
                    </VasBox>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    boxShadow: "unset",
                    borderRadius: 0,
                    padding: 15,
                  }}
                >
                  <CardContent
                    style={{
                      boxShadow:
                        "0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <VasBox>
                      <Box>
                        <ModalText>Product Sales by Category</ModalText>
                        <SubTextVas style={webStyle.secondHeaderModal}>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </SubTextVas>
                      </Box>
                      <ModalButtonMain
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <GrayButton
                          style={{
                            borderRadius: "5px",
                            border: "2px solid #848FAC",
                            padding: "10px",
                            width: "240px",
                          }}
                          data-test-id="compareProviderButtonTestID"
                          onClick={this.gotoProvider}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#848FAC",
                            }}
                          >
                            Compare Provider
                          </Typography>
                        </GrayButton>
                        <RedButton
                          style={{
                            borderRadius: "5px",
                            border: "2px solid #C12030",
                            width: "240px",
                            padding: "10px",
                          }}
                          data-test-id="selectCategoryButtonTestID"
                          onClick={this.gotoCatogory}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#C12030",
                            }}
                          >
                            Select Category
                          </Typography>
                        </RedButton>
                      </ModalButtonMain>
                    </VasBox>
                  </CardContent>
                </Card>

                <Card
                  style={{
                    boxShadow: "unset",
                    borderRadius: 0,
                    padding: 15,
                  }}
                >
                  <CardContent
                    style={{
                      boxShadow:
                        "0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <VasBox>
                      <Box>
                        <ModalText>Loan Detail</ModalText>
                        <SubTextVas style={webStyle.secondHeaderModal}>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </SubTextVas>
                      </Box>
                      <ModalButtonMain
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <GrayButton
                          style={{
                            borderRadius: "5px",
                            border: "2px solid #848FAC",
                            width: "240px",
                            padding: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#848FAC",
                            }}
                          >
                            Loan agreement paperwork
                          </Typography>
                        </GrayButton>
                        <RedButton
                          style={{
                            border: "2px solid #C12030",
                            borderRadius: "5px",
                            width: "240px",
                            padding: "10px",
                          }}
                          data-test-id={"gotoLoanID"}
                          onClick={() => this.gotoLoan()}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#C12030",
                            }}
                          >
                            View statement
                          </Typography>
                        </RedButton>
                      </ModalButtonMain>
                    </VasBox>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    boxShadow: "unset",
                    padding: 15,
                    borderRadius: 0,
                  }}
                >
                  <CardContent
                    style={{
                      boxShadow:
                        "0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <VasBox>
                      <Box>
                        <ModalText>Stock Management</ModalText>
                        <SubTextVas style={webStyle.secondHeaderModal}>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </SubTextVas>
                      </Box>
                      <ModalButtonMain
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <GrayButton
                          style={{
                            borderRadius: "5px",
                            border: "2px solid #848FAC",
                            width: "240px",
                            padding: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#848FAC",
                              width: "240px",
                            }}
                          >
                            View Replenish Stock
                          </Typography>
                        </GrayButton>
                        <RedButton
                          style={{
                            border: "2px solid #C12030",
                            borderRadius: "5px",
                            width: "240px",
                            padding: "10px",
                          }}
                          onClick={this.handleReplenishBtnNew}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "15px",
                              color: "#C12030",
                            }}
                          >
                            View Stock Levels
                          </Typography>
                        </RedButton>
                      </ModalButtonMain>
                    </VasBox>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    borderRadius: 0,
                    padding: 15,
                    boxShadow: "unset",
                  }}
                >
                  <CardContent
                    style={{
                      boxShadow:
                        "0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <VasBox>
                      <Box>
                        <ModalText>Wallet History</ModalText>
                        <SubTextVas style={webStyle.secondHeaderModal}>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </SubTextVas>
                      </Box>
                      <SingleBtnParent
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <RedButton
                          style={{
                            border: "2px solid #C12030",
                            borderRadius: "5px",
                            padding: "10px",
                            width: "240px",
                          }}
                          data-test-id="viewHistoryButtonTestID"
                          onClick={this.GoWalletHistory}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              color: "#C12030",
                              fontSize: "15px",
                            }}
                          >
                            View History
                          </Typography>
                        </RedButton>
                      </SingleBtnParent>
                    </VasBox>
                  </CardContent>
                </Card>
              </CardBox>
            </div>
          </Modal>
        </RightBoxLanding>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  secondContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height:"1100px"
    backgroundColor: "#E2E8F0",
  } as React.CSSProperties,
  image1: {
    // maxWidth: '1173px',
    width: "100%",
  } as React.CSSProperties,
  topHeader2: {
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  image: {
    width: "100%",
    height: "248px",
    borderRadius: "8px",
  },
  cardTexts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "8px",
  } as React.CSSProperties,
  cardTexts4: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "5px 0",
    gap: "8px",
  } as React.CSSProperties,
  cardTexts5: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
  cardTexts2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
  cardTexts3: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
  refrence: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
  } as React.CSSProperties,
  refrence2: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    textAlign:'right'
  } as React.CSSProperties,
  referenceno: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#C12030",
  } as React.CSSProperties,
  referenceThree: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#C12030",
  } as React.CSSProperties,
  approveText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#059669",
  },
  approveText2: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#059669",
  },
  referenceno2: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#C12030",
  } as React.CSSProperties,
  mainContainer: {
    display: "flex",
    flexDirection: "row", // Display cards in a row
    flexWrap: "wrap", // Allow cards to wrap to the next row if needed
    justifyContent: "center", // Center the content horizontally
    // padding: 30,
    backgroundColor: "#fff",
    border: "10px",
    alignItems: "center", // Center the content vertically
    marginTop: 20, // Add some top margin if needed
    // width: '100%', // Make sure it takes up the full width
    gap: 30,
    padding: '40px',
    width:'100%'
  } as React.CSSProperties,
  seeMoreButton: {
    width: "228px",
    height: "50px",
    color: "#fff",
    fonstSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    backgroundColor: "#C12030",
    margin: "32px auto 30px",
    textTransform: "capitalize",
  } as React.CSSProperties,
  modalStyle: {
    // display: 'flex'
  },
  ModalHeader: {
    fontSize: "24px",
    fontWeight: 500,
    fontFamily: "Poppins",
    marginLeft: 17,
  },
  ModalContent: {
    maxWidth: "446px",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#848FAC",
    marginTop: "8px",
  } as React.CSSProperties,
  modalTexts: {
    backgroundColor: "red",
  },
  secondHeaderModal: {
    maxWidth: "647px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#848FAC",
    fontFamliy: "Poppins",
    width: "100%",
  } as React.CSSProperties,
  secondHeaderModal2: {
    width: "647px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#848FAC",
    fontFamliy: "Poppins",
  } as React.CSSProperties,
};
// Customizable Area End
