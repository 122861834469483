import React from "react";

import {
    Box,
    Card,
    CardContent,
    Modal,
    Tooltip,
    Typography,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import Header from "../../../components/src/Header.web"
import SideNavbar from "../../../components/src/SideBar.web"
import { storeicon, BackIcn, DeleteIcn, CloseIcn } from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"
import { setStorageData } from "../../../framework/src/Utilities";


const RightBoxLandingCommon = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const AddContainer = styled(Box)({
    '@media(max-width: 700px)': {
        flexWrap:"wrap"
    }
})

const AddCard = styled(Box)({
    '@media(max-width: 700px)': {
        maxWidth: "100% !important"
    }
})

const SubmitBtnRes = styled(Box)({
    '@media(max-width: 700px)': {
        maxWidth: "100% !important",
        margin:"10px 0"
    }
})

const CardText = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"14px !important"
    }
})

const CardSubText = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"12px !important"
    }
})

const RefText = styled(Typography)({
    '@media(max-width: 800px)': {
       fontSize:"12px !important"
    }
})

const OperatorName = styled(Box)({
    '@media(max-width: 800px)': {
       width:"50% !important"
    }
})

const OperatorButton = styled(Box)({
    '@media(max-width: 800px)': {
        width:"50% !important"
     }
})

const ConfirmPopup = styled(Box)({
    '@media(max-width: 670px)': {
        width:"440px !important"
     },
     '@media(max-width: 490px)': {
        width:"260px !important"
     }
})

const MobileText = styled(Box)({
    '@media(max-width: 490px)': {
       fontSize:"13px !important"
     }
})

export default class AddOperators extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount = async () => {
    const storeId = this.props.navigation.getParam('storeId')
    this.handleGetOperatorStoreDetail(storeId)
    setStorageData('storeId',storeId)
    window.addEventListener('resize', this.handleResize);
       }

       handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
      };

       MEDIUM_SCREEN_WIDTH = 600;
       SMALL_SCREEN_WIDTH = 500;

       getEmailTruncateLength = () => {
        const { windowWidth } = this.state;
        if (windowWidth > this.MEDIUM_SCREEN_WIDTH) {
          return Infinity;
        } else if (windowWidth === this.MEDIUM_SCREEN_WIDTH) {
          return 20;
        } else if (windowWidth <= this.SMALL_SCREEN_WIDTH) {
          return 12;
        } else {
          return 20; 
        }
      };

      truncateEmail = (email: string) => {
        const truncateLength = this.getEmailTruncateLength();
        
        if (email.length > truncateLength) {
          return email.substring(0, truncateLength) + '...';
        }
        return email;
      };

  renderEmail = (email: string) => {
    const truncatedEmail = this.truncateEmail(email);
    if (truncatedEmail !== email) {
      return (
        <Tooltip title={email} placement="top">
          <Typography style={webStyle.emailId as React.CSSProperties}>
            {truncatedEmail}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <Typography style={webStyle.emailId as React.CSSProperties}>
        {email}
      </Typography>
    );
  };
    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <RightBoxLandingCommon style={{ width: "100%", backgroundColor: "rgb(226, 232, 240)" }}>
                <Box style={webStyle.drawerBox }>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Header />
                    <Box style={webStyle.addsMain} >
                    <Box style={{ cursor:"pointer",marginBottom:"30px",maxWidth:"80px"}} data-test-id="backsetting" onClick={()=>this.props.navigation.navigate('OperatorSettings')}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center",fontFamily: 'Poppins',fontWeight: 500, }}>
                                <img src={BackIcn} alt="back-icn" />
                                Back
                            </Typography>
                        </Box>
                        <AddContainer style={{ display: "flex", justifyContent: "space-between", alignItems: "end",}}>
                            <AddCard style={{ width: "100%", maxWidth: "50%" }}>
                                <Card>

                                    <CardContent style={{ padding: "10px" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>

                                            <Box sx={{ marginBottom: 2, height: '110px', width: "220px" }}>
                                                <img src={storeicon} alt="Store" style={{ width: '100%', height: "100%",objectFit:"contain" }} />
                                            </Box>
                                            <Box style={{width:"100%"}}>
                                                <Box sx={{ marginLeft: "20px" }}>
                                                    <CardText style={webStyle.productTitle as React.CSSProperties}>Khandal saree store, 403</CardText>
                                                    <Box style={webStyle.cardTexts2 as React.CSSProperties}>
                                                        <CardSubText style={webStyle.productDescription as React.CSSProperties}>Reference Number</CardSubText>
                                                        <RefText style={webStyle.referenceno as React.CSSProperties}>A4D0V2</RefText>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </AddCard>
                            <SubmitBtnRes onClick={this.handleNavigate} style={webStyle.settingTabDoc as React.CSSProperties}>
                                <Typography style={webStyle.tabTextDoc as React.CSSProperties}>Add New Operator</Typography>
                            </SubmitBtnRes>
                        </AddContainer>
                        {this.state.operatorData.map((item, index) => (
                            <Box style={webStyle.operatorListMain as React.CSSProperties}>
                                <OperatorName style={{ width: "80%",cursor:"pointer"}} data-test-id={`approveOperator${index}`} onClick={()=>this.handleNavigateApprove(item.OperatorEmail)}>
                                    <Typography style={webStyle.nameMain as React.CSSProperties}>
                                        {item.Operator}
                                    </Typography>
                                    <Typography style={webStyle.emailId as React.CSSProperties}>
                                    {this.renderEmail(item.OperatorEmail)}
                                    </Typography>
                                </OperatorName>
                                <OperatorButton style={webStyle.ButtonsMain as React.CSSProperties}>
                                    <Box style={{ cursor: "pointer", height: "40px", marginRight: "15px" }} data-test-id={`deleteOperator${index}`} onClick={()=>this.handleDeleteOprat(item.OperatorEmail)}>
                                        <img src={DeleteIcn} alt="delete-icn" style={{ height: "100%", width: "100%" }} />
                                    </Box>
                                    <Box data-test-id={`Approve-${index}`} style={webStyle.ApproveMain as React.CSSProperties} onClick={()=>this.handleAction(item.OperatorEmail,'approve')}>
                                        <Typography style={webStyle.approveBtnText as React.CSSProperties}>  Approve</Typography>
                                    </Box>
                                </OperatorButton>
                                <Box>

                                </Box>
                            </Box>
                        ))}
                    </Box>
                    
                    <Modal
                        open={this.state.removeOprat}
                        onClose={this.handleHide}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <ConfirmPopup style={{
                            backgroundColor: "white",
                            padding: "10px",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            border: '2px solid white',
                            borderRadius: "8px 8px 32px 8px",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 500,
                            outline: "none",
                            width:"100%",
                            maxWidth:"600px"
                        }}>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                            <MobileText style={webStyle.modalText as React.CSSProperties}>
                                Are you sure you want to remove operator?
                            </MobileText>
                            <Box style={{display:"flex",justifyContent:"flex-end",marginTop:"5px",marginRight:"10px",cursor:"pointer"}} onClick={this.handleHide}>
                                <img src={CloseIcn} alt="close-icn"/>
                            </Box>
                            </Box>
                            <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
                            <Box style={webStyle.buttonContainer as React.CSSProperties}>
                                <Box style={webStyle.noButton as React.CSSProperties} onClick={this.handleHide}>
                                    <MobileText style={{fontFamily: "Poppins",fontWeight:700}}>No</MobileText>
                                </Box>
                                <Box data-test-id={"confirmDeleteBtn"} style={webStyle.yesButton as React.CSSProperties} onClick={()=>this.handleAction(this.state.setEmail,'delete')}>
                                    <MobileText style={{fontFamily: "Poppins",fontWeight:700}}>Yes</MobileText>
                                </Box>
                            </Box>
                        </ConfirmPopup>
                    </Modal>
                    <Toaster position="top-center" />

                </RightBoxLandingCommon>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    productTitle: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    cardTexts2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: "10px 0"
    },
    productDescription: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    },
    approveText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    approveText2: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#059669'
    },
    referenceno: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#C12030'
    },
    settingTabDoc: {
        padding: "8px",
        borderRadius: "8px",
        border: "2px solid #C12030",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"
    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '30px',
        fontSize: '16px',
        color: "#C12030",
    },
    operatorListMain: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "15px 0"
    },
    ButtonsMain: {
        display: "flex",
        alignItems: "center",
        width: "20%",
        justifyContent: "flex-end"
    },
    nameMain: {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "#000000",
        marginBottom:"5px"
    },
    emailId: {
        color: "#848FAC",
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "18px",
    },
    ApproveMain: {
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "80px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer"
    },

    modalText:{
        fontSize:"16px",
        fontFamily:"Poppins",
        fontWeight:"600",
        lineHeight:"24px",
    },


    approveBtnText: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '30px',
        fontSize: '16px',
        color: "#ffffff",
    },

    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-end",
        marginRight:"20px"

    },

    yesButton: {
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#ffffff",
    },

    noButton: {
        borderRadius: "8px",
        border:"1px solid #848FAC",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#848FAC",
        marginRight:"10px"
    },
    addsMain:{
        padding: "20px 40px", 
        backgroundColor: "rgb(226, 232, 240)"
    }as React.CSSProperties,

    drawerBox:{ width: '100%',backgroundColor: "rgb(226, 232, 240)" }as React.CSSProperties,


}
// Customizable Area End