import React from "react";


// Customizable Area Start

import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  styled,
  Paper,
  TableContainer,
  Table,
  Input,
} from "@material-ui/core";

import SideNavbar from "../../../components/src/SideBar.web";

import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, search, } from "./assets";

const ShopperBoxLanding = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const ShopperWallet = styled(Typography)({
  fontFamily: 'Poppins',
  color: '#000',
  fontWeight: 600,
  fontSize: '20px',
  //padding: '10px 16px',
  // width: "30%",
  '@media(max-width: 600px)': {
    padding: '0px'
  }
})


const ShopperBackMain = styled(Box)({
  display: 'flex',
  margin: "10px 0"
})
const SearchBox = styled(Box)({

})


const ShopperBox = styled(Box)({
  display: 'flex',
  flexWrap: "wrap",
  gap: '8px',
  backgroundColor: "#FFFFFF",
  padding: "17px 24px 17px",
  borderRadius: "4px",
  justifyContent: 'space-between',
  margin: "20px 0",
  maxWidth: "100%",
  alignItems: 'center'

})

const ShopperCustomButton2 = styled(Button)({
  alignItems: 'center',
  margin: '20px 0px ',
  border: '1px solid #C12030',
  borderRadius: '8px',
  maxWidth: '159px',
  width: '100%',
  height: '46px',

})




interface Data {
  customerID: string;
  name: string;
  accountBalance: string;
  phoneNo: string
}

interface Column {
  id:
  | "customerID"
  | "name"
  | "phoneNo"
  | "accountBalance"
  label: string;
  minWidth?: number;
  align?: "right";
}

const providercolumns: Column[] = [
  { id: "customerID", label: "Customer ID" },
  { id: "name", label: "Name" },
  { id: "phoneNo", label: "Phone No" },
  { id: "accountBalance", label: "Account Balance" },
];


// Customizable Area End

import CfshoppersaccountController, {
  Props,
  configJSON,
} from "./CfshoppersaccountController";

export default class Cfshoppersaccount extends CfshoppersaccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getShopperAccountList()
  }
  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;

    return (
      // Customizable Area Start
      <Box style={webStyle.userSettingContainer} data-test-id={'handleBodyClickOnScreen'} onClick={(e) => this.handleBodyClickOnScreen(e)}>
        <SideNavbar />
        <ShopperBoxLanding style={webStyle.providerSecondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>
          <Box style={{ padding: "0px 40px 40px 40px" }}>
            <ShopperBackMain >
              <img src={leftArrow} onClick={this.handleLandingPageFirst} style={webStyle.backArrow} />
              <Typography onClick={this.handleLandingPageFirst} style={webStyle.backText}>Back</Typography>
            </ShopperBackMain>
            <ShopperBox >
              {!this.state.isSearchText &&
                <ShopperWallet>
                  Shopper Account
                </ShopperWallet>
              }
              <SearchBox style={{
                maxWidth: this.state.isSearchText ? '' : '353px',
                height: '44px', borderRadius: '8px', border: '1px solid #CBD5E1',
                padding: '0px 15px 0px 15px', display: 'flex', justifyContent: 'space-between',      // Use flexbox layout
                alignItems: 'center', width: '90%',
              }} id='dateModal' className="dateModal">
                <Box style={{
                  width: '100%', justifyContent: 'space-between',
                  display: 'flex',       // Use flexbox layout
                  alignItems: 'center',
                }} id='dateModal' className="dateModal">
                  <input
                    data-test-id={"txtInput"}
                    type={"text"}
                    placeholder={'Search by name, phone or ID'}
                    style={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: 400, outline: 'none', border: 'none', width: '85%' }}
                    value={this.state.searchText}
                    onChange={(e) => this.handleSearch(e)}
                    onClick={this.onClickSearchText}
                    id='dateModal' className="dateModal"
                  />
                  <img src={search} onClick={this.handleLandingPageFirst} style={webStyle.searchIcon} id='dateModal' className="dateModal" />

                </Box>
              </SearchBox>


            </ShopperBox>
            <Paper style={{ padding: "0px 25px 5px 20px", cursor: 'pointer' }}>
              <TableContainer style={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {providercolumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={webStyle.columnCell}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          onClick={() => this.gotoShopperDetails(row)}
                        >
                          {providercolumns.map((column) => {
                            const value = row[column.id];

                            return (
                              <TableCell key={column.id} align={column.align} style={webStyle.columnCellTwo}>
                                {typeof value === 'string' ? (
                                  value.split('R').map((part: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {index === 0 ? (
                                        <span>
                                          {value[1] === 'R' && value[0] === '+' || value[0] === '-' ? '' : part}
                                        </span>
                                      ) : (
                                        <React.Fragment>
                                          <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>
                                            {value[0]}
                                          </span>

                                          <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>
                                            R
                                          </span>

                                          <span style={{ color: value[0] === '+' ? 'green' : 'red' }}>{part}</span>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <span>{value}</span>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>


                </Table>
              </TableContainer>
              <ShopperCustomButton2 onClick={this.handleButtonClick}>
                <Typography style={{ ...webStyle.exportText, textAlign: 'center' }}>+ Add Account</Typography>
              </ShopperCustomButton2>

            </Paper>
          </Box>

        </ShopperBoxLanding>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  userSettingContainer: {
    backgroundColor: "#E2E8F0",
    width: '100%',
    height: '100%'
  },
  providerSecondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,

  columnCellTwo: {
    color: "#475569",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  backArrow: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  searchIcon: {
    width: '11.37px',
    height: '11.37px',
    cursor: 'pointer',
  },
  backText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '8px',
    cursor: 'pointer'
  },
  columnCell: {
    fontSize: '14px',
    fontWeight: 700, width: '100px',
    color: '#475569',
    fontFamily: 'Poppins'
  } as React.CSSProperties,
  exportText: {
    fontFamily: 'Poppins',
    fontSixe: '16px',
    fontWeigth: '400',
    color: '#C12030',
    textAlign: 'right',
    textTransform: 'capitalize',
    //marginLeft: '10px'
  } as React.CSSProperties,

}
// Customizable Area End
