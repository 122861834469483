import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Modal,
  Fade,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { background_image, cross, greentick, a2payImage } from './assets';
const BackimgForgot = styled('img')({
  height:"100%",
  '@media (max-width: 600px)': {
      width:'100%',
      height: '400px ',
      objectFit: 'fill',

  },
  '@media (min-width: 600px) and (max-width: 960px)': {
    objectFit: 'fill',
      height: '500px ',
      width: '100%',
    },
})
const LogoimgForgot = styled('img')({
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },
})

const CustomGridForgot = styled(Grid)({
  padding: '0px 80px',
  '@media (max-width: 600px)': {
    padding: '0px 20px'
  }
})
const MobTypographyForgot = styled(Typography)({
  fontSize: 28,
  '@media (max-width: 600px)': {
    fontSize: 20
  }
})
const MobSubTypographyForgot = styled(Typography)({
  fontSize: 18,
  fontFamily:"Poppins",
  '@media (max-width: 600px)': {
    fontSize: 16
  }
})
const CustomModalForgot = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll',
  "@media (max-width: 600px)": {
    '& .MuiPaper-root': {
      width: '90%',
    },
  },
  "@media (min-width: 601px) and (max-width: 1024px)": {
    '& .MuiPaper-root': {
      width: '70%',
    },
  },
});
const VarTypographyForgot = styled(Typography)({
  fontSize: 40,
  marginTop: 0,
  '@media (max-width: 600px)': {
    fontSize: 28,
    marginTop: 10
  }
});
const SubTypographyForgot = styled(Typography)({
  whiteSpace: 'nowrap',
  marginTop: 0,
  '@media (max-width: 600px)': {
    fontSize: '12px',
    marginTop: 10,
    whiteSpace: 'normal'
  }
})
const TimeTypographyForgot = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'normal',
  marginTop: 0,
  '@media (max-width: 600px)': {
    fontSize: '12px',
    marginTop: 10,
    whiteSpace: 'nowrap'
  }
})
const ModalBoxForgot = styled(Box)({
  height: '60%',
  width: '100%',
  maxWidth: '400px',
  '@media (max-width: 600px)': {
    height: 'auto',
    width: '70%',
    maxWidth: '400px',
  }
})
const ImageCont = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  height: '100%',
  left:0,
  position:'fixed',
  width:'50%',
  '@media (min-width: 600px) and (max-width: 960px)': {
    height: '500px ',
    width: '100%',
    position:'relative',
    marginBottom:'20px',
  },

  '@media (max-width: 600px)': {
    height: '400px ',
    position:'relative',
    marginBottom:'20px',
    width:'100%'
  },
})

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

export default class ForgotPasswordFlow extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    const { seconds, timeout } = this.state;
    const displayTime = seconds >= 10 ? seconds : `0${seconds}`;
    return (
      // Customizable Area Start
      <Grid container justifyContent={"flex-end" }  style={{  height: "100vh" }}>
        <ImageCont item xs={12}
            sm={12} lg={6}
            md={6}
          >
          <LogoimgForgot src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top: 50, left: 242 }} />
          <BackimgForgot src={background_image} style={{ width: '100%' }} />
        </ImageCont>
        <CustomGridForgot item xs={12}
                sm={12} lg={6}
                md={6} >
          <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box style={{ width: "100%" }}>
              <MobTypographyForgot style={webStyle.welcomeText as React.CSSProperties}>Forgot Password</MobTypographyForgot>
              <MobSubTypographyForgot variant="body1" style={webStyle.merchantText as React.CSSProperties}>
                No Worries, We'll send an recovery OTP to your Registered Phone Number
              </MobSubTypographyForgot>
              <label style={webStyle.labelStyle as React.CSSProperties}>Phone Number</label>
              <input
                type="text"
                name="mobileNumber"
                id="phoneNumber"
                value={this.state.phoneNumber}
                data-test-id='forgotpassword'
                onChange={this.handlePhoneNumbervalidate}
                style={{
                  ...webStyle.inputStyle,
                  borderColor: this.state.invalidInputs.includes('mobileNumber') ? 'red' : '#273567',
                }}
              />
              {this.state.errorPhoneMessage && (
                                    <span style={{ color: 'red', fontSize: '16px', marginTop: 16, fontFamily: 'Poppins' }}>
                                     {this.state.errorPhoneMessage}
                                    </span>
                                )}


              <Button 
                data-test-id='forgotpasswordBtn'
                style={webStyle.nextButton as React.CSSProperties} onClick={this.handleOpenModalOtp}>
                Send a recovery link
              </Button>
            </Box>
          </Box>
          <CustomModalForgot
            aria-labelledby="transition-modal-title"
            onClose={this.handleCloseModal}
            aria-describedby="transition-modal-description"
            open={this.state.isModalOpen}
            closeAfterTransition
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.isModalOpen}>
              <div
                style={webStyle.Modal2 as React.CSSProperties}
              >
                <img
                  style={webStyle.crossImage2 as React.CSSProperties}
                  src={cross}
                  onClick={this.handleCloseModal}
                />
                <VarTypographyForgot variant="h5" style={webStyle.verificationText as React.CSSProperties}>Verification</VarTypographyForgot>

                <Box style={{
                  justifyContent: 'center',
                  display: 'flex'
                }}>
                 
                 {this.state.otp.map((digit, index) => (
  <input
    key={index}
    ref={el => this.inputRefs[index] = el}
    type="text"
    inputMode="numeric"
    pattern="\d*"
    maxLength={1}
    value={digit}
    style={webStyle.otpInput as React.CSSProperties}
    data-test-id={`txtInputotp${index}`}
    data-testId='otp-test'
    onChange={(e) => this.handleInputChangeForgot2(index, e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        this.handleInputChangeForgot2(index, '', true);
      }
    }}
    onFocus={(e) => e.target.select()}
  />
))}
                </Box>
                <SubTypographyForgot style={webStyle.cardOtp2 as React.CSSProperties
                }>Enter the OTP code contained in the Email we sent you</SubTypographyForgot>
                <TimeTypographyForgot
                  style={
                    timeout
                      ? { ...webStyle.otpVerificationText2, color: 'red' }
                      : webStyle.otpVerificationText2
                  }
                >
                  {timeout ? 'OTP verification timed out' : `OTP verification code expires in: ${displayTime}s`}
                </TimeTypographyForgot>
              {this.state.otpError && timeout === false &&   <TimeTypographyForgot
                  style={
                    { ...webStyle.otpVerificationText2, color: 'red' }
                  }
                  data-test-id="errorOtpMsg"
                >
                  {'Invalid OTP, please try again!'}
                </TimeTypographyForgot>}
                <Typography style={webStyle.resendOtpText2 as React.CSSProperties}>Resend OTP</Typography>
                <Button style={{...webStyle.submitButton2,width: '100%',opacity: this.state.otpError ? 0.6 : ""} as React.CSSProperties}  disabled={this.state.otpError} onClick={this.handleOpenModalNewUser2}>Submit</Button>
              </div>
            </Fade>
          </CustomModalForgot>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            open={this.state.tickModel}
            onClose={this.handleCloseModal2}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.tickModel}>
              <ModalBoxForgot
                sx={webStyle.greentickModal2}
              >
                <img
                  src={greentick}
                  style={{
                    height: '119px',
                    marginBottom: '20px',
                    width:'100%',
                    maxWidth: '119px',
                  }}
                />
                <Typography variant="h5" style={webStyle.userVerifiedText2 as React.CSSProperties}>User Verified</Typography>
              </ModalBoxForgot>
            </Fade>
          </Modal>
        </CustomGridForgot>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    paddingBottom: "30px",
    flexDirection: "column",
    background: "#fff",
    alignItems: "center",
  },
  inputStyle: {
    width: '100%',
    color: '#273567',
    top: '26px',
    border: '1px solid',
    borderRadius: '8px',
    padding: 15,
    height: '50px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500
  },
  buttonStyle: {
    height: "45px",
    marginTop: "40px",
    width: "100%",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  welcomeText: {
    color: '#000',
    fontWeight: '600',
    // fontSize: '28px',
    fontFamily: 'Poppins',
    lineHeight: '42px'
  },
  merchantText: {
    maxWidth: '491px',
    // fontSize: '18px',
    color: '#848FAC',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    fontFamily:"Poppins",
    marginBottom: 30,

  },
  nextButton: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    color: '#fff',
    marginTop: '42px',
    width: '100%',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    fontWeight: '400',
    height: '50px',
    textTransform: 'none', // Set to 'none' to prevent automatic capitalization
  },
  inputStyleTwo: {
    width: '100%',
    color: '#273567',
    border: '1px solid #273567',
    top: '26px',
    borderRadius: '8px',
    height: '40px',
    padding: 15,
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    fontWeight: 500
  },
  labelStyle: {
    fontFamily: 'Poppins',
    color: "#273567",
    letterSpacing: "0em",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 'normal',
    fontStyle: 'normal'
  },
  Modal2: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    // maxWidth: '400px',
    // height: 'auto',
    margin: 'auto',
    marginTop: '100px',
    // width: '50%',
    flexShrink: '0',
    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    '@media (max-width: 600px)': {
      width: '90%', // Adjust width for smaller screens
    },
    '@media (min-width: 601px) and (max-width: 1024px)': {
      width: '70%', // Adjust width for tablets
    },
  },
  crossImage2: {
    position: 'absolute',
    cursor: 'pointer',
    right: '5px',
    marginTop: -10,
    marginRight: 10
  },
  verificationText: {
    display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
    fontFamily: " Poppins",
    fontWeight: 500,
    // fontSize: "40px",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "140%"
  },
  otpInput: {
    textAlign: "center",
    fontSize: "18px",
    letterSpacing: "10px",
    margin: "10px",
    width: "2em",
    border: "none",
    borderBottom: "1px solid #273567",
    fontFamily: 'Poppins',
    outline: "none",
    fontWeight: 400,
    color: '#273567',
  },
  cardOtp2: {
    color: '#848FAC',
    textAlign: "center",
    fontSize: "19px",
    fontFamily: " Poppins",
    fontStyle: "normal",
    lineHeight: "140%",
    fontWeight: 400
  },
  otpVerificationText2: {
    display: 'flex',
    fontFamily: " Poppins",
    justifyContent: 'center',
    color: '#848FAC',
    alignContent: 'ceneter',
    textAlign: "center",
    fontWeight: 400,
    lineHeight: "140%",
    marginTop: '10',
    fontSize: "19px",
    fontStyle: "normal",
  } as React.CSSProperties,
  resendOtpText2: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#273567',
    marginTop: '10',
    textAlign: "center",
    fontFamily: " Poppins",
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%"
  },
  submitButton2: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    borderRadius: '8px',
    marginTop: '16px',
    color: '#fff',
    width: '100%',
    textTransform: 'capitalize',
    height: '50px',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  greentickModal2: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    // height: '60%',
    // width: '542px',
    borderRadius: '8px',
    // maxWidth: '400px',
    margin: 'auto',
    marginTop: '100px',
    flexShrink: '0',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
  },
  userVerifiedText2: {
    fontSize: "33px",
    justifyContent: 'center',
    lineHeight: "140%",
    fontWeight: 400,
    alignContent: 'center',
    textAlign: "center",
    color: '#030F2D',
    display: 'flex',
    fontFamily: " Poppins",
    fontStyle: "normal"
  },
};
// Customizable Area End
