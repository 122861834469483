import React, { Component } from 'react'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
export const a2pay = require('./a2pay.png');
import {
  getStorageData,
} from "../../framework/src/Utilities";
// import { baseURL } from "../../blocks/bulkuploading/src/BulkUploadingController";
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { styled } from '@material-ui/core';
export const DashboardActive = require('./DashboardActive.png')
export const ProfileActive = require('./Profileactive.png')
export const NotificationActive = require('./NotificatonActive.png')
export const SearchActive = require('./Searchactive.png')


const Customdiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  alignItems: 'center',
  width: '150px',
  // height: '96vh',
  padding: '20px 0px',
  '@media(max-width: 992px)': {
    display: 'none'
  }
})

export default class OperatorSideBar extends Component {
  profileId: string ="";
  state = {
    additionalPrice: "",
    isMobileView: false, 
  };


  async componentDidMount() {
    try {
      const profileImageOfBo = await getStorageData('profileImageOfBo');
      const additionalPrice = JSON.parse(profileImageOfBo);
      this.setState({ additionalPrice });
    } catch (error) {
      console.error('Error fetching profile image:', error);
    }
  }

  componentDidUpdate() {
    if (
      window.location.href.includes("BownerDetaild")
    ) {
     
    }
  }
  
  handleResize = () => {
    // Check if window width is less than 600px
    const isMobileView = window.innerWidth < 600;
    this.setState({ isMobileView });
  };
  

   render() {
    let path = window.location.pathname;

    return (
      <>
       <Customdiv style={{position: 'fixed', top: 0, background: 'white',minHeight:'100vh'}}>
        <div style={{cursor:"pointer"}}>
          <Link to="/OperatorDashboard">
            <img alt="logo" src={a2pay} style={{width: '84px',height: '41px',marginTop:20}}/>
            </Link>
        </div>
        <div>


            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC", marginTop:'150px',cursor:"pointer"}}>
            <Link to="/OperatorDashboard" style={{all:"unset",display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC",appearance:"none",}}>
               {path == "/OperatorDashboard"? 
            <img alt="logo" src={DashboardActive} />
            : <HomeOutlinedIcon style={{width: '24px',height: '24px'}}/>}
                <span style={{fontSize:"12px", fontWeight: 700, fontFamily:"Poppins"}}>{ path == "/OperatorDashboard" ? "" : "Dashboard"}</span>
                </Link>
            </div>
         

            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC", marginTop:'40px',cursor:"pointer"}}>
              <Link to="/OperatorNotification" style={{all:"unset",display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC", appearance:"none",}}>
                {path == "/OperatorNotification" ?
            <img alt="logo" src={NotificationActive} />:
            <NotificationsNoneIcon style={{width: '24px',height: '24px'}}/>}
                <span style={{fontSize:"12px", fontWeight: 700, fontFamily:"Poppins"}}>{path == "/OperatorNotification" ? "" : "Notification"}</span>
                </Link>            
            </div>
           

              <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC", marginTop:'40px',cursor:"pointer"}}>
                <Link to="/OperatorUserProfile" style={{all:"unset",display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", color:"#848FAC", appearance:"none",}}>
                {path==="/AddMyStores"||path === "/AddOtherStore"||path === "/StoreSettings" || path ===  "/StoreSettingDetails" || path === "/OperatorUserProfile" || path === "/BillingSettings" || path === "/OperatorSettings" || path === "/RegisterNewOperator" || path === "/AddOperatorSettings" || path === "/AddOperators" ?
            <img alt="logo" src={ProfileActive} /> :
                <PersonOutlineIcon style={{width: '24px',height: '24px'}}/>}
                <span style={{fontSize:"12px", fontWeight: 700, fontFamily:"Poppins"}}>{path ==="/AddMyStores" || path ===  "/StoreSettingDetails" || path === "/AddOtherStore"|| path === "/StoreSettings" || path === "/BillingSettings" || path === "/OperatorUserProfile" || path === "/OperatorSettings" || path === "/RegisterNewOperator" || path === "/AddOperatorSettings" || path === "/AddOperators" ? "" : "Profile"}</span>
                </Link>
            </div>

           
            
        </div>
      </Customdiv>
      </>
    )
  }
}