import React from "react";
import {
  Box,
  Typography,
  Button,
  Fade,
  Modal,
  styled,
  Select,
  MenuItem,
} from "@material-ui/core";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick, upload_icn } from "./assets";
import { Toaster } from "react-hot-toast";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";
import Header from "../../../components/src/Header.web";

const RightBoxLanding2 = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});
const ModalBoxForgot2 = styled(Box)({
  height: "60%",
  width: "100%",
  maxWidth: "400px",
  "@media (max-width: 600px)": {
    height: "auto",
    width: "70%",
    maxWidth: "400px",
  },
});
const StyledFileInput2 = styled("input")({
  border: "none",
  "::placeholder": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
  },
  height: "100%",
  width: "100%",
  top: 0,
  position: "absolute",
  outline: "none",
  cursor: "pointer",
  opacity: 0,
  left: 0,
});
const StyledSelect2 = styled(Select)({
  '&>#demo-simple-select-outlined': {
    display: 'flex',
    paddingLeft: '15px',
    flexDirection: 'column',
    fontSize: '14px',
    justifyContent: 'center',
    fontWeight: 500,
    color: '#273567',
    height: "100%", 
  },
  '&>.MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  }
})
export default class AddOtherStore extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  renderInputField2(name2:any, label:any) {
    const _this2 = this
    const inputStyles2 = {
      errorStyle: { border: "1px solid red" },
      baseStyle: { ...webStyle.inputStyle2 },
    validStyle: { border: "1px solid #273567" },
  };
  const borderValidate2 = this.state.requiredInput.includes(name2)
  ? "red"
  : "#273567";
  const checkValidBorder2 = (condition:boolean)=>{
    return condition? inputStyles2.validStyle.border
    : inputStyles2.errorStyle.border
  }
    const currentValue = this.state[name2 as keyof typeof _this2.state];
  const renderContactDetailField2 = () => (
    <>
      <input
        data-test-id="contactDetail"
        name={name2}
        value={this.state.contactDetail}
        type="text"
        onChange={this.handleContactDetailsChange}
        style={{
          ...inputStyles2.baseStyle,
          border:checkValidBorder2( this.state.isPhoneNumberValid)
            ,
        }}
      />
      {!this.state.isPhoneNumberValid && (
        <div style={webStyle.validationMessage2}>Invalid Phone Number</div>
      )}
    </>
  );

  const renderEmailAddressField2 = () => (
    <>
      <input
        name={name2}
        type="email"
        style={{
          ...inputStyles2.baseStyle,
          // borderColor: borderValidate2,
          border: this.state.isEmailValid
            ? inputStyles2.validStyle.border
            : inputStyles2.errorStyle.border,
        }}
        onChange={this.handleEmailChange}
        value={this.state.email}
      />
      {this.state.errorMessage && (
        <div className="errorMessageEmail2" style={webStyle.validationMessage2}>
          {this.state.errorMessage}
        </div>
      )}
    </>
  );

  const renderSelectField = () => {
    return (
      <StyledSelect2
        name={name2}
        value={currentValue}
        id="demo-simple-select-outlined"
        onChange={this.handleInputChanges}
        style={{
          ...inputStyles2.baseStyle,
          padding:0,
          top: 0,
          borderColor: borderValidate2,
        }}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
        disableUnderline
      >
        <MenuItem value={'yes'} style={webStyle.menuItem2}>Yes</MenuItem>
        <MenuItem value={'no'} style={webStyle.menuItem2}>No</MenuItem>
      </StyledSelect2>
    );
  };

  const renderUifNumberField = () => (
    <>
      <input
        data-test-id="uifNumber"
        name={name2}
        value={this.state.uifNumber}
        type="text"
        onChange={this.handleUifNumberChange}
        style={{
          ...inputStyles2.baseStyle,
          border: checkValidBorder2(this.state.isUifNumberValid)
           ,
        }}
      />
      {!this.state.isUifNumberValid && (
        <div style={webStyle.validationMessage2}>
          UIF Number should be 8 digits
        </div>
      )}
    </>
  );

  const renderKycDocumentationField2 = () => (
    <div id="kycDocumentation2">
      <div style={{ cursor: "pointer", marginTop: "5px" }}>
        <label htmlFor="kycDoc2" style={{ display: "block" }}>
          <div
            style={{
              ...webStyle.inputStyleDoc2,
              borderColor:this.state.showValidationMessage && !this.state.kycDocumentation 
              ? "red"
              : "",
              position: "relative",
            }}
          >
            <div id="kycDocumentationName2" style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                  }}>
              <span style={{
                  marginLeft: '5px',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 500,
                  color:'rgb(39, 53, 103)'
                }}>
                {this.state.kycDocumentation ? this.state.kycDocumentation : 'Upload Document'}
              </span>
            </div>
            <StyledFileInput2
              type="file"
              id="kycDoc2"
              data-test-id="kycDocumentation"
              name={name2}
              style={{
                width: "100%",
                top: 0,
                position: "absolute",
                height: "100%",
                cursor: "pointer",
                opacity: 0,
                left: 0,
                }}
              onChange={this.handleUploadKycDocumentation}
              onBlur={this.handleFileBlur}
            />
          </div>
        </label>
      </div>
      {this.state.showValidationMessage && !this.state.kycDocumentation && (
        <div id="errorMessageFileUpload2" style={webStyle.validationMessageDoc2}>
          Please upload in correct format (.PDF / .JPG / .PNG)
        </div>
      )}
    </div>
  );
  const renderNextOfKin = () => (
    <input
      name={name2}
      type="text"
      style={{
        ...inputStyles2.baseStyle,
        borderColor: borderValidate2,
      }}
      maxLength={50}
      value={currentValue}
      onChange={this.handleInputChanges2}
    />
  );
  const renderDefaultField = () => (
    <input
      name={name2}
      type="text"
      style={{
        ...inputStyles2.baseStyle,
        borderColor: borderValidate2,
      }}
      value={currentValue}
      onChange={this.handleInputChanges2}
    />
  );

  const inputFieldMap: Record<string, () => JSX.Element> = {
    emailAddress: renderEmailAddressField2,
    contactDetail: renderContactDetailField2,
    typeStore: renderSelectField,
    cipro: renderSelectField,
    nextOfKin:renderNextOfKin,
    kycDocumentation: renderKycDocumentationField2,
    uifNumber: renderUifNumberField,
    vatStatus: renderSelectField,
  };

  const inputField:JSX.Element = inputFieldMap[name2]  ? inputFieldMap[name2]() : renderDefaultField();

  return (
    <Box style={webStyle.fieldsMainOther as React.CSSProperties}>
      <label style={webStyle.labelStyle2 as React.CSSProperties}>{label}</label>
      {inputField}
    </Box>
  );
}


  render() {
    return (
      <Box style={webStyle.userSettingContainer2}>
        <SideNavbar />
        <RightBoxLanding2
          style={webStyle.secondContainer as React.CSSProperties}
        >
          <Box style={webStyle.drawerBox}>
            <CommonDrawer anchor={"right"} style={{ width: "100%" }} />
          </Box>
          <Header />
          <Box
            style={{
              paddingLeft: 40,
              display: "flex",
              maxWidth: "80px",
              cursor: "pointer",
              marginTop: "25px",
            }}
            onClick={this.goBack}
          >
            <img src={leftArrow} style={{ width: "24px", height: "24px" }} />
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#000",
                fontWeight: 500,
                paddingLeft: 8,
                cursor: "pointer",
              }}
            >
              Back
            </Typography>
          </Box>
          <Box style={webStyle.userSettingFeildsMian}>
            <Box style={webStyle.userSettingTextMain as React.CSSProperties}>
              <Typography
                style={webStyle.userSettingText as React.CSSProperties}
              >
                Add Other Store
              </Typography>
            </Box>
            {this.renderInputField2("storeName", "Store Name")}
            {this.renderInputField2("contactDetail", "Contact Details")}
            {this.renderInputField2("emailAddress", "Email Address")}
            {this.renderInputField2("nextOfKin", "Next of Kin")}
            {this.renderInputField2("storeAddress", "Store Address")}
            <Box sx={webStyle.cordinateBox}>
              <label style={webStyle.labelStyle as React.CSSProperties}>
                Store Coordinates
              </label>
              <input
                name="updateCoordinates"
                title={this.state.updateCoordinates}
                type="text"
                value={this.state.updateCoordinates}
                style={{
                  paddingRight:'50px',
                  borderColor: this.state.requiredInput.includes(
                    "updateCoordinates"
                  )
                    ? "red"
                    : "#273567",
                  ...webStyle.inputStyleAdd,
                }}
                readOnly
                onChange={this.handleInputChanges2}
              />
              <img
                alt="Capture Icon"
                onClick={this.handleCoordinatesClick}
                src={capture_icon}
                style={webStyle.captureIcon2 as React.CSSProperties}
              />
              {this.state.requiredInput.includes("updateCoordinates") && (
                <div style={{  color: "red", marginTop: "5px"}}>
                  Store Coordinates is required
                </div>
              )}
            </Box>
            {this.renderInputField2("typeStore", "Type of Store")}
            {this.renderInputField2("cipro", "CIPRO Registration Status")}
            {this.renderInputField2("vatStatus", "VAT Status")}
            {this.renderInputField2("uifNumber", "UIF Number")}
            {this.renderInputField2("kycDocumentation", "KYC Documentation")}
            <Button
              onClick={()=>this.handleSubmit3(false)}
              style={webStyle.submitButton2 as React.CSSProperties}
            data-test-id="submitBtn"
            >
              Submit
            </Button>
          </Box>
          <Toaster position="top-center" />
          <Modal
            aria-labelledby="transition-modal-title"
            open={this.state.tickModel}
            closeAfterTransition
            aria-describedby="transition-modal-description"
            onClose={this.handleCloseModal2}
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.tickModel}>
              <ModalBoxForgot2 sx={webStyle.greentickModal2}>
                <img
                  src={greentick}
                  alt="Green Tick"
                  style={{
                    width: "100%",
                    height: "119px",
                    marginBottom: "20px",
                    maxWidth: "119px",
                  }}
                />
                <Typography
                  variant="h5"
                  style={webStyle.userVerifiedText2 as React.CSSProperties}
                >
                  Store has been sent to Admin for approval, Hang Tight!"
                </Typography>
              </ModalBoxForgot2>
            </Fade>
          </Modal>
          <Modal
                    open={this.state.showUploadModal}
                    aria-describedby="modal-modal-description"
                    aria-labelledby="modal-modal-title"
                >
                    <Box style={{
                        backgroundColor: "black",
                        transform: 'translate(-50%, -50%)',
                        padding: "10px",
                        color: "white",
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        width: 300,
                        border: '2px solid #000',
                        textAlign: 'center',
                        fontSize: "24px",
                        fontFamily: "Poppins",
                        borderRadius: "12px",
                        fontWeight: 500
                    }}>
                        Uploading.....
                    </Box>
                </Modal>
        </RightBoxLanding2>
      </Box>
    );
  }
}

const webStyle = {
  userSettingContainer2: {
    backgroundColor: "#E2E8F0",
  },
  userSettingFeildsMian: {
    maxWidth: "610px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    margin: "40px",
    padding: "20px",
  },
  labelStyle2: {
    fontFamily: "Poppins",
    color: "#273567",
    letterSpacing: "0em",
    fontSize: "16px",
    lineHeight: " 24px",
    fontWeight: "400",
  },
  userSettingTextMain: {
    marginBottom: "40px",
  },
  userSettingText: {
    fontFamily: "Poppins",
    fontWeight: "500",
    lineHeight: "40px",
    color: "#000000",
    fontSize: "20px",
  },
  inputStyle2: {
    color: "#273567",
    width: "100%",
    top: "26px",
    borderRadius: "8px",
    border: "1px solid",
    height: "40px",
    padding: "15px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  drawerBox: { width: "100%" } as React.CSSProperties,
  fieldsMainOther: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "20px 0",
  },
  inputStyleAdd: {
    color: "#273567",
    top: "26px",
    width: "100%",
    border: "1px solid",
    borderRadius: "8px",
    padding: "15px",
    fontFamily: "Poppins",
    height: "40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelStyle: {
    fontFamily: "Poppins",
    color: "#273567",
    fontSize: "16px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  cordinateBox: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },
  captureIcon2: {
        cursor:'pointer',
    width: "auto",
    height: "30px",
    right: "10",
    top: "30",
    backgroundColor:'#fff',
    zIndex:10,
    position: "absolute",
  },
  submitButton2: {
    backgroundColor: "#C12030",
    marginTop: "16px",
    fontSize: "16px",
    color: "#fff",
    textTransform: "capitalize",
    height: "50px",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Poppins",
  },
  secondContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: "#E2E8F0",
  },
  greentickModal2: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    margin: "auto",
    marginTop: "100px",
    flexShrink: "0",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)",
  },
  displayFileName2:{
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  menuItem2: {
    fontFamily: "Poppins",
    color: '#273567',
    fontWeight: 500,
    fontSize: '14px'
  },
  userVerifiedText2: {
    fontSize: "33px",
    lineHeight: "140%",
    justifyContent: "center",
    alignContent: "center",
    fontWeight: 400,
    display: "flex",
    textAlign: "center",
    color: "#030F2D",
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  errorMessage2:{
    color: "red",
    marginTop: "5px",
    fontFamily: "poppins",
    fontSize: "12px",
    fontWeight: 400,
  },
  validationMessageDoc2: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "red",
    fontWeight: 400,
    marginTop: "5px",
  } as React.CSSProperties,  
  validationMessage2:{
    color: "red",
    marginTop: "5px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
  },
  inputStyleDoc2: {
    borderRadius: "8px",
    height: "40px",
    boxSizing: "border-box",
    backgroundPosition: "right 8px center", // Adjust the position of the image
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "24px", // Adjust the size of the image
    padding: "8px 40px 8px 8px", // Adjust padding for text and image
    border: "1px solid #ccc",
    backgroundImage: `url(${upload_icn})`, // Set the image as the background
  } as React.CSSProperties,
};
