import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import toast from "react-hot-toast"
const privacyPlocyNotify = () => toast.error('Select Privacy Policy and Terms of use',{
  style: {
    fontSize:'16px',
    fontWeight:400,
    fontFamily: "Poppins",
    display:"flex",
    alignItems:"baseline",maxWidth:"100%"
  },
 
})
const notify = () => toast.error('Fill in all your details because all the fields are manadatory',{
  style: {
    fontSize:'16px',
    fontWeight:400,
    fontFamily: "Poppins",
    display:"flex",
    alignItems:"baseline",maxWidth:"100%"
  },
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  passport:string;
  agreeToTerms:boolean;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  passwordError:boolean,
  passportError:boolean,
  checkBoxError:boolean,
  passwordErrorinvalid:boolean,
  passportErrorInvalid:boolean,
  passportIdNumberId:any;
  userNameError: any;
  isValid: any;
  invalidInputs:any;
  whatsappNumber:any;
  isWhatsappNumber:any;
  showPassword: boolean;
  conpassvalid:any;
  loginButtonClicked: any;
  initialLoad: any;
  username: any;
  dummyPassword: any;
  invalidCredentials: any;
  usernameError: any;
  usernameErrorInvalid: any;
  passwordErrorInvalid: any;
  agreeToTermsError: any;
  errorMessage:string;
  privacyOpen:boolean;
  setValue:boolean;
  isAuthenticating:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  oAuthLoginMsgId:string="";
  getUserProfileMsgId:string ="";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      isAuthenticating:false,
      agreeToTerms:false,
      email: "",
      password: "",
      passport:"",
      passwordError:false,
      passportError:false,
      checkBoxError:false,
      passwordErrorinvalid:false,
      enablePasswordField: true,
      checkedRememberMe: false,
      passportIdNumberId:'',
      invalidInputs:[],
      isValid: true,
      passportErrorInvalid:false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      userNameError: false,
      whatsappNumber:'',
      isWhatsappNumber:false,
      showPassword: false,
      conpassvalid:[],
      loginButtonClicked: false,
      initialLoad: true,
      username: '',
      dummyPassword: '',
      invalidCredentials: false,
      usernameError: false,
      usernameErrorInvalid: false, 
      passwordErrorInvalid: false,
      agreeToTermsError: false,
      errorMessage:"",
      privacyOpen:false,
      setValue:false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setAgreeTerms = (value: boolean) => {
    this.setState({ agreeToTerms: value });
  }

   


  handleWhatsappNumberNumber = (e: any) => {
    const inputUsername = e.target.value;

    // Check if the input username has at least 8 characters
    if (inputUsername.length < 8) {
        this.setState({
            usernameError: true,
            usernameErrorInvalid: true,
        });
    } else {
        this.setState({
            whatsappNumber: inputUsername,
            isWhatsappNumber: inputUsername.length === 10,
            usernameError: false,
            usernameErrorInvalid: false,
        });
    }
};

 
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };


  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

      loginEmail=(values:any)=>{
    
      }
      validateEmail = (passport: string)=> {
        const emailRegex = /^[a-zA-Z0-9]{1,12}$/;
        return emailRegex.test(passport)
    ;
      };
    
      validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return passwordRegex.test(password);
      };
      isStaticC = (slot1: string, slot2: string, agreeToTerms: boolean): boolean => {
      
        if (slot1.includes(configJSON.valueA1,0) && slot2.includes(configJSON.valueA2,0) && agreeToTerms) {
          if (typeof window !== "undefined") {
             localStorage.setItem("Role","Merchant")
             window.location.href = "/LandingPage"; 
          }
          return true;
        } else if (slot1.includes(configJSON.valueB1,0) && slot2.includes(configJSON.valueB2,0) && agreeToTerms) {
          localStorage.setItem("Role","Operator")
          window.location.href = "/LandingPage"; 

          return true;
        }
        return false;
      };
      handleSubmit = () => {
      
        const { password, passportIdNumberId } = this.state;
        const textP = password
        let isUserValid = true;
        let isPasswordValid = true;
      
        // Clear existing errors
        this.setState({
            passportError: false,
            passportErrorInvalid: false,
            passwordError: false,
            passwordErrorInvalid: false,
            agreeToTermsError: false,
        });
       
        // Username validation
        let numberregex = /^[/d]+$/
        let alphabetsRegex = /^[a-zA-Z\s]+$/
        if(passportIdNumberId.trim() === '' || password.trim() === ''){
          this.handleToast()
         }
       else if (!passportIdNumberId.trim() || passportIdNumberId.length < 8 || alphabetsRegex.test(passportIdNumberId) || numberregex.test(passportIdNumberId)) {
            this.setState({ passportError: true, conpassvalid: ['passportIdNumberId'] });
            isUserValid=false
        }
       
      
        // Password validation
        if (!password.trim()) {
            this.setState({ passwordError: true, conpassvalid: ['password'] });
            isPasswordValid = false;
        } else if (!this.validatePassword(password)) {
            this.setState({ passwordErrorinvalid: true,passwordError:false, conpassvalid: ['password'] });
            isPasswordValid = false;
        } 
      
        // Agree terms validation
        if (!this.state.agreeToTerms) {
            this.setState({ agreeToTermsError: true });
            if(passportIdNumberId.trim() && password.trim()){
              this.handleToastForPrivacyPolicy()
             }
        } 
      
        if (this.isStaticC(passportIdNumberId, textP, this.state.agreeToTerms)) {
          return;
        }
        if ( this.state.agreeToTerms && isUserValid && isPasswordValid) {

          const header = {
            "Content-Type": configJSON.validationApiContentType,
          };
          const httpBody = {
            userName: passportIdNumberId,
            password
          }
      
          const submitMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.apiEmailLoginCallId = submitMessage.messageId;
      
          
          submitMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          
              submitMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
              );
          submitMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginAPiEndPoint
          );
          
          submitMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
          );
          runEngine.sendMessage(submitMessage.id, submitMessage);
        }
        else {
          this.setState({
              loginButtonClicked: true,
              initialLoad: false,
              invalidCredentials: true,
          });}
    };

    navigateToLandingOperator = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "OperatorDashboard"
      );
  
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
      this.send(message);
    };
    
    handleChange = (event: any) => {
      const inputValue = event.target.value;
      const sanitizedInput = inputValue.replace(/[^0-9]/g, '').slice(0, 13);
  
  
      if (inputValue.trim() === '') {
          this.setState({
              passportIdNumberId: '',
              passportError: true,
              passportErrorInvalid: false,
              errorMessage: 'Username (ID/Passport) is required'
          });
      } else if (sanitizedInput.length !== 13) {
          this.setState({
              passportIdNumberId: sanitizedInput,
              passportError: true,
              passportErrorInvalid: true,
              errorMessage: 'Please enter a 13-digit numeric value.'
          });
      } else {
          this.setState({
              passportIdNumberId: sanitizedInput,
              passportError: false,
              passportErrorInvalid: false,
              errorMessage: ''
          });
      }

  };
    
    
    
      
    handleTogglePassword = () => {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    };
    handleInputChangesTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
    
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
        conpassvalid: prevState.conpassvalid.filter((input: string) => input !== name),
      }));
    };
    
    handleToastForPrivacyPolicy = () => {
      privacyPlocyNotify()
    };
    handleToast = () => {
      notify()
    };
    
    
     handleChangeEvent=(event: any)=>{
     this.setState({ password: event.target?.value, passwordError: false ,passwordErrorinvalid:false})
     }
     
     handleSubmitOAuth2 = () => {
      this.setState({isAuthenticating:true})
      const redirectURI = window.location.origin
       const width = 600;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;
      const popup = window.open(
        `${configJSON.AUTH_URL}&response_type=${configJSON.RESPONSE_TYPE}&client_id=${configJSON.CLIENT_ID}&redirect_uri=${redirectURI}`,
        'OAuthPopup',
        `width=${width},height=${height},top=${top},left=${left}`
      );
      if (popup) {
        const popupCheckInterval = setInterval(() => {
            // Check if popup is closed
            if (popup.closed) {
              clearInterval(popupCheckInterval);
              this.setState({isAuthenticating:false})
            }
            
            // Optionally, check if redirect URL is reached by inspecting popup location
            if (popup.location.href.includes(redirectURI)) {
              const oauthCode: string | null = new URLSearchParams(popup.location.search).get('code');
              clearInterval(popupCheckInterval);
              popup.close();
              
              if (oauthCode) {
                this.handleOAuthCode(oauthCode);
              } else {
                console.error('OAuth code is null.');
              }
            }
          
        }, 500); // Check every 500ms
      } else {
        console.error('Popup window could not be opened.');
      }
    };
  
    handleOAuthCode = async (code:string) => {
      try { 
      const redirectURI = window.location.origin
      const body = new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: redirectURI,
          client_id: configJSON.CLIENT_ID,
        })
        const headers = {
          "Content-Type": 'application/x-www-form-urlencoded',
        };
    
        const getAccessTokenMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.oAuthLoginMsgId = getAccessTokenMsg.messageId;
    
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ACCESS_TOKEN_URL
        );
    
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(getAccessTokenMsg.id, getAccessTokenMsg);
      } catch (error) {
        console.error('Error exchanging OAuth code for token:', error);
      }
      this.setState({isAuthenticating:false})
    };
    GetUserProfile(token:string){
      try {
        const headers = {
          Authorization:`Bearer ${token}`
        };
    
        const getAccessTokenMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileMsgId = getAccessTokenMsg.messageId;
    
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'User/Info'
        );
    
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
      
        getAccessTokenMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(getAccessTokenMsg.id, getAccessTokenMsg);
      this.props.navigation.navigate('Dashboard') 
      window.localStorage.setItem("Role","Merchant")
      } catch (error) {
        console.error('Error exchanging OAuth code for token:', error);
      }
    }

    handleOAuthLoginMessage(res:any){
      console.log('tres',res)
      if ( res.error === undefined) {
        const accesToken:string = res.access_token;
        localStorage.setItem('token',accesToken)
        this.GetUserProfile(accesToken)
        return
      } else {
        this.parseApiErrorResponse(res);
      }
    }

    handleRestAPIResponseMessage(message: Message) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.validationApiCallId:
            this.handleValidationApiCallMessage(responseJson)
            break;
          case this.apiEmailLoginCallId:
            this.handleEmailLoginCallMessage(responseJson,errorReponse)
            break;
          case this.oAuthLoginMsgId:
            this.handleOAuthLoginMessage(responseJson)
            break;
          case this.getUserProfileMsgId:
            this.handleGetUserProfileApiCallMessage(responseJson, errorReponse)
            break;
          default:
            break;
        }
      }else{
        return toast.error(errorReponse,{
          style: {
            fontSize:'16px',
            fontWeight:400,
            fontFamily: "Poppins",
            display:"flex",
            alignItems:"baseline",maxWidth:"100%"
          },
        })
      }
    }
    handleGetUserProfileApiCallMessage(res:any,err:any){
      if(!res.hasError && res.payload){
        const userRole = res.payload.Role
        window.localStorage.setItem("Role",userRole)
        switch (userRole) {
          case "Merchant":
            this.props.navigation.navigate("Dashboard")
            break;
          case "Operator":
            this.props.navigation.navigate("OpeartorDashboard")
          break
        }
      }else{
        if(res.statusCode === 403){
          return this.props.navigation.navigate("SignUp")
        }
      this.parseApiCatchErrorResponse(err);

      }
    }
  handleValidationApiCallMessage(res:any){
    const arrayholder = res.data;

    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];

      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  handleEmailLoginCallMessage(response:any,errorReponse:any){
    if (response && response.meta && response.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(response);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(response);
      this.sendLoginFailMessage();
    }

      this.parseApiCatchErrorResponse(errorReponse);
  }
  handleCloseModalPrivacy = () => {
    this.setState({ privacyOpen: false });
  };
  handlePrivacy = (e:string) => {
    this.setState({ privacyOpen: true,setValue: e == "terms" ? true :false });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestAPIResponseMessage(message)
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
