import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Button,
    Modal,
    styled,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    RadioGroup,
    FormControlLabel,
    TableBody,
    TableSortLabel,
    Snackbar
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import VisualAnalyticsController, {
    Props, configJSON
} from "./VisualAnalyticsController";
import { leftArrow, downIcon, check, printIcon } from "./assets";
import SideNavbar from "../../../components/src/SideBar.web";
import StyleRadio from '../../../components/src/StyleRadio.web';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Pagination } from "@material-ui/lab";
import ReactToPrint from "react-to-print";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area End

// Customizable Area Start
interface WalletDataItem { 
    date:string;
    timestamp: string;
    id: string;
    type: string;
    status: string;
    description:string;
    amount: string;
    balance:string;
    productCode: number,
    productDescription: string;
    terminal: string;
    user: string;
    comment: string;
  };

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomBox = styled(Box)({
    display: 'flex',
    gap: 16,
    marginTop: '38px',
    paddingBottom: 20,

    '@media(max-width: 600px)': {
        marginLeft: '0px',
        justifyContent: 'center'
    }
})

const CustomWalletTypography = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    paddingTop: '8px',
    '@media(max-width: 600px)': {
        padding: '0px'
    }
})


const CustomUserBox = styled(Box)({
    display: 'flex',
    gap: '8px',
    backgroundColor: "#FFFFFF",
    padding: "17px 24px",
    borderRadius: "4px",
    justifyContent: 'space-between',
    margin: "20px auto",
    '@media(max-width: 600px)': {
        display: 'flex',
        flexDirection:"column"
    }
})


const CustomButtonRed = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    padding: '4px 16px 4px 20px',
    alignItems: 'center',
    marginLeft: '5%',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})


const CustomButtonRed2 = styled(Button)({
    border: '1px solid #C12030',
    borderRadius: '8px',
    maxWidth: '159px',
    width: '100%',
    height: '44px',
    padding: '4px 16px 4px 20px',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        maxWidth: '130px'
    }
})


const CustomFormLabelRadio = styled(FormControlLabel)({
    margin: "auto !important",
    "& .MuiFormControlLabel-root": {
        margin: "auto !important"
    }
})


const ExportBox = styled(Box)({
    '@media(max-width: 450px)': {
        height: "100px !important",
        marginaxWidth: "100px !important"
    }
})


const ExportContainer = styled(Box)({
    '@media(max-width: 450px)': {
        gap: "20px !important"
    }
})

const CustomBackBox = styled(Box)({
    display: 'flex'
});

const DateFilterBox = styled(Box)(({theme})=>({
    right:"0",
    top:"45px",
    [theme.breakpoints.down('xs')]: {
    left:"0",
    top:"45"
    }
}));

const FilterByTypography = styled(Typography)(({theme})=>({
    textAlign:"right",
    [theme.breakpoints.down('xs')]: {
        textAlign:"left"
  }
}));

const SortByBox = styled(Box)({
    '@media (max-width: 450px)': {
        width:"244 !important"
    }
});

interface Column {
    id:
    | "id"
    | "date"
    | "timestamp"
    | "type"
    | "status"
    | "description"
    | "amount"
    | "balance"
    | "productCode"
    | "productDescription"
    | "terminal"
    | "user"
    | "comment";
    label: string;
    minWidth?: number;
    align?: "right";
    sortable?: boolean;
}

interface Data {
    id: string;
    timestamp: string;
    type: string;
    status: string;
    description: string;
    amount: string;
    balance: string;
    productCode: number;
    productDescription: string;
    terminal: string;
    user: string;
    comment: string;
}


const columns: Column[] = [
    { id: "id", label: "ID",sortable:false },
    { id: "timestamp", label: "Timestamp",sortable:false },
    { id: "type", label: "Type",sortable:false },
    { id: "status", label: "Status",sortable:false },
    { id: "description", label: "Description",sortable:false },
    { id: "amount", label: "Amount",sortable:true },
    { id: "balance", label: "Balance",sortable:true },
    { id: "productCode", label: "Product code",sortable:false },
    { id: "productDescription", label: "Product Description",sortable:false },
    { id: "terminal", label: "Terminal",sortable:false },
    { id: "user", label: "User",sortable:false },
    { id: "comment", label: "Comment",sortable:false },
];

const rows: Data[] =
    [
        {
            timestamp: "12:00PM",
            id: "1",
            type: "Online",
            status: "Status",
            description: "Description",
            amount: "R100",
            balance: "R5400",
            productCode: 1909922,
            productDescription: "Description",
            terminal: "General",
            user: "User 1",
            comment: "Comment",
        },
        {
            id: "2",
            type: "Online",
            timestamp: "12:00PM",
            status: "Status",
            description: "Description",
            amount: "R100",
            balance: "R5400",
            productCode: 1909922,
            terminal: "General",
            productDescription: "Description",
            user: "User 2",
            comment: "Comment",
        },
        {
            id: "3",
            timestamp: "12:00PM",
            type: "Online",
            status: "Status",
            description: "Description",
            balance: "R5400",
            amount: "R100",
            productCode: 1909922,
            productDescription: "Description",
            terminal: "General",
            user: "User 2",
            comment: "Comment",
        },
        {
            id: "4",
            timestamp: "12:00PM",
            type: "Online",
            status: "Status",
            description: "Description",
            productCode: 1909922,
            amount: "R100",
            balance: "R5400",
            productDescription: "Description",
            terminal: "General",
            user: "User 2",
            comment: "Comment",
        },
        {
            id: "5",
            timestamp: "12:00PM",
            type: "Online",
            status: "Status",
            description: "Description",
            amount: "R100",
            productCode: 1909922,
            productDescription: "Description",
            balance: "R5400",
            terminal: "General",
            user: "User 2",
            comment: "Comment",
        },
    ];

const CustomPrintHeaderCell = styled(TableCell)({
    fontWeight:700,
    borderBottom: "none",
    color:"white !important",
    padding:"6px"
});
      
const CustomPrintBodyCell = styled(TableCell)({
    padding:"6px",
    borderBottom: "none",
    color:"#626161"
});

const CustomPrintHeaderRow = styled(TableRow)({
    color:"white",
    backgroundColor: "#2980ba",
    fontWeight:700
});
    
const EvenPrintCustomBodyRow = styled(TableRow)({
    color:"white",
    backgroundColor: "#f5f5f5",
    fontWeight:700
});

const FilterMainWrapper = styled(Box)({
    display:"flex",
    gap:"10px",
    alignItems:"center",
    "@media (max-width:450px)":{
        flexWrap:"wrap"
    }
});

const SortOptionBox = styled(Box)({
    "@media (max-width:450px)":{
        width:"240px !important"
    }
});

// Customizable Area End

export default class WalletHistory extends VisualAnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount(){
        this.getWalletHistory()
    }
    handleSortValueFilter = (value: boolean, title: string, order:string) => {
        return (
            <SortOptionBox className="sortByWalletH" id='dateModal' style={{ ...webStyle.labelStyleBox, backgroundColor: this.state.orderByWallet=="balance"&&value ? '#CDF0EE' : 'white', }} 
                onClick={()=>this.handleSortFilterWallet(order)} data-test-id={`sortByOption${order}TestID`}>
                {this.state.orderByWallet=="balance"&&value ? <img src={check} style={{ height: '24px', width: '24px', marginLeft: '5px', }} /> : <div style={{ marginLeft: '5px', height: '24px', width: '24px' }} />}
                <label id='dateModal' className="sortByWalletH" style={{ ...webStyle.labelStyle as React.CSSProperties, fontFamily: "Lato", fontSize: "16px", fontWeight: 400 }}>{title}</label>
            </SortOptionBox>
        )
    }

    renderHeaderRow = () => {
        return (
            <TableRow>
            {columns.map((column) => (
                <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ fontSize: '14px', fontWeight: 700, width: '100px', color: '#475569', fontFamily: 'Poppins' }}
                >
                    {column.sortable ? (
                        <TableSortLabel
                            active={this.state.orderByWallet === column.id}
                            direction={this.state.orderByWallet === column.id ? this.state.orderWallet : 'asc'}
                            onClick={this.handleRequestSortWalletColumns.bind(this,column.id)}
                            data-test-id={`columnHead${column.id}TestID`}
                        >
                            {column.label}
                        </TableSortLabel>
                    ) : (
                        <span>{column.label}</span>
                    )}
                </TableCell>
            ))}

        </TableRow>
        )
    };

    renderExceptStatusCell = (column: string, value: string | number, index:number) => {
        return  ( 
        <React.Fragment key={index}>
            {   
                column === "type" ?
                    <TableCell key={index} align="left" style={webStyle.typeColumnStyle}>
                        {value}
                    </TableCell> :
                    <TableCell
                        key={index}
                        align="left"
                        style={{
                        color: "#475569",
                        minWidth: column === "id" ? "20px" : "80px",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        }}
                    >
                        {column === "amount" || column === "balance" ? (
                        <span>
                            <span style={{ fontWeight: 700 }}>R</span>
                            {value.toString().slice(1)}
                        </span>
                        ) : (
                        value
                        )}
                    </TableCell>
            }
          </React.Fragment>
        );
      };
      

    renderTable = () => {
        return (
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                       {this.renderHeaderRow()}
                    </TableHead>

                    <TableBody>

                        {this.state.walletSortedData.length!==0?this.state.walletShowData.map((Item: WalletDataItem, index: number) => (
                                <TableRow key={index} data-test-id={`tableRow${index}TestID`} hover role="checkbox" tabIndex={-1}>
                                    {columns.map((column, index) => (
                                        column.id!=="status"?
                                        this.renderExceptStatusCell(column.id, Item[column.id], index)
                                        :<TableCell
                                            align={column.align}
                                            style={webStyle.statusCellStyle}
                                            key={index}
                                        >
                                            <span style={webStyle.statusCell}>{Item[column.id]}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )):<TableRow>
                            <TableCell 
                                colSpan={12} 
                                style={webStyle.noResultFoundTextStyle}
                            >
                                {configJSON.noResultFoundText}
                            </TableCell>
                        </TableRow>}

                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    renderPrintableTable = () => {
        return (
          <Box style={webStyle.tableWrapper}>
            <TableContainer ref={this.printableDivRef}  style={webStyle.printTableStyle}>
                <Box style={webStyle.printTableHeading}>{configJSON.walletHistoryHeadingText}</Box>
                <Table style={webStyle.printMainTable}>
                    <TableHead>
                    <CustomPrintHeaderRow>
                        {columns.map((column) => (
                        <CustomPrintHeaderCell
                            key={column.id}
                            align={column.align}
                            style={webStyle.printColumnCell}
                        >
                            <span>{column.label}</span>
                        </CustomPrintHeaderCell>
                        ))}
                    </CustomPrintHeaderRow>
                    </TableHead>
                    <TableBody>
                    {this.state.walletSortedData.map((Item: WalletDataItem, index: number) => (
                        index % 2 !== 0 ? 
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <CustomPrintBodyCell
                                key={column.id}
                                align={column.align}
                                style={{width:(column.id==="productDescription"||column.id==="description"||column.id==="comment")?"100px":"auto", wordBreak:"break-all"}}
                                >
                                {Item[column.id]}
                                </CustomPrintBodyCell>
                            ))}
                        </TableRow>:
                        <EvenPrintCustomBodyRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                                <CustomPrintBodyCell
                                    key={column.id}
                                    align={column.align}
                                    style={{width:(column.id==="productDescription"||column.id==="description"||column.id==="comment")?"100px":"auto", wordBreak:"break-all"}}
                                >
                                    {Item[column.id]}
                                </CustomPrintBodyCell>
                            ))}
                        </EvenPrintCustomBodyRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </Box>
        );
      };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box 
                onClick={(event)=>this.handleProviderBodyClick(event)}
                style={webStyle.userSettingContainer} 
                data-test-id='user-setting-containerf'
            >
                <SideNavbar />
                <RightBoxLanding style={webStyle.secondContainer}>

                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={webStyle.headingDataWrapper}>
                    <CustomBackBox>
                        <Box data-test-id="backButtonTestID" style={{marginTop:"10px", maxWidth: "500px", display: "flex", alignItems: "center" }} onClick={this.handleWalletBack}>
                            <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                            <Typography style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, paddingLeft: 5, cursor: 'pointer' }}>Back</Typography>
                        </Box>
                    </CustomBackBox>
                    <CustomUserBox style={webStyle.userSettingFeildsMian}>
                        <CustomWalletTypography style={webStyle.userSettingText as React.CSSProperties}>
                            Wallet History
                        </CustomWalletTypography>
                        <FilterMainWrapper>
                            <FilterByTypography style={webStyle.filterbyText as React.CSSProperties}>
                                Filter by:
                            </FilterByTypography>
                            <Box style={{ display: 'flex', gap: 16 }}>
                            <div style={webStyle.filterButtonWrapper as React.CSSProperties}>

                                <Button data-test-id="timelineButtonTestID" style={webStyle.timelinebutton} onClick={this.handleOpenState} id='dateModal'>
                                    <Typography id='dateModal' style={webStyle.timelineText}>Timeline</Typography>
                                </Button>
                                    <Button data-test-id="sortByButtonTestID" id='dateModal' style={webStyle.timelinebutton2} onClick={()=>this.handleWalletSortToggle()} className="sortByWalletH">
                                        <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id='dateModal'>
                                            <Typography style={{ ...webStyle.timelineText, whiteSpace: 'nowrap' }} id='dateModal'>
                                                Sort <span id='dateModal' style={{ textTransform: 'lowercase' }}>by</span>
                                            </Typography>
                                            <img src={downIcon} id='dateModal' style={{ marginLeft: '10px' }} />
                                        </div>
                                    </Button>
                                    {this.state.isWalletSort &&
                                        <SortByBox style={webStyle.dateModelStyle} id='dateModal' className="sortByWalletH">
                                            <Box sx={webStyle.inputBox2} >
                                                {this.handleSortValueFilter(this.state.selectAsending, 'Sort by Ascending to Descending', 'desc')}

                                                {this.handleSortValueFilter(!this.state.selectAsending, 'Sort by Descending to Ascending', 'asc')}
                                            </Box>
                                        </SortByBox>

                                    }
                                    {this.state.isOpen ?
                                        <DateFilterBox style={webStyle.dateSelectDropdown as React.CSSProperties}>


                                            <Box sx={webStyle.inputBox} className="dateModal">

                                                <Box sx={webStyle.labelBox}>
                                                    <label style={webStyle.labelStyle as React.CSSProperties}>Start Date</label>
                                                    <input data-test-id="startDateInputTestID" type="date" value={this.state.startdate} onChange={this.onStartChange} style={{
                                                        ...webStyle.inputStyle,
                                                        padding: "0 8px",
                                                    }} id='dateModal' />
                                                </Box>
                                                <Box sx={webStyle.labelBox}>
                                                    <label style={webStyle.labelStyle as React.CSSProperties}>End Date</label>
                                                    <input data-test-id="endDateInputTestID" type="date" value={this.state.enddate} onChange={this.onEndChange} style={{
                                                        ...webStyle.inputStyle,
                                                        padding: "0 8px",
                                                    }} id='dateModal' />
                                                </Box>
                                            </Box>


                                        </DateFilterBox> : ''
                                    }

                                </div>
                            </Box>
                        </FilterMainWrapper>
                    </CustomUserBox>

                    <Paper style={{ overflowX: "auto" }}>
                        {this.renderTable()}
                        {this.state.walletSortedData.length!==0?
                        <div style={webStyle.paginationResultWrapper}>
                            <div />

                            <Pagination 
                                style={webStyle.walletPaginationStyle} 
                                data-test-id="walletPaginationTestID"
                                page={this.state.providerSelectedPage}
                                count={Math.ceil(this.state.walletSortedData.length / 10)}
                                onChange={(event:React.ChangeEvent<unknown>, newPage: number)=>
                                this.handlePagination(event,newPage,"walletPagination")} 
                            />
                            <Typography style={webStyle.resultCountStyle}>
                                {this.state.providerSelectedPage*10-9} -{" "}
                                {(this.state.providerSelectedPage*10-9)+(this.state.walletShowData.length-1)}&nbsp;
                                of {this.state.walletSortedData.length} results
                            </Typography>
                        </div>:null}

                    </Paper>


                    <CustomBox>

                        <CustomButtonRed data-test-id="walletExportAllButtonTestID" style={webStyle.exportbutton} onClick={this.handleButtonClick}>
                            <Typography style={webStyle.exportText}>Export All</Typography>
                        </CustomButtonRed>
                        <style>
                        {`
                            @media print {
                                html, body {
                                    height: initial !important;
                                    margin: 0; 
                                    overflow: initial !important;
                                    padding: 0;
                                }
                                @page {
                                    margin: 0.5cm;
                                    size: landscape;
                                }
                            }
                        `}
                        </style>
                        {this.state.walletSortedData.length!==0?
                        <ReactToPrint
                        data-test-id="print"
                            trigger={() => (
                                <CustomButtonRed2 data-test-id="print1" style={webStyle.exportbutton} >
                                    <img src={printIcon} />
                                    <Typography style={webStyle.exportText}>{configJSON.printText}</Typography>
                                </CustomButtonRed2>
                            )}
                            documentTitle="wallet_history"
                            content={() => this.printableDivRef.current}
                        /> :
                        <CustomButtonRed2 data-test-id="print2" style={webStyle.exportbutton} >
                            <img src={printIcon} />
                            <Typography style={webStyle.exportText}>{configJSON.printText}</Typography>
                        </CustomButtonRed2>}

                    </CustomBox>
                    </Box>
                    <Modal
                        data-test-id="walletFileTypeModalTestID"
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflowY: 'auto',
                            width: '90%',
                            height: '90%',
                            margin: 'auto',
                        }}
                    >
                        <Box style={{
                            maxWidth: '550px',
                            width: '100%',
                            padding: 30,
                            backgroundColor: 'white',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: "8px",
                        }}>
                            <Typography style={{
                                fontSize: '22px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                maxWidth: '373px',
                                margin: 'auto',
                            }}>
                                Select the file type you would like to download :
                            </Typography>
                            <ExportContainer style={{ display: 'flex', gap: 55, marginTop: 45, justifyContent: 'center' }}>

                                <ExportBox data-test-id="pdfButtonTestID" onClick={this.handleNewUserChange} style={{
                                    height: '117px',
                                    width: '100%',
                                    maxWidth: '143px',
                                    padding: '20px 30px',
                                    border: '1px solid #E6E6E6',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    backgroundColor: this.state.isNewUserChecked ? '#C1203026' : 'white',
                                    cursor: "pointer"
                                }}>

                                    <Typography style={{ fontFamily: 'Poppins', color: '#0F172A', fontSize: '20px', fontWeigth: '400', margin: 'auto' } as React.CSSProperties}>PDF</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <CustomFormLabelRadio value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>

                                </ExportBox>

                                <ExportBox onClick={this.handleExistingUserChange} 
                                    data-test-id="xlsxButtonTestID"
                                    style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: '143px',
                                        width: '100%',
                                        height: '117px',
                                        padding: '20px 30px',
                                        borderRadius: '4px',
                                        border: '1px solid #E6E6E6',
                                        backgroundColor: this.state.isExistingUserChecked ? '#C1203026' : 'white',
                                        cursor: "pointer"
                                    }}>
                                    <Typography
                                    style={
                                        {
                                        fontSize: "20px",
                                        fontWeigth: "400",
                                        fontFamily: "Poppins",
                                        color: "#0F172A",
                                        margin: "auto",
                                        } as React.CSSProperties
                                    }
                                    >
                                    {configJSON.labelXLSXText}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                                            <CustomFormLabelRadio value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                                        </RadioGroup>
                                    </div>

                                </ExportBox>

                            </ExportContainer>

                            <Button 
                                data-test-id="confirmButtonTestID" 
                                onClick={()=>this.handleWalletExportConfirm()} 
                                style={webStyle.submitButton2}
                            >
                                Confirm
                            </Button>

                        </Box>
                    </Modal>

                </RightBoxLanding>
                {this.renderPrintableTable()}
                <Snackbar 
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    onClose={this.handleSnackbarClose}
                    open={this.state.isSnackbarOpen} 
                    autoHideDuration={2000} 
                >
                    <Box  style={webStyle.snackbarInnerBox}>
                        <CancelIcon  style={{ color: 'red' }}/>
                        <Typography style={{fontFamily:"Poppins"}}>{this.state.dateError}</Typography>
                    </Box>
                </Snackbar>
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    walletPaginationStyle:{
        fontFamily:"Poppins",
        fontWeight:400,
        color:"#0F172A",
        fontSize:"12px"
    },
    resultCountStyle:{
        fontFamily:"Poppins",
        fontWeight:400,
        color:"#64748B",
        marginRight:"10px",
        fontSize:"12px"
    },
    paginationResultWrapper:{
        alignItems: "center", 
        display: "flex", 
        justifyContent: 'space-between',
        padding:"20px 10px" 
    },
    snackbarInnerBox:{
        background:"white", 
        display:"flex", 
        borderRadius:"8px", 
        gap:"16px", 
        padding:"14px"
    },
    typeColumnStyle:{
        color: "#475569",
        minWidth:"40px",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400
    },
    statusCellStyle:{
        color: "#475569",
        minWidth:"80px",
        whiteSpace:"nowrap",
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400
    } as React.CSSProperties,
    statusCell:{
        background: "#D1FAE5",
        fontFamily:"Inter",
        padding:"2px 8px",
        textTransform:"uppercase",
        borderRadius:"50px",
        color:"#059669",
        fontWeight:700
    } as React.CSSProperties,
    tableWrapper:{
        display:"none"
    },
    printTableStyle:{
        margin: "20px auto 0 auto", 
    },
    printTableHeading:{
        fontSize:"25px", 
        fontFamily:"sans-serif"
    },
    printMainTable:{
        marginTop: "30px"
    },
    printColumnCell: {
        fontSize: '14px',
        fontWeight: 700, width: '100px',
        color: '#475569',
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    noResultFoundTextStyle:{
        fontSize:"20px",
        color:"gray",
        padding:"80px 0",
        textAlign:"center",
        fontFamily:"Poppins",
    } as React.CSSProperties,
    userSettingContainer: {
        backgroundColor: "#E2E8F0"
    },
    headingDataWrapper:{
       padding:"0 40px 40px"
    },
    filterButtonWrapper:{
        position: "relative", 
        display:"flex", 
        gap:"16px" 
    },
    secondContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    userSettingFeildsMian: {

        // display:"flex",
    },
    stButtonDump: {
        textTransform: 'capitalize',
        width: "100%",
        maxWidth: '550px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400,
        backgroundColor: '#C12030',
        fontSize: '16px',
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
    } as React.CSSProperties,
    userSettingText: {

        // display:"flex",
        // alignItems:"center",
        // justifyContent:"center",



    },
    filterbyText: {
        fontFamily: 'Inter',
        color: '#334155',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '22px'
    },
    timelineText: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#334155',
        textAlign: 'right',
        textTransform: 'capitalize',

    } as React.CSSProperties,
    exportText: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize',

    } as React.CSSProperties,
    timelinebutton: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',

    } as React.CSSProperties,
    exportbutton: {
        marginLeft:"0"
    } as React.CSSProperties,
    timelinebutton2: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',
    } as React.CSSProperties,
    inputBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        justifyContent: 'space-between',
        // Additional responsive adjustments
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    } as React.CSSProperties,
    inputBox2: {
        gap: '16px',
        flexDirection: 'column',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
        width: '300px',
    } as React.CSSProperties,
    submitButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        maxWidth: '550px',
        width: "100%",
        alignSelf: 'center',
        fontFamily: 'Poppins',
        marginTop: '53px',
        fontWeight: 400,
    } as React.CSSProperties,
    datemodalStyle: {

        // border: '1px solid #CBD5E1',
        // borderRadius: '8px',
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    labelStyle: {
        fontFamily: 'Poppins',
        color: "0F172A",
        fontSize: "12px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },

    inputStyle: {
        color: '#64748B',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: 15,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500
    },
    labelStyleBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '10px',
        paddingTop: '5px',
        width: "100%",
        height: '48px',
        alignItems: 'center',
        cursor:"pointer"
    } as React.CSSProperties,
    dateSelectDropdown:{
        position: 'absolute', 
        background: 'white', 
        zIndex: 999999, 
        padding: '20px', 
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        borderRadius:"8px",
        top:"48px",
        overflow:"hidden"
    },
    dateModelStyle: {
        position: 'absolute',
        background: 'white',
        right: 0, zIndex: 999999,
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        top:"48px",
        width:"310px",
        borderRadius:"8px",
        overflow:"hidden"
    } as React.CSSProperties,
    timelinebutton23: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        maxWidth: '159px',
        width: '100%',
        height: '44px',
        padding: '4px 16px 4px 20px',
        alignItems: 'center',

    } as React.CSSProperties,
}
// Customizable Area End