import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    Modal,
    Fade,
    styled
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { background_image, cross, greentick, a2payImage } from './assets';
const BackimgExisting = styled('img')({
    '@media (max-width: 600px)': {
        height: '400px !important',
        objectFit: 'cover',
        objectPosition: 'bottom'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        height: '500px !important',
        objectFit: 'cover',
        objectPosition: 'bottom',
        width: '100%'
    },
})
const LogoimgExisting = styled('img')({
    '@media (max-width: 600px)': {
        left: 'unset !important',
        top: '250px !important'
    },
    '@media (min-width: 600px) and (max-width: 992px)': {
        left: 'unset !important',
        top: '250px !important'
    },
})
const SubTypograpgyExisting = styled(Typography)({
    fontSize: '28px',
    ineHeight: '42px',
    marginTop: 0,
    flexWrap: "wrap",
    '@media (max-width: 600px)': {
        fontSize: '18px',
        lineHeight: '24px',
        flexWrap: "nowrap",
        marginTop: 30,
        padding:5
    },
})
const SubInTypograpgyExisting = styled(Typography)({
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    flexWrap: "wrap",
    '@media (max-width: 600px)': {
        fontSize: '12px',
        flexWrap: "wrap",
        padding:5
    },

})
const GridImage = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    position:'fixed',
    height: '100%',
    left:0,
    width:'50%',
    '@media (min-width: 600px) and (max-width: 960px)': {
        width: '100%',
        height: '500px ',
        marginBottom:'20px',
        position:'relative',
    },
  
    '@media (max-width: 600px)': {
      marginBottom:'20px',
      height: '400px ',
      position:'relative',
      width:'100%'
    },
  })
  
// Customizable Area End

import SignuploginController, {
    Props
} from "./SignuploginController";

const MainImg = styled('img')({
    height:"100%",
    '@media (max-width: 600px)': {
        width:'100%',
        height: '400px ',
        objectFit: 'fill',
    },
    '@media (min-width: 600px) and (max-width: 960px)': {
        objectFit: 'fill',
        height: '500px ',
        width: '100%',
      },
  })
  
  const IconA2 = styled('img')({
    '@media (max-width: 600px)': {
        left: 'unset !important',
        top: '250px !important'
      },
      '@media (min-width: 600px) and (max-width: 992px)': {
        left: 'unset !important',
        top: '250px !important'
      },
  })

  const UserTitle = styled(Typography)({
    '@media (max-width: 380px)': {
        fontSize:"16px !important"
      },
  })

  const UserSubTitle = styled(Typography)({
    '@media (max-width: 380px)': {
        fontSize:"12px !important"
      },
  })
  const CustomModalExistingUser = styled(Modal)({
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    alignItems: 'center',
    "@media (max-width: 600px)": {
      '& .MuiPaper-root': {
        width: '90%',
      },
    },
    "@media (min-width: 601px) and (max-width: 1024px)": {
      '& .MuiPaper-root': {
        width: '70%',
      },
    },
  });
export default class ExistingUser extends SignuploginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { seconds, timeout } = this.state;
        const displayTime = seconds >= 10 ? seconds : `0${seconds}`;
        return (
            // Customizable Area Start

            <Grid container justifyContent="flex-end" style={{  height: "100vh" }} >
                     <GridImage item
            xs={12}
            sm={12} lg={6}
            md={6}
          >
            <IconA2 src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top:50, left: 242 }} />
            <MainImg src={background_image} style={{ width: '100%'}} />

          </GridImage>
                <Grid item xs={12}
                sm={12} lg={6}
                md={6}>
                    <Box style={{ padding: '0px 79px',display:"flex",alignItems:"center",justifyContent:"center",height:"100%" }}>
                        <Box style={{ width: '100%' }}>
                            <UserTitle style={webStyle.welcomeText2 as React.CSSProperties}>Welcome back to A2pay</UserTitle>
                            <UserSubTitle variant="body1" style={webStyle.merchantText as React.CSSProperties}>
                                if you are existing user enter your phone number
                            </UserSubTitle>
                            <Box sx={webStyle.labelBox}>
                                <label style={webStyle.labelStyleTwo as React.CSSProperties}>Phone Number</label>
                                <input
                                    data-test-id="phoneNumber"
                                    type="text"
                                    name="mobileNumber"
                                    id="phoneNumber"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                    style={{
                                        ...webStyle.inputStyle,
                                        borderColor: this.state.invalidInputs.includes('mobileNumber') ? 'red' : '#273567',
                                    }}
                                />
                                {this.state.invalidInputs.includes('mobileNumber') && (
                                    <span style={{ color: 'red', fontSize: '16px', marginTop: 16, fontFamily: 'Poppins' }}>
                                       Please enter valid mobile number
                                    </span>
                                )}
                            </Box>
                            <Button data-test-id="registor" style={webStyle.nextButton2 as React.CSSProperties} onClick={this.handleOpenModalOtp}>Next</Button>
                        </Box>
                    </Box>
                    <CustomModalExistingUser
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.isModalOpen}
                        onClose={this.handleCloseModal}
                        closeAfterTransition
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.isModalOpen}>
                            <div
                                style={webStyle.Modal1 as React.CSSProperties}
                            >
                                <img
                                    src={cross}
                                    style={webStyle.crossImage2 as React.CSSProperties}
                                    onClick={this.handleCloseModal}
                                />
                                <Typography variant="h5" style={webStyle.verificationText as React.CSSProperties}>Verification</Typography>

                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
{this.state.otp.map((digit, index) => (
  <input
    key={index}
    ref={el => this.inputRefs[index] = el}
    type="text"
    inputMode="numeric"
    pattern="\d*"
    maxLength={1}
    value={digit}
    style={webStyle.otpInput as React.CSSProperties}
    data-test-id={`txtInputotp${index}`}
    onChange={(e) => this.handleInputChange(index, e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        this.handleInputChange(index, '', true);
      }
    }}
  />
))}
                                </Box>

                                <Typography style={webStyle.cardOtp as React.CSSProperties

                                }>Enter the OTP code contained in the Email we sent you</Typography>

                                <Typography
                                    style={
                                        timeout
                                            ? { ...webStyle.otpVerificationText, color: 'red' }
                                            : webStyle.otpVerificationText
                                    }
                                >
                                   
                                    {timeout ? 'OTP verification timed out' : `OTP verification code expires in: ${displayTime}s`}
                                </Typography>
                                <Typography style={webStyle.resendOtpText2 as React.CSSProperties}>Resend OTP</Typography>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button style={{
                                        ...webStyle.submitButton2,
                                        width: '100%',
                                        opacity: this.state.otpError ? 0.6 : ""
                                    } as React.CSSProperties} 
                                    
                                    disabled={this.state.otpError} data-test-id="testsubmit" onClick={this.handleOpenModalNewUser}>Submit</Button>
                                </div>
                            </div>
                        </Fade>
                    </CustomModalExistingUser>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.tickModel}
                        data-test-id="testmodal"
                        onClose={this.handleCloseModalVerified}
                        closeAfterTransition
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.tickModel}>
                            <Box
                                sx={webStyle.greentickModal}
                            >
                                <img
                                    src={greentick}
                                    style={{
                                        height: '119px',
                                        width: '119px',
                                        marginBottom: '20px'
                                    }}

                                />
                                <Typography variant="h5" style={webStyle.userVerifiedText as React.CSSProperties}>User Verified</Typography>
                            </Box>
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    welcomeText2: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeight: '600',
        fontSize: '28px',
        lineHeight: '42px'
    },
    merchantText: {
        fontSize: '14px',
        color: '#848FAC',
        fontWeight: '400',
        fontFamily: 'Poppins',
        lineHeight: '21px',
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '14px'
    },
    labelStyleTwo: {
        color: "#273567",
        fontFamily: 'Poppins',
        lineHeight: " 24px",
        letterSpacing: "0em",
        marginTop: "16px",
        fontSize: "16px",
        fontWeight: "400",

    },
    inputStyleTwo: {
        width: '100%',
        color: '#273567',
        borderRadius: '8px',
        border: '1px solid #273567',
        height: '50px',
        fonstSize: '14px',
        fontWeight: '500',
        fontFamily: 'Poppins',
        flexShrink: 0,
        paddingLeft: 15,
        boxShadow: '0px 0px 10px 0px rgba(0, 151, 203, 0.20)'
    },
    nextButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '42px',
        color: '#fff',
        width: '100%',
        height: '50px',
        borderRadius: '8px',
        justifyContent: 'center',
        alightItems: 'center',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: 'Poppins',

    },
    Modal1: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        height: 'auto',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)'
    },
    crossImage2: {
        cursor: 'pointer',
        right: '5px',
        position: 'absolute',
        marginTop: -10,
        marginRight: 10
    },
    verificationText: {
        display: 'flex', alignContent: 'ceneter', justifyContent: 'center', color: '#000000',
        fontWeight: 500,
        fontFamily: " Poppins",
        fontSize: "40px",
        fontStyle: "normal",
        textAlign: "center",
        lineHeight: "140%"
    },
    otpInput: {
        textAlign: "center",
        letterSpacing: "10px",
        fontSize: "18px",
        width: "2em",
        margin: "10px",
        border: "none",
        borderBottom: "1px solid #273567",
        outline: "none",
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#273567',
    },
    cardOtp: {
        whiteSpace: 'nowrap',
        color: '#848FAC',
        textAlign: "center",
        fontSize: "19px",
        fontFamily: " Poppins",
        lineHeight: "140%",
        fontStyle: "normal",
        fontWeight: 400
    },
    otpVerificationText: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: " Poppins",
        color: '#848FAC',
        alignContent: 'ceneter',
        textAlign: "center",
        fontWeight: 400,
        lineHeight: "140%",
        marginTop: '10',
        fontSize: "19px",
        fontStyle: "normal",
    } as React.CSSProperties,
    resendOtpText2: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        color: '#273567',
        textAlign: "center",
        marginTop: '10',
        fontFamily: " Poppins",
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%"
    },
    submitButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '16px',
        borderRadius: '8px',
        color: '#fff',
        width: '100%',
        textTransform: 'capitalize',
        height: '50px',
        fontWeight: 400,
        fontFamily:'Poppins'
    },
    userVerifiedText: {
        fontSize: "33px",
        lineHeight: "140%",
        display: 'flex',
        fontWeight: 400,
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: "center",
        color: '#030F2D',
        fontFamily: " Poppins",
        fontStyle: "normal"
    },
    greentickModal: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '542px',
        maxWidth: '400px',
        margin: 'auto',
        marginTop: '100px',
        height: '60%',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    inputStyle2: {
        width: '100%',
        color: '#273567',
        top: '26px',
        border: '1px solid',
        borderRadius: '8px',
        padding: 15,
        height: '50px',
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
    },
    inputStyle: {
        width: '100%',
        color: '#273567',
        top: '26px',
        border: '1px solid',
        borderRadius: '8px',
        padding: 15,
        height: '50px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500
      },
      firstBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        padding: '0px 30px'
    },
    welcomeText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize: '28px',
        // lineHeight: '42px'
    },
};
// Customizable Area End
