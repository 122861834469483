import React from "react";


// Customizable Area Start

import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  styled,
  Paper,
  TableContainer,
  Table,
  Input,
} from "@material-ui/core";

import SideNavbar from "../../../components/src/SideBar.web";

import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { leftArrow, search, } from "./assets";

const ShopperBoxLanding = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const ShopperWallet = styled(Typography)({
  fontFamily: 'Poppins',
  color: '#000',
  fontWeight: 600,
  fontSize: '20px',
  //padding: '10px 16px',
  // width: "30%",
  '@media(max-width: 600px)': {
    padding: '0px'
  }
})


const ShopperBackMain = styled(Box)({
  display: 'flex',
  margin: "10px 0"
})
const SearchBox = styled(Box)({
  // '@media(min-width: 375px)': {
  //   width: '80%',
  //   maxWidth: '300px',
  // }
})


const ShopperBox = styled(Box)({
  display: 'flex',
  flexWrap: "wrap",
  gap: '8px',
  backgroundColor: "#FFFFFF",
  padding: "17px 24px 17px",
  borderRadius: "4px",
  justifyContent: 'space-between',
  margin: "20px 0",
  maxWidth: "100%",
  alignItems: 'center'

})

const ShopperCustomButton2 = styled(Button)({
  alignItems: 'center',
  margin: '20px 0px ',
  border: '1px solid #C12030',
  borderRadius: '8px',
  maxWidth: '159px',
  width: '100%',
  height: '46px',
  // '@media(max-width: 600px)': {
  //   maxWidth: '130px'
  // }
})






interface Data {
  customerID: string;
  name: string;
  accountBalance: string;
  phoneNo: string
}

interface Column {
  id:
  | "customerID"
  | "name"
  | "phoneNo"
  | "accountBalance"
  label: string;
  minWidth?: number;
  align?: "right";
}
const providercolumns: Column[] = [
  { id: "customerID", label: "Customer ID" },
  { id: "name", label: "Name" },
  { id: "phoneNo", label: "Phone No" },
  { id: "accountBalance", label: "Account Balance" },
];
const providerrows: Data[] = [
  {
    customerID: '125CCC4444',
    accountBalance: "-R100",
    name: "Aakshit Shrivastav",
    phoneNo: '+91 97854*****'
  },


];
interface Data1 {
  dateAndTime: any;
  name: string;
  accountBalance: string;
  availableBalance: string
}
interface Column1 {
  id:
  | "dateAndTime"
  | "name"
  | "accountBalance"
  | "availableBalance"
  label: string;
  minWidth?: number;
  align?: "right";
}

const trasactioncolumns1: Column1[] = [
  { id: "dateAndTime", label: "Date & Time" },
  { id: "name", label: "" },
  { id: "accountBalance", label: "Account Balance" },
  { id: "availableBalance", label: "Available Balance" },
];
const providerrows1: Data1[] = [
  {
    dateAndTime: '12 DEC 2023  |  10:50 PM',
    name: " ",
    accountBalance: "-R100",
    availableBalance: 'R900'
  },
  {
    dateAndTime: '12 DEC 2023  |  10:50 PM',
    name: " ",
    accountBalance: "+R100",
    availableBalance: 'R900'
  },
  {
    dateAndTime: '12 DEC 2023  |  10:50 PM',
    name: " ",
    accountBalance: "-R100",
    availableBalance: 'R900'
  },

];



// Customizable Area End

import CfshoppersaccountController, {
  Props,
  configJSON,
} from "./CfshoppersaccountController";

export default class ShopperAccountDetails extends CfshoppersaccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    const accountId = this.props.navigation.getParam('accountId')
    this.getShopperAccountDetail(accountId)
  }
  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;

    return (
      // Customizable Area Start
      <Box style={webStyle.userSettingContainer} data-test-id={'handleBodyClickOnScreen'} onClick={(e) => this.handleBodyClickOnScreen(e)}>
        <SideNavbar />
        <ShopperBoxLanding style={webStyle.providerSecondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>
          <Box style={{ padding: "0px 40px 40px 40px" }}>
            <ShopperBackMain >
              <img src={leftArrow} onClick={this.handleBackClick} style={webStyle.backArrow} />
              <Typography onClick={this.handleBackClick} style={webStyle.backText}>Back</Typography>
            </ShopperBackMain>
            <ShopperBox >
              <ShopperWallet>
                Shopper Account Details
              </ShopperWallet>



            </ShopperBox>
            <Paper style={{ padding: "0px 25px 5px 20px" }}>
  <TableContainer style={{ maxHeight: 500 }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {providercolumns.map((column) => (
            <TableCell key={column.id} align={column.align} style={webStyle.columnCell}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow hover role="checkbox" tabIndex={-1}>
          {/* Safely retrieve and parse selectedCustomer from localStorage */}
          {providercolumns.map((column) => {
            const selectedCustomer = localStorage.getItem('selectedCustomer');
            const customerData = selectedCustomer ? JSON.parse(selectedCustomer) : {}; // Handle null case
            const value = customerData[column.id] || ''; // Safely access the values, fallback to empty string
            return (
              <TableCell key={column.id} align={column.align} style={webStyle.columnCellTwo}>
                {typeof value === 'string' ? (
                                  value.split('R').map((part: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {index === 0 ? (
                                        <span>
                                          {value[1] === 'R' && value[0] === '+' || value[0] === '-' ? '' : part}
                                        </span>
                                      ) : (
                                        <React.Fragment>
                                          <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>
                                            {value[0]}
                                          </span>

                                          <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>
                                            R
                                          </span>

                                          <span style={{ color: value[0] === '+' ? 'green' : 'red' }}>{part}</span>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <span>{value}</span>
                                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
</Paper>


            <Paper style={{ padding: "0px 25px 5px 20px", marginTop: '30px' }}>
              <TableContainer style={{ maxHeight: 500, paddingBottom: '70px' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 600, fontFamily: 'Poppins', padding: '30px 0px 30px 5px' }}> Transaction Details</Typography>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {trasactioncolumns1.map((column: any) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={webStyle.columnCell}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {providerrows1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => {

                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          {trasactioncolumns1.map((column) => {
                            console.log("column====>>", column)
                            const value = row[column.id];

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={webStyle.columnCellTwo}>


                                {typeof value === 'string' ? (
                                  value.split('R').map((part: any, index: any) => (

                                    <React.Fragment key={index}>

                                      {index === 0 ? (
                                        <span>{value[1] === 'R' && value[0] === '+' || value[0] === '-' ? '' : part}</span>

                                      )
                                        : (
                                          <React.Fragment>
                                            {column.id === 'accountBalance' ?
                                              <>
                                                <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>{value[0]}</span>
                                                <span style={{ fontWeight: 700, fontSize: '16px', color: value[0] === '+' ? 'green' : 'red' }}>R</span>
                                                <span style={{ color: value[0] === '+' ? 'green' : 'red' }}>{part}</span>
                                              </>
                                              :
                                              <>
                                                <span style={{ fontWeight: 700, fontSize: '16px', color: 'black' }}>R</span>
                                                <span style={{ color: 'black' }}>{part}</span>
                                              </>
                                            }
                                          </React.Fragment>
                                        )}

                                    </React.Fragment>
                                  ))

                                ) :
                                  (

                                    <span>{value}</span>
                                  )}


                              </TableCell>


                            );

                          })}
                        </TableRow>

                      );
                    })}

                  </TableBody>

                </Table>

              </TableContainer>

            </Paper>
          </Box>

        </ShopperBoxLanding>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  userSettingContainer: {
    backgroundColor: "#E2E8F0"
  },
  providerSecondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,

  columnCellTwo: {
    color: "#475569",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  backArrow: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  searchIcon: {
    width: '11.37px',
    height: '11.37px',
    cursor: 'pointer',
  },
  backText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '16px',
    marginLeft: '8px',
    fontWeight: 500,
    cursor: 'pointer'
  },
  columnCell: {
    fontSize: '14px',
    fontWeight: 700, width: '100px',
    color: '#475569',
    fontFamily: 'Poppins'
  } as React.CSSProperties,
  exportText: {
    fontFamily: 'Poopins',
    fontSixe: '16px',
    fontWeigth: '400',
    color: '#C12030',
    textAlign: 'right',
    textTransform: 'capitalize',
    //marginLeft: '10px'
  } as React.CSSProperties,

}
// Customizable Area End
