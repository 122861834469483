export const leftArrow  = require("../assets/leftArrow.png");
export const downIcon = require("../assets/downIcon.png");
export const printIcon = require("../assets/printIcon.png");
export const checkBox = require("../assets/checkbox.png");
export const check = require("../assets/check.png");
export const selectedCheckbox = require("../assets/selected_checkbox.png");
export const BackIcn = require("../assets/BackIcn.png");
export const AttachMent = require("../assets/attachment.png");
export const CloseIcn = require("../assets/CloseIcn.png");
export const FileIcn = require("../assets/FileIcn.png");
export const africanBank = require("../assets/affrican.png");
export const standardBank = require("../assets/standard.png");
export const investEC = require("../assets/investec.png");
export const nedBank = require("../assets/ned.png");
export const bidvestBank = require("../assets/bidvest.png");

export const absaBank = require("../assets/absaBank.png");
export const CapitecBank = require("../assets/CapitecBank.png");
export const discoveryBank = require("../assets/discoveryBank.png");
export const FNBBank = require("../assets/FNBBank.png");
export const TymeBank = require("../assets/TymeBank.png");
export const RightIcn = require("../assets/greentick.png");

