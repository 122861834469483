import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast"
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { boolean } from "yup";
const successNotify = () => toast.success(`Payment of R${localStorage.getItem('amount')} Rahul Jain Successful`, {
  style: {
    maxWidth: 550,
    height: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
  },

})
const paymenteErorNotify = () => toast.error('something went wrong', {
  style: {
    maxWidth: 550,
    height: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
})

const amountErrorNotify = () => toast.error('Enter The amount', {
  style: {
    maxWidth: 550,
    height: '24px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
})


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selected: any;
  selectedTabBank: any;
  selectStore: any;
  storeError: any;
  amount: any;
  walletId: any;
  bankModal: any;
  selectedValue: any;
  flag: boolean;
  showSuccess: boolean;
  getAmount: any;
  selectedStore: any;
  walletIdError: boolean,
  paymentMode: any
  disableButton: boolean;
  showWallet: boolean;
  selectedPaymentMethod: any;
  bankSelect: any;
  selectStorePaybill: any;
  selectSupplierPaybill: any;
  invoicenumber: any;
  selectedBox: any;
  selectpay: any;
  buxId: any;
  buxIdError: boolean;
  storeValidate: boolean;
  setImage: boolean;
  setCommissionImage: boolean;
  setPayBillImage: boolean;
  moneyField: boolean;
  invoiceError: boolean;
  commisionAmount:any;
  storeAmount:any;
  commisionError:any;
  finalCommisionValue:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfwallet53Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiCallMessageGetCommision: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selected: 'redwallet',
      selectedTabBank: 'walletTopUp',
      selectStore: '',
      storeError: false,
      amount: "",
      bankModal: false,
      selectedValue: '',
      flag: true,
      showSuccess: false,
      getAmount: '',
      selectedStore: '',
      disableButton: false,
      walletId: '',
      walletIdError: false,
      paymentMode: '',
      showWallet: false,
      selectedPaymentMethod: "creditCard",
      bankSelect: 0,
      selectStorePaybill: '',
      selectSupplierPaybill: '',
      invoicenumber: '',
      selectedBox: '',
      selectpay: '',
      buxId: "",
      buxIdError: false,
      storeValidate: false,
      setImage: false,
      setCommissionImage: false,
      setPayBillImage: false,
      moneyField: false,
      invoiceError: false,
      commisionAmount:"",
      storeAmount:'0',
      commisionError:"",
      finalCommisionValue:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      this.handleRestAPIResponseMessage(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleAddBuxGoBack = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletChooseBank');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  ProceedToPayBuxWallet = () => {
if (this.state.amount <= 0) {
      toast.error("Please enter the amount", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    }
    else {

      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'BuxPaymentFirstPages');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
      setStorageData("selectStore", this.state.selectStore);
      setStorageData("amount", this.state.amount);
    }
  }

  handleBuxNext = async () => {
    if (this.state.buxId) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'BuxWallet');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    } else {
      this.setState({
        buxIdError: true
      }); 
  
      
      await removeStorageData('amount')
      await removeStorageData('selectStore')
    }
    setStorageData("buxId", this.state.buxId);
  }
  handleTranferBuxWallet = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AddBux');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showSuccess", true);
    await removeStorageData('selectStore')
    await removeStorageData('buxId')
}


  handleClick = (selectedOption: any) => {
    this.setState({ selected: selectedOption }, () => {
      // Navigate to the desired screen based on the selected option
      if (selectedOption === 'redwallet') {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletChooseBank');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
        
      } else if (selectedOption === 'PayBillToStore') {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PayBillToStore');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
      }
      else if (selectedOption === 'redcommision') {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Commision');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
      }
      else if (selectedOption === 'redcommision') {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Commision');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
      }
    });
  };
  handleBackFunction = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletTransact');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  goBack = () => {
    this.props.navigation.navigate('WalletTransact'); // This will navigate back to the previous screen
  };

  handleSelect = (option: any) => {
    this.setState({ selectedTabBank: option }, () => {
      if (option === 'walletTopUp') {
        this.props.navigation.navigate('WalletTopUp');
      }
      else if (option === 'transfer') {
        this.props.navigation.navigate('TransferToOtherWallet')
      }
      else if (option === 'bux') {
        this.props.navigation.navigate('AddBux')
      }
    })
  }

  handleChange = (event: any) => {
    const selectedStore = event.target.value;
    let storeAmount = 0;
    switch (selectedStore) {
      case 'Kahndal Saree Store':
          storeAmount = 1000;
          break;
      case 'Pretty Saree Store':
          storeAmount = 2000;
          break;
      case 'Linen Saree Shop':
          storeAmount = 3000;
          break;
      default:
          storeAmount = 0;
  }
  this.setState({ 
    selectStore: selectedStore, 
    storeAmount, 
    storeValidate: false, 
    storeError: false 
});
  };
  handleBankOpenModal = () => {
    this.setState({ bankModal: true });
  };

  handleBankCloseModal = () => {
    this.setState({ bankModal: false, bankSelect: 0 });
  };
  handleSubmitDetail = () => {
    this.props.navigation.navigate('WalletTopUp')
    setTimeout(() => {
      toast.success(`Payment of R${localStorage.getItem('amount')} to Rahul Jain is Successful`, {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      });
    }, 1000);

  };
  ProceedToPayWallet = () => {
    if (this.state.selectStore === '') {
      this.setState({ storeValidate: true });
    } else if (this.state.amount <= 0) {
      toast.error('Enter The amount', {
        style: {
          maxWidth: 550,
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      })
    }
    else {

      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PaymentFirstPage');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
      setStorageData("selectStore", this.state.selectStore);
      setStorageData("amount", this.state.amount);
    }
  }
  handleRadio(event: any) {
    this.setState({
      selectedValue: event.target.value
    });
  }


  handlePayWallet = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletTopUp');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showSuccess", true);
    await removeStorageData('amount')
    await removeStorageData('selectStore')

  }

  handleError = async () => {
    paymenteErorNotify()
    await removeStorageData('showSuccess')
  }
  // Customizable Area 

  handleSetEndAmount = (event: any) => {
    const inputAmount = (event?.target?.value || '').toString();
  
    if (/^\d*$/.test(inputAmount)) {
      const amountNum = parseInt(inputAmount, 10);
  
      if (amountNum > this.state.storeAmount) {
        this.setState({ commisionError: 'Entered amount exceeds your commission amount!' });
        return;
      }
  
      this.setState({
        commisionError: '',
        amount: inputAmount,
        commisionAmount: inputAmount,
        finalCommisionValue: this.state.storeAmount - amountNum,
      }, () => this.handleAmount(inputAmount));
    }
  };
  

  handleSetWalletId = (event: any) => {
    const inputAlternateNumber = (event?.target?.value ?? '').toString();

    if (/^\d{0,10}$/.test(inputAlternateNumber)) {
      this.setState({
        walletId: inputAlternateNumber, // Update the value as you type
        walletIdError: inputAlternateNumber.length > 0 && inputAlternateNumber.length < 5, // Error if less than 5 characters
      });
    }
  };
  
  
  ProceedToPay = () => {
    if (this.state.selectStore === '') {
      this.setState({ storeError: true })
      toast.error('Please Select Store', {
        style: {
          maxWidth: 550,
          height: '24px',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      }
      )
    }else if (this.state.commisionError){

    }
    else if (this.state.amount <= 0) {
      amountErrorNotify()
    }
    else {
      if (this.state.amount == 10000) {
        setStorageData("amount", 10000);
      }
      this.setState({ storeError: false, disableButton: false })
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CommisionSecond');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
      setStorageData("selectStore", this.state.selectStore);
    }
  }
  handlePay = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Commision');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showSuccess", true);
    await removeStorageData('selectStore')
  }
  handleTranferWallet = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWalletTwo');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showSuccess", true);
    await removeStorageData('amount')
    await removeStorageData('selectStore')
    //  this.setState({bankSelect:0})

  }
  handleBankDetailsModel = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWallet');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showTransferToOtherWalletSuccess", true);

  }
  handleTransferToOtherWallet = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWallet');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)

  }

  handleChangesTranferWallet = async () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletTopUp');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    setStorageData("showSuccess", true);
    await removeStorageData('amount')
    await removeStorageData('selectStore')

  }

  handleBack = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Commision');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleSuccess = async () => {
    successNotify()
    await removeStorageData('showSuccess');
    await removeStorageData('showTransferToOtherWalletSuccess'); 
  }
  handleAmount = async (amount: any) => {
    setStorageData("amount", amount);
    this.setState({commisionAmount:amount})
  }

  onClickHandler = (staticAmount: number) => {
    const currentAmount = parseInt(this.state.amount || "0", 10);
    const newAmount = currentAmount + staticAmount;
  
    if (newAmount > this.state.storeAmount) {
      this.setState({ commisionError: 'Entered amount exceeds your commission amount!' });
      return;
    }
  
    this.setState({
      commisionError: '',
      finalCommisionValue: this.state.storeAmount - newAmount,
      amount: newAmount.toString(),
    }, () => this.handleAmount(newAmount.toString()));
  };
  
  

  handleInvoiceBack = () => {
    this.props.navigation.navigate('PayBillToStore')
  }

  handlePaymentModeBack = () => {
    this.props.navigation.navigate('InvoiceDetail')
  }

  handleEFTPaymentModeBack = () => {
    this.props.navigation.navigate('PaymentMode')
  }
  handleWallet = () => {
    this.setState(prevState => ({
      showWallet: !prevState.showWallet
    }));
  }
  handleSelectPaymentMethod = (event: any) => {
    this.setState({ selectedPaymentMethod: event?.target.value });
  };
  handleWalletToast = () => {
    toast.error("Your Wallet balance is low, please Top-up", {
      style: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "Poppins",
        maxWidth: "100%",
      },
    })
  }

  handlerbank = (value: any) => {
    this.setState({ bankSelect: value })
  }

  handlePaymentDone = () => {
    if (this.state.selectedPaymentMethod === "") {
      toast.error("Please select payment method", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    } 

    else if (this.state.selectedPaymentMethod === "eft") {
      toast.error("Please Select Bank", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      })
    }
  }

  ProceedToAddWalletId = () => {
    if (this.state.walletId === '') {
      this.setState({ walletIdError: true })
    }
    else if(this.state.walletId.length < 1){
      this.setState({ walletIdError: true })
    }
    else {
      this.setState({ storeError: false, disableButton: false, walletIdError: false })
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWalletTwo');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }

  }
  ProceedToAddWalletMoney = () => {
     if (this.state.amount <= 0) {
      amountErrorNotify()
    }
    else {
      if (this.state.amount == 10000) {
        setStorageData("amount", 10000);
      }
      this.setState({ storeError: false, disableButton: false })
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWalletThree');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
     // setStorageData("selectStore", this.state.selectStore);
      //setStorageData("amount", this.state.amount);
      setStorageData("clearAmount", true)
    }
  }
  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  handleTransferToOtherWalletThree = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWalletThree');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleWalletChooseBank = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletChooseBank');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleTransferToOtherWalletTwo = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TransferToOtherWalletTwo');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  gotoCommision = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Commision');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handlePaymentMode = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PaymentSecondPage');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleBackToWalletTransact = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletTransact');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleChangeStore = (e: any) => {
    this.setState({ selectStorePaybill: e?.target?.value })
  }
  handleChangeSupplier = (e: any) => {
    this.setState({ selectSupplierPaybill: e?.target?.value })
  }

  handleInvoice = (e: any) => {
    const inputValue = e?.target?.value;
    const alphanumericWithNumberAndMinLengthRegex = /^(?=.*[0-9])[a-zA-Z0-9]{5,}$/;
  
    if (alphanumericWithNumberAndMinLengthRegex.test(inputValue)) {
      this.setState({
        invoicenumber: inputValue,
        invoiceError: false,
      });
    } else {
      this.setState({
        invoiceError: true,
      });
    }
  };
  
  
  

  handlepayBill = () => {
    if (this.state.selectStorePaybill === '') {
      toast.error('Select the store', {
        style: {
          maxWidth: 550,
          height: '24px',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      }
      )
    } else if (this.state.selectSupplierPaybill === '') {
      toast.error("Select the supplier", {
        style: {
          maxWidth: 550,
          height: '24px',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      })
    } else if (this.state.invoicenumber === '') {
      // toast.error("Enter Invoice number")
      this.setState({ invoiceError: true })
    } else {
      this.props.navigation.navigate('InvoiceDetail');
    }
  }

  handleSuccessPayment = () => {
    this.setState({ bankModal: false });
    this.props.navigation.navigate('PayBillToStore');

    setTimeout(() => {
      toast.success("Payment of R1000 Rahul Jain Successful", {
        style: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
          maxWidth: "100%",
        },
      });
    }, 100);
  }

  handleBoxClick = async (boxId: any) => {
    if (boxId) {
      this.handleBankOpenModal()
    }
    this.setState({ selectedBox: boxId })
  }

  chandleRadioChange = (event: any) => {
    if (event.target.checked) {
      this.setState({ selectpay: event.target.value })
    } else {
      this.setState({ selectpay: null })
    }
  };

  handleBuxIdInput = (event: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    if ('key' in event) {
      const isNumber = /^\d$/.test(event.key);
      const isBackspaceOrDelete = event.key === 'Backspace' || event.key === 'Delete';
      
      if (!isNumber && !isBackspaceOrDelete) {
        event.preventDefault();
        return;
      }
      
      if (this.state.buxId.length >= 10 && !isBackspaceOrDelete) {
        event.preventDefault();
        return;
      }
    } else {

      const value = event.target.value;
      if (/^\d{0,10}$/.test(value)) {
        this.setState({
          buxId: value,
          buxIdError: value.length > 0 && value.length < 5, // Show error if less than 5 characters
        });
      }
    }
  }
  
  
  handleCancel = () => {
    this.props.navigation.navigate('PaymentFirstPage');
  }
  handleWalletSubmit = () => {
    if (this.state.selectedPaymentMethod == "") {
      toast.error("Please Select Payment Method", {
        style: {
          maxWidth: 550,
          height: '24px',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      })
    } else if (this.state.selectedPaymentMethod == "eft" && this.state.bankModal === false) {
      toast.error("Please Select Bank", {
        style: {
          maxWidth: 550,
          height: '24px',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: "Poppins",
        },
      })
    }
  }
  handleChangeImage = () => {
    // this.setState({ setImage: true})
    this.setState(prevState => ({
      setImage: !prevState.setImage
    }));
  }
  handleChangeImageCommission = () => {
    this.setState(prevState => ({
      setCommissionImage: !prevState.setCommissionImage
    }));
  }
  handlePayBillChangeImage = () => {
    this.setState(prevState => ({
      setPayBillImage: !prevState.setPayBillImage
    }));
  }

  handleNavtoWallet = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'WalletTransact');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleBackInvoice = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'InvoiceDetail');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleGoBackBux = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'BuxWallet');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }


  handleGoBackBuxSecond = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'BuxPaymentFirstPages');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleGoBackBuxWallet = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AddBux');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleGoBackPaymode = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'PaymentFirstPage');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
 
   //api for sending request
   sendApiRequest = (endpoint:string, data:any,METHOD:string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallMessageGetCommision = requestMessage.messageId;
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      METHOD
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  
//handle the api response
handleRestAPIResponseMessage(message: Message) {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (apiRequestCallId && responseJson) {
    switch (apiRequestCallId) {
      case this.apiCallMessageGetCommision:
        this.handleMessageGetCommisionSend(responseJson)
        break;
      default:
        break;
    }
  }
}

handleMessageGetCommisionSend(responseJson:any){
  if (responseJson != null && responseJson.error === undefined) {
    return
  } else {
    this.parseApiErrorResponse(responseJson);
  }
}

  // add money to wallet API call
  MoneyToWallet = () => {
    this.ProceedToPayWallet();
    const data = {
      Store: this.state.selectStore,
      WalletBalance: "1000",
      AddedMoney: this.state.amount
    };
    return this.sendApiRequest("MoneyToWallet", data,"POST");
  }

  //add bux sending ID
  AddBux = () => {
    this.handleBuxNext();
    const data = {
      BuxID: this.state.buxId,
    };
    return this.sendApiRequest("AddBux", data,"POST");
  }
  //Add money to bux
  AddMoneyBux = () => {
    this.ProceedToPayBuxWallet();
    const data = {
      BuxID: this.state.buxId,
      AddedMoney:this.state.amount
    };
    return this.sendApiRequest("AddMoneyToBux", data,"POST");
  }

    //Add money via wallet id
    sendWalletID = () => {
      this.ProceedToAddWalletId();
      const data = {
        walletID: this.state.walletId,
      };
      return this.sendApiRequest("Sendwalletid", data,"POST");
    }
    //send store and money
    sendstore = () => {
      this.ProceedToAddWalletMoney();
      const data = {
        Storename: "Kahndal Saree Store",
        Money:this.state.amount
      };
      return this.sendApiRequest("Sendwalletid", data,"POST");
    }

    //commision add
    CommisionWallet = () => {
      const data = {
        Storename: this.state.selectStore,
        Money:this.state.amount,
        finalCommisionValue:this.state.storeAmount - this.state.amount
      };
      this.ProceedToPay();
      
      // this.setState({})
      return this.sendApiRequest("CommisionWallet", data,"POST");
    }

    //paybill to store
    BillToStore = () => {
      this.handlepayBill();
      const data = {
        Store: this.state.selectStorePaybill,
        Supplier:this.state.selectSupplierPaybill,
        Invoice:this.state.invoicenumber
      };
      return this.sendApiRequest("CommisionWallet", data,"POST");
    }

  // Customizable Area End
}
