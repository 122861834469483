import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    Modal,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"
const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "624px",
    height: '428px',
    width: '100%',
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '40px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto'
    }
})

const ModalBoxForgot = styled(Box)({
    height: '360px',
    width: '100%',
    maxWidth: '542px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
        fontSize:"14px"
    }
})

const ModalText = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize:"20px !important"
    }
})

const RightImg = styled("img")({
    '@media (max-width: 600px)': {
        height:"90px !important",
        width:"90px !important"
    }
})

import { RightIcn, leftArrow, } from "./assets";
const CustomTypography = styled(Typography)({

})
// Customizable Area End
import CfshoppersaccountController, {
    Props,
    configJSON,
} from "./CfshoppersaccountController";

export default class CreateShopperAccount extends CfshoppersaccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={webStyle.mainView}>
                    <SideNavbar />
                    <RightBoxLanding2 style={webStyle.second_container}>
                        <Box style={{ width: '100%' }}>
                            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                        </Box>
                        <Box style={webStyle.backIcon}
                            data-test-id={"back-arrow"} >
                            <img src={leftArrow}
                                style={webStyle.arrowBack} onClick={this.handleGoToshopperAccount} data-test-id={'backImageId'} />
                            <Typography style={webStyle.backText} onClick={this.handleGoToshopperAccount} data-test-id={'backTextId'}>
                                Back
                            </Typography>
                        </Box>
                        <CustomBox>
                            <Box style={webStyle.secondMainContainer}>
                                <CustomTypography style={webStyle.commisionText}>Create Shopper Account</CustomTypography>

                                <Box style={webStyle.storeBox}>
                                    <Typography style={webStyle.walletText}>
                                        Name
                                    </Typography>
                                    <input
                                        className="no-outline"
                                        type="text"
                                        name="username"
                                        placeholder="Enter the Name"
                                        onKeyPress={this.handleKeyPress} 
                                        value={this.state.name}
                                        onChange={this.handleName}
                                        style={{
                                            ...webStyle.inputStyle,
                                            border: `1px solid ${this.state.nameError ? 'red' : '#273567'}`
                                        }}
                                    >
                                    </input>

                                    {this.state.nameError && <div style={{ color: 'red', fontSize: "12px", fontWeight: 400, fontFamily: 'Poppins',marginTop:"5px" }}>Enter the name</div>}

                                    <Typography style={{ ...webStyle.walletText, marginTop: '15px' }}>
                                        Phone number
                                    </Typography>
                                    <input
                                        className="no-outline"
                                        type="text"
                                        name="username"
                                        placeholder="Add phone number"
                                        value={this.state.phoneNumber}
                                        onChange={this.handleSetPhoneNumber}
                                        style={{
                                            ...webStyle.inputStyle,
                                            border: `1px solid ${this.state.phoneNumbererror ? 'red' : '#273567'}`
                                        }}
                                    >
                                    </input>

                                    {this.state.phoneNumbererror && <div style={{ color: 'red', fontSize: "12px", fontWeight: 400, fontFamily: 'Poppins',marginTop:"5px" }}>{this.state.phoneNumbererror}</div>}

                                    <Button style={webStyle.firstButton}
                                        data-test-id={'buttonPress'}
                                        onClick={() => this.ProceedToSubmit()}>
                                        Submit
                                    </Button>
                                </Box>

                            </Box>
                        </CustomBox>

                    </RightBoxLanding2>
                    <Modal
                        open={this.state.openModal}
                        onClose={this.handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-Category"
                        style={webStyle.modelView}>
                        <ModalBoxForgot sx={webStyle.greentickModal}>
                                <RightImg src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '119px', width: '100%', height: '119px' }} />
                                <ModalText style={webStyle.userVerifiedText2 as React.CSSProperties}>The shopper's account has been successfully created.</ModalText>
                            </ModalBoxForgot>
                    </Modal>
                    <Toaster position="top-center" toastOptions={{
                        success: {
                            style: {
                                padding: '16px',
                            },
                        },
                    }} />
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainView: {
        display: "flex",
        backgroundColor: "#F6F7FA",
    },
    container: {
        margin: '60px 0px',
    },
    walletText: {
        color: "#273567",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
    },
    createdImageStyle: {
        maxWidth: '542px',
        height: '362px',
        borderRadius: '12px',
        width: '100%'

    },
    firstButton: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        marginTop: "45px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
        fontWeight: 400
    } as React.CSSProperties,
    modelViewBox: {
        maxWidth: '542px',
        height:"360px",
        width: '100%',
        padding: 30,
    } as React.CSSProperties,
    inputStyle: {
        color: "#000000",
        fontFamily: 'Poppins',
        borderColor: 'white',
        fontWeight: 400,
        fontSize: '14px',
        alignSelf: 'center',
        borderRadius: '12px',
        justifyContent: 'space-between',
        border: `1px solid #273567`,
        alignItems: 'center',
        width: '100%',
        padding: '0px 10px 0px',
        height: '50px',
        boxSizing: 'border-box'
    } as React.CSSProperties,
    storeBox: {
        flexWrap: 'wrap',
        alignSelf: 'center',
        marginTop: '30px',
    } as React.CSSProperties,
    second_container:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
            height: "700px"
        } as React.CSSProperties,
    arrowBack: {
        width: "24px",
        height: "24px"
    },
    commisionText: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '20px',
    },
    secondMainContainer: {
        margin: '40px',
    },
    modelView: {
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
    } as React.CSSProperties,
    backText: {
        color: "#0000000",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        marginLeft: "8px",
    },
    backIcon: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        margin:'10px 0 10px 40px'
    },
    text: {
        color: "#000000",
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        marginLeft: '5px'
    },
    greentickModal: {
        position:"absolute",
        top:"50%",
        left:"50%",
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2: {
        fontSize: "29px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    },
};
// Customizable Area End
