import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface CardItem {
  referenceNumber: string;
  PosStatus: string;
  walletBalance: number;
  overallCommissions: number;
  buxBalance: number;
  bonusBalance: number;
  numberTerminals: number;
  numberCardDevices: number;
  cardTransactionsMTD: string;
  cashDepositorMTD: string;
  loanStatus: string;
  outStandingLoanAmount: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  modalOpen: any;
  selectedCardDetails: CardItem | null;
  listStore:CardItem[];
  isFullStore:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      modalOpen: false,
      selectedCardDetails: null,
      isFullStore:false,
      listStore:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectProviderBtn1 = () => {
    this.props.navigation.navigate("SalesReport");
  };
  handleSelectCategoryBtn1 = () => {
    this.props.navigation.navigate("ProductSalesByCategory");
  };
  handleReplenishBtnNew = () => {
    this.props.navigation.navigate("InventoryTrend");
  };
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleCardClick = (value: CardItem | null) => {
    this.setState({ selectedCardDetails: value, modalOpen: true });
  };
  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };
  gotoCatogory = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProductSalesByCategory"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  gotoProvider = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProductSalesByProvider"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  gotoLoan = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MultipageForms");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  GoWalletHistory = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "WalletHistory");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleLoanStatusColor = (value: string) => {
    let color = "#059669";
    if (value === "Overdue") {
      color = "#f59e0b";
    }
    if (value === "Default") {
      color = "#C12030";
    }
    return color;
  };


  async componentDidMount() {
    this.getAllStores()
  }

  apiEmailLoginCallId: string = "" ;
  getAllStoresCallId:string = "";
  getAllStores = () => {
  
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllStoresCallId = requestMessage.messageId;
  
    const endPoint = "Stores"; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  handleSeeMoreStore =()=>{
    const newListStore = [...this.state.listStore,...configJSON.storeCardData]
    
    this.setState({
      listStore:newListStore,
      isFullStore:newListStore.length>14
    })
   }
  // Customizable Area End
}
