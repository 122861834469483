export const getLocation = (cbAPI:(url:string)=>void,stringVl:string)=>{
  const randomString = `&key=`
       navigator.geolocation.getCurrentPosition((position)=>{
            const { latitude, longitude } = position.coords;
          const urlApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}${randomString}${stringVl}`
          cbAPI(urlApi)
        },(error) => {
            console.error("Geolocation permission denied:", error);
            alert("We couldn't access your location. Please enable location services.");
          })
}